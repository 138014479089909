import FilialApiService from "../../../../services/api/FilialApiService";

const baseAliqOptions = [
    { label: '...', value: '' },
    { label: 'Salario', value: 'salario' },
    { label: 'Benefício', value: 'beneficio' }
];

export const getFormFields = async () => {
    const filiaisData = await (FilialApiService.getAll()).then(resp => resp.data);

    return {
        groups:[
            {
                header: '',
                fields: [
                    {
                        label: 'Filial',
                        placeholder: 'Ex: Vertigo Rio',
                        name: 'filialCod',
                        type: 'select',
                        size: 3,
                        options: [
                            { value: '0', label: '--- Todas ---' },
                            ...(filiaisData.data.map(item => ({
                                    value: item.cod,
                                    label: item.label
                                })
                            )),
                        ],
                        defaultValue: 0
                    },
                ]
            },
            {
                header: 'Valor e Aliquota de Benefício',
                fields: [
                    {
                        label: 'Valor Benefício',
                        name: 'valor',
                        type: 'currency',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Período de Aplicação Benefício',
                        name: 'periodoValor',
                        type: 'select',
                        options: [
                            { label: '...' },
                            { label: 'Diário', value: 'dia' },
                            { label: 'Mensal', value: 'mes' },
                        ],
                        size: 4,
                    },
                ]
            },
            {
                header: '',
                fields: [
                    {
                        label: 'Aliquota Benefício',
                        name: 'aliquotaBenef',
                        type: 'percentage',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Base Aliq.Benefício',
                        name: 'baseAliqBenef',
                        type: 'select',
                        options: baseAliqOptions,
                        size: 4,
                    },
                ]
            },
            {
                header: 'Descontos do Colaborador',
                fields: [
                    {
                        label: 'Valor Desconto Padrão',
                        name: 'valorDesc',
                        type: 'currency',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Aliquota Desconto Padrão',
                        name: 'aliquotaDesc',
                        type: 'percentage',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Base Aliq.Desconto',
                        name: 'baseAliqDesc',
                        type: 'select',
                        options: baseAliqOptions,
                        size: 4,
                    },
                ]
            },
            {
                header: 'Custos da Empresa',
                fields: [
                    {
                        label: 'Valor Pago Filial',
                        name: 'valorFilial',
                        type: 'currency',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Aliq. Pago Filial',
                        name: 'aliquotaFilial',
                        type: 'percentage',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Base Aliq. Pago Filial',
                        name: 'baseAliqFilial',
                        type: 'select',
                        options: baseAliqOptions,
                        size: 4,
                    },
                ]
            }
        ]
    }
}

const BeneficioValorFormInfo = {
    getFormFields
}

export default BeneficioValorFormInfo;
