import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiArrowUpRight, FiArrowDown, FiHelpCircle } from 'react-icons/fi';
import '../../../components/HelpModal/HelpModal.css';

function RelatorioListHelpModal() {
    const [show, setShow] = useState(false);
    const [isSteps, setIsSteps] = useState(1);

    const handleClose = () => {
        setIsSteps(1);
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const getStepsView = (step) => {
        switch (step) {
            case 1: return (
                <Modal.Body>
                    <p className="description-text-info">
                        Acompanhamento e controle dos projetos.
                        <br></br>
                    </p>
                </Modal.Body>
            );
            default: return ""
        }
    }

    return (
        <>
            <button onClick={() => handleShow(true)} className="button-info">
                <FiHelpCircle size={17} />
            </button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>O que é o Relatórios Delivery ?</Modal.Title>
                </Modal.Header>

                {getStepsView(isSteps)}

                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { RelatorioListHelpModal }
