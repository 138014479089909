import React from 'react'
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import UtilService from '../../services/util';
import TopActions from './../TopActions';

import './PageContainer.css';

function PageContainer({ title, actions, children, infoButton }) {

    if (title) UtilService.setTitle(title);

    return (
        <Container fluid className="page-container">
            <div className="page-head">
                <div className="page-head-info">
                    <h3>{ title }</h3>

                    {infoButton && infoButton}
                </div>
                { (actions.length > 0) &&
                    <TopActions actions={actions} />
                }
            </div>
            <hr/>
            <div className="page-content">
                { children }
            </div>
        </Container>
    )
}

// DEFAULT PROPS
PageContainer.defaultProps = {
    title: UtilService.getTitle(),
    actions: [],
    children: [],
    infoButton: <></>,
}

// PROPTYPES
PageContainer.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.array,
    children: PropTypes.any,
    infoButton: PropTypes.element,
}

export default PageContainer;

