// import { Nav } from 'react-bootstrap';
import NavigationData from './NavigationData';

/** MOCK itens menu lateral */
// export const sideNavItems = [
//     <Nav.Link href='#'>Item 1</Nav.Link>,
// ]

/** Service para dados de navigation */
const NavigationService = {
    /** Retorna os dados para menu de navegação */
    getNavigationData: (resource_access) => {
        const menu = [];
        
        for (const iterator of resource_access) {
            const item = NavigationData.navigation.find(item => item.key === iterator);

            menu.push(item);
        }

        return menu.sort((a, b) => a.order - b.order);
    },

    /** Retorna os items de subgrupo de navegação */
    getSubNavData: (navKey, resource_access) => {
        return NavigationData.subgroups[navKey] || [];

        // const submenu = [];

        // if (!NavigationData.subgroups[navKey]) return submenu;

        // for (const iterator of resource_access) {
        //     const item = NavigationData.subgroups[navKey].find(item => item.key === iterator);

        //     if (item) submenu.push(item);
        // }

        // return submenu.sort((a, b) => a.order - b.order);
    }
}

export default NavigationService;
