import React, { useEffect, useState } from "react";
import ProjetosApiService from "../../../../services/api/ProjetosApiService";
import { Form, Button } from "react-bootstrap";
import Select from 'react-select';
import MonthYearPicker from "../../../../components/MonthYearPicker";
import 'react-datepicker/dist/react-datepicker.css';
import ClienteoApiService from "../../../../services/api/CadastroClientesService";
import makeAnimated from 'react-select/animated';
import useAlertNotification from '../../../../context/AlertNotificationContext';

const animatedComponents = makeAnimated();

const FiltroHistoricoCusto = ({clientes, projetos, handleDadosProjetosHistorico}) => {
    //States
    const [clienteSelecionado, setClienteSelecionado] = useState("");
    const [projetosFiltrados, setProjetosFiltrados] = useState([]);
    const [projetosSelecionados, setProjetosSelecionados] = useState([]);
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [selectedOptionRadio, setSelectedOptionRadio] = useState('default');
    const [tipoCusto, setTipoCusto] = useState("");
    const [categories, setCategories] = useState([]);
    const [categoriesSelect, setCategoriesSelect] = useState("");
    const [projetoFiltradoCategoria, setProjetoFiltradoCategoria] = useState(projetos);
    const [projetoFiltradoCategoriaCliente, setProjetoFiltradoCategoriaCliente] = useState([]);
    const [categoriasLabel, setCategoriasLabel] = useState([]);
    const [todasCategorias, setTodasCategorias] = useState(true);
    const [botaoAdicionarTodos, setBotaoAdicionarTodos] = useState(true);
    const [categoriasChave, setCategoriasChave] = useState({});
    const [projetosCliente, setProjetosCliente] = useState([])
    const alert = useAlertNotification();

    //Effects
    useEffect(async () => {
        
        if (clienteSelecionado && clienteSelecionado !== '--Selecione o cliente--') {
            const cliente = clientes.find(c => c.nome === clienteSelecionado);
            try{
                const resp = await ClienteoApiService.getClientProjectsById(cliente.id);
                const projetosCliente = resp.data.projetos;

                const projetosFiltrados = projetosCliente.map(item => ({
                    key: item.chave,
                    name: item.nome || item.chave,
                    category: categoriasChave[item.chave]
                }));
                setProjetosFiltrados(projetosFiltrados);
                setProjetoFiltradoCategoriaCliente(projetosFiltrados);
            }
            catch(err){
                console.error("Erro em getClientProjectsById: ", err)
            }
            
        } else {
            setProjetosFiltrados([]);
            setProjetosSelecionados([]);
            setProjetoFiltradoCategoriaCliente([]);
        }
    }, [clienteSelecionado, clientes]);

    useEffect(() => {
        if(categoriesSelect) {
            setTodasCategorias(false);
        } else {
            setTodasCategorias(true);
        }
    }, [categoriesSelect])

    useEffect( async () => {
        adicionarCategoria(projetos)
        try {
            const respCategorias = await ProjetosApiService.getCategories();
            setCategories(respCategorias.data.data)
        } catch (error) {
            console.error("Erro ao buscar categorias")
        }
    }, []);

    useEffect( async () => {
        if(categoriesSelect != "") {
            const projetoFiltrado = projetos.filter(projeto =>  projeto.category == categoriesSelect)
            setProjetoFiltradoCategoria(projetoFiltrado);
        } else {
            setProjetoFiltradoCategoria(projetos)
        }
    }, [categoriesSelect])

    //Funções handle
    const handleClienteChange = (e) => {
        setProjetosSelecionados("")
        const selectedValue = e.target.value;
        if (selectedValue === '--Selecione o cliente--') {
            setClienteSelecionado('');
        } else {
            setClienteSelecionado(selectedValue);
        }
    };

    const handleProjetosClienteChange = (selectedOptions) => {
        if(selectedOptions.some(option => option.categoria === '')){
            setTodasCategorias(true)
        } else {
            setTodasCategorias(false)
        }

        if (selectedOptions.some(option => option.value === 'ALL')) {
            setTodasCategorias(true);
            setProjetosSelecionados(optionsProjetosClientes.filter(option => option.value !== 'ALL'));
        } else {
            setProjetosSelecionados(selectedOptions);
            
        }
    };

    const handleProjetosChange = (selectedOptions) => {
        if(selectedOptions.some(option => option.value === 'TODOS')){
            setTodasCategorias(true)
        } 

        if (selectedOptions.some(option => option.value === 'ALL')) {
            setTodasCategorias(true);
            setProjetosSelecionados(optionsProjetos.filter(option => option.value !== 'ALL'));
        } else {
            setProjetosSelecionados(selectedOptions);
        }
    };

    const handleRadioChange = (event) => {
        setSelectedOptionRadio(event.target.value);
        setProjetosSelecionados([]);
        setProjetosFiltrados([]);
        setClienteSelecionado('');
        setCategoriesSelect('');
    };

    //Options
    const optionsProjetosClientes 
    =  projetoFiltradoCategoriaCliente.length > 0 && botaoAdicionarTodos ? [
        { value: 'ALL', label: 'Adicionar todos' },
        ...projetoFiltradoCategoriaCliente.map(projeto => (
            {
                value: projeto.key,
                label: `${projeto.key} - ${projeto.name}`,
                categoria: projeto.category
            }
        ))
    ] : [
        ...projetoFiltradoCategoriaCliente.map(projeto => (
            {
                value: projeto.key,
                label: `${projeto.key} - ${projeto.name}`,
                categoria: projeto.category
            }
        ))
    ]

    const optionsProjetos = 
    categoriesSelect === "" && botaoAdicionarTodos ? 
    [
        { value: 'ALL', label: 'Adicionar todos' },
        {value: "TODOS", label: 'TODOS OS PROJETOS'},
        ...projetoFiltradoCategoria
        .slice()
        .sort((a, b) => a.key.localeCompare(b.key))
        .map(item => ({
            value: item.key,
            label: `${item.key} - ${item.name}`,
            categoria: item.category
        }))
    ] : [
        //{value: "ALL", label: 'TODOS OS PROJETOS'},
        ...projetoFiltradoCategoria
        .slice()
        .sort((a, b) => a.key.localeCompare(b.key))
        .map(item => ({
            value: item.key,
            label: `${item.key} - ${item.name}`,
            categoria: item.category
        }))
    ];

    const filtrarProjetoCliente = (categoria) => {    
        const filteredData = projetos
        .filter(item2 => item2.category === categoria)
        .filter(item2 => projetosFiltrados.some(item1 => item1.key === item2.key && item1.name === item2.name));
        if(categoria == ""){
            setBotaoAdicionarTodos(true);
            setTodasCategorias(true);
            setProjetoFiltradoCategoriaCliente(projetosFiltrados)
            
        } else if(filteredData.length < 1 && categoria !== "") {
            setTodasCategorias(true);
            alert.error("Não há projeto com a categoria escolhida");
            setProjetoFiltradoCategoriaCliente([])
        } else {
            setBotaoAdicionarTodos(false);
            setProjetoFiltradoCategoriaCliente(filteredData)
        }

        return filteredData;
    };

    const filtrarProjeto = (categoria) => {   
        if(categoria == "--Todas"){
            setBotaoAdicionarTodos(true);
            setTodasCategorias(true);
        } 
    };

    const agroupCategory = (categorias) => {
        const uniqueCategorias = categorias.reduce((acc, curr) => {
            if (!acc.some(item => item.categoria === curr.categoria)) {
                acc.push(curr);
            }
            return acc;
        }, []);
        setCategoriasLabel(uniqueCategorias)
    }

    const adicionarCategoria = (projetosProps) => {
        const categorias = {};
        
        projetosProps.forEach(item => {
            categorias[item.key] = item.category;
        });
        setCategoriasChave(categorias)
    };    

    return(
        <>
        <span style={{fontSize: "12px"}}>* Campo obrigatório</span>
        <Form className="mt-3">
            <div className='d-flex'>
            <Form.Group className='d-flex flex-column mr-3'>
                <Form.Label>Período inicial: </Form.Label>
                <MonthYearPicker setMonthYear={setInitialDate} monthYear={initialDate}/>
            </Form.Group>
            <Form.Group className='d-flex flex-column mr-3'>
                <Form.Label>Período final: </Form.Label>
                <MonthYearPicker setMonthYear={setFinalDate} monthYear={finalDate}/>
            </Form.Group>
            </div>

            <Form.Group>
                <Form.Label>Filtrar por: *</Form.Label>
                <Form.Check
                    type="radio"
                    label="Cliente"
                    name="radioGroup"
                    id="por-cliente"
                    value="cliente"
                    checked={selectedOptionRadio === 'cliente'}
                    onChange={handleRadioChange}
                />
                <Form.Check
                    type="radio"
                    label="Projeto"
                    name="radioGroup"
                    id="por-projeto"
                    value="projeto"
                    checked={selectedOptionRadio === 'projeto'}
                    onChange={handleRadioChange}
                />
            </Form.Group>
            {selectedOptionRadio == "cliente" ? 
            <>
            <Form.Group>
                <Form.Label>Selecione o cliente: *</Form.Label>
                <Form.Control
                    as="select"
                    name="clientes"
                    onChange={handleClienteChange}
                >
                <option>--Selecione o cliente--</option>
                {clientes
                    .slice()
                    .sort((a, b) => a.nome.localeCompare(b.nome))
                    .map((item, index) => (
                        <option value={item.nome} key={index}>{item.nome}</option>
                    ))
                }
                </Form.Control>
            </Form.Group>
            {clienteSelecionado && (
                <>
                <Form.Group>
                    <Form.Label>Selecione as categorias: <br/>{!todasCategorias && <span>Categorias - {categoriasLabel.map(projeto =>( ` ${projeto.categoria} |`))}</span>}</Form.Label>
                    
                    <Form.Control
                        as="select"
                        name="categoria"
                        value={categoriesSelect}
                        onChange={(e) => {setCategoriesSelect(e.target.value); filtrarProjetoCliente(e.target.value); filtrarProjeto(e.target.value)}}
                        >   
                        <option value="">--Todas as categorias--</option>
                        {categories.map(categorie => (
                            <option key={categorie.id} value={categorie.name}>{categorie.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>          
                <Form.Group>
                    <Form.Label>Selecione os projetos do cliente: *</Form.Label>
                        <Select
                            components={animatedComponents}
                            isMulti
                            options={optionsProjetosClientes}
                            value={projetosSelecionados}
                            onChange={(e)=>{handleProjetosClienteChange(e); agroupCategory(e)}}
                            placeholder="Selecione os projetos"
                            closeMenuOnSelect={true}
                        />
                </Form.Group>
                </>
            )}
            </>
            : selectedOptionRadio == "projeto" ?
            <>
                <Form.Group>
                <Form.Label>Selecione as categorias: <br/>{!todasCategorias && <span>Categorias - {categoriasLabel.map(projeto =>( ` ${projeto.categoria} |`))}</span>}</Form.Label>
                <Form.Control
                    as="select"
                    name="categoria"
                    value={categoriesSelect}
                    onChange={(e) => {setCategoriesSelect(e.target.value)}}
                    >   
                    <option value="">--Todas as categorias--</option>
                    {categories.map(categorie => (
                        <option key={categorie.id} value={categorie.name}>{categorie.name}</option>
                    ))}
                </Form.Control>
                </Form.Group>
                <Form.Group>
                <Form.Label>Selecione os projetos: *</Form.Label>
                    <Select
                        components={animatedComponents}
                        isMulti
                        options={optionsProjetos}
                        value={projetosSelecionados}
                        onChange={(e) => {handleProjetosChange(e); agroupCategory(e)}}
                        placeholder="Selecione os projetos"
                        closeMenuOnSelect={true}
                    />
                </Form.Group>
            </>
            : <></>}
            
            <Form.Group>
                <Form.Label>Selecione o tipo de custo: *</Form.Label>
                <Form.Control
                    as="select"
                    name="tipoCusto"
                    value={tipoCusto}
                    onChange={(e) => {setTipoCusto(e.target.value)}}
                    >
                    <option value="">--Selecionar Custo--</option>
                    <option value="INFRA_CLOUD">Infraestrutura em Cloud</option>
                    <option value="OUTROS_SOFTWARES">Outros softwares</option>
                    <option value="HORAS_EXTRAS">Horas extras</option>
                    <option value="FORNECEDORES_TERCEIROS">Fornecedores terceiros</option>
                    <option value="OUTROS">Outros</option>
                    <option value="VALOR_HH">Valor HH</option>
                    <option value="total">Total</option>
                </Form.Control>
            </Form.Group>
        </Form>
        <Button onClick={() => handleDadosProjetosHistorico(projetosSelecionados, selectedOptionRadio, initialDate, finalDate, tipoCusto, clienteSelecionado)}>Gerar</Button>
        </>
    )
}

export default FiltroHistoricoCusto;