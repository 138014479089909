import React from 'react';
import DatePicker from 'react-datepicker';

const MonthYearPicker = ({ setMonthYear, monthYear }) => {
  
    const handlePeriodChange = (date) => {
        setMonthYear(date);
    };
  
    return (
      <DatePicker
        selected={monthYear}
        onChange={handlePeriodChange}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        locale="pt-BR"
      />
    );
  };

  export default MonthYearPicker