import {
    FECHAMENTO_FETCH_SUCCEEDED,
    FECHAMENTO_FETCH_CUSTO_HORA_BY_SUCCEEDED
} from '../types';

const INITIAL = {
    fechamentos:[],
    custosHora: []
};

const fechamentoReducer = (state = INITIAL, actions) => {
    switch (actions.type) {
        case FECHAMENTO_FETCH_SUCCEEDED:
            return {
                ...state, 
                fechamentos: actions.payload
            }

        case FECHAMENTO_FETCH_CUSTO_HORA_BY_SUCCEEDED:
            return {
                ...state, 
                custosHora: actions.payload
            }
        
        default:
            return state
    }
}

export default fechamentoReducer;
