import React, { useCallback, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Button, ButtonGroup, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { FaEraser, FaFilter, FaPlus } from 'react-icons/fa';

import './ListTopbar.css';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';

function ListTopbar({ columnsListTopbar, actions, onSubmitFilter, firstColumn = "", optionInitialFilter, multiFilter, isFilteredBool, setVerificarRow, setIsFilteredBool, noProjects }) {
    const url = useLocation();

    // STATE
    const [form, setForm] = useState({ column: optionInitialFilter || columnsListTopbar[0].name });
    const [multiFilterForm, setMultiFilterForm] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [infoMsg, setInfoMsg] = useState('');
    const [infoMultiFilterMsg, setInfoMultiFilterMsg] = useState('');

    // FUNCTIONS
    const getLabelColumn = () => {
        const found = columnsListTopbar.find(item => item.name === form.column);
        if (form.column === 'checkbox') return form.column;
        return found.label || form.column;
    }

    const formatMultMessage = (msg) => {
        const formatedMsg = msg.map(item => `${item.column}: ${item.filter}`).toString();
        return formatedMsg;
    }

    const onSelectDropdown = (evKey) => {
        setForm(({ ...form, column: evKey }));
    }

    const onChangeInput = (ev) => {
        setForm(({ ...form, filter: ev.target.value }));
    }

    const onAddMoreFilter = () => {
        setIsFiltered(false);
        const multFilter = [...multiFilterForm, form];
        setMultiFilterForm(multFilter);

        const msg = formatMultMessage(multFilter);
        setInfoMultiFilterMsg(`Aplicando filtro para ${msg}`);

        const clearFilterForm = { ...form, filter: '' };
        setForm(clearFilterForm);
    }

    const onSubmitForm = (ev) => {
        ev.preventDefault();
        setIsFiltered(true);
        const formFilter = form.filter === null || form.filter === undefined ? '' : `'${form.filter}'`;
        const msg = multiFilter ? formatMultMessage(multiFilterForm) : `Filtro aplicado ${formFilter} para '${getLabelColumn()}'`;
        setInfoMsg(msg);
        setInfoMultiFilterMsg('');

        const submitForm = multiFilter ? multiFilterForm : form;
        onSubmitFilter && onSubmitFilter(submitForm);
    }

    const toggleIsFiltered = () => {
        const unfielteredForm = { ...form, filter: '' };
        onSubmitFilter({ column: '', filter: '' });
        setForm(unfielteredForm);
        setIsFiltered(false);
        setInfoMultiFilterMsg('');
        setMultiFilterForm([]);
        if(url.pathname == "/projetos/") {
            setVerificarRow(false)
            setIsFilteredBool(false)
        };
    };

    useEffect(() => {
        if(noProjects) {
            toggleIsFiltered()
        }
    }, [noProjects])

    const notFilterWithEmptyField = () => {
        const filterName = getLabelColumn();

        if (multiFilter && multiFilterForm.length > 0) return false;

        if (filterName === 'checkbox')
            return false;
        return !form.filter;
    }

    /** Define no início o campo inicial a ser usado no filtro */
    const onStart = useCallback(() => {
        const isFormNull = form === null;
        const hasColumns = columnsListTopbar.length > 0;
        const hasOnSubmitFilter = onSubmitFilter !== null;
        if (isFormNull && hasColumns && hasOnSubmitFilter) {
            const fields = Object.keys(columnsListTopbar[0]);
            const firstField = columnsListTopbar[0][fields[0]];
            setForm({ column: firstField });
            return
        }
    }, [columnsListTopbar, form, onSubmitFilter]);

    // EFFECTS
    useEffect(() => onStart(), [onStart]);
    useEffect(() => {
        if (firstColumn) {
            // setForm({ column: firstColumn });
            setIsFiltered(true)
        }
    }, [firstColumn])

    const disabledSearch = (onSubmitFilter === null);
    const notInput = notFilterWithEmptyField();

    return (
        <div className="list-topbar">

            {/* BOTOÕES DE AÇÃO ADICIONAIS RECEBIDOS PROPS */}
            {actions &&
                <div className="actions">
                    <ButtonGroup className="btn-grp">
                        {actions.map((action, k) => {
                            const actionTypes = {
                                button: (item) => (
                                    <Button
                                        key={`btn-${k}`}
                                        onClick={item.onClick}
                                        variant={`outline-${item.color || 'primary'}`}
                                        style={item.styleCustom || {}}
                                    >
                                        {item.icon} {item.label}
                                    </Button>
                                ),

                                dropdown: (item) => (
                                    <DropdownButton
                                        key={`btn-dd-${k}`}
                                        as={ButtonGroup}
                                        title={<>{item.icon} {item.label}</>}
                                        id={`dd-${k}-${String(item.label).replace(' ', '-').toLowerCase()}`}
                                        style={item.styleCustom || {}}
                                        onSelect={item.onSelect}
                                    >
                                        {item.options.map((opt, optK) => {
                                            return (
                                                <Dropdown.Item
                                                    key={`ddi-${optK}-${opt.value}`}
                                                    eventKey={opt.value}
                                                >
                                                    {opt.label}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </DropdownButton>
                                ),

                                select: (item) => (
                                    <div className="select-container" key={`select-${k}`}>
                                        <Form.Label style={{ whiteSpace: 'nowrap' }} column>
                                            {item.icon} {item.label}
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={item.onChange}
                                            title={item.label}
                                            value={item.value || ''}
                                        >
                                            {item.options.map((opt, optK) => {
                                                return (
                                                    <option
                                                        key={`ddi-${optK}-${opt.evKey}`}
                                                        value={opt.value}
                                                    >
                                                        {opt.label}
                                                    </option>
                                                )
                                            })}
                                        </Form.Control>
                                    </div>
                                )
                            }

                            return actionTypes[action.type](action);

                        })}
                    </ButtonGroup>
                </div>
            }

            <div className="filter">

                {/* MESANGEM DE FILTRO APLICADO */}
                {isFiltered || isFilteredBool ?
                    <div className="filtered-msg order-last order-lg-first">
                        <span>{infoMsg}</span>
                        <Button
                            as="a"
                            onClick={toggleIsFiltered}
                            variant="link"
                        >
                            <FaEraser />{' Limpar filtro'}
                        </Button>
                    </div>
                    : <></>
                }

                {/* MENSAGEM DOS FILTROS QUE IRÃO SER APLICADOS */}
                {infoMultiFilterMsg &&
                    <div className="filtered-msg order-last order-lg-first">
                        <span>{infoMultiFilterMsg}</span>
                        <Button
                            as="a"
                            onClick={toggleIsFiltered}
                            variant="link"
                        >
                            <FaEraser />{' Limpar filtro'}
                        </Button>
                    </div>
                }

                {/* FORM PARA APLICAÇÃO DO FILTRO */}
                {onSubmitFilter &&
                    <Form
                        className="filter-form"
                        onSubmit={onSubmitForm}
                    >
                        <Dropdown onSelect={onSelectDropdown}>
                            <Dropdown.Toggle
                                variant="light"
                                id="dd-column"
                                disabled={disabledSearch}
                                value={form.column}
                                defaultValue={form.column}
                                name="column"
                            >
                                Filtrar por: <span style={{ fontWeight: 'bold' }}>
                                    {form.column
                                        ? getLabelColumn()
                                        : columnsListTopbar[0].label
                                    }
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {columnsListTopbar.map((item, idx) => {
                                    const key = `dd-item-${idx}`;
                                    return (
                                        <Dropdown.Item
                                            key={key}
                                            eventKey={item.name}
                                        >
                                            {item.labelAlternative ? item.name : item.label}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Form.Control
                            placeholder="Filtro"
                            className="filter-input"
                            disabled={disabledSearch}
                            onChange={onChangeInput}
                            value={(form.filter || '')}
                            name="filter"
                            autoComplete="off"
                            type="text"
                        />
                        {multiFilter && (
                            <Button
                                size="sm"
                                variant="outline-secondary"
                                type="button"
                                disabled={notInput}
                                onClick={onAddMoreFilter}
                            >
                                <FaPlus />
                            </Button>
                        )}
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            type="submit"
                            disabled={disabledSearch || notInput}
                        >
                            <FaFilter />
                        </Button>
                    </Form>
                }
            </div>

        </div>
    )
}

// DEFAULT PROPS
/* ListTopbar.propTypes = {
    columns: PropTypes.array,
    actions: PropTypes.array,
    onSubmitFilter: PropTypes.func
} */

ListTopbar.defaultProps = {
    columns: [],
    actions: [],
    onSubmitFilter: null,
    firstColumn: ''
}

export default ListTopbar;
