export const columns = [
    {
        label: '',
        name: 'button',
        width: '10%'
    },
    {
        label: 'Função',
        name: 'funcao_nome',
        width: '25%'
    },
    {
        label: 'Custo HH',
        name: 'custo_hora_colaborador',
    },
    {
        label: 'Custo total',
        name: 'custo_total',
    },

];

export const columnsSprints = [
    {
        label: '',
        name: 'button',
        width: '10%'
    },
    {
        label: 'Função',
        name: 'funcao_nome',
        width: '25%'
    },
    {
        label: 'Quantidade',
        name: 'quantidade'
    },
    {
        label: 'Custo HH',
        name: 'custo_hora_colaborador',
    },
    {
        label: 'Custo total',
        name: 'custo_total',
    },

];

const ListSquadViewInfo = { columns, columnsSprints };

export default ListSquadViewInfo;
