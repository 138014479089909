import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import CustosPrevistosService from "../../../services/api/CustosPrevistosService";
import ProjetosApiService from "../../../services/api/ProjetosApiService";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAuth from "../../../context/AuthContext";
import { Button, Table } from "react-bootstrap";
import { MdOutlineArrowBack } from "react-icons/md";
import UtilService from "../../../services/util";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, XAxis, YAxis, Tooltip, ReferenceLine, Area } from "recharts";

function ResultadoAnualProjeto() {
    const [margemProjeto, setMargemProjeto] = useState([]);
    const [margemPrevistaPorcent, setMargemPrevistaPorcent] = useState("");
    const [selectedYear, setSelectedYear] = useState(0);
    const [dataSelectYear, setDataSelectYear] = useState([]);
    const [dataFormated, setDataFormated] = useState({});
    const [totaisPorAno, setTotaisPorAno] = useState({})
    const [totais, setTotais] = useState({
        receitaLiquidaTotal: 0,
        custoTotalRealTotal: 0,
        totalMargemLiquida: 0,
        totalMargemContribuicao: 0,
    });


    const { ano, key } = useParams();
    const { user } = useAuth();
    const { email } = user;

    const handleMargemProjeto = async () => {
        try {
            const resp = await ProjetosApiService.getMargemforYear(ano, key, email);

            let receitaLiquidaTotal = resp.data.totaisGerais.faturamentoLiquidoRealTotal;
            let custoTotalRealTotal = resp.data.totaisGerais.custoTotalRealTotal;
            let totalMargemLiquida = resp.data.totaisGerais.margemLiquidaMensalTotal;
            let totalMargemContribuicao = resp.data.totaisGerais.margemContribuicaoMensalTotal;     

            setMargemProjeto(resp.data.result);
            setTotais({
                receitaLiquidaTotal,
                custoTotalRealTotal,
                totalMargemLiquida,
                totalMargemContribuicao
            });
            setTotaisPorAno(resp.data.totaisPorAno)
            
        } catch (err) {
            console.error("Erro ao buscar margem", err)
        }
    };

    const handleMargemPrevista = async () => {
        try {
            const resp = await CustosPrevistosService.findByProject(key);

            setMargemPrevistaPorcent(resp.data.margem_custo_projeto);
        } catch (err) {
            console.error("Erro ao buscar margem prevista", err)
        }
    };

        function formatReal( value ){   
            // Convert the string to a number
            const numberValue = parseFloat(value);

            // Format the number as Brazilian Real
            return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
         }).format(numberValue);
        }

    const exportDataInExcel = async () => {
        const header = [
            [
                'Período', 'Faturamento Líquido Real', 'Custo Total Real', 'Margem Líquida Mensal', 'Margem de Contribuição Mensal'
            ]
        ];

        const transformData = (data) => {
            const monthsMapping = {
                "janeiro": "Janeiro",
                "fevereiro": "Fevereiro",
                "março": "Março",
                "abril": "Abril",
                "maio": "Maio",
                "junho": "Junho",
                "julho": "Julho",
                "agosto": "Agosto",
                "setembro": "Setembro",
                "outubro": "Outubro",
                "novembro": "Novembro",
                "dezembro": "Dezembro"
            };

            const monthOrder = [
                "janeiro", "fevereiro", "março", "abril", "maio", "junho",
                "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
            ];

            const transformedData = [];

            Object.keys(data).forEach((year) => {
                const orderedMonths = Object.keys(data[year]).sort((a, b) => {
                    return monthOrder.indexOf(a.split(' ')[0]) - monthOrder.indexOf(b.split(' ')[0]);
                });
        
                orderedMonths.forEach((month) => {
                    const report = data[year][month];
                    transformedData.push({
                        mes: `${monthsMapping[month.split(' ')[0]]} ${year}`,
                        receita_liquida: parseFloat(report.receita_liquida),
                        custo_venda: parseFloat(report.custo_venda),
                        margem_liquida: `${(parseFloat(report.margem_liquida) * 100).toFixed(2).replace('.', ',')}%`,
                        margem_contribuicao: `${(parseFloat(report.margem_contribuicao) * 100).toFixed(2).replace('.', ',')}%`
                    });
                });
            });

            return transformedData;
        };

        UtilService.exportXls(transformData(dataFormated), `Resultado do Projeto: ${key} - ${ano}`, header);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (        
                <div
                    tabIndex="-1"
                    className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom"
                    >
                    <div
                        className="recharts-default-tooltip"
                        style={{
                        margin: '0px',
                        padding: '10px',
                        backgroundColor: 'rgb(255, 255, 255)',
                        border: '1px solid rgb(204, 204, 204)',
                        whiteSpace: 'nowrap'
                        }}
                    >
                        <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
                        {label}
                        </p>
                        <ul className="recharts-tooltip-item-list" style={{ padding: '0px', margin: '0px' }}>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: '#00c49f' }}>
                            <span className="recharts-tooltip-item-name">Faturamento Líquido</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">R$ {!isNaN(payload[0].value) ? parseFloat(payload[0].value).toLocaleString('pt-br') : "-" }</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: '#0088fe' }}>
                            <span className="recharts-tooltip-item-name">Custo Total</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">R$ {!isNaN(payload[1].value) ? parseFloat(payload[1].value).toLocaleString('pt-br') : "-" }</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: 'rgb(255, 115, 0)' }}>
                            <span className="recharts-tooltip-item-name">Margem Líquida</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">{!isNaN(payload[2].value) ? parseFloat(payload[2].payload.margem_liquida).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 }) : "-" }</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: 'rgb(199, 177, 8)' }}>
                            <span className="recharts-tooltip-item-name">Margem de Contribuição</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">{!isNaN(payload[3].value) ? parseFloat(payload[3].value).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 }) : "-" }</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        </ul>
                    </div>
                </div>
            );
        }
    
        return null;
    };

    const actions = [
        {
            label: 'Voltar',
            icon: <MdOutlineArrowBack />,
            color: 'info',
            type: 'button',
            onClick: useHistory().goBack
        }
    ];

    useEffect(() => {
        handleMargemProjeto();
        handleMargemPrevista();
    }, []);


    useEffect(() => {
        dataFormatedForYear();
    }, [margemProjeto]);

    useEffect(() => {
        if (dataFormated[selectedYear] && margemPrevistaPorcent != "") {
            setDataSelectYear(processReportData(dataFormated[selectedYear]));
        }
    }, [selectedYear, margemPrevistaPorcent]);

    useEffect(() => {
        const anos = Object.keys(dataFormated);
        
        const ultimoAno = anos[anos.length - 1];

        setSelectedYear(ultimoAno)

        if (dataFormated[ultimoAno] && margemPrevistaPorcent != "") {
            setDataSelectYear(processReportData(dataFormated[ultimoAno]));
        }
    }, [dataFormated, margemPrevistaPorcent]);

    const dataFormatedForYear = () => {
        const dadosPorAno = margemProjeto.reduce((acc, item) => {
            const ano = new Date(item.periodo_inicial).getFullYear();
            const mesAno = new Date(item.periodo_inicial).toLocaleString('pt-BR', { month: 'long', year: 'numeric' });
    
            if (!acc[ano]) {
                acc[ano] = {};
            }
    
            if (!acc[ano][mesAno] || new Date(item.periodo_inicial) > new Date(acc[ano][mesAno].periodo_inicial)) {
                acc[ano][mesAno] = item;
            }
    
            return acc;
        }, {});

        setDataFormated(dadosPorAno)
    }

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };
    
    const processReportData = (reportData) => {
        const getMonthIndex = (key) => {
            const months = {
                "janeiro": 1,
                "fevereiro": 2,
                "março": 3,
                "abril": 4,
                "maio": 5,
                "junho": 6,
                "julho": 7,
                "agosto": 8,
                "setembro": 9,
                "outubro": 10,
                "novembro": 11,
                "dezembro": 12
            };
            const month = key.split(" ")[0].toLowerCase();
            return months[month] || 0;
        };

        const reportArray = Object.keys(reportData).map((key) => {
            const report = reportData[key];

            return {
                mesAno: key.split(" ")[0].charAt(0).toUpperCase() + key.split(" ")[0].slice(1),
                receita_bruta: report.receita_bruta || "-",
                receita_liquida: report.receita_liquida || "-",
                custo_venda: report.custo_venda || "-",
                despesas_operacionais: report.despesas_operacionais || "-",
                imposto_sem_resultado: report.imposto_sem_resultado || "-",
                margem_contribuicao: report.margem_contribuicao || "-",
                margem_liquida: report.margem_liquida || "-",
                resultado_liquido: report.resultado_liquido || "-",
                margem_prevista: margemPrevistaPorcent/100,
                monthIndex: getMonthIndex(key)
            };
        });

        reportArray.sort((a, b) => a.monthIndex - b.monthIndex);

        return reportArray.map(({ monthIndex, ...rest }) => rest);
    };

    return (
        <PageContainer title={`Resultado Anual do Projeto ${key}`} actions={actions} >
            {Object.keys(dataFormated).length === 0 ? <h5>Não existe dados para o período selecionado.</h5> :  
            <>
                <Button onClick={exportDataInExcel} className='mb-3'>Exportar</Button>
                <div className='w-25'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Meta Prevista</th>
                                {margemPrevistaPorcent ?
                                    <th style={{color: '#2977ff'}}> {margemPrevistaPorcent ? `${margemPrevistaPorcent.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}%` : '-'}</th>
                                    :
                                    <th> {margemPrevistaPorcent ? `${margemPrevistaPorcent.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}%` : '-'}</th>
                                }
                            </tr>
                        </thead>
                    </Table>
                </div>
                {ano == "total" &&
                <Table striped bordered hover style={{background: "#e1eaf3"}}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Faturamento Líquido Real</th>
                            <th>Custo Total Real</th>
                            <th>Margem Líquida do Projeto</th>
                            <th>Margem de Contribuição do Projeto</th>
                        </tr>
                        
                    </thead>
                    <tbody>
                        <tr style={{background: "transparent"}}>
                            <td><strong>Total</strong></td>
                            <td><strong>R$ {totais.receitaLiquidaTotal ? totais.receitaLiquidaTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "-"}</strong></td>
                            <td><strong>R$ {totais.custoTotalRealTotal ? totais.custoTotalRealTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "-"}</strong></td>
                            <td><strong>{totais.totalMargemLiquida ? totais.totalMargemLiquida.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 }) : "-"}</strong></td>
                            <td><strong>{totais.totalMargemContribuicao ? totais.totalMargemContribuicao.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 }) : "-"}</strong></td>
                        </tr>
                    </tbody>
                </Table>}
                {Object.keys(dataFormated).sort().map((ano) => {
                    const mesesOrdenados = Object.values(dataFormated[ano]).sort((a, b) => new Date(a.periodo_inicial) - new Date(b.periodo_inicial));

                    return (
                        <div key={ano}>
                            <h5><b>Ano: </b>{ano}</h5>
                            <Table className="mr-5" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Mês</th>
                                        <th>Faturamento Líquido Real</th>
                                        <th>Custo Total Real</th>
                                        <th>Margem Líquida Mensal</th>
                                        <th>Margem de Contribuição Mensal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mesesOrdenados.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{new Date(item.periodo_inicial).toLocaleString('pt-BR', { month: 'long', year: 'numeric' })}</td>
                                            <td>{item.receita_liquida !== "-" ? `R$ ${parseFloat(item.receita_liquida).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : item.receita_liquida}</td>
                                            <td>{item.custo_venda !== "-" ? `R$ ${parseFloat(item.custo_venda).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : item.custo_venda}</td>
                                            <td>{item.margem_liquida !== "-" ? parseFloat(item.margem_liquida).toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 }) : item.margem_liquida}</td>
                                            <td>{item.margem_contribuicao !== "-" ? parseFloat(item.margem_contribuicao).toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 }) : item.margem_contribuicao}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td></td>
                                        <td><strong>R$ {totaisPorAno[ano]?.receitaLiquidaTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</strong></td>
                                        <td><strong>R$ {totaisPorAno[ano]?.totalCustoVenda.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</strong></td>
                                        <td><strong>{totaisPorAno[ano]?.totalMargemLiquida.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 })}</strong></td>
                                        <td><strong>{totaisPorAno[ano]?.totalMargemContribuicao.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 })}</strong></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    );
                })}
                <div className="w-100 d-flex justify-content-center mt-3">
                    {ano == 'total' &&
                        <select style={{padding: "8px 16px", borderRadius: "16px", border: "1px solid #555", color: "#555",background: "transparent"}} value={selectedYear} onChange={handleYearChange}>
                            {Object.keys(dataFormated).map(year => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    }
                </div>
                {dataFormated[selectedYear] &&
                <>
                <ResponsiveContainer className="mt-5" width="100%" height={400}>
                    <ComposedChart
                        data={dataSelectYear}
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="mesAno" />
                        <YAxis tickFormatter={(value) => `R$ ${value.toLocaleString('pt-br')}`}  />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar dataKey="receita_liquida" fill="#00c49f" name="Faturamento Líquido Real" />
                        <Bar dataKey="custo_venda" fill="#0088fe" cursor="pointer" name="Custo Total Real" />
                        <Line strokeWidth={0} type="monotone" dataKey="resultado_liquido" name="Margem Líquida" stroke="transparent" dot={false} legendType="none" strokeDasharray="0 100" />
                        <Line strokeWidth={0} dataKey="margem_contribuicao" stroke="transparent" dot={false} legendType="none" />
                        {/* <ReferenceLine dataKey="margem_prevista" label={`${margemPrevistaPorcent}%`} stroke="rgb(199, 177, 8)" /> */}
                    </ComposedChart>
                </ResponsiveContainer>
                <ResponsiveContainer width="100%" id="responsive-grafic-recharts" height={400}>
                    <ComposedChart
                        data={dataSelectYear}
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="mesAno" />
                        <YAxis tickFormatter={(value) => value.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}  />
                        <Tooltip formatter={(value) => Number(value).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })} />
                        <Legend />
                        <Line strokeWidth={2} type="monotone" dataKey="margem_contribuicao" name="Margem de Contribuição" stroke="#ff7300" />
                        <Line strokeWidth={2} type="monotone" dataKey="margem_liquida" name="Margem Líquida" stroke="rgb(199, 177, 8)" />
                        <Line strokeWidth={2} type="monotone" dataKey="margem_prevista" name="Margem Prevista" stroke="#2977ff" />
                    </ComposedChart>
                </ResponsiveContainer>
                </>
                }
            </>}
        </PageContainer>
    )
}

export default ResultadoAnualProjeto;
