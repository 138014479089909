import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FaSave, FaSpinner } from 'react-icons/fa';
import { MdClose, MdRemoveCircleOutline } from 'react-icons/md';
import { FormBuilder, FormStateBuilder } from '../../../../components/FormBuilder';
import ContentContainer from '../../../../components/ContentContainer';
import Loading from '../../../../components/Loading';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../../services/errorRequest';
import BeneficioApiService from '../../../../services/api/BeneficioApiService';
import BeneficioColabValidation from '../../../../services/validation/BeneficioColabValidation';
import UtilService from '../../../../services/util';
import BeneficioColabFormInfo from './BeneficioColabFormInfo';
import BeneficioColabNewInfo from './BeneficioColabNewInfo';
import { storeBeneficios } from '../../../../store/actions/dadosActions';

let beneficio = {benefCod:""};

function BeneficioColabForm({cod: benefCod, onSave, onCancel, editable=false}) {
    // HOOKS
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const alert = useAlertNotification();

    // REDUX
    const { beneficios } = useSelector(state => state.dados);
    const dispatch = useDispatch();
    const reduxStoreBenefs = useCallback(
        (benefs) => dispatch(storeBeneficios(benefs)),
        [dispatch]
    )

    // PROPS
    const { mat, filial } = location.state || {};
    const cod = benefCod || match.params.cod;
    const isFormView = !!cod;

    // STATE
    const [origData, setOrginData] = useState(null);
    const [formFields, setFormFields] = useState({});
    const [formData, setFormData] = useState(null);
    const [edit, setEdit] = useState([]);
    const [isEdited, setIsEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    // FUNCTIONS
    const isFormEdited = () => {
        if (!edit.length) return false;
        const jsonOrigData = JSON.stringify(origData);
        const jsonFormData = JSON.stringify(formData);
        return (jsonOrigData !== jsonFormData);
    }

    const askForLeave = (msg = null, cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            // const msg = 'Foram feitas alterações!\nDeseja realmente sair e perdê-las?';
            const leave = window.confirm(msg);

            if (leave) cbPositive();
            else cbNegative();
        }
        return false;
    }

    const resetForm = () => UtilService.genericResetFormFunction();

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        history.goBack();
    }

    const hanldeOnSave = () => {
        if (onSave) {
            onSave();
            return;
        }
        const url = `${match.url.replace(`/${cod}`, '')}`;
        history.replace(url, location.state);
    }

    const saveForm = async () => {
        try {
            setIsSaving(true);

            // validar form
            const validation = await BeneficioColabValidation.validate(formData);
            if (!validation.isValid) {
                alert.error("Erros no formulário!");
                return;
            }

            // se ok, enviar p/ API
            const resp = !isFormView // diferente endpoints se novo, ou já existente
                ? await BeneficioApiService.addBeneficioColab(mat, formData.benefCod, formData)
                : await BeneficioApiService.updateByColabMatAndBenefCod(mat, cod, formData);

            if (resp.data.result) {
                setIsEdited(false);
                // setEdit(false);
                alert.success(isFormView
                    ? "Dados do beneficio atualizados para o colaborador!"
                    : "Benefício inserido para colaborador!"
                );
                hanldeOnSave();
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsSaving(false);
        }
    }

    const handleCancel = () => {
        if (isEdited) {
            const msg = 'Foram feitas alterações!\nDeseja realmente sair e perdê-las?'

            askForLeave(msg, () => {
                setIsEdited(false);
                setFormData(origData);
                resetForm();
                handleOnCancel();
            });
            return;
        }
        handleOnCancel();
    }

    const handleDelete = () => {
        const msg = 'Confirma a exclusão de benefício do colaborador?'

        askForLeave(msg, async () => {
            const { rolMat, benefCod, filialCod } = origData;
            await BeneficioApiService.deleteEmployeeBenefits(rolMat, benefCod, filialCod);

            alert.info('É necessário recalcular os fechamentos para este colaborador');

            const url = `${match.url.replace(`/${cod}`, '')}`;
            history.replace(url, location.state);

            handleOnCancel();
        });
        return;
    }

    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setFormData(newform);
        setEdit([...edit, name]);
    }

    // Pega o beneficio escolhido
    const getBenefChosen = () => {
        const benef = beneficios.find(b => {
            return b.cod == formData.benefCod
        });
        const valores = benef.valores.find(v => v.filialCod == filial);
        beneficio = {...valores};
        setFormData({...formData, ...valores});
        setOrginData({...formData, ...valores});
    }

    const onEdit = () => {
        // verifica os dados do beneficio escolhido
        const { benefCod } = formData || {};
        if (!isFormView && !!benefCod && benefCod !== beneficio.benefCod) {
            getBenefChosen();
        }
        // verifica se formulário foi editado
        if (isFormEdited() && !isEdited) setIsEdited(true);
    };

    const onStart = () => {
        const getData = async () => {
            try {
                const benefs = beneficios || await BeneficioApiService.getAll().then(resp => {
                    const { data } = resp.data;
                    return (data
                        .filter(b => b.valores.length > 0)
                        .sort((a, b) => ((a.sigla < b.sigla) ? -1 : 1))
                    ) || [];
                });
                
                const beneficiosAtivos = benefs.filter(item => item.ativo === 1);

                if (!beneficios) reduxStoreBenefs(benefs);

                const formCampos = (isFormView)
                    ? BeneficioColabFormInfo.getFormFields()
                    : BeneficioColabNewInfo.getFormFields(benefs);

                const formDados = (isFormView)
                    ? await BeneficioApiService.getByColabMatAndBenefCod(mat, cod).then(resp => resp.data)
                    : FormStateBuilder(formCampos);

                setFormFields( formCampos );
                setFormData( formDados );
                setOrginData( formDados );

            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert.error(errorMsg);
            }
            finally {
                setIsLoading(false);
            }
        }
        getData();
    }

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(onEdit, [formData]);

    const actions = [
        {
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            disabled: isSaving,
            onClick: handleCancel
        }
    ]

    if (editable) {
        actions.unshift(
            {
                label: isSaving ? 'Salvando...' : 'Salvar',
                icon: isSaving ? <FaSpinner /> : <FaSave />,
                color: 'success',
                type: 'button',
                disabled: isSaving,
                onClick: saveForm
            }
        );
    }

    if (benefCod) {
        actions.push({
            label: 'Remover',
            icon: <MdRemoveCircleOutline /> ,
            color: 'danger',
            type: 'button',
            onClick: handleDelete
        })
    }

    // const { nome: benef } = formData || {};
    const title = `Benefício do Colaborador`;

    return (
        <ContentContainer
            title={title}
            actions={actions}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                : (origData &&
                    <FormBuilder
                        formFields={formFields}
                        formData={formData}
                        onChange={onChange}
                        disabledForm={!editable || isSaving}
                        initialValues={origData}
                        schema={BeneficioColabValidation.schema}
                    />
                )
            }

            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        resetForm();
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </ContentContainer>
    )
}

export default BeneficioColabForm;
