import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiArrowUpRight, FiArrowDown, FiHelpCircle } from 'react-icons/fi';
import '../../../components/HelpModal/HelpModal.css';

function FechamentolListHelpModal() {
    const [show, setShow] = useState(false);
    const [isSteps, setIsSteps] = useState(1);

    const handleClose = () => {
        setIsSteps(1);
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const getStepsView = (step) => {
        switch (step) {
            case 1: return (
                <Modal.Body>
                    <p className="description-text-info">
                        O fechamento tem por objetivo entregar o custo médio mensal de cada colaborador, baseado nos parâmetros de benefícios e salário, dentro de um número de dias úteis/mês.
                        <br></br>
                        Esse processo, calcula o custo folha, o total de impostos, benefícios, as provisões de 13º, férias e rescisão.
                        <br></br>
                        A partir dos resultados acima, encontra-se o custo total e custo hora. Custo total é o somatório do custo do salário, benefícios e as provisões. Já o custo hora é o resultado da divisão do custo total pela multiplicação dos dias úteis a considerar do colaborador, pela base de horas trabalhadas por dia, no caso, 8 horas.
                    </p>
                </Modal.Body>
            );
            case 2: return (
                <Modal.Body>
                    <p className="description-text-info">
                        Para criar um novo fechamento, clique no <span className="blue-text">botão azul</span> que fica no canto superior direito da página.
                    </p>
                    <div className="icon-arrow-right">
                        <FiArrowUpRight size={25} />
                    </div>
                </Modal.Body>
            );
            case 3: return (
                <Modal.Body>
                    <p className="description-description-text-info">
                        Nesta página, mostra a lista dos fechamentos que foram criados com seu status, data de início e fim e com sua identificação.
                        <br></br>
                        Para saber mais detalhes de cada um fechamento, é só clicar em um dos fechamentos que está na lista.
                        <br></br><br></br>
                        Se você não tiver nenhuma lista ainda, começe cadastrando um novo fechamento!
                    </p>
                    <div className="icon-arrow-right">
                        <FiArrowDown size={25} />
                    </div>
                </Modal.Body>
            );
            default: return ""
        }
    }

    const handleNext = () => {
        if (isSteps !== 3)
            setIsSteps(isSteps + 1)
    }

    const handlePrevious = () => {
        if (isSteps !== 1)
            setIsSteps(isSteps - 1)
    }

    return (
        <>
            <button onClick={() => handleShow(true)} className="button-info">
                <FiHelpCircle size={17} />
            </button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>O que é o Fechamento?</Modal.Title>
                </Modal.Header>

                {getStepsView(isSteps)}

                <Modal.Footer>
                    {isSteps !== 1 && (
                        <Button variant="outline-primary" onClick={handlePrevious}>
                            Voltar
                        </Button>
                    )}

                    {isSteps !== 3 && (
                        <Button variant="outline-primary" onClick={handleNext}>
                            Próximo
                        </Button>
                    )}

                    <Button variant="outline-danger" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { FechamentolListHelpModal }
