import RelatorioApiService from '../../api/RelatorioApiService';
import formFieldInfo from './formFieldInfo';
import filterValidation from './filterValidation';

const RelatorioColaboradorBeneficio = {
    apiRequest: RelatorioApiService.getColaboradorBeneficio,
    formFieldInfo,
    filterValidation,
    description:`Relatório que em sintético totaliza os benefícios de cada colaborador, 
        ou em analítico detalha os valores de benefícios de cada colaborador ativo.`,
    orientation: 'portrait',
}

export default RelatorioColaboradorBeneficio;