import React, { useState, useEffect } from 'react'
import { totalCostsColumns, totalFunctionsColumns } from '../ProjetoPrevistoViewInfo';
import ListLayout from '../../../../components/ListLayout';
import * as R from 'ramda';
import { Card } from 'react-bootstrap';
import { getTipoCusto, getTotais } from '../formatData';
import CustosPrevistosService from '../../../../services/api/CustosPrevistosService';

const INITIAL_PAGEPARAMS = {
    orderBy: 'id',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function TableTotal({ rowsDataFuncoes, rowsDataCustos, tabs, projetoKey }) {
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [totalValorVenda, setTotalValorVenda] = useState("")

    useEffect( async () => {
        try {
            const resp = await CustosPrevistosService.findByProject(projetoKey);
            setTotalValorVenda(resp.data.total_valor_venda)
        } catch (error) {
            console.error("Erro buscar o total do valor venda.", error)
        }
    }, [projetoKey])

    const transformNumber = (value) => {
        return Number(value?.replaceAll('.', '').replace(',', '.'));
    }

    const getListHeader = (type) => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }

        const columnsHeader = getColumns(type)

        columnsHeader.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    // let totais = getTotais(rowsDataFuncoes, rowsDataCustos);

    const getFunctionsRows = () => {
        const totaisFuncoes = getTotais(rowsDataFuncoes, rowsDataCustos);

        const columns = getColumns('funcoes');

        let rows = rowsDataFuncoes.map(item => {
            const row = {
                data: columns.map(col => {
                    const totalHoras = R.sum(Object.values(item.horas).filter(value => !isNaN(transformNumber(value))).map(transformNumber))

                    if (col.name === 'mes') {
                        return {
                            value: transformNumber(item.horas[col.month]) ?
                                transformNumber(item.horas[col.month]).toString().replace('.', ',')
                                : '-'
                        }
                    }

                    if (col.name === 'totalHora') {

                        return {
                            value: totalHoras.toString().replace('.', ',')
                        }
                    }

                    if (col.name === 'taxaCustoVenda') {
                        return {
                            value: transformNumber(item[col.name])
                                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        }
                    }

                    if (col.name === 'valorTotal') {
                        return {
                            value: R.multiply(totalHoras, transformNumber(item.taxaCustoVenda))
                                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        }
                    }

                    return {
                        value: item[col.name]
                    }
                })
            }
            return row;
        })

        const classNameTotal = ['font-weight-bold'];

        if (rows.length > 0) {
            const arrInitialTotal = Array(columns.length - 4).fill(0);

            const totaisMes = rows.reduce((total, item) => {
                for (let i = 1; i < item.data.length - 3; i++) {

                    total[i - 1] = R.sum([total[i - 1], (transformNumber(item.data[i].value) || 0)])
                }
                return total;
            }, arrInitialTotal)

            rows.push({
                data: [
                    { value: 'Total', className: classNameTotal },
                    ...totaisMes.map(item => ({ value: item.toString().replace('.', ','), className: classNameTotal })),
                    { value: totaisFuncoes.totalHoras.toString().replace('.', ','), className: classNameTotal },
                    { value: totaisFuncoes.totalCustoVenda.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), className: classNameTotal },
                    { value: totaisFuncoes.totalFuncao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), className: classNameTotal}
                ]
            })
        }

        return rows;
    }

    const getCostsRows = () => {
        const totaisCustos = getTotais(rowsDataFuncoes, rowsDataCustos);

        const columns = getColumns('custos');

        let rows = rowsDataCustos.map(item => {
            const row = {
                data: columns.map(col => {
                    const totalValor = R.sum(Object.values(item.valor)
                        .filter(value => !isNaN(transformNumber(value)))
                        .map(transformNumber))

                    if (col.name === 'mes') {
                        const value = transformNumber(item.valor[col.month]) || '-'

                        return {
                            value: value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        }
                    }

                    if (col.name === 'totalValor') {
                        return {
                            value: totalValor
                                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        }
                    }

                    if (col.name === 'tipoCusto') {
                        return { value: getTipoCusto(item[col.name]) }
                    }

                    return {
                        value: item[col.name]
                    }
                })
            }
            return row;
        })

        const classNameTotal = ['font-weight-bold'];

        if (rows.length > 0) {
            const arrInitialTotal = Array(columns.length - 3).fill(0);

            const totaisMes = rows.reduce((total, item) => {
                for (let i = 2; i < item.data.length - 1; i++) {
                    total[i - 2] = R.sum([total[i - 2], Number(item.data[i].value.replace('R$', '').replaceAll('.', '').replace(',', '.'))]) || 0
                }
                return total;
            }, arrInitialTotal)

            rows.push({
                data: [
                    { value: 'Total', className: classNameTotal },
                    { value: '', className: classNameTotal },
                    ...totaisMes.map(item => ({ value: item.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), className: classNameTotal })),
                    { value: totaisCustos.totalCustoIndireto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), className: classNameTotal }
                ]
            })
        }

        return rows;
    }

    const getColumns = (type) => {
        const monthsToAdd = tabs.map(tab => ({
            label: `M${tab}`,
            name: 'mes',
            month: tab,
        }));

        const columns = {
            funcoes: [...totalFunctionsColumns],
            custos: [...totalCostsColumns]
        }

        columns.funcoes.splice(1, 0, ...monthsToAdd);
        columns.custos.splice(2, 0, ...monthsToAdd);

        return columns[type];
    }

    return (
        <div>
            <h4>Total</h4>

            <h5>Funções</h5>
            <ListLayout columns={getListHeader('funcoes')} rows={getFunctionsRows()} />

            <h5>Custos Indiretos</h5>
            <ListLayout columns={getListHeader('custos')} rows={getCostsRows()} />

            <Card className='p-3 mt-3'>
                <h5>Total Geral de Custo</h5>
                <p>
                    { getTotais(rowsDataFuncoes, rowsDataCustos)
                    .total
                    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }
                </p>
            </Card>
            <Card className='p-3 mt-3'>
                <h5>Total Valor Venda</h5>
                <p>{parseFloat(totalValorVenda).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
            </Card>
        </div>
    )
}

export default TableTotal
