import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FaPen, FaPlus, FaTrash, FaUsers } from 'react-icons/fa';
import PageContainer from '../../../components/PageContainer';
import CustomModal from '../../../components/CustomModal';
import ListLayout from '../../../components/ListLayout';
import Loading from '../../../components/Loading';
import { columns } from './SimulacaoListInfo';
import useAuth from '../../../context/AuthContext';
import ErrorRequestService from '../../../services/errorRequest';
import SimulacaoApiService from '../../../services/api/SimulacaoApiService';
import { Button, ButtonGroup } from 'react-bootstrap';
import useAlertNotification from '../../../context/AlertNotificationContext';
import SimulacaoDetails from './SimulacaoDetails';
import UtilService from '../../../services/util';

const INITIAL_PAGEPARAMS = {
    orderBy: 'id',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function SimulacoesList() {
    // CONTEXT
    const { user } = useAuth();
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();

    const [rowsData, setRowsData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);

    const rowClick = (item) => {
        CustomModal.show({
            title: `Simulação - ${item.id}`,
            body: <SimulacaoDetails props={item} />,
            footer: (
                <ButtonGroup>
                    <Button variant="outline-info" onClick={() => history.push(`${match.url}${item.id}`)}>
                        <FaPen /> Editar
                    </Button>
                    <Button variant="outline-danger" onClick={() => modalDelete(item)}>
                        <FaTrash /> Excluir
                    </Button>
                </ButtonGroup>
            )
        })
    }

    const modalDelete = (item) => {
        CustomModal.show({
            body: `Tem certeza que deseja excluir o relatório ${item.id} ?`,
            footer: (
                <>
                    <Button variant="outline-success" onClick={() => deleteById(item.id)}>Sim</Button>
                    <Button variant="outline-danger" onClick={() => CustomModal.hide()}>Cancelar</Button>
                </>
            )
        })
    }

    const deleteById = async (itemId) => {
        const { email } = user;

        setIsLoading(true);

        try {
            const resp = (await SimulacaoApiService.deleteById(email, {
                id: itemId,
                ativo: false
            }));
            getDataList();
            CustomModal.hide();
            alert.success(resp.data.message);
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);

        } finally {
            setIsLoading(false);
        }
    }

    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    onClick: () => rowClick(item),
                    data: columns.map(col => {
                        if (col.name !== "id" && col.name !== "funcao_nome") {
                            return {
                                value: parseFloat(item[col.name])
                                    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                className: col.className
                            }
                        }

                        return {
                            value: item[col.name],
                            className: col.className
                        }
                    })
                };
                dataList.push(row);
            });
        return dataList;
    }

    const filterList = (params) => {
        const { column, filter } = params;

        if (!column || !filter) {
            setFilteredData(rowsData);
            return
        }

        const filtered = rowsData.filter(item => {
            const lwData = String(item[column]).toLowerCase();
            const lwFilter = String(filter).toLowerCase();

            return lwData.includes(lwFilter);
        });
        setFilteredData(filtered);
    }

    const exportDataInExcel = async () => {
        const transformFormatNumber = (number) => {
            return parseFloat(parseFloat(number).toFixed(2));
        }

        const header = [
            [
                'Função', 'Regime', 'Filial', 'Benefícios', 'Impostos',
                'Dependentes', 'Salário', 'Custo Folha', 'Salário Líquido',
                'Custo Total', 'Custo Hora', 'Custo Hora Meio Período',
                'Provisão 13º Salário', 'Provisão Férias', 'Provisão Rescisão'
            ]
        ];

        const formatedData = await rowsData.map(item => {
            return {
                funcaoNome: item['funcao_nome'],
                regimeSigla: item['regime_sigla'],
                filialLabel: item['filial_label'],
                beneficioTotal: transformFormatNumber(item["beneficio_total"]),
                impostoTotal: transformFormatNumber(item["imposto_total"]),
                dependentes: item['dependentes'],
                salarioBruto: transformFormatNumber(item["salario_bruto"]),
                slarioFilial: transformFormatNumber(item["salario_filial"]),
                salarioLiquido: transformFormatNumber(item["salario_liquido"]),
                custoTotal: transformFormatNumber(item["custo_total"]),
                custoHH: transformFormatNumber(item["custo_hora_colaborador"]),
                custoHHMeioPeriodo: transformFormatNumber(item["custo_hora_colaborador_meio_periodo"]),
                provisaoDecimoTerceiro: transformFormatNumber(item["provisao_decimo_terceiro"]),
                provisaoFerias: transformFormatNumber(item["provisao_ferias"]),
                provisaoRescisao: transformFormatNumber(item["provisao_rescisao"])
            }
        })

        UtilService.exportXls(formatedData, title, header);
    }

    const getDataList = useCallback(async () => {
        const { email } = user;
        setIsLoading(true);

        try {
            const resp = await SimulacaoApiService.getAll(email).then(r => r.data);
            setRowsData(resp.data);
            setFilteredData(resp.data);

        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        if (!rowsData) getDataList();
    }, [rowsData, getDataList]);

    const pageActions = [
        {
            label: 'Novo',
            icon: <FaPlus />,
            color: 'primary',
            type: 'button',
            href: `${match.path}/new`,
        }
    ]

    const title = 'Lista de simulações de colaboradores';

    return (
        <PageContainer
            title={title}
            actions={pageActions}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                :
                <>
                    <Button onClick={exportDataInExcel}>Exportar</Button>
                    <ListLayout
                        columns={getListHeader()}
                        rows={defineDataList()}
                        onSubmitFilter={filterList}
                        optionInitialFilter="salario_bruto"
                    />
                </>
            }
            <CustomModal />
        </PageContainer>
    )
}

export default SimulacoesList;
