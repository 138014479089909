import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function MargemResult({ formResult }) {

    const transformLocaleString = (number) => {
        return parseFloat(number).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    return (
        <>
            <div className='d-flex justify-content-center'>
                <h4>Resultado do relatório</h4>
            </div>
            <Container>
                <Row>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Receita Bruta: </strong>{transformLocaleString(formResult['receita_bruta'])}</p>
                            <p><strong>Impostos: </strong>{transformLocaleString(formResult['impostos'])}</p>
                            <p className='m-0'><strong>Receita Líquida: </strong>{transformLocaleString(formResult['receita_liquida'])}</p>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Custo de vendas: </strong>{transformLocaleString(formResult['custo_venda'])}</p>
                            <p><strong>Custo Indiretos: </strong>{transformLocaleString(formResult['total_custo_indireto'])}</p>
                            <p className='m-0'><strong>Custo HH: </strong>{transformLocaleString(formResult['custo_hora_colaborador'])}</p>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='h-100 p-3'>
                            <p><strong>Resultado Bruto: </strong>{transformLocaleString(formResult['resultado_bruto'])}</p>
                            <p><strong>Margem Bruta: </strong>{(formResult['margem_bruta'] * 100).toFixed(2).toString().replace('.', ',')} %</p>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-3'>
                            <p><strong>Desp. Operacionais: </strong>{transformLocaleString(formResult['despesas_operacionais'])}</p>
                            <p><strong>RV: </strong>{transformLocaleString(formResult['rv'])}</p>
                            <p className='m-0'><strong>Rateio Administrativo: </strong>{transformLocaleString(formResult['rateio_administrativo'])}</p>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='p-3'>
                            <p><strong>Resultado IR/CSLL: </strong>{transformLocaleString(formResult['resultado_ir_csll'])}</p>
                            <p className='m-0'><strong>Imposto s/ Resultado: </strong>{transformLocaleString(formResult['imposto_sem_resultado'])}</p>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-3'>
                            <p><strong>Resultado Líquido: </strong>{transformLocaleString(formResult['resultado_liquido'])}</p>
                            <p className='m-0'><strong>Margem Líquida: </strong>{(formResult['margem_liquida'] * 100).toFixed(2).toString().replace('.', ',')} %</p>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Margem de Contribuição: </strong>{(formResult['margem_contribuicao'] * 100).toFixed(2).toString().replace('.', ',')} %</p>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default MargemResult;
