/**
 * Informações das colunas para listagem de faixas de imposto
 * @type {Array<{
*   label: string,
*   name: string,
*   width: string | number,
*   colSpan: string | number,
*   className: string,
*   style: string
* }>}
*/
export const columns = [
   {
       label: 'Tipos de custos',
       name: 'tipo_custo',
       width: '20%'
   },
   {
       label: 'Descrição',
       name: 'descricao',
       width: '25%'
   },
   {
       label: 'Valor',
       name: 'valor',
       width: '15%'
   },
   {
        label: 'Valor Convertido',
        name: 'valor_convertido',
        width: '15%'
    },
    {
        label: 'Moeda',
        name: 'moeda',
        width: '10%'
    },
    {
        label: 'Projeto',
        name: 'projeto',
        width: '20%'
    },
];


const CustosListInfo = { columns };

export default CustosListInfo;
