/** 
 * Informações das colunas para lista de colaboradores
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>} 
*/
export const columns = [
    {
        label: 'Matricula',
        name: 'mat',
        width: '10%',
        // colSpan: '',
        className: '',
        // style: ''
    },
    {
        label: 'CPF',
        name: 'cpf',
        width: '15%',
        // colSpan: '',
        // className: 'd-none d-sm-table-cell',
        // style: ''
    },
    {
        label: 'Nome',
        name: 'nome',
        width: '20%',
        // colSpan: '',
        // className: '',
        // style: ''
    },
    {
        label: 'Sobrenome',
        name: 'sobrenome',
        width: '25%',
        className: 'd-none d-lg-table-cell',
        // colSpan: '',
        // className: '',
        // style: ''
    },
    {
        label: 'E-mail',
        name: 'email',
        // width: '',
        // colSpan: '',
        className: 'd-none d-md-table-cell',
        // style: ''
    },
];


const ColaboradorListInfo = { columns };

export default ColaboradorListInfo;
