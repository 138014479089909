import FilialApiService from "../../../../services/api/FilialApiService";
import UtilService from "../../../../services/util";
import store from '../../../../store';
import { storeFiliais } from '../../../../store/actions/dadosActions';

const baseAliqOptions = [
    { label: '...', value:'' },
    { label: 'Salário', value: 'salario' },
    { label: 'Imposto', value: 'imposto' }
];

const fields = [
    'filial_cod', 'imposto_cod', 'tipo_imposto', 'valor_fixo', 'periodo_valor', 
    'valor_desc', 'aliquota_desc', 'base_aliq_desc', 
    'valor_filial', 'aliquota_filial', 'base_aliq_filial',
];

export const getFormFields = async () => {
    // REDUX
    const { dados } = store.getState();
    const filiaisData = dados.filiais || await (FilialApiService.getAll()).then(resp => resp.data.data);

    // UPDATE REDUX ?
    if (!dados.filiais) {
        store.dispatch( storeFiliais(filiaisData) );
    }

    return {
        groups:[
            {
                header: '',
                fields: [
                    {
                        label: 'Filial',
                        placeholder: '',
                        name: 'filial_cod',
                        type: 'select',
                        size: 3,
                        options: [
                            { value: '0', label: '--- Todas ---' },
                            ...(filiaisData.map(item => ({
                                    value: item.cod,
                                    label: item.label
                                })
                            )),
                        ],
                        defaultValue: 0
                    },
                ]
            },
            {
                header: 'Informações do Imposto',
                fields: [
                    {
                        label: 'Tipo de Imposto',
                        name: 'tipo_imposto',
                        type: 'select',
                        options: [
                            { label: '...' },
                            { value: 'fixo', label: 'Fixo - Valor fixo somado ao valor base (unitário)' },
                            { value: 'percentual', label: 'Percentual - Aliquota sobre valor base (ad valorem)' },
                        ],
                        size: 6,
                    },
                    {
                        label: 'Valor Fixo',
                        name: 'valor_fixo',
                        type: 'currency',
                        size: 3,
                        defaultValue: 0
                    },
                    {
                        label: 'Período do Valor',
                        name: 'periodo_valor',
                        type: 'select',
                        options: [
                            { label: '...' },
                            { value: 'dia', label: 'Diário (valor aplicado diário)' },
                            { value: 'mes', label: 'Mensal (valor aplicado mensal)' },
                        ],
                        size: 3,
                    },
                ]
            },
            {
                header: 'Descontos do Colaborador',
                fields: [
                    {
                        label: 'Valor Desc.',
                        name: 'valor_desc',
                        type: 'currency',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Aliq. Desconto',
                        name: 'aliquota_desc',
                        type: 'percentage',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Base Aliq.Desconto',
                        name: 'base_aliq_desc',
                        type: 'select',
                        options: baseAliqOptions,
                        size: 4,
                    },
                ]
            },
            {
                header: 'Custos da Empresa',
                fields: [
                    {
                        label: 'Valor Filial.',
                        name: 'valor_filial',
                        type: 'currency',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Aliq. Filial',
                        name: 'aliquota_filial',
                        type: 'percentage',
                        size: 4,
                        defaultValue: 0
                    },
                    {
                        label: 'Base Aliq.Filial',
                        name: 'base_aliq_filial',
                        type: 'select',
                        options: baseAliqOptions,
                        size: 4,
                    },
                ]
            },
        ]
    }
}

const ImpostoValorFormInfo = {
    getFormFields
}

export default ImpostoValorFormInfo;
