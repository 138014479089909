import React from 'react';
import { Route, Switch } from 'react-router-dom';

// PAGES
import ImpostosPage from './ImpostosPage';
import ImpostoNew from './ImpostoNew';
import ImpostoPage from './ImpostoPage';
// import ImpostoView from './ImpostoView';


function Imposto(props) {
    const { match } = props;

    return (
        <Switch>
            <Route path={match.path} exact component={ImpostosPage} />
            <Route path={`${match.path}/new`} exact component={ImpostoNew} />
            <Route path={`${match.path}/:cod`} component={ImpostoPage} />
            
        </Switch>
    )
}

export default Imposto;
