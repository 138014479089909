import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import GraficoBarras from "../../components/GraficoBarras";
import GraficoPie from "../../components/GraficoPie";
import "./DashboardPage.css";

/** Pagina contendo informações iniciais */
function GraficosCustoTotalBruto({
    fechamentos,
    selectedMonth,
    setSelectedMonth,
    isLoading,
    totalData
}) {

    return isLoading ?
        <div className="text-center m-auto">
            <Spinner
                animation="border"
                style={{ color: "#6265ea" }}
                />
            <h4>Construindo gráfico: Custo Total Bruto por Área</h4>
        </div> :
        <Container className="m-auto divCustoTotal">
            <Row>
                <Col md={12} className="mt-4">
                    <h4 className="text-center">Custo Total Bruto por Área</h4>
                    <div className="grafico2 selectbox">
                        <h5>Mês de referência: </h5>
                        <select
                            className="select grafico2"
                            value={selectedMonth}
                            onChange={(e) =>
                                setSelectedMonth(Number(e.target.value))
                            }
                        >
                            {fechamentos.map((item) => (
                                <option value={item.id} key={item.id}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </Col>
            </Row>
            {!totalData.length ?
            <div className="m-auto">
                <h5 className="p-2 text-center">Não foi possível carregar os dados, esse mês não foi calculado o fechamento</h5>
            </div> :
            <Row>
                <Col lg={6} className="my-3">
                    <GraficoBarras
                        data={totalData}
                        barKey="name"
                        dataKey="custo"
                        layout="vertical"
                        variant="purple"
                        styles={{ height: "100%"}}
                    />
                </Col>
                <Col lg={6} className="my-3">
                    <GraficoPie
                        data={totalData}
                        dataKey="custo"
                        styles={{ flexDirection: "column", height: "100%" }}
                    />
                </Col>
            </Row>}
        </Container>;
}

export default GraficosCustoTotalBruto;
