/**
 * Informações das colunas para lista
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>}
*/
export const columns = [
    {
        // SEMPRE VISÌVEL
        label: 'Cod',
        name: 'matricula',
    },
    {
        // SEMPRE VISÌVEL
        label: 'Nome',
        name: 'nome',
    },
    {
        // SEMPRE VISÌVEL
        label: 'Função',
        name: 'funcao',
    },
    {
        // SEMPRE VISÌVEL
        label: 'Dep.',
        name: 'departamento',
    },
    {
        // SEMPRE VISÌVEL
        label: 'Regime',
        name: 'regime',
    },
    {
        // SEMPRE VISÌVEL
        label: 'Salário',
        name: 'salarioBase',
        attr: {
            title: 'Salário bruto do colaborador',
            'data-toggle': 'tooltip',
        },
    },
    {
        // VISÌVEL A PARTIR DE X-LARGE
        label: 'Benefícios',
        name: 'beneficioTotal',
        className: 'd-none d-xl-table-cell w-auto',
        attr: {
            title: 'Soma dos benefícios usufruídos pelo colaborador, \nmesmo que de seu próprio custo.',
            'data-toggle': 'tooltip',
        },
    },
    {
        // VISÌVEL A PARTIR DE X-LARGE
        label: 'Impostos',
        name: 'impostoTotal',
        className: 'd-none d-xl-table-cell w-auto',
        attr: {
            title: 'Soma de todos os impostos que incidem para \ncolaborador no regime de contratação.',
            'data-toggle': 'tooltip',
        },
    },
    {
        // VISÌVEL A PARTIR DE X-LARGE
        label: 'Salário Líq.',
        name: 'salarioLiquido',
        className: 'd-none d-xl-table-cell w-auto',
        attr: {
            title: 'Salário líquido do colaborador após os descontos cabíveis.',
            'data-toggle': 'tooltip',
        },
    },
    {
        // VISÌVEL A PARTIR DE LARGE
        label: 'Custo Folha',
        name: 'salarioFilial',
        className: 'd-none d-lg-table-cell w-auto',
        attr: {
            title: 'Custo total de folha de pagamento, dentro do período, \nconsiderando salário, impostos, benefícios, etc.',
            'data-toggle': 'tooltip',
        },
    },
    {
        // VISÌVEL A PARTIR DE LARGE
        label: 'Prov. 13º Sal.',
        name: 'provisaoDecimoTerceiro',
        className: 'd-none d-lg-table-cell w-auto',
        attr: {
            title: 'Total de custo de provisão para 13º salário, dentro do período.',
            'data-toggle': 'tooltip',
        },
    },
    {
        // VISÌVEL A PARTIR DE LARGE
        label: 'Prov. Rescisão',
        name: 'provisaoRescisao',
        className: 'd-none d-lg-table-cell w-auto',
        attr: {
            title: 'Total de custo de provisão para recisão, dentro do período.',
            'data-toggle': 'tooltip',
        },
    },
    {
        // VISÌVEL A PARTIR DE LARGE
        label: 'Prov. Férias',
        name: 'provisaoFerias',
        className: 'd-none d-lg-table-cell w-auto',
        attr: {
            title: 'Total de custo de provisão para férias, dentro do período.',
            'data-toggle': 'tooltip',
        },
    },
    // {
    //     // @TODO: Verificar necessidade de exibição de custo efetivo férias
    //     // VISÌVEL A PARTIR DE X-LARGE
    //     label: 'Custo Férias',
    //     name: 'salarioFilialFerias',
    //     className: 'd-none d-xl-table-cell w-auto',
    //     attr: {
    //         title: 'Custo total se férias forem gozadas no período.',
    //         'data-toggle': 'tooltip',
    //     },
    // },
    {
        // VISÌVEL A PARTIR DE SMALL
        label: 'Custo Total',
        name: 'custoTotal',
        attr: {
            title: 'Custo Total Geral, dentro do período, consideranco \nFolha, Benefícios, Prov.13º,  Prov.Rescisão, Prov.Férias',
            'data-toggle': 'tooltip',
        },
        className: 'd-none d-sm-table-cell w-auto',
    },
    {
        // SEMPRE VISÌVEL
        label: 'Custo Hora',
        name: 'custoHora',
        attr: {
            title: 'Considera os custos imediatos e provisões pelos \ndias úteis/atuantes do colaborador dentro do período.',
            'data-toggle': 'tooltip',
        },
    },
    {
        // SEMPRE VISÌVEL
        label: '1/2 Período?',
        name: 'meioPeriodo',
    },
];


const FechamentoPageListInfo = { columns };

export default FechamentoPageListInfo;
