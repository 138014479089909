import { isEqual, set } from 'date-fns';

export const getInitialDate = () => {
    let date = new Date();
    let initial = new Date(new Date().setDate(1));

    if (isEqual(date, initial))
        return set(new Date(), { month: new Date().getMonth() - 1 });

    return new Date(new Date().setDate(1));
}

export const getFormFields = () => {
    return {
        groups: [
            {
                header: '',
                fields: [
                    {
                        label: 'Início',
                        placeholder: '',
                        name: 'initialDate',
                        type: 'date',
                        defaultValue: new Date()
                    },
                    {
                        label: 'Fim',
                        placeholder: '',
                        name: 'finalDate',
                        type: 'date',
                        defaultValue: new Date()
                    }
                ]
            }
        ]
    };
}

const FiltroInfo = { getFormFields };

export default FiltroInfo;
