import { Route, Switch } from 'react-router';
import AtualizarColaboradoresImport from './AtualizarImport';

function AtualizarColaboradores(props) {
    const {match} = props;

    return (
        <Switch>
            <Route path={match.path} exact component={AtualizarColaboradoresImport}/>
        </Switch>
    )
}

export default AtualizarColaboradores;
