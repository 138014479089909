export const columns = [
    {
        label: 'Função',
        name: 'funcao_nome',
        width: '50%'
    },
    {
        label: 'Salário Bruto',
        name: 'salario_bruto',
        width: '40%'
    },

];
    
const SimulacaoTeamListInfo = { columns };

export default SimulacaoTeamListInfo;
