import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import { Prompt, useRouteMatch, useHistory } from "react-router-dom";
import Loading from "../../../components/Loading";
import { Button, Card, Col, Form, InputGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import FuncaoApiService from "../../../services/api/FuncaoApiService";
import FuncaoDetail from "./FuncaoDetail";
import TableFuncoes from "./TableFuncoes";
import TableCustos from "./TableCustos";
import CustoDetail from "./CustoDetail";
import * as R from 'ramda'
import TableTotal from "./TableTotal";
import { FaPen, FaPlus, FaSave, FaTrash } from "react-icons/fa";
import CustosPrevistosService from "../../../services/api/CustosPrevistosService";
import { formatDataSave, formatResponse, getTotais } from "./formatData";
import useAlertNotification from "../../../context/AlertNotificationContext";
import ErrorRequestService from "../../../services/errorRequest";
import { MdClose } from "react-icons/md";
import ClienteoApiService from "../../../services/api/CadastroClientesService";
import ConvertDataService from "../../../services/util/ConvertDatas";
import ProjetosApiService from "../../../services/api/ProjetosApiService";

function ProjetoPrevistoView() {
    const history = useHistory();
    const alert = useAlertNotification()

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [tabs, setTabs] = useState([1]);
    const [currentTab, setCurrentTab] = useState(1)
    const [checkCopy, setCheckCopy] = useState(false)
    const [edit, setEdit] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [clientes, setClientes] = useState("");
    const [client, setClient] = useState("");
    const [keyClient, setKeyClient] = useState("");
    const [dataCliente, setDataCliente] = useState("");
    const [nomeProjetoFiltrado, setNomeProjetoFiltrado] = useState("")

    const [origData, setOrigData] = useState({ funcoes: [], custos: [], meses: [] });
    const [rowsDataFuncoes, setRowsDataFuncoes] = useState([]);
    const [rowsDataCustos, setRowsDataCustos] = useState([]);
    const [mesAno, setMesAno] = useState({ 1: '' })

    const [funcoes, setFuncoes] = useState([]);
    const [valorVendaChange, setValorVendaChange] = useState([]);
    const [valorVenda, setValorVenda] = useState([]);
    const [mesAnoExclude, setMesAnoExclude] = useState([])
    const [margemCusto, setMargemCusto] = useState('');

    const match = useRouteMatch();
    const { key } = match.params;

    const transformNumber = (value) => {
        return Number(value?.replaceAll('.', '').replace(',', '.'));
    }

    const askForLeave = (cbPositive, cbNegative = () => { }) => {
        if (cbPositive instanceof Function) {
            const msg = 'É preciso salvar as alterações!\nDeseja realmente sair e perdê-las?';
            const leave = window.confirm(msg);

            if (leave) cbPositive();
            else cbNegative();
        }
        return false;
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                setRowsDataFuncoes(origData.funcoes);
                setRowsDataCustos(origData.custos);
                setTabs(origData.indices);
                setMesAno(origData.mesesAnos);
                setEdit(!edit);
            });
            return;
        }
        if (edit) {
            setEdit(!edit);
            setClient("");
            setDataCliente("");
            handleGetNameClient();
            return;
        }
        history.goBack();
    }

    const handleFuncao = (item) => {
        const indexFunction = rowsDataFuncoes.findIndex(funcao => funcao.id === item.id);

        if (indexFunction !== -1) {
            const newRows = [...rowsDataFuncoes];

            newRows[indexFunction] = {
                ...item,
                totalFuncaoMes: {
                    ...item.totalFuncaoMes,
                    [currentTab]: R.multiply(transformNumber(item.horas[currentTab]), transformNumber(item.taxaCustoVenda))
                }
            };

            setRowsDataFuncoes(newRows);
        } else {
            setRowsDataFuncoes((state) => [
                ...state,
                {
                    ...item,
                    totalFuncaoMes: {
                        [currentTab]: R.multiply(transformNumber(item.horas[currentTab]), transformNumber(item.taxaCustoVenda))
                    },
                    id: Math.random().toString(36).substring(2, 9)
                }
            ]);
        }

        setIsEdited(true);
        CustomModal.hide();
    }

    const handleCustos = (item) => {
        const indexFunction = rowsDataCustos.findIndex(funcao => funcao.id === item.id);

        if (indexFunction !== -1) {
            const newRows = [...rowsDataCustos];

            newRows[indexFunction] = item;
            setRowsDataCustos(newRows);
        } else {
            setRowsDataCustos((state) => [
                ...state,
                { ...item, id: Math.random().toString(36).substring(2, 9) }
            ]);
        }

        setIsEdited(true);
        CustomModal.hide();
    }

    const deleteHoraFuncao = async (id) => {
        let response = await CustosPrevistosService.deleteByProject(key)
        const newRows = rowsDataFuncoes.map(obj => {
            if (obj.id === id) {
                const newHoras = { ...obj.horas }
                delete newHoras[currentTab]

                if (Object.keys(newHoras).length === 0) {
                    return null;
                }

                return { ...obj, horas: newHoras }
            }
            return obj;
        })

        setRowsDataFuncoes(newRows.filter(Boolean))
        CustomModal.hide();
    }

    const deleteValorCusto = async (id) => {
        let response = await CustosPrevistosService.deleteByProject(key)
        const newRows = rowsDataCustos.map(obj => {
            if (obj.id === id) {
                const newValor = { ...obj.valor }
                delete newValor[currentTab]

                if (Object.keys(newValor).length === 0) {
                    return null;
                }

                return { ...obj, valor: newValor }
            }
            return obj;
        })

        setRowsDataCustos(newRows.filter(Boolean))
        CustomModal.hide();
    }

    const openModalFunction = (item) => {
        CustomModal.show({
            title: 'Nova Função',
            body: (
                <FuncaoDetail
                    item={item}
                    handleFuncao={handleFuncao}
                    deleteHoraFuncao={deleteHoraFuncao}
                    currentTab={currentTab}
                    dataFuncoes={funcoes}
                    rowsDataFuncoes={rowsDataFuncoes}
                    onCancel={() => CustomModal.hide()}
                    edit={edit}
                />
            )
        })
    }

    const openModalCosts = (item) => {
        CustomModal.show({
            title: 'Novo Custo',
            body: (
                <CustoDetail
                    item={item}
                    handleCustos={handleCustos}
                    deleteValorCusto={deleteValorCusto}
                    currentTab={currentTab}
                    rowsDataCustos={rowsDataCustos}
                    onCancel={() => CustomModal.hide()}
                    edit={edit}
                    verificarPagina={'custo-indireto'}
                />
            )
        })
    }

    const newTab = () => {
        if (checkCopy) {
            const newRows = rowsDataFuncoes.map(item => {
                if(item.horas[currentTab]){
                    const newHoras = { ...item.horas, [tabs.length + 1]: item.horas[currentTab] }
                    const newTotalFuncaoMes = { ...item.totalFuncaoMes, [tabs.length + 1]: item.totalFuncaoMes[currentTab] }
                    return { ...item, horas: newHoras, totalFuncaoMes: newTotalFuncaoMes }
                }
                return item
            })

            const newRowsCustos = rowsDataCustos.map(item => {
                if(item.valor[currentTab]){
                    const newValor = { ...item.valor, [tabs.length + 1]: item.valor[currentTab] }
                    return { ...item, valor: newValor }
                }
                return item
            })

            if(mesAno[currentTab]) {
                const mesAnoCopy = mesAno[currentTab];
                const [mes, ano] = mesAno[tabs.length].split("-");
                let mesAnoNext = ""
                if(Number(mes) < 9) {
                    mesAnoNext = `0${Number(mes)+1}-${ano}`
                } else if ( Number(mes) > 11) {
                    mesAnoNext = `0${1}-${Number(ano)+1}`
                } else {
                    mesAnoNext = `${Number(mes)+1}-${ano}`;
                };
                let valorFiltered = valorVenda.filter((valor) => valor.mesAno == mesAnoCopy);
                if(valorFiltered.length < 1) {
                    valorFiltered = valorVendaChange.filter((valor) => valor.mesAno == mesAnoCopy);
                }

                if(valorFiltered[0]?.valor) {

                    const data = {
                        mesAno: mesAnoNext,
                        valor: valorFiltered[0]?.valor, 
                        key_project: key
                    }

                    setValorVendaChange((prev) => {
                        const index = prev.findIndex(item => item.mesAno === mesAno);
                
                        if (index !== -1) {
                            const newState = [...prev];
                            newState[index] = data;
                            return newState;
                        } else {
                            return [...prev, data];
                        }
                    });
                };
            } else {
                alert.error("Não é possível cadastrar um Valor Venda sem um mês selecionado.")
            }

            setRowsDataFuncoes(newRows);
            setRowsDataCustos(newRowsCustos);
            setIsEdited(true);
        }

        let lastTab = Math.max(...tabs.map(Number));
        if (mesAno[tabs.length] !== undefined) {
            setMesAno(state => {
                const newState = { ...state }
                newState[tabs.length + 1] = incrementarMes(newState[tabs.length])

                return newState
            })
        }

        setTabs((state) => [...state, state.length + 1])
        setCurrentTab(tabs.length + 1)
    }

    const deleteMonth = () => {
        if (tabs.length === 1) {
            return;
        }

        const newRows = rowsDataFuncoes.reduce((acc, item) => {
            const newHoras = { ...item.horas };
            delete newHoras[tabs.length];
            const hasHours = Object.keys(newHoras).length > 0;
            if (hasHours) {
                acc.push({ ...item, horas: newHoras });
            }
            return acc;
        }, []);

        const newRowsCustos = rowsDataCustos.reduce((acc, item) => {
            const newValor = { ...item.valor };
            delete newValor[tabs.length];
            const hasValue = Object.keys(newValor).length > 0;
            if (hasValue) {
                acc.push({ ...item, valor: newValor });
            }
            return acc;
        }, []);

        setMesAnoExclude((prevMesAno) => [...prevMesAno, mesAno[tabs.length]]);
        setRowsDataFuncoes(newRows);
        setRowsDataCustos(newRowsCustos);
        setTabs(tabs.filter(item => Number(item) !== tabs.length))
        setIsEdited(true)

        if (Number(currentTab) === tabs.length) {
            setCurrentTab(tabs[tabs.length - 2])
        }
    }

    const getTotalMonth = () => {
        const totalFuncao = rowsDataFuncoes
            .filter(item => item.horas[currentTab] !== undefined)
            .reduce((total, item) => {
                total = R.sum([total, item.totalFuncaoMes[currentTab]])
                return total;
            }, 0)

        const totalCusto = rowsDataCustos
            .filter(item => item.valor[currentTab] !== undefined)
            .reduce((total, item) => {
                total = R.sum([total, transformNumber(item.valor[currentTab])])
                return total;
            }, 0)

        return R.sum([totalFuncao, totalCusto]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    const removeDuplicates = (data) => {
        const uniqueData = {};

        data.forEach((item) => {
            if (!uniqueData[item.mesAno]) {
                uniqueData[item.mesAno] = item;
            }
        });

        return Object.values(uniqueData);
    };

    const saveValorVenda = async (valoresVenda) => {
        const valoresVendaFormat = removeDuplicates(valoresVenda);
        valoresVendaFormat.map(async (valorVenda) => {
            if(valorVenda.valor.includes(",")) {
                valorVenda.valor = valorVenda.valor.replace("," , ".")
            }

            try {
                const resp = await CustosPrevistosService.createValorVenda(valorVenda);
                getValorVenda();
            } catch (error) {
                console.error("Erro ao cadastrar valor de venda.", error);
                alert.error("Não foi possível cadastrar um valor venda")
            }
        })
    };

    const saveMargemCusto = async () => {
        if(margemCusto) {
            if(margemCusto.includes(",")) {
                valorVenda.valor = valorVenda.valor.replace("," , ".")
            };
        };

        try {
            const resp = await CustosPrevistosService.updateMargemCusto({
                "margem_custo_projeto": Number(margemCusto)
            }, key);
        } catch (error) {
            console.error("Erro ao cadastrar margem de custo do projeto.", error);
            alert.error("Não foi possível cadastrar uma margem de custo")
        }
    };

    const deleteValorVenda = async () => {
        mesAnoExclude.map( async (mesAno) => {
            const filteredData = valorVenda.filter(item => item.mesAno !== mesAno);
            setValorVenda(filteredData);
            try {
                const resp = await CustosPrevistosService.deleteValorVenda(key, mesAno);
            } catch (error) {
                console.error("Erro ao deletar valor venda", error);
            }
        });
    };

    const saveData = async () => {

        if(mesAnoExclude.length > 1) {
            deleteValorVenda();
        };

        try {
            setIsLoadingSave(true);
            saveValorVenda(valorVendaChange);
            setValorVendaChange([]);
            saveMargemCusto();

            const formFormated = formatDataSave(rowsDataFuncoes, rowsDataCustos, key, mesAno)
            const resultTotal = getTotais(rowsDataFuncoes, rowsDataCustos, margemCusto)

            let response = null;

            if (formFormated?.funcoes.length === 0 && formFormated?.custos.length === 0) {
                response = await CustosPrevistosService.deleteByProject(key)
            } else {
                response = await CustosPrevistosService.insert(formFormated)
            }

            const responseTotal = await CustosPrevistosService.insertTotal({
                "total_custo_indireto": resultTotal.totalCustoIndireto,
                "total_horas": resultTotal.totalHoras,
                "total_funcao": resultTotal.totalFuncao,
                "total_custo_venda": resultTotal.totalCustoVenda,
                "total": resultTotal.total,
                "margem_custo": resultTotal.margemCusto,
                "projeto": key,
                "margem_custo_projeto": margemCusto,
            })

            if (response.status === 201 && responseTotal.status === 201) {
                alert.success("Dados salvos com sucesso");
                setEdit(false);
                setIsEdited(false);
            }

        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            setIsLoadingSave(false);
        }
    }

    const onChangeMesAno = (event) => {
        const { name, value } = event.target;

        // Funcao para deixar no formato mm-yyyy
        setMesAno((state) => {
            if (name === 'mes') {
                const newMes = `${value}-${state[1]?.split('-')[1] || ''}`

                const newState = { ...state, 1: newMes }

                for (let tab = 2; tab <= tabs.length; tab++) {
                    newState[tab] = incrementarMes(newState[tab - 1])
                }

                return newState
            } else {
                const newAno = `${state[1]?.split('-')[0] || ''}-${value}`

                const newState = { ...state, 1: newAno }

                for (let tab = 2; tab <= tabs.length; tab++) {
                    newState[tab] = incrementarMes(newState[tab - 1])
                }

                return newState
            }
        })
    }

    const incrementarMes = (mouthYear) => {
        const [mes, ano] = mouthYear.split('-');
        const nextMonth = parseInt(mes, 10) === 12 ? 1 : parseInt(mes, 10) + 1;
        const nextYear = parseInt(mes, 10) === 12 ? parseInt(ano, 10) + 1 : parseInt(ano, 10);
        return `${nextMonth.toString().padStart(2, '0')}-${nextYear}`;
    };

    const onStart = () => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const dataFunction = await FuncaoApiService.getAll()
                const data = await CustosPrevistosService.getAll(key)

                const { funcoes, custos, indices, mesesAnos, indiceComplete } = formatResponse(data?.data)

                setTabs(indiceComplete);
                setMesAno(mesesAnos);
                setFuncoes(dataFunction.data.data);

                setRowsDataFuncoes(funcoes);
                setRowsDataCustos(custos);
                setOrigData({ funcoes, custos, indiceComplete, mesesAnos });

            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }

        getData();
    }

    useEffect(onStart, [])

    const actions = [
        (!edit ? {
            label: 'Editar',
            icon: <FaPen />,
            color: 'primary',
            type: 'button',
            onClick: () => setEdit(true)
        } : {
            label: 'Salvar',
            icon: <FaSave />,
            color: 'success',
            type: 'button',
            disabled: isLoadingSave,
            onClick: saveData
        }),
        {
            label: 'Cancelar',
            icon: <MdClose />,
            color: 'danger',
            type: 'button',
            onClick: handleCancel
        }
    ];

    const getClientsList = async () => {
        try {
            const data = await ClienteoApiService.getClientAll();
            setClientes(data.data);
        } catch (error) {
            console.error('Erro ao chamar todos os clientes:', error);
        }
    };

    const handleClientForm = async (cliente) => {
        if(cliente == "") {
            console.error("Cliente inválido")
            alert.error("Cliente inválido")
        } else {
            
            let dadosCliente = {
                "nome": cliente.name,
                "chave" : cliente.key
            }

            let dadosProjeto = {
                "nome": nomeProjetoFiltrado,
                "chave" : key
            }

            try {
                await ClienteoApiService.createClientWithProjects(dadosCliente, dadosProjeto)
                setEdit(false)
                handleGetNameClient();
            } catch (error) {
                alert.error("Não é possível adicionar esse cliente")
                console.error('Erro ao chamar relacionar cliente:', error);
            }
        }
    };

    const modalAlterClientConfirm = (cliente) => {
        if(cliente == "") {
            console.error("Cliente inválido")
            alert.error("Cliente inválido")
        } else {
            CustomModal.show({
                title: "Alterar Cliente",
                body:   <>
                            <p>Deseja alterar o projeto <b>{key}</b> do cliente <b>{client}</b> para o cliente <b>{cliente.name}</b> ?</p>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-outline-success" onClick={() => handleAlterClientForm(cliente)}>Alterar</button>
                                <button className="btn btn-outline-danger ml-3" onClick={() => CustomModal.hide()}>Cancelar</button>
                            </div>
                        </>
            })
        }
    };

    const handleAlterClientForm = async (cliente) => {  

        let newClient = {
            "nome": cliente.name,
            "chave" : cliente.key
        }

        try {
            await ClienteoApiService.updateCliente(key, newClient)
            setEdit(false)
            handleGetNameClient();
            CustomModal.hide();
        } catch (error) {
            alert.error("Não é possível adicionar esse cliente")
            console.error('Erro ao chamar relacionar cliente:', error);
        }
    };

    const handleChangeCliente = (clienteData) => {
        setDataCliente(clienteData)
    };

    const handleGetNameClient = async () => {
        try {
            const resp = await ClienteoApiService.getClientByProjectKey(key);
            setClient(resp.data.result[0].cliente_nome);
            setKeyClient(resp.data.result[0].cliente_chave);
        } catch (err) {
            console.error("Erro ao buscar o nome do cliente: ", err)
        }
    };

    const handleGetProjectNameFiltered = async () => {
        try {
            setIsLoading(true)
            const resp = await ProjetosApiService.getAllProject();
            const nomeFiltrado = resp.data.data.filter(projeto => projeto.key === key);
            setNomeProjetoFiltrado(nomeFiltrado[0].name);
        } catch (error) {
            console.error("Erro ao buscar o nome do projeto: ", error)
        } finally{
            setIsLoading(false);
        }
    };

    const handleChangeValorVenda = (event, mesAno) => {
        let inputValue = event.target.value;
    
        const data = { mesAno, valor: inputValue, key_project: key };
    
        setValorVendaChange((prev) => {
            const index = prev.findIndex(item => item.mesAno === mesAno);
    
            if (index !== -1) {
                const newState = [...prev];
                newState[index] = data;
                return newState;
            } else {
                return [...prev, data];
            }
        });
    };

    const verifyValorVenda = (e) => {
        const totalMes = getTotalMonth();
        let totalMesValor = totalMes.replace("R$", "").trim();
        totalMesValor = totalMesValor.replace(",", ".");

        const valorVenda = e.target.value.replace(",", ".");

        if(valorVenda <= totalMesValor) {
            alert.info("Atenção! Valor Venda menor ou igual ao Total do Mês")
        }
    }

    const handleChangeMargem = (e) =>{
        setMargemCusto(e.target.value)       
    }

    const getValorVenda = async () => {
        try {
            const resp = await CustosPrevistosService.getValorVendaByKey(key);
            setValorVenda(resp.data.data)
        } catch (error) {
            console.error("Erro ao buscar valor venda.", error)
        }
    };

    const getMargemCusto = async () => {
        try {
            const resp = await CustosPrevistosService.findByProject(key);
            setMargemCusto(resp.data.margem_custo_projeto);
        } catch (error) {
            console.error("Erro ao buscar valor venda.", error);
        }
    };

    useEffect(() => {
        handleGetProjectNameFiltered();
        getClientsList();
        handleGetNameClient();
        getValorVenda();
        getMargemCusto();
    }, [])

    const title = `Planejamento Financeiro - ${key}`

    return (
        <PageContainer
            title={title}
            actions={actions}
        >
            {isLoading
                ? <Loading message="Carregando..." />
                : <>
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Cliente: {client == "" ? "Cliente não cadastrado" : client}</h5>
                

                    <InputGroup style={{width:"300px"}}>
                        <h5 className="d-flex align-self-center mb-0 mr-3">Margem Prevista: </h5>
                        
                        <Form.Control
                            type="number"
                            disabled={!edit}
                            placeholder="00.00%"
                            aria-label="Valor"
                            aria-describedby="basic-addon2"
                            value={margemCusto}
                            onChange={handleChangeMargem}
                            className="no-arrows"
                        /><InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                    </InputGroup>
            
                </div>
            
                    {edit &&
                        <Form className="mt-3 w-50">
                            <Form.Group className="d-flex justify-content-between align-items-center">
                                <Form.Label>Cliente: </Form.Label>
                                <Form.Control
                                    as="select"
                                    name="clientes"
                                    onChange={(e) => {
                                        const selectedClient = JSON.parse(e.target.value);
                                        handleChangeCliente(selectedClient)
                                    }}
                                    className="w-50"
                                >
                                    <option value="">--Cliente--</option>
                                    {clientes
                                        .slice()
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((item, index) => (
                                            <option value={JSON.stringify(item)} key={index}>{item.name}</option>
                                        ))
                                    }
                                </Form.Control>
                                {client ?
                                    <Button onClick={() => modalAlterClientConfirm(dataCliente)}>Alterar Cliente</Button>
                                    :
                                    <Button onClick={() => handleClientForm(dataCliente)}>Adicionar Cliente</Button>
                                }
                            </Form.Group>
                        </Form>
                    }
                    <hr></hr>
                    {edit &&
                        <Form className="mb-3 d-flex align-items-center">
                            <Form.Check
                                type="checkbox"
                                label="Copiar dados do mês atual"
                                checked={checkCopy}
                                onChange={() => setCheckCopy(!checkCopy)}
                            />

                            <Button onClick={newTab} className="ml-2" disabled={!edit}>
                                <FaPlus /> Adicionar mês
                            </Button>

                            {tabs.length > 1 && (
                                <Button variant="danger" className="ml-2" onClick={deleteMonth} disabled={!edit}>
                                    <FaTrash /> Excluir mês
                                </Button>
                            )}
                        </Form>
                    }
                    <div style={{maxWidth: "calc(100vw - 280px)"}}>
                    <Tabs
                        activeKey={currentTab}
                        onSelect={(key) => setCurrentTab(key)}
                    >
                        {tabs.map((tab, index) => {
                            const valorVendaMes = valorVenda.find(item => item.mesAno == mesAno[tab]);
                            const valorVendaChangeMes = valorVendaChange.find(item => item.mesAno == mesAno[tab]);
                            return (
                            <Tab key={tab} eventKey={tab} title={`Mês ${tab}`}>

                                <Form noValidate className="d-flex mt-3">
                                    <Form.Group className="mr-3">
                                        <Form.Label>Mês</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="mes"
                                            value={mesAno[tab]?.split('-')[0] || ''}
                                            onChange={onChangeMesAno}
                                            disabled={tab !== "1" || !edit}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="01">Janeiro</option>
                                            <option value="02">Fevereiro</option>
                                            <option value="03">Março</option>
                                            <option value="04">Abril</option>
                                            <option value="05">Maio</option>
                                            <option value="06">Junho</option>
                                            <option value="07">Julho</option>
                                            <option value="08">Agosto</option>
                                            <option value="09">Setembro</option>
                                            <option value="10">Outubro</option>
                                            <option value="11">Novembro</option>
                                            <option value="12">Dezembro</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Ano</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="ano"
                                            value={mesAno[tab]?.split('-')[1] || ''}
                                            onChange={onChangeMesAno}
                                            disabled={tab !== "1" || !edit}
                                        >
                                            <option value="">Selecione</option>

                                            {ConvertDataService.getAnos().map(ano => (
                                                <option key={ano} value={ano}>{ano}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Form>

                                <Form className="mb-3">
                                {edit || valorVendaMes ?
                                    <div className="d-flex mb-4 mt-3">
                                        <h5 className="d-flex align-self-center mb-0">Valor de Venda Mensal: <span className="ml-2"> R$ {valorVendaMes ? parseFloat(valorVendaMes?.valor).toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : parseFloat(valorVendaChangeMes?.valor).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span></h5>
                                    </div>
                                    : <></>
                                }
                                {edit ?
                                    <InputGroup className="" style={{width:"200px"}}>
                                        <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                                        <Form.Control
                                            type="number"
                                            placeholder="00,00"
                                            aria-label="Valor"
                                            aria-describedby="basic-addon1"
                                            value={valorVendaChange.value}
                                            onChange={(e) => handleChangeValorVenda(e, mesAno[tab])}
                                            onBlur={(e) => verifyValorVenda(e)}
                                        />
                                    </InputGroup>
                                    : <></>
                                }
                                </Form>

                                <Row className="mt-1">
                                    <Col>
                                        <TableFuncoes
                                            currentTab={currentTab}
                                            rowsDataFuncoes={rowsDataFuncoes}
                                            openModalFunction={openModalFunction}
                                            edit={edit}
                                        />
                                    </Col>

                                    <Col>
                                        <TableCustos
                                            currentTab={currentTab}
                                            rowsDataCustos={rowsDataCustos}
                                            openModalCosts={openModalCosts}
                                            edit={edit}
                                        />
                                    </Col>
                                </Row>

                                <Card className="p-3">
                                    <Row>
                                        <Col>
                                            <p className="m-0"><strong>Total do mês:</strong>{getTotalMonth()}</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Tab>
                        )}
                        )}
                        <Tab eventKey={0} title="Total">
                            <TableTotal
                                rowsDataFuncoes={rowsDataFuncoes}
                                rowsDataCustos={rowsDataCustos}
                                tabs={tabs}
                                projetoKey={key}
                            />
                        </Tab>
                    </Tabs>
                    </div>

                    <CustomModal />
                </>
            }
            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </PageContainer>
    )
}

export default ProjetoPrevistoView;
