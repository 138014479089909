import * as Yup from 'yup';
import ValidationService from './validation';

const schema = Yup.object().shape({
    email: Yup
        .string()
        .required("Campo obrigatório.")
        .email("Digite um e-mail válido")
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const ForgotValidation = {
    schema,
    validate,
    castValues
};

export default ForgotValidation;