/** 
 * Informações para os campos do form
 * @type {{
 *   groups: Array<{
 *      header: string,
 *      fields: Array<{
 *        label: string,
 *        placeholder: string,
 *        name: string,
 *        type: string,
 *        size: number,
 *        className: string
 *      }>
 *   }
 * }>} 
*/
export const getFormFields = () => {
    return {
        groups: [
            {
                header: '',
                shouldRender: (form) => {
                    return ![null, undefined].includes(form.cod);
                },
                fields: [
                    {
                        label: 'Id',
                        placeholder: '',
                        name: 'id',
                        type: 'text',
                        size: 3,
                        readOnly: true
                    },
                    {
                        label: 'Usuário',
                        placeholder: '',
                        name: 'usuario',
                        type: 'text',
                        size: 4,
                        readOnly: true
                    },
                ]

            },
            {
                header: '',
                fields: [
                    // {
                    //     label: 'Nome',
                    //     placeholder: 'Digite seu nome aqui...',
                    //     name: 'nome',
                    //     type: 'text',
                    //     size: 6,
                    //     max: 60,
                    // },
                    {
                        label: 'E-mail',
                        placeholder: 'Digite seu e-mail aqui...',
                        name: 'email',
                        type: 'email',
                        size: 6,
                        max: 60,
                        readOnly: true
                    },
                ]
            },
            {
                fields: [
                    {
                        label: 'Alterar senha?',
                        name: 'altera',
                        type: 'checkbox',
                        // defaultChecked: true,
                        // defaultValue: true,
                        size: 12,
                        // readOnly: true
                    },
                ]

            },
            {
                header: 'Alteração de Senha',
                shouldRender: (form) => {
                    return !!form.altera;
                },
                fields: [
                    {
                        label: 'Nova Senha',
                        placeholder: '******',
                        name: 'novaSenha',
                        type: 'password',
                        size: 6,
                        // max: 60,
                    },
                    {
                        label: 'Confirmação',
                        placeholder: '******',
                        name: 'confirma',
                        type: 'password',
                        size: 6,
                        // max: 60,
                    },
                ]
            },
            {
                header: '', 
                fields: [
                    {
                        label: 'Observações',
                        placeholder: 'Digite aqui outras informações ou detalhes sobre você...',
                        name: 'observacao',
                        type: 'textarea',
                        // defaultValue: '',
                        size: 12,
                        max: 300
                    },
                ]
            },
        ]
    };
}
const UsuarioFormInfo = {
    getFormFields
}

export default UsuarioFormInfo;