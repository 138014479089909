function hoursToMinutesInBR(value) {
    const h = parseInt(value);
    let min = ((value - Math.floor(h)) + "").split(".")[1];

    min = (min * 60);

    min = Number.isNaN(min) ? '0' : String(min).substring(0,2);

    const hours = h < 9 ? `0${h}` : h;
    const minute = min < 9 ? `0${min}` : min;

    return `${hours}h ${minute}min`;
}

const FormatDataService = {
    hoursToMinutesInBR
}

export default FormatDataService;
