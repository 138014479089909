import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { FaArrowLeft, FaKey, FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import Loading from '../../../components/Loading';
import useAuth from '../../../context/AuthContext';
import useAlertNotification from '../../../context/AlertNotificationContext';
import LoginValidation from '../../../services/validation/LoginValidation';
import UtilService from '../../../services/util';
import AuthApiService from '../../../services/api/AuthApiService';
import ErrorRequestService from '../../../services/errorRequest';

import './ConfirmRegisterPage.css';

const INITIAL_PAGE_PATH = "/login";

/**
 * Página de confirmação a ser renderizada
 */
function ConfirmRegisterPage() {
    const title = "Confirmação de cadastro de usuário";
    UtilService.setTitle(title);

    // CONTEXT
    const { user } = useAuth();
    const alert = useAlertNotification();
    const history = useHistory();
    const match = useRouteMatch();

    const { token } = match.params; // token da solicitação

    // STATE
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenChecked, setIsTokenChecked] = useState(false);
    const [solicitation, setSolicitation] = useState(null);
    const [isValidToken, setIsValidToken] = useState(false);
    const [authentication, setAuthentication] = useState(null);
    const [messageDone, setMessageDone] = useState(null);

    // FUNCTIONS
    const handleCancel = () => {
        history.replace(INITIAL_PAGE_PATH);
    }

    /**
     * Manuseia os erros recebidos da api (quando api retorna 200 c/ erro)
     * @param {any} errors 
     */
    const handleError = (errors) => {
        const msgError = []
        Object.keys(errors).forEach(key => {
            const errStr = errors[key].map(item => item.errorMessage).join('\n');
            msgError.push(errStr);
        })
        const errorsStr = msgError.join('\n')
        alert.error(errorsStr);
        return errorsStr;
    }

    /**
     * Faz a autenticação de admin, e envia o token de solicitação p/ verificar validade
     * @param {*} form formulário de autenticação
     */
    const onSubmitAuthentication = async (form) => {
        setIsLoading(true);
        try {
            const encodedForm = UtilService.encodeUtf8AsBase64(JSON.stringify(form));
            const respAuth = await AuthApiService.authenticateRegister(encodedForm).then(r => r.data);
            const { errors, token: authToken } = respAuth;

            // Em caso de erro de autenticação (api retorna 200 c/ erro)
            if (errors) {
                const errStr = handleError(errors);
                console.log("[ERROR] [ConfirmRegisterPage.onSubmitAuthentication]:", { errors, errStr });
                return;
            }

            setAuthentication(authToken);
        }
        catch (err) {
            const errorReq = new ErrorRequestService(err);
            const errMsg = errorReq.getErrors();
            console.log("[ERROR] [ConfirmRegisterPage.onSubmitAuthentication]:", errMsg);
            alert.error(errMsg);
            return;
        }
        finally {
            setIsLoading(false);
        }
    }

    /**
     * Submete confirmação (positiva ou negativa) do cadastro armazenado no token 
     * @param {boolean} confirmation true=Aceitar, false=Rejeitar
     * @returns 
     */
    const onSubmitConfirmation = async (confirmation) => {
        setIsLoading(true);
        try {
            const response = await AuthApiService.confirmRegister(confirmation, authentication, token).then(r => r.data);
            const { errors, message } = response;
            
            // Em caso de erro c/ solicitação (api retorna 200 c/ erro)
            if (errors) {
                const errStr = handleError(errors);
                console.log("[ERROR] [ConfirmRegisterPage.onSubmitConfirmation]:", { errors, errStr });
                return;
            }

            setMessageDone(message);
        }
        catch (error) {
            const errorReq = new ErrorRequestService(error);
            const errMsg = errorReq.getErrors();
            console.log("[ERROR] [ConfirmRegisterPage.onSubmitConfirmation]:", errMsg);
            alert.error(errMsg);
        }
        finally {
            setIsLoading(false);
        }
    };

    // Verifica dados da solicitação do token  
    const onStart = () => {
        const checkSolicitation = async () => {
            setIsLoading(true);
            try {
                const respSolic = await AuthApiService.verifySolicitation(token).then(r => r.data);
                const { errors, nome, email, mensagem } = respSolic;

                // Em caso de erro c/ solicitação (api retorna 200 c/ erro)
                if (errors) {
                    const errStr = handleError(errors);
                    console.log("[ERROR] [ConfirmRegisterPage.onStart]:", { errors, errStr });
                    setMessageDone(errStr);
                    setIsValidToken(false);
                    setAuthentication('xxx');
                    return;
                }
                
                setSolicitation({ nome, email, mensagem });
                setIsValidToken(true);
            }
            catch (error) {
                const errorReq = new ErrorRequestService(error);
                const errMsg = errorReq.getErrors();
                console.log("[ERROR] [ConfirmRegisterPage.onStart]:", { error, errMsg });
                alert.error(errMsg);
                setMessageDone(errMsg);
                setIsValidToken(false);
                setAuthentication('xxx');
            }
            finally {
                setIsLoading(false);
                setIsTokenChecked(true);
            }
        } 
        if (!isTokenChecked) checkSolicitation();
    }

    // USEEFFECTS
    useEffect(onStart, [token, isTokenChecked]);
    
    // RENDER
    // Não renderizar essa página se logado na aplicação, ou não houver token da solicitação
    if (!!user || !token) return <Redirect to={INITIAL_PAGE_PATH} />

    const cssExplain = [
        'text-explain  text-center',
        (messageDone ? `text-${(isValidToken ? 'success' : 'danger')}` : 'text-muted'),
    ].join(' ');

    return (
        <div className="confirm-container">

            <h4>{ title }</h4>

            {(!isTokenChecked && isLoading) && (
                <Loading message="Carregando..." />
            )}

            {(isTokenChecked) && (<>

                {(!authentication) && (
                    <FormAuthentication
                        onSubmit={onSubmitAuthentication}
                        isLoading={isLoading}
                    />
                )}

                {(!!authentication) && (
                    <div>
                        <p className={cssExplain}>
                            { messageDone || `Dados da solicitação precisam ser confirmados e aprovados/rejeitados.` }
                        </p>
                        <hr/>
                        {(!messageDone) 
                            // DADOS DA SOLICITAÇÃO E BOTÕES DE CONFIRMAÇÃO
                            ? <SolicitationDecision
                                solcitationData={solicitation}
                                onChoose={onSubmitConfirmation}
                                isLoading={isLoading}
                            />
                            // PROCESSO CONCLUÍDO/INTERROPIDO
                            : <ConcludedProcess
                                isSuccess={isValidToken}
                                buttonAction={handleCancel}
                            />
                        }
                    </div>
                )}

            </>)}
        </div>
    )
}


/**
 * Renderia a mensagem após concluído o processo
 */
function ConcludedProcess({ isSuccess, buttonAction }) {
    const successCss = (isSuccess ? 'success' : 'danger');
    return (
        <div className={`success-container text-${successCss}`}>
            <div className="thumbs-up-icon">
                { isSuccess ? <FaThumbsUp /> : <FaThumbsDown /> }
            </div>

            <hr/>
            
            <Button 
                className="confirm-button"
                variant={successCss}
                onClick={buttonAction}
            >
                <FaArrowLeft /> Ir para tela de Login
            </Button>
        </div> 
    )
}


/**
 * Exibe os dados da solicitação, e botões de decisão de cadastro
 */
function SolicitationDecision({ solcitationData, onChoose, isLoading }) {
    // STATE
    const [isAccepted, setIsAccepted] = useState(false);

    const handleOnClick = (decision) => {
        setIsAccepted(decision);
        onChoose(decision);
    }

    return (
        <>
            <p>Dados da Solicitação:</p>
            <ul style={{listStyle:'none', fontStyle:'italic'}}>
                <li>Nome: {solcitationData.nome}</li>
                <li>E-mail: {solcitationData.email}</li>
                <li>mensagem: {solcitationData.mensagem}</li>
            </ul>
            <div className="confirm-btn-container row">
                <Button
                    className="confirm-button col"
                    variant="success"
                    onClick={() => handleOnClick(true)}
                    disabled={isLoading}
                >
                    { isLoading && isAccepted
                        ? <span>Processando...</span>
                        : (<><FaThumbsUp /> Aprovar</>)
                    }
                </Button>

                <Button 
                    className="confirm-button col"
                    variant="danger"
                    onClick={() => handleOnClick(false)}
                    disabled={isLoading}
                >
                    { (isLoading && !isAccepted)
                        ? <span>Processando...</span>
                        : (<><FaKey /> Rejeitar</>)
                    }
                </Button>
            </div>
        </>
    )
}


/**
 * Formulário de autenticação
 */
function FormAuthentication({ onSubmit, isLoading }) {
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                usuario: 'admin',
                senha: ''
            }}
            validationSchema={LoginValidation.schema}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
            }) => (
                <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className="confirm-form"
                >
                    <Form.Group controlId="fg-usuario">
                        <Form.Label>Usuário (Administrador)</Form.Label>
                        <Form.Control 
                            name="usuario"
                            type="text"
                            placeholder="Digite seu nome de usuário..."
                            onChange={handleChange}
                            value={values.usuario}
                            autoComplete="off"
                            disabled={true}
                        />
                    </Form.Group>

                    <Form.Group controlId="fg-senha">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            name="senha"
                            type="password"
                            placeholder="Digite sua senha..."
                            onChange={handleChange}
                            onBlur={handleBlur('senha')}
                            value={values.senha}
                            isValid={(touched.senha && !errors.senha)}
                            isInvalid={(touched.senha && !!errors.senha)}
                            autoComplete="off"
                            disabled={isLoading}
                        />
                        <Form.Control.Feedback type="invalid">
                            { errors.senha }
                        </Form.Control.Feedback>
                    </Form.Group>

                    <hr/>

                    <div className="confirm-btn-container">
                        <Button
                            type="submit"
                            className="confirm-button"
                            variant="primary"
                            disabled={isLoading}
                        >
                            { isLoading
                                ? <span>Autenticando...</span>
                                : (<><FaThumbsDown /> Autenticar</>)
                            }
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}


export default ConfirmRegisterPage;
