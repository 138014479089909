import { useState } from "react";
import { FaSpinner, FaUpload } from "react-icons/fa";
import LoadingProgress from "../../../../components/LoadingProgress";
import PageContainer from "../../../../components/PageContainer";
import Uploader from "../../../../components/Uploader";
import useAlertNotification from "../../../../context/AlertNotificationContext";
import ImportacaoApiService from "../../../../services/api/ImportacaoApiService";
import ErrorRequestService from "../../../../services/errorRequest";

// progress values
let progressInterval = null;
let progVal = 0;

function ColaboradorImport(props) {
    // Hooks
    const alert = useAlertNotification();
    const reader = new FileReader();

    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [imported, setImported] = useState([]);
    const [failed, setFailed] = useState(null);
    const [progress, setProgress] = useState(0);

    // função desencadeada ao ler um arquivo com o reader
    reader.onload = (e) => {
        const lines = reader.result.split(/\r\n|\n|\r/);
        const headers = lines[0].split(";");

        let result = [];

        // começa em 1 porque a primeira linha tem os headers
        // termina na penúltima linha porque a útlima linha fica em branco
        for (let i = 1; i < lines.length - 1; i++) {
            const currentline = lines[i].split(";");
            let obj = {};

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }
            result.push(obj);
        }
        setFileData(result);
    };

    /** Function que atua na exibição de valores do progress */
    const controlProgressInterval = () => {
        if (progVal == 80) {
            progVal = 0;
            clearInterval(progressInterval);
            return;
        }
        progVal += 2;
        setProgress(progVal);
    }

    const changeHandler = (event) => {
        const fileEv = event.target.files[0];

        if (!fileEv) {
            alert.error("Nenhum arquivo selecionado!");
            setFile(null);
            return;
        }

        setFile(fileEv);
        setProgress(0);
        if (fileEv.size)
            reader.readAsText(fileEv);
    };

    const sendData = async () => {
        try {
            setIsSending(true);
            setProgress(0);
            progressInterval = setInterval(controlProgressInterval, 500);

            // Lê o arquivo
            reader.readAsText(file);

            const result = await ImportacaoApiService.beneficiosColaborador(fileData);

            if (!result.data.result) {
                console.log("----- ERROR -------");
                throw new ErrorRequestService();
            }

            const { success, fail } = result.data.result;

            setProgress(100);
            setImported(success);

            const arrErr = [];
            for (const [key, value] of Object.entries(fail)) {
                arrErr.push({
                    'Colaborador': key,
                    'Erros': value
                })
            }
            setFailed(arrErr)

            const successCount = Object.entries(success).length;
            if (!!successCount) {
                alert.success(`Cadastrados ${successCount} com sucesso`);
            }
            
            const failCount = Object.entries(fail).length;
            if (!!failCount) {
                alert.error(`Ocorreu ${failCount} falhas`);
            }
        
        } catch (err) {
            setProgress(0);
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            clearInterval(progressInterval);
            setIsSending(false);
        }
    };

    const actions = [
        {
            label: isSending ? "Importando..." : "Importar",
            icon: isSending ? <FaSpinner /> : <FaUpload />,
            color: "success",
            type: "button",
            disabled: !file || isSending || (progress == 100),
            onClick: sendData,
        },
    ];

    const title = `Importar benefícios de colaboradores`;
    console.log("RENDER", progress);
    return (
        <PageContainer title={title} actions={actions}>

            <Uploader
                accept=".csv,.xlsx,.xls"
                onChange={changeHandler}
                label="Selecione um arquivo XLS, XLSX, ou CSV"
            />

            {(isSending || (progress > 0)) &&
                <LoadingProgress
                    value={progress}
                    altProgressText={`${progress}%`}
                    finishText="Upload Concluído!"
                />
            }

            <br />
            <br />

            {failed &&
                <p>
                    <p>
                        <h3>{ failed.length } registros com falhas na importação</h3>
                    </p>
           
                    {failed.map(item => (
                        <>
                            <strong>CPF</strong>: { item.Colaborador }
                            <p>
                                { item.Erros.map(item => <>{ item }<br /></>) }
                            </p>
                            <hr />
                        </>
                    ))}
                </p>
            }
            
        </PageContainer>
    );
}

export default ColaboradorImport;
