export const getFormFields = () => {
    return {
        groups: [

            {
                fields: [
                    {
                        label: 'Nome do time',
                        placeholder: 'Ex: Convert Squad',
                        name: 'nome',
                        type: 'text',
                        size: 8,    
                    },
                    
                ]
            },
        ]
    };
}

const SimulacaoNewTeamInfo = { getFormFields };

export default SimulacaoNewTeamInfo;
