import { useEffect, useState } from "react";
import { Row, Col, Form, Card } from 'react-bootstrap';
import GraficoBarras from "../../../../components/GraficoBarras";
import * as R from "ramda";
import { format, parse } from "date-fns";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



function CompararCustos({ dataProjectDetail, dataProjectCosts, custosPrevistosData, changeCompareCustos }) {
    const [data, setData] = useState([
        {
            name: 'Total HH',
            "Custos Previstos": 0,
            "Custos Realizados": 0,
        },
        {
            name: 'Infra Cloud',
            "Custos Previstos": 0,
            "Custos Realizados": 0,
        },
        {
            name: 'Outros softwares',
            "Custos Previstos": 0,
            "Custos Realizados": 0,
        },
        {
            name: 'Horas extras',
            "Custos Previstos": 0,
            "Custos Realizados": 0,
        },
        {
            name: 'Fornecedores terceiros',
            "Custos Previstos": 0,
            "Custos Realizados": 0,
        },
        {
            name: 'Outros',
            "Custos Previstos": 0,
            "Custos Realizados": 0,
        },
    ]);
    const [periodoCustoPrevisto, setPeriodoCustoPrevisto] = useState('1');

    const formatarMes = (mesAno) => {
        const [mes, ano] = mesAno.split('-');
        const data = parse(`${mes}-01-${ano}`, 'MM-dd-yyyy', new Date());
        const mesFormatado = format(data, 'MMM-yyyy');

        return mesFormatado;
    };

    const getDesvio = () => {
        let totalExecutado = 0

        if (dataProjectCosts) {
            totalExecutado = R.pipe(
                R.map(cost => parseFloat(cost.cotacao) !== 0 ?
                    R.multiply(parseFloat(cost.valor), parseFloat(cost.cotacao)) : parseFloat(cost.valor)),
                R.sum
            )(dataProjectCosts)

            totalExecutado = R.sum([totalExecutado, dataProjectDetail?.total])
        }

        const desvio = R.pipe(
            R.subtract(),
            R.divide(R.__, custosPrevistosData[periodoCustoPrevisto]?.total),
            R.multiply(100)
        )(custosPrevistosData[periodoCustoPrevisto]?.total, totalExecutado);

        return `${desvio.toFixed(2)}%`
    }

    useEffect(() => {
        let totalByType = {}

        // Separando custos pelo tipo_custo
        if (dataProjectCosts) {
            const groupedCosts = R.groupBy(cost => cost.tipo_custo, dataProjectCosts)

            totalByType = R.mapObjIndexed((costs, key) => {
                return R.pipe(
                    R.map(cost => parseFloat(cost.cotacao) !== 0 ?
                        R.multiply(parseFloat(cost.valor), parseFloat(cost.cotacao)) : parseFloat(cost.valor)),
                    R.sum
                )(costs)
            }, groupedCosts)
        }

        setData(state => {
            let newState = [ ...state ]

            newState[0]["Custos Realizados"] = dataProjectDetail?.total
            newState[1]["Custos Realizados"] = totalByType["INFRA_CLOUD"] || 0
            newState[2]["Custos Realizados"] = totalByType["OUTROS_SOFTWARES"] || 0
            newState[3]["Custos Realizados"] = totalByType["HORAS_EXTRAS"] || 0
            newState[4]["Custos Realizados"] = totalByType["FORNECEDORES_TERCEIROS"] || 0
            newState[5]["Custos Realizados"] = totalByType["OUTROS"] || 0

            return newState
        })
    }, [dataProjectDetail, dataProjectCosts])

    useEffect(() => {
        setData(state => {
            let newState = [ ...state ]

            newState[0]["Custos Previstos"] = custosPrevistosData[periodoCustoPrevisto]?.totalFuncao
            newState[1]["Custos Previstos"] = custosPrevistosData[periodoCustoPrevisto]?.totalCustoIndireto["INFRA_CLOUD"] || 0
            newState[2]["Custos Previstos"] = custosPrevistosData[periodoCustoPrevisto]?.totalCustoIndireto["OUTROS_SOFTWARES"] || 0
            newState[3]["Custos Previstos"] = custosPrevistosData[periodoCustoPrevisto]?.totalCustoIndireto["HORAS_EXTRAS"] || 0
            newState[4]["Custos Previstos"] = custosPrevistosData[periodoCustoPrevisto]?.totalCustoIndireto["FORNECEDORES_TERCEIROS"] || 0
            newState[5]["Custos Previstos"] = custosPrevistosData[periodoCustoPrevisto]?.totalCustoIndireto["OUTROS"] || 0

            return newState
        })
    }, [custosPrevistosData, periodoCustoPrevisto])

    const styleButtonClose = {
        "background" : "none",
        "border" : "none",
        "fontSize" : "24px", 
        "position" : "absolute",
        "right" : "10px",
        "top" : "10px",
        "z-index" : "100"
    }

    return (
        <div className="mt-5">
            <div className="container position-relative" >
                <button onClick={changeCompareCustos} style={styleButtonClose}> X </button>
            </div>
           
            <GraficoBarras
                data={data}
                title="Comparação de Custos"
                barKey="name"
                layout="horizontal"
                multibarras
            >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <Row>
                        <Col>
                            <p className="mb-2">
                                Custo Previsto Total: {' '}
                                <strong>
                                    { custosPrevistosData[periodoCustoPrevisto]?.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }
                                </strong>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="mb-3">
                                Desvio de Custo Total no período: {' '}
                                <strong>{ getDesvio() }</strong>
                            </p>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs="auto">
                            <Form.Label>Período Custo Previsto</Form.Label>
                            <Form.Control
                                as="select"
                                name="periodoCustoPrevisto"
                                value={periodoCustoPrevisto}
                                onChange={e => setPeriodoCustoPrevisto(e.target.value)}
                            >
                                {Object.keys(custosPrevistosData).map(key => (
                                    <option key={key} value={key}>
                                        { custosPrevistosData[key]?.mesAno && formatarMes(custosPrevistosData[key].mesAno)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>
                </div>
            </GraficoBarras>

        </div>
    )
}

export default CompararCustos;
