import api from "./api";

const BASE_ENDPOINT = '/imposto';

/** Traz uma lista das imposto cadastradss */
function getAll() {
    return api.get(`${BASE_ENDPOINT}/list`);
}

/**
 * Traz dados de um imposto pelo seu codigo, e se ativo/inativo
 * @param {number | string} cod codigo do imposto
 * @param {number | string} ativo 1=ativo, 0=inativo
 */
function getByCod(cod, ativo) {
    return api.get(
        `${BASE_ENDPOINT}/${cod}`,{
        params: { ativo }
    });
}

/** Cria um novo imposto no database */
function createNew(form) {
    return api.post(
        `${BASE_ENDPOINT}/create`,
        {...form}
    );
}

/** Atualiza os dados de um imposto */
function updateOne(cod, form) {
    return api.put(`${BASE_ENDPOINT}/update/${cod}`, { ...form })
}


// ************ IMPOSTO-FAIXA ************
/** 
 * Traz uma lista das faixas cadastradas para o código de imposto 
 * @param {string | number} imposto_cod código do imposto
 */
function getFaixas(imposto_cod) {
    return api.get(`${BASE_ENDPOINT}/faixa/${imposto_cod}`);
}

/** 
 * Cria um registro de faixa para o código de imposto 
 * @param {string | number} imposto_cod código do imposto
 * @param {object} form objeto contendo os dados
 */
function createFaixa(imposto_cod, form) {
    return api.post(
        `${BASE_ENDPOINT}/faixa/${imposto_cod}`,
        {...form}
    );
}

/** 
 * Atualiza um registro de faixa para o código de imposto 
 * @param {string | number} imposto_cod código do imposto
 * @param {object} form objeto contendo os dados
 */
function updateFaixa(imposto_cod, form) {
    return api.put(
        `${BASE_ENDPOINT}/faixa/${imposto_cod}`,
        {...form}
    );
}

/**
 * Diz a API para efetuar os calculos de impostos de um fechamento
 * @param {string | number} id id do fechamento
 * @param {boolean} isRecalculate indica se é recalculo
 */
function calculate(id, isRecalculate=false) {
    return api.post(
        `${BASE_ENDPOINT}/calculate/${id}`,
        { isRecalculate }
    )
}

const ImpostoApiService = {
    getAll,
    getByCod,
    createNew,
    updateOne,
    // IMPOSTO-FAIXAS
    getFaixas,
    createFaixa,
    updateFaixa,
    // IMPOSTO-CALCULATE
    calculate
}

export default ImpostoApiService;