import api from "./api";

const BASE_ENDPOINT = '/relatorio';

/**
 * Traz uma lista de colaboradores e seus benefícios 
 * totais (sintetico) ou em detalhes (analítico) 
 * @param {{
 *  apresentacao: 'analitico' | 'sintetico',
 *  filtros
 * }}
 */
const getColaboradorBeneficio = ({ apresentacao, ...filtros }) => {
    return api.get(
        `${BASE_ENDPOINT}/colaborador-beneficio`,
        { params: { apresentacao, filtros } }
    );
}

const getPredictedCost = (key, data) => {
    return api.get(`${BASE_ENDPOINT}/custo-previsto/${key}/${data}`);
}

const getDadosRelatoriosCustosToList = () =>{
    return api.get(`${BASE_ENDPOINT}/buscar-relatorios`)
}

const postDadosRelatoriosCustosToList = ( dados ) => {
    return api.post(`${BASE_ENDPOINT}/salvar-relatorio`, dados)
}

const deleteDadosRelatorioCustosToList = ( key, ano ) => {
    return api.delete(`${BASE_ENDPOINT}/deletar-relatorio/${key}/${ano}`)
}

const getDadosRelatorio = ( key, ano ) => {
    return api.get(`${BASE_ENDPOINT}/buscar-nome-relatorio/${key}/${ano}`)
}


const RelatorioApiService = {
    getColaboradorBeneficio,
    getPredictedCost,
    getDadosRelatoriosCustosToList,
    postDadosRelatoriosCustosToList,
    deleteDadosRelatorioCustosToList,
    getDadosRelatorio
}

export default RelatorioApiService;