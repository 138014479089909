import React from 'react';
import { Route, Switch } from 'react-router-dom';

// PAGES
import BeneficiosPage from './BeneficiosPage';
import BeneficioNew from './BeneficioNew';
import BeneficioPage from './BeneficioPage';

function Beneficio(props) {
    const { match } = props;

    return (
        <Switch>
            <Route path={match.path} exact component={BeneficiosPage} />
            <Route path={`${match.path}/new`} exact component={BeneficioNew} />
            <Route path={`${match.path}/:cod`} component={BeneficioPage} />
        </Switch>
    )
}

export default Beneficio;
