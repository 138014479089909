import api from "./api";

const BASE_ENDPOINT = '/simulator/custo-hh';

function getAll(email) {
    return api.get(`${BASE_ENDPOINT}/${email}`);
}

function getById(email, id) {
    return api.get(`${BASE_ENDPOINT}/${email}/${id}`);
}

function createNew(form) {
    return api.post(`${BASE_ENDPOINT}/create`, { ...form });
}

function updateOne(email, id, form) {
    return api.put(`${BASE_ENDPOINT}/${email}/${id}`, { ...form });
}

function deleteById(email, payload) {
    return api.patch(`${BASE_ENDPOINT}/${email}/change-active`, { ...payload });
}

function calculateSimulation(form) {
    return api.post(`${BASE_ENDPOINT}`, { ...form });
}


const SimulacaoApiService = {
    getAll,
    getById,
    createNew,
    updateOne,
    deleteById,
    calculateSimulation
}

export default SimulacaoApiService;
