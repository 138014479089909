import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import CustomModal from '../../../../components/CustomModal';
import BeneficioColabList from '../../Beneficio/BeneficioColabList';
import ColaboradorView from '../ColaboradorView';
import BeneficioColabForm from '../../Beneficio/BeneficioColabForm';

import './ColaboradorPage.css';

function ColaboradorPage() {
    // CONTEXT
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory()

    // STATE
    const [edit, setEdit] = useState(false);

    // FUNCTIONS
    const toggleEdit = (value) => {
        if (edit !== value) setEdit(value);
    }

    const openBeneficioModal = (row) => {
        CustomModal.show({
            body: (
                <BeneficioColabForm
                    cod={row && row.cod}
                    onSave={() => {
                        CustomModal.hide();
                        history.replace(match.url, location.state);
                    }}
                    onCancel={() => CustomModal.hide()}
                    editable={edit}
                />
            )
        });
    }

    const sectionHeadCss = "d-flex justify-content-between mb-3";
    // const splitLine = {borderLeft:'1px solid lightgrey'};

    return (
        <div className="colaborador-page">
            <ColaboradorView
                editable={edit}
                onEditableChange={toggleEdit}
            />

            <Row className="appended-list">
                {/* BENEFICIO */}
                <Col>
                    <div className={sectionHeadCss}>
                        <h4>Benefícios</h4>
                        <Button
                            variant="outline-primary"
                            onClick={() => {
                                if (!edit) {
                                    history.replace(match.url, location.state);
                                    openBeneficioModal()
                                } else {
                                    openBeneficioModal()
                                }
                            }}
                            // disabled={!edit}
                        >
                            <FaPlus/> Adic.Benefício
                        </Button>
                    </div>
                    <BeneficioColabList
                        key={`benef-${location.key}`}
                        onRowClick={openBeneficioModal}
                    />
                </Col>
            </Row>

            <CustomModal />
        </div>
    )
}

export default ColaboradorPage;
