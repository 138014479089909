import api from "./api";

const BASE_ENDPOINT = '/valor-imposto';

/**
 * Traz todos os valor-imposto aplicando os filtros
 * @param {*} valorId  id do valor-imposto
 * @example
 * filters = { filial: 1 }
 */
function getAllAndFilter(filters={}) {
    return api.get(
        `${BASE_ENDPOINT}/list`, 
        { params: {...filters} }
    );
}

/**
 * Traz os dados para valor-imposto a partir do id de valor-imposto
 * @param {*} valorId  id do valor-imposto
 */
function getByValorId(valorId) {
    return api.get(`${BASE_ENDPOINT}/${valorId}`)
}

/** Cria um novo valor de imposto para uma filial no database */
function createNew(form) {
    return api.post(
        `${BASE_ENDPOINT}/create`,
        {...form}
    );
}

/**
 * Atualiza os valores de um imposto em uma filial
 * @param {*} id chave id do valor-imposto
 * @param {*} form 
 */
function updateOne(id, form) {
    return api.put(`${BASE_ENDPOINT}/update/${id}`, { ...form })
}

const ValorImpostoApiService = {
    getAllAndFilter,
    getByValorId,
    createNew,
    updateOne,
}

export default ValorImpostoApiService;