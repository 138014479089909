import { Table } from "react-bootstrap"

function TableFuncaoExistente({ dataNotThisTab, changeFuncaoExistente }) {

    return (
        <Table striped bordered>
            <thead>
                <tr>
                    <th>Tipo</th>
                    <th>Taxa de custo</th>
                </tr>
            </thead>

            <tbody>
                { dataNotThisTab.map(item => (
                    <tr
                        key={item.id}
                        onClick={() => changeFuncaoExistente(item)}
                        style={{ cursor: "pointer" }}
                    >
                        <td>{item.nomeFuncao}</td>
                        <td>{item.taxaCustoVenda}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default TableFuncaoExistente
