/** 
 * Informações para os campos do form
 * @type {{
 *   groups: Array<{
 *      header: string,
 *      fields: Array<{
 *        label: string,
 *        placeholder: string,
 *        name: string,
 *        type: string,
 *        size: number,
 *        className: string
 *      }>
 *   }
 * }>} 
*/
export const getFormFields = () => {
    return {
        groups: [
            {
                fields: [
                    {
                        label: 'Sigla',
                        placeholder: 'Ex:',
                        name: 'sigla',
                        type: 'text',
                        size: 4,
                        max: 10,
                        readOnly: true
                    },
                    {
                        label: 'Nome',
                        placeholder: '',
                        name: 'nome',
                        type: 'text',
                        size: 8,
                        max: 60,
                        readOnly: true
                    },
                    {
                        label: 'Descrição',
                        placeholder: 'Digite aqui descrição, observações ou detalhes...',
                        name: 'descricao',
                        type: 'textarea',
                        defaultValue: '',
                        size: 12,
                        max: 300,
                        // readOnly: true,
                        disabled: true,
                    },
                ]
            },
        ]
    };
}

const RegimeImpostoFormViewInfo = { getFormFields };

export default RegimeImpostoFormViewInfo;
