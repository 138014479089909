import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PageContainer from '../../../components/PageContainer';
import Loading from '../../../components/Loading';
import ProjetosApiService from '../../../services/api/ProjetosApiService';
import ErrorRequestService from '../../../services/errorRequest';
import useAlertNotification from '../../../context/AlertNotificationContext';
import useAuth from '../../../context/AuthContext';
import * as R from 'ramda'
import MargemResult from './MargemResult';
import CustomModal from '../../../components/CustomModal';
import MargemDetails from './MargemDetails';
import { Button, ButtonGroup, Form, InputGroup, Table } from 'react-bootstrap';
import { FaPen, FaSave } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

const MOEDA = {
    "BRL": "Real",
    "USD": "Dólar",
    "EUR": "Euro"
}

const TIPO_CUSTO = {
    "INFRA_CLOUD": "Infraestrutura em Cloud",
    "OUTROS_SOFTWARES": "Outros softwares",
    "HORAS_EXTRAS": "Horas extras",
    "FORNECEDORES_TERCEIROS": "Fornecedores terceiros",
    "OUTROS": "Outros",
}

function MargemView(props) {
    // CONTEXT
    const match = useRouteMatch();
    const alert = useAlertNotification();

    const { user } = useAuth();
    const { email } = user;

    // // PROPS
    // const { sigla, nome } = location.state || {};
    const { id } = match.params;

    // // STATE
    const [formData, setFormData] = useState(null);
    const [formResult, setFormResult] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    const onStart = () => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const formData = await ProjetosApiService.getMargemById(email, id).then(resp => {
                    const { data } = resp.data;
                    return data;
                })

                setFormResult(formData);

                const form = {
                    nome: formData['nome'],
                    custoHH: formData['custo_hora_colaborador'],
                    receitaBruta: formData["receita_bruta"],
                    baseImpostoSemReceitaBruto: R.divide(formData["impostos"], formData["receita_bruta"]),
                    baseImpostoSemResultado: R.divide(formData["imposto_sem_resultado"], formData["receita_bruta"]),
                    baseRateioAdministrativo: R.divide(formData["rateio_administrativo"], formData["receita_bruta"]),
                    baseRV: R.divide(formData["rv"], formData["receita_bruta"]),
                    periodoInicio: formData["periodo_inicial"],
                    periodoFim: formData["periodo_fim"],
                    custosIndiretos: formData.custosIndiretos,
                    projeto: formData.projeto
                }

                setFormData(form);
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert.error(errorMsg);
            }
            finally {
                setIsLoading(false);
            }
        }
        getData();
    }

    const mask = (value) => {
        const cleanedValue = value.replace(/[^\d.,]/g, '');

        const formattedValue = parseFloat(cleanedValue).toFixed(2);

        const result = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 })
            .format(formattedValue);

        return result;
    }


    const BodyAlterName = () => {
        const [alterName, setAlterName] = useState(formData.nome);

        const handleAlterName = (event) => {
            setAlterName(event.target.value);
        }


        const postAlterName = async () => {
            try {
                const resp = await ProjetosApiService.updateNameMargem(id, {"nome": alterName});
                CustomModal.hide();
                onStart();
            } catch (error) {
                console.error("Não foi possível atualizar o nome do relatório", error);
                alert.error("Não foi possível alterar o nome do relatório.")
            }
        }

        return (
            <>
                <InputGroup>
                    <Form.Control
                        placeholder="Digite o novo nome do relatório..."
                        aria-label="Nome"
                        value={alterName}
                        onChange={handleAlterName}
                    />
                </InputGroup>

                <div className='mt-3 d-flex justify-content-end'>
                    <Button variant="outline-info" onClick={() => postAlterName()}>
                        <FaSave /> Salvar
                    </Button>
                    <Button variant="outline-danger" onClick={() => CustomModal.hide()}>
                        <MdClose /> Cancelar
                    </Button>
                </div>

            </>
        )
    }



    const alterTitle = () => {

        CustomModal.show({
            title: "Deseja alterar o nome do relatório ?",
            body: < BodyAlterName />,
        })
    }

    const actions = [
        {
            label: 'Editar',
            icon: <FaPen />,
            color: 'primary',
            type: 'button',
            onClick: alterTitle
        },
    ]

    // USEEFFECTS
    useEffect(onStart, []);

    const title = `Relatório - ${id}`;

    return (
        <PageContainer
            title={title}
            // actions={actions}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                : (
                    <>
                        <MargemDetails data={formData} />

                        { formData.custosIndiretos.length > 0 && (
                            <>
                                <h5 className="text-center">Custos Indiretos</h5>

                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            <th>Descrição</th>
                                            <th>Moeda</th>
                                            <th>Cotação</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.custosIndiretos.map(cost => (
                                            <tr key={cost.id}>
                                                <td>{TIPO_CUSTO[cost['tipo_custo']]}</td>
                                                <td>{cost.descricao}</td>
                                                <td>{MOEDA[cost.moeda]}</td>
                                                <td>{mask(cost.cotacao)}</td>
                                                <td>{mask(cost.valor)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        )}

                        <MargemResult formResult={formResult} />

                        <CustomModal />
                    </>
                )
            }
        </PageContainer>
    )
}

export default MargemView;
