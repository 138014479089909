import React, {useState} from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import CustomModal from '../../../components/CustomModal';
import useAuth from '../../../context/AuthContext';
import SquadApiService from '../../../services/api/SquadApiService';
import { FaSave} from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import useAlertNotification from '../../../context/AlertNotificationContext';

const NewSquadModal = ({getDataList}) =>{
    const { user } = useAuth();
    const { email } = user;
    const [newTime, setNewTime] = useState({ nome: "" });

    const alert = useAlertNotification();

    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {[name]: value, email: email};
        setNewTime(newform);
    }
    const handleSubmitSquad = (ev) =>{
        ev.preventDefault();
        if (newTime.nome === "") {
            alert.error("Nome do time não pode ser vazio.");
            return;
        }
        try {
            SquadApiService.createNew(newTime);
            getDataList();
        } catch (error) {
            console.log(error)
        }finally{
            CustomModal.hide();
        }
    }
    return (
        <Form onSubmit={handleSubmitSquad}>
            <Form.Group className="mb-3" controlId="newSquadForm">
                <Form.Label>Nome do novo time</Form.Label>
                <Form.Control name="nome" onChange={(ev) => onChange(ev)}  type="text" placeholder="Digite o nome do novo time" />
            </Form.Group>
            <div className='modal-footer'>
                <ButtonGroup>
                    <Button variant="outline-success" type="submit">
                        <FaSave /> Salvar
                    </Button>
                    <Button variant="outline-danger" onClick={() => CustomModal.hide()}>
                        <MdClose /> Cancelar
                    </Button>
                </ButtonGroup>
            </div>
        </Form>
    );
}
export default NewSquadModal;
