import React, { useEffect, useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import { FaPen, FaSave } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import ContentContainer from '../../components/ContentContainer';
import { FormBuilder } from '../../components/FormBuilder';
import useAuth from '../../context/AuthContext';
import useAlertNotification from '../../context/AlertNotificationContext';
import ErrorRequestService from '../../services/errorRequest';
import UsuarioApiService from '../../services/api/UsuarioApiService';
import UtilService from '../../services/util';
import UsuarioValidation from '../../services/validation/UsuarioValidation';
import UsuarioFormInfo from './UsuarioFormInfo';
import userImgDefault from '../../assets/img/user-icon.png';


function UsuarioForm({ onSave, onCancel }) {

    const { user, updateUser } = useAuth();
    const alert = useAlertNotification();

    // STATE
    const [origData, setOrginData] = useState(null);
    const [formFields, setFormFields] = useState(UsuarioFormInfo.getFormFields());
    const [formData, setFormData] = useState({});
    const [edit, setEdit] = useState(false);

    // FUNCTIONS
    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setFormData(newform);
    }

    const resetForm = () => UtilService.genericResetFormFunction();
    
    const handleCancel = () => {
        setFormData(origData);
        setEdit(false);
        resetForm();
        if (onCancel) onCancel();
    }

    const toggleEdit = () => {
        if (edit) {
            setFormData(origData);
        }
        setEdit(!edit);
    }

    const saveForm = async () => {
        try {
            // validar form
            const validation = await UsuarioValidation.validate(formData);
            if (!validation.isValid) {
                alert.error("Erros no formulário!");
                return;
            }
            
            // se ok, enviar p/ API
            const resp = await UsuarioApiService.updateUserData(formData);
            if (resp.data) {
                const { novaSenha, confirma, ...form } = formData;
                updateUser(form);
                alert.success("Dados atualizados com sucesso!");
                setEdit(!edit);
                if (onSave) onSave();
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    }

    const onStart = () => {
        const formInicial = {...user, novaSenha: '', confirma: ''};
        setOrginData( formInicial );
        setFormData( formInicial );
    }

    // USEEFFECTS
    useEffect(onStart, []);

    const actions = !edit ? [{ 
        label: 'Editar',
        icon: <FaPen />,
        color: 'primary',
        type: 'button',
        onClick: toggleEdit
    }]
    :[{ 
        label: 'Salvar',
        icon: <FaSave />,
        color: 'success',
        type: 'button',
        onClick: saveForm
    }];

    actions.push({ 
        label: 'Cancelar',
        icon: <MdClose /> ,
        color: 'danger',
        type: 'button',
        onClick: handleCancel
    });

    const title = "Dados de Usuário";

    return (
        <ContentContainer
            title={title}
            actions={actions}
        >
            <Row>
                <Col lg={3} className="text-center">
                    <Image
                        className="avatar-img"
                        src={user.avatar_imgref || userImgDefault}
                        alt={user.nome}
                        title={user.usuario}
                        height={100}
                        // roundedCircle
                    />
                </Col>
                <Col lg={9}>
                    { !!origData && 
                        <FormBuilder 
                            formFields={formFields}
                            formData={formData}
                            onChange={onChange}
                            disabledForm={!edit}
                            initialValues={origData}
                            schema={UsuarioValidation.schema}
                        />
                    }
                </Col>
            </Row>
        </ContentContainer>
    )
}

export default UsuarioForm
