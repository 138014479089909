import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import RelatoriosMainPage from './RelatoriosMainPage';
import RelatorioViewPage from './RelatorioViewPage';

function Relatorios() {
    const match = useRouteMatch();
    
    return (
        <Switch>
            <Route path={`${match.path}`} exact component={RelatoriosMainPage} />
            <Route path={`${match.path}/view`} exact component={RelatorioViewPage} />
            
            <Redirect path="*" to="/" />
        </Switch>
    )
}

export default Relatorios;
