import api from "./api";

const ENDPOINT_BASE = '/departamento';

/** Traz uma lista de todos os departamentos cadastrados */
function getAll() {
    return api.get(`${ENDPOINT_BASE}/list`);
}

/** Envia um novo deparamento para ser criado no database */
function createNew(form) {
    return api.post(`${ENDPOINT_BASE}/create`, { ...form });
}

/** Traz um departamento pelo seu código */
function getByCod(cod) {
    return api.get(`${ENDPOINT_BASE}/${cod}`);
}

/** Envia para a API os dados para atualizar um departamento */
function updateOne(cod, form) {
    return api.put(`${ENDPOINT_BASE}/update/${cod}`, { ...form });
}

const DepartamentoApiService = {
    getAll,
    createNew,
    getByCod,
    updateOne
}

export default DepartamentoApiService;