import * as Yup from 'yup';
import UtilService from '../util';
import ValidationService from './validation';

// filial_cod, imposto_cod, tipo_imposto, valor_fixo, periodo_valor, 
// valor_desc, aliquota_desc, base_aliq_desc, 
// valor_filial, aliquota_filial, base_aliq_filial,
const schema = Yup.object().shape({

    filialCod: Yup 
        .number(),

    imposto_cod: Yup  
        .string(),

    tipo_imposto: Yup
        .string()
        .required('Umas das opções deve ser informada.'),

    valor_fixo: Yup
        .number("Preencher com um valor numérico.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser maior de 0,00')
        .default(0),

    periodo_valor: Yup 
        .string()
        .required('Umas das opções deve ser informada.'),

    valor_desc: Yup 
        .number()
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser maior de 0,00')
        .default(0),

    aliquota_desc: Yup  
        .number()
        .transform(UtilService.yupIntFixPercentage)
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .default(0),

    base_aliq_desc: Yup  
        .string()
        .test({
            name:'tem-aliq-desc',
            message: 'Base da aliquota deve ser informada',
            test: (value, context) => {
                if (context.parent.aliquota_desc > 0) {
                    return !['', null, undefined].includes(value);
                }
                return true;
            }
        }),

    valor_filial: Yup   
        .number()
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser maior de 0,00')
        .default(0),

    aliquota_filial: Yup
        .number()
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .transform(UtilService.yupIntFixPercentage)
        .default(0),

    base_aliq_filial: Yup
        .string()
        .test({
            name:'tem-aliq-filial',
            message: 'Base da aliquota deve ser informada',
            test: (value, context) => {
                if (context.parent.aliquota_filial > 0) {
                    return !['', null, undefined].includes(value);
                }
                return true;
            }
        }),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const ValorImpostoValidation = {
    schema,
    validate,
    castValues
};

export default ValorImpostoValidation;