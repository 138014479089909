import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import { FaSave, FaSpinner } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FormBuilder, FormStateBuilder } from '../../../../components/FormBuilder';
import ErrorRequestService from '../../../../services/errorRequest';
import ColaboradorValidation from '../../../../services/validation/ColaboradorValidation';
import ColaboradorApiService from '../../../../services/api/ColaboradorApiService';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import PageContainer from '../../../../components/PageContainer';
import ColaboradorNewInfo from './ColaboradorNewInfo';
import Loading from '../../../../components/Loading';

function ColaboradorNew(props) {
    // CONTEXT
    const history = useHistory();
    const match = useRouteMatch();
    const alert = useAlertNotification();

    // STATE
    const [formFields, setFormFields] = useState({});
    const [formData, setFormData] = useState({});
    const [initialData, setInitialData] = useState({});
    const [isEdited, setIsEdited] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    const isFormEdited = () => {
        const filledFields = [] 
        Object.keys(formData).forEach(item => {
            if (formData[item]) {
                filledFields.push(formData[item]);
            }
        });
        
        const isFormFilled = filledFields.length > 0;
        return isFormFilled;
    }

    const askForLeave = (cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            const msg = 'Foram adicionados dados!\nDeseja realmente sair e perdê-los?';
            const leave = window.confirm(msg);
            
            if (leave) cbPositive();
            else cbNegative();
        }   
        return false;
    }
    
    const saveForm = async() => {
        try {
            setIsSaving(true);
            // validar form
            const validation = await ColaboradorValidation.validate(formData);
            // mostrar possiveis erros
            if (!validation.isValid) {
                alert.error("Formulário possui erros");
                return;
            }
            
            // se ok, enviar dados p/ API
            const result = await ColaboradorApiService.createNew(formData);
            if (result.data.result) {
                const { insertId } = result.data.result;
                setIsEdited(false);
                alert.success("Cadastrado novo colaborador com MATRICULA: "+ insertId);
                // history.push(`${match.path.replace('/new','')}/${insertId}`);
                history.replace( match.path.replace('/new',`/${insertId}`), {...formData, mat: insertId} );
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsSaving(false);
        }
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                history.goBack()
            });
            return;
        }
        history.goBack();
    }

    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setFormData(newform);
    }

    const onEdit = () => {
        if (isFormEdited() && !isEdited) {
            setIsEdited(true);
        }
    }

    const onStart = () => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const formFields = await ColaboradorNewInfo.getFormFields();
                const initialData = FormStateBuilder(formFields);
                setFormFields( formFields );
                setFormData( initialData );
                setInitialData( initialData );
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors(); 
                console.log(errorMsg);
                alert.error(errorMsg);
            }
            finally {
                setIsLoading(false);
            }
        }
        getData();
    }

    // USEEFFECTS
    useEffect(onStart, []);

    useEffect(onEdit, [formData])

    const actions = [
        { 
            label: isSaving ? 'Salvando...' : 'Salvar',
            icon: isSaving ? <FaSpinner /> : <FaSave />,
            color: 'success',
            type: 'button',
            disabled: isSaving,
            onClick: saveForm
        },
        { 
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            disabled: isSaving,
            onClick: handleCancel
        },
    ]
    
    const title = `Novo Colaborador`;

    return (
        <PageContainer
            title={title}
            actions={actions}
        >
            { isLoading 
                ? <Loading message="Carregando..." />
                : (initialData && 
                    <FormBuilder 
                        formFields={formFields}
                        formData={formData}
                        onChange={onChange}
                        schema={ColaboradorValidation.schema}
                        initialValues={initialData}
                        disabledForm={isSaving}
                    />
                )
            }
            
            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </PageContainer>
    )
}

export default ColaboradorNew;
