import api from "./api";

const BASE_ENDPOINT = '/beneficio';

/** Traz uma lista dos benefícios cadastradss */
function getAll(filters={}) {
    return api.get(
        `${BASE_ENDPOINT}/list`,
        { params: { ...filters }}
    );
}

/**
 * Traz dados de um benefício pelo seu codigo, e se ativo/inativo
 * @param {number | string} cod codigo do benefício
 * @param {number | string} ativo 1=ativo, 0=inativo
 */
function getByCod(cod, ativo) {
    return api.get(
        `${BASE_ENDPOINT}/${cod}`,{
        params: { ativo }
    });
}

/** Traz uma lista dos benefícios cadastradss */
function getByFilialCod(filialCod) {
    return api.get(`${BASE_ENDPOINT}/filial/${filialCod}`);
}

/** Cria um novo benefício no database */
function createNew(form) {
    return api.post(
        `${BASE_ENDPOINT}/create`,
        {...form}
    );
}

/** Atualiza os dados de um benefício */
function updateOne(cod, form) {
    return api.put(`${BASE_ENDPOINT}/update/${cod}`, { ...form })
}

/**
 * Retorna os beneficios de um colaborador pelo sua matrícula
 * @param {number | string} mat matricula do colaborador
 */
function getByColabMat(mat) {
    return api.get(`${BASE_ENDPOINT}/colab/${mat}`)
}

/**
 * Retorna um beneficio de um colaborador pelo sua matrícula e codigo do beneficio
 * @param {number | string} mat matricula do colaborador
 * @param {number | string} benef código do beneficio
 */
function getByColabMatAndBenefCod(mat, benef) {
    return api.get(`${BASE_ENDPOINT}/colab/${mat}/${benef}`)
}

/**
 * Atualiza um beneficio de um colaborador pelo sua matrícula e codigo do beneficio
 * @param {number | string} mat matricula do colaborador
 * @param {number | string} benef código do beneficio
 * @param {object} form objeto contendo os dados a do beneficio
 */
function updateByColabMatAndBenefCod(mat, benef, form) {
    return api.put(`${BASE_ENDPOINT}/colab/${mat}/${benef}`, {...form});
}

/**
 * Adiciona um beneficio a um colaborador pelo sua matrícula e codigo do beneficio
 * @param {number | string} mat matricula do colaborador
 * @param {number | string} benef código do beneficio
 * @param {object} form objeto contendo os dados a do beneficio
 */
function addBeneficioColab(mat, benef, form) {
    return api.post(`${BASE_ENDPOINT}/colab/${mat}/${benef}`, {...form});
}

/**
 * Diz a API para efetuar os calculos de benefícios de um fechamento
 * @param {string | number} id id do fechamento
 * @param {boolean} isRecalculate indica se é recalculo
 */
function calculate(id, isRecalculate=false) {
    return api.post(
        `${BASE_ENDPOINT}/calculate/${id}`,
        { isRecalculate }
    )
}

function calculateAverage(mesAtual) {
    return api.get(`${BASE_ENDPOINT}/calculate/average/${mesAtual}`)
}

function deleteEmployeeBenefits(mat, benef, filial) {
    return api.delete(`${BASE_ENDPOINT}/${mat}/${benef}/${filial}`);
}

const BeneficioApiService = {
    getAll,
    getByCod,
    getByFilialCod,
    createNew,
    updateOne,
    getByColabMat,
    getByColabMatAndBenefCod,
    updateByColabMatAndBenefCod,
    addBeneficioColab,
    // BENEFICIO-CALCULATE
    calculate,
    calculateAverage,
    deleteEmployeeBenefits
}

export default BeneficioApiService;
