/**
 * Informações das colunas para lista
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   dynamicClass: function,
 *   style: string
 * }>}
*/
export const columns = [
    {
        label: 'Nome',
        name: 'nome',
        width: '35%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Projeto',
        name: 'projeto',
        width: '24%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Período Inicial',
        name: 'periodo_inicial',
        width: '20%',
    },
    {
        label: 'Período Final',
        name: 'periodo_fim',
        width: '20%',
    },
    {
        label: '',
        name: 'menu_opcoes',
        width: '1%',
    }
];


const MargemListInfo = { columns };

export default MargemListInfo;
