import React from "react";
import { Button } from 'react-bootstrap';

import UtilService from '../../../../services/util';

function HeaderDetailTarefas(props) {
    const { timesheet } = props;

    return (
        <div style={{ marginTop: 30 }}>
            <h4>
                Tarefas &nbsp;

                {
                    timesheet.length > 0 &&
                    <Button onClick={
                            () => {
                                
                                const data = timesheet.map(item => ({ key: item.key, summary: item.summary, timeLogged: parseFloat(String(item.timeLogged).replace(',', '.')) }));
                                const header = [['Issue', 'Descrição', 'Horas']];
                                const label= 'Tarefas';

                                UtilService.exportXls(data, label, header)
                            }
                        }
                    >
                        Exportar
                    </Button>
                }
            </h4>
        </div>
    );
}

export default HeaderDetailTarefas;
