import UtilService from "../util";
import api from "./api";

const BASE_ENDPOINT = '/usuario';

/** 
 * Realiza alterações em dados do usuário
 * @param authorization string em base64 contendo objeto `{usuário, senha}`
 */
function updateUserData(form) {
    const encodedForm = UtilService.encodeUtf8AsBase64(JSON.stringify(form));
    return api.put(BASE_ENDPOINT, { data: encodedForm })
}

const UsuarioApiService = {
    updateUserData,
}

export default UsuarioApiService;