import React, { useEffect, useState } from "react"
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import TableCustoExistente from "./TableCustoExistente";
import { FaPen, FaSave, FaTrash } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import axios from 'axios';

function CustoDetail({
    item,
    handleCustos,
    onCancel,
    currentTab,
    rowsDataCustos,
    deleteValorCusto,
    edit,
    verificarPagina
}) {
    const [formData, setFormData] = useState({ 
        tipoCusto: "", 
        descricao: "", 
        valor: {}, 
        moeda: "BRL", 
        cotacao: "1,00",
        valorOriginal: "0,00"
    })
    const [editForm, setEditForm] = useState(false)
    const [errors, setErrors] = useState({})
    const [showExistentes, setShowExistentes] = useState(false)
    const [cotacoes, setCotacoes] = useState({
        BRL: "1,00",
        USD: "0,00",
        EUR: "0,00"
    })

    const dataNotThisTab = rowsDataCustos.filter(item => !item.valor.hasOwnProperty(currentTab))

    const mask = (value) => {
        if (!value && value !== 0) return "0,00";
        value = value.toString().replace('.', '').replace(',', '').replace(/\D/g, '')
        const options = { minimumFractionDigits: 2 }
        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )
        return result
    }

    const unmask = (value) => {
        if (!value) return 0;
        return parseFloat(value.replace('.', '').replace(',', '.')) || 0
    }

    const getCotacoes = async () => {
        try {
            const config = {
                method: 'get',
                url: `https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL`
            };

            const result = await axios(config);
            setCotacoes({
                BRL: "1,00",
                USD: mask(parseFloat(result.data['USDBRL'].bid).toFixed(2)),
                EUR: mask(parseFloat(result.data['EURBRL'].bid).toFixed(2))
            })
        } catch (error) {
            console.error("Erro ao buscar cotações:", error);
            // Manter cotações padrão em caso de erro
        }
    }

    const convertValue = (value, cotacao) => {
        const valorNumerico = unmask(value)
        const cotacaoNumerica = unmask(cotacao)
        return mask((valorNumerico * cotacaoNumerica).toFixed(2))
    }

    const handleSave = () => {
        const newErrors = {}

        if (!formData.tipoCusto) newErrors.tipoCusto = "Campo obrigatório"
        if (!formData.descricao) newErrors.descricao = "Campo obrigatório"
        if (!formData.valorOriginal || Number(unmask(formData.valorOriginal)) <= 0) {
            newErrors.valorOriginal = "Campo obrigatório"
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            return;
        }

        handleCustos(formData);
    }

    const onChangeData = (event) => {
        const { name, value } = event.target;

        setFormData(prevState => {
            let newState = { ...prevState };

            if (name === "valorOriginal") {
                const inputValue = mask(value)
                const convertedValue = convertValue(inputValue, prevState.cotacao)
                newState = {
                    ...newState,
                    valorOriginal: inputValue,
                    valor: { ...prevState.valor, [currentTab]: convertedValue }
                }
            } else if (name === "moeda") {
                const newCotacao = cotacoes[value] || "1,00"
                const convertedValue = convertValue(prevState.valorOriginal, newCotacao)
                newState = {
                    ...newState,
                    moeda: value,
                    cotacao: newCotacao,
                    valor: { ...prevState.valor, [currentTab]: convertedValue }
                }
            } else if (name === "cotacao") {
                const inputValue = mask(value)
                const convertedValue = convertValue(prevState.valorOriginal, inputValue)
                newState = {
                    ...newState,
                    cotacao: inputValue,
                    valor: { ...prevState.valor, [currentTab]: convertedValue }
                }
            } else {
                newState = { ...newState, [name]: value }
            }

            return newState;
        });

        setErrors({})
    }

    const changeCustoExistente = (item) => {
        setFormData(prevState => ({
            ...prevState,
            ...item,
            valorOriginal: item.valorOriginal || item.valor[currentTab] || "0,00"
        }))
        setShowExistentes(false)
    }

    const onStart = () => {
        const formDataItem = item ? {
            ...item,
            valorOriginal: item.valorOriginal || item.valor[currentTab] || "0,00"
        } : { 
            tipoCusto: "", 
            descricao: "", 
            valor: {}, 
            moeda: "BRL", 
            cotacao: "1,00",
            valorOriginal: "0,00"
        }

        setFormData(formDataItem);
        setEditForm(!!item)
        getCotacoes()
    }

    useEffect(onStart, [item, currentTab])

    return (
        <Form>
            { !showExistentes ? (
                <>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control
                            as="select"
                            name="tipoCusto"
                            value={formData.tipoCusto}
                            onChange={onChangeData}
                            disabled={editForm}
                            isInvalid={!!errors.tipoCusto}
                            >
                                <option value="">Selecione</option>
                                <option value="INFRA_CLOUD">Infraestrutura em Cloud</option>
                                <option value="OUTROS_SOFTWARES">Outros softwares</option>
                                <option value="HORAS_EXTRAS">Horas extras</option>
                                <option value="FORNECEDORES_TERCEIROS">Fornecedores terceiros</option>
                                <option value="OUTROS">Outros</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                {errors?.tipoCusto}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                type="text"
                                name="descricao"
                                value={formData.descricao}
                                onChange={onChangeData}
                                disabled={editForm}
                                isInvalid={!!errors.descricao}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.descricao}
                            </Form.Control.Feedback>
                        </Col>

                        <Col>
                            <Form.Label>Valor Original</Form.Label>
                            <Form.Control
                                type="text"
                                name="valorOriginal"
                                value={formData.valorOriginal}
                                onChange={onChangeData}
                                disabled={editForm}
                                isInvalid={!!errors.valorOriginal}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.valorOriginal}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <Form.Label>Moeda</Form.Label>
                            <Form.Control
                                as="select"
                                name="moeda"
                                value={formData.moeda}
                                onChange={onChangeData}
                                disabled={editForm}
                            >
                                <option value="BRL">Real</option>
                                <option value="USD">Dólar</option>
                                <option value="EUR">Euro</option>
                            </Form.Control>
                        </Col>
                        {formData.moeda !== 'BRL' && (
                            <Col>
                                <Form.Label>Cotação</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="cotacao"
                                    autoComplete='off'
                                    maxLength={5}
                                    value={formData.cotacao}
                                    onChange={onChangeData}
                                    disabled={editForm}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Label>Valor Convertido (BRL)</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.valor[currentTab] || '0,00'}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <TableCustoExistente
                    dataNotThisTab={dataNotThisTab}
                    changeCustoExistente={changeCustoExistente}
                />
            )}
            { edit ?
                item ? (
                    <div className="mt-3 d-flex justify-content-end">
                        { editForm ? (
                            <ButtonGroup>
                                <Button variant="outline-info" onClick={() => setEditForm(false)} disabled={!edit}>
                                    <FaPen /> Editar
                                </Button>
                                <Button variant="outline-danger" onClick={() => deleteValorCusto(item?.id)} disabled={!edit}>
                                    <FaTrash /> Excluir
                                </Button>
                            </ButtonGroup>
                        ) : (
                            <ButtonGroup>
                                <Button variant="outline-success" onClick={handleSave}>
                                    <FaSave /> Salvar
                                </Button>
                                <Button variant="outline-danger" onClick={() => setEditForm(true)}>
                                    <MdClose /> Cancelar
                                </Button>
                            </ButtonGroup>
                        )}
                    </div>
                ) : (
                    <div className={`mt-3 d-flex justify-content-${dataNotThisTab.length <= 0 ? 'end' : 'between'}`}>
                        { dataNotThisTab.length > 0 && (
                            <Button variant="outline-info" onClick={() => setShowExistentes(!showExistentes)}>
                                { showExistentes ? "Voltar" : "Adicionar um de outro mês" }
                            </Button>
                        )}

                        { !showExistentes && (
                            <ButtonGroup>
                                <Button variant="outline-success" onClick={handleSave}>Salvar</Button>
                                <Button variant="outline-danger" onClick={onCancel}>Cancelar</Button>
                            </ButtonGroup>
                        )}
                    </div>
                )
                : null
            }
        </Form>
    )
}

export default CustoDetail;
