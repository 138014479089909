import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Beneficio from './Beneficio';
// import CentroCusto from './CentroCusto';
import Colaborador from './Colaborador';
import Departamento from './Departamento';
import Filial from './Filial';
import Funcao from './Funcao';
import Imposto from './Imposto';
import Regime from './Regime';

function Cadastros(props) {
    // PROPS
    const { match } = props;

    return (
        <Switch>
            <Route path={`${match.path}/beneficios`} component={Beneficio} />
            {/* <Route path={`${match.path}/centro-custo`} component={CentroCusto} /> */}
            <Route path={`${match.path}/colaboradores`} component={Colaborador} />
            <Route path={`${match.path}/departamentos`} component={Departamento} />
            <Route path={`${match.path}/filial`} component={Filial} />
            <Route path={`${match.path}/funcoes`} component={Funcao} />
            <Route path={`${match.path}/impostos`} component={Imposto} />
            <Route path={`${match.path}/regime-contrato`} component={Regime} />
          
            <Redirect path='*' to='/' />
        </Switch>
    )
}

export default Cadastros
