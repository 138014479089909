import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import ContentContainer from '../../../../components/ContentContainer';
import { FormBuilder, FormStateBuilder } from '../../../../components/FormBuilder';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import RelatorioService from '../../../../services/relatorio';
import ErrorRequestService from '../../../../services/errorRequest';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { buildQueryString } from '../../../../services/api/api';

/**
 * Renderiza os campos de filtro da API
 */
function FiltroEmissaoRelatorio({
    label,
    path,
    onConfirm,
    onCancel
}) {
    const title = `Relatório ${label}`;

    // HOOKS
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();

    // STATE
    const [formData, setFormData] = useState(null);
    const [formFields, setFormFields] = useState(null);
    const [relatorio] = useState(new RelatorioService());

    // FUNCTIONS
    const onSubmit = async e => {
        e.preventDefault();

        try {
            // validar dados do filtro, mostrar possiveis erros
            const validation = await relatorio.filterValidation.validate(formData);
            if (!validation.isValid) {
                alert.error("Formulário possui erros");
                return;
            }

            // chamando URL c/ filtro 
            const pathname = `${match.path}/view`;
            const search = buildQueryString({ path, title, ...formData });
            history.push({ pathname, search });
        }
        catch(err) {
            const error = new ErrorRequestService(err);
            const errMsg = error.getErrors();
            console.log("ERROR FiltroEmissaoRelatorio onSubmit", errMsg);
            alert.error(errMsg);
        }
        finally {
            onConfirm();
        }
    }

    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setFormData(newform);
    }

    const onStart = useCallback(async () => {
        try {
            await relatorio.init(path);
            
            const formCampos = relatorio.getFilterFields();
            const formDados = FormStateBuilder(formCampos);

            setFormFields( formCampos );
            setFormData( formDados );
        }
        catch (err) {
            const errMsg = String(err);
            console.log(errMsg);
            alert.error(errMsg);
        }
    }, [path, relatorio, alert]);

    // USEEFFECTS
    useEffect(onStart, []);

    const actions = [
        { 
            label: 'Confirmar',
            icon: <FaCheck /> ,
            color: 'success',
            type: 'button',
            onClick: onSubmit
        },
        { 
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            onClick: onCancel
        }
    ];

    return (
        <ContentContainer
            title={title}
            actions={actions}
        >
            <div style={{color: 'grey'}}>
                { relatorio?.description }
            </div>
            <hr/>

            {(formData && formFields) &&
                <FormBuilder
                    formFields={formFields}
                    formData={formData}
                    initialValues={formData}
                    schema={relatorio.filterValidation.schema}
                    onChange={onChange}
                />
            }
        </ContentContainer>
    )
}

FiltroEmissaoRelatorio.defaultProps = {
    onConfirm: () => {}
}

FiltroEmissaoRelatorio.propTypes = {
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default FiltroEmissaoRelatorio;

