/**
 * Array contendo as opções de menu de relatórios a serem disponibilizados
 */
export const menuRelatorios = [
    {
        groupLabel: "Colaboradores",
        groupKey: "colaboradores",
        menus: [
            {
                label: "Colaborador e Benefícios",
                path: "colaborador-beneficio",
            },{
                label: "Colaborador e Impostos (MOCK)",
                path: "colaborador-imposto",
            },{
                label: "Colaborador e Salaŕios (MOCK)",
                path: "colaborador-salario",
            },{
                label: "Informações Cadastrais por Colaborador (MOCK)",
                path: "info-cadastrais-colaborador",
            },
        ]
    },
    {
        groupLabel: "Benefícios",
        groupKey: "benefícios",
        menus: [
            {
                label: "Benefícios Totais por Fechamento (MOCK)",
                path: "beneficios-totais-fechamento",
            },{
                label: "Benefícios Agrupados (MOCK)",
                path: "beneficios-agrupados",
            },{
                label: "Informações Cadastrais por Benefício (MOCK)",
                path: "info-cadastrais-beneficio",
            },
        ]
    },
    {
        groupLabel: "Impostos",
        groupKey: "impostos",
        menus: [
            {
                label: "Impostos Totais por Fechamento (MOCK)",
                path: "imposto-totais-fechamento",
            },{
                label: "Impostos Agrupados (MOCK)",
                path: "imposto-agrupamento",
            },{
                label: "Informações Cadastrais por Imposto (MOCK)",
                path: "info-cadastrais-imposto",
            },
        ]
    },
];

/**
 * Filtra o menu de relatórios conforme o termo recebido
 * @param {string} termo a ser buscado no array de menu
 * @returns {Array<any>} array de opções filtrado pelo termo
 */
export const applyFiltroMenu = (termo = "") => {
    if (!termo.length) return menuRelatorios;
    
    const filtrados = [];
    const lowerTermo = termo.toLowerCase();

    menuRelatorios.forEach((group => {
        const menus = group.menus.filter(item => {
            const lowerLabel = item.label.toLowerCase();
            return lowerLabel.includes(lowerTermo);
        })

        if (menus.length) {
            filtrados.push({...group, menus});
        }
    }));

    return filtrados;
} 