import React,  { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { FaArrowLeft, FaCheck, FaThumbsUp, FaTimes } from 'react-icons/fa';
import useAuth from '../../../context/AuthContext';
import useAlertNotification from '../../../context/AlertNotificationContext';
import CadastrarLoginValidation from '../../../services/validation/CadastrarLoginValidation';
import UtilService from '../../../services/util';
import AuthApiService from '../../../services/api/AuthApiService';
import ErrorRequestService from '../../../services/errorRequest';

import './CadastrarPage.css';
// import Loading from '../../../components/Loading';

const INITIAL_PAGE_PATH = "/login";

function CadastrarPage() {
    const title = "Solicitar cadastro";
    UtilService.setTitle(title);

    // STATE
    const [messageDone, setMessageDone] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // CONTEXT
    const { user } = useAuth();
    const alert = useAlertNotification();
    const history = useHistory();

    if (!!user) return <Redirect to={INITIAL_PAGE_PATH} />

    const handleCancel = () => {
        history.goBack();
    }

    const onSubmit = async (form, actions) => {
        try {
            setIsLoading(true);
            const validation = await CadastrarLoginValidation.validate(form);
            if (!validation.isValid) {
                const { errors } = validation;
                const errorsTxt = Object.keys(errors).map(key => errors[key]).join('\n');
                alert.error(errorsTxt);
                return;
            }

            const encodedForm = UtilService.encodeUtf8AsBase64(JSON.stringify(form));//btoa(JSON.stringify(form));
            const response = await AuthApiService.requestRegister(encodedForm);

            if (!response.data) {
                alert.error("Problema com ao validar dados enviados.");
                return;
            }

            const { status, message, errors } = response.data;
            
            if (errors) {
                const msgError = []
                Object.keys(errors).forEach(key => {
                    const errStr = errors[key].map(item => item.errorMessage).join('\n');
                    msgError.push(errStr);
                })
                console.log("[ERROR] [CadastrarPage.onSubmit]:", { errors, msgError });
                alert.error(msgError.join('\n'));
                return;
            }
            
            console.log("[ OK ] [CadastrarPage.onSubmit]:", { status, message });
            setMessageDone(message);
        }
        catch (error) {
            const errorService = new ErrorRequestService(error);
            const errMsg = errorService.getErrors();
            console.log("[ERROR] [CadastrarPage.onSubmit]:", errMsg);
            alert.error(errMsg);  /* "Erro na tentativa de enviar/cadastrar novo usuário." */
        }
        finally {
            setIsLoading(false);
        }
    };

    const textCounter = (value, max) => {
        return `${String(value).length}${(!max ? '' : '/'+max)}`
    }

    // RENDER
    const cssExplain = `text-explain ${(!messageDone) ? 'text-muted' : 'text-success'}`;
    const explainMessage = messageDone || `Seu pedido de cadastro será enviado ao administrador.`;

    return (
        <div className="cadastrar-container">
            <h4>{ title }</h4>
            <p className={cssExplain}>
                { explainMessage }
            </p>
            <hr/>

            {(messageDone) ? (
                <div className="success-container">
                    <div className="thumbs-up-icon text-success">
                        <FaThumbsUp />
                    </div>

                    <hr/>
                    
                    <Button 
                        className="cadastrar-button"
                        variant="success"
                        onClick={handleCancel}
                    >
                        <FaArrowLeft /> Voltar à tela de Login
                    </Button>
                </div> 
            ):(<>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                        nome: '',
                        email: '',
                        senha: '',
                        confirma: '',
                        mensagem: ''
                    }}
                    validationSchema={CadastrarLoginValidation.schema}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form
                            noValidate
                            onSubmit={handleSubmit}
                            className="cadastrar-form"
                        >

                            <Form.Group controlId="fg-nome">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control 
                                    name="nome"
                                    type="text"
                                    placeholder="Digite seu nome"
                                    onChange={handleChange}
                                    onBlur={handleBlur('nome')}
                                    value={values.nome}
                                    isValid={(touched.nome && !errors.nome)}
                                    isInvalid={(touched.nome && !!errors.nome)}
                                    autoComplete="off"
                                    disabled={isLoading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.nome }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="fg-sobrenome">
                                <Form.Label>Sobrenome</Form.Label>
                                <Form.Control 
                                    name="sobrenome"
                                    type="text"
                                    placeholder="Digite seu sobrenome"
                                    onChange={handleChange}
                                    onBlur={handleBlur('sobrenome')}
                                    value={values.sobrenome}
                                    isValid={(touched.sobrenome && !errors.sobrenome)}
                                    isInvalid={(touched.sobrenome && !!errors.sobrenome)}
                                    autoComplete="off"
                                    disabled={isLoading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.sobrenome }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="fg-email">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control 
                                    name="email"
                                    type="email"
                                    placeholder="Digite seu e-mail"
                                    onChange={handleChange}
                                    onBlur={handleBlur('email')}
                                    value={values.email}
                                    isValid={(touched.email && !errors.email)}
                                    isInvalid={(touched.email && !!errors.email)}
                                    autoComplete="off"
                                    disabled={isLoading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.email }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="fg-senha">
                                <Form.Label>Senha</Form.Label>
                                <Form.Control
                                    name="senha"
                                    type="password"
                                    placeholder="Digite sua senha"
                                    onChange={handleChange}
                                    onBlur={handleBlur('senha')}
                                    value={values.senha}
                                    isValid={(touched.senha && !errors.senha)}
                                    isInvalid={(touched.senha && !!errors.senha)}
                                    autoComplete="off"
                                    disabled={isLoading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.senha }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="fg-confirma">
                                <Form.Label>Confirmar Senha</Form.Label>
                                <Form.Control
                                    name="confirma"
                                    type="password"
                                    placeholder="Digite sua confirma"
                                    onChange={handleChange}
                                    onBlur={handleBlur('confirma')}
                                    value={values.confirma}
                                    isValid={(touched.confirma && !errors.confirma)}
                                    isInvalid={(touched.confirma && !!errors.confirma)}
                                    autoComplete="off"
                                    disabled={isLoading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.confirma }
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* <Form.Group controlId="fg-mensagem">
                                <Form.Label>Mensagem</Form.Label>
                                <Form.Text className="text-muted float-right">
                                    { textCounter(values.mensagem, 300) }
                                </Form.Text>
                                <Form.Control
                                    name="mensagem"
                                    style={{
                                        minHeight: '5rem',
                                    }}
                                    as="textarea"
                                    rows={2}
                                    placeholder="Digite sua mensagem de solicitação aqui..."
                                    onChange={handleChange}
                                    onBlur={handleBlur('mensagem')}
                                    value={values.mensagem}
                                    isValid={(touched.mensagem && !errors.mensagem)}
                                    isInvalid={(touched.mensagem && !!errors.mensagem)}
                                    autoComplete="off"
                                    disabled={isLoading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.mensagem }
                                </Form.Control.Feedback>
                            </Form.Group> */}

                            <hr/>

                            <div className="cadastrar-btn-container">
                                <Button 
                                    className="cadastrar-button button-register"
                                    variant="primary"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    { isLoading
                                        ? <span>Em processamento...</span>
                                        : (<><FaCheck /> Solicitar</>)
                                    }
                                </Button>

                                <Button 
                                    className="cadastrar-button"
                                    variant="secondary"
                                    onClick={handleCancel}
                                    disabled={isLoading}
                                >
                                    <FaTimes /> Cancelar
                                </Button>
                            </div>

                        </Form>
                    )}
                </Formik>

                {/* { isLoading && <Loading message="Aguarde, processando..." />} */}
            </>)}

        </div>
    )
}

export default CadastrarPage;
