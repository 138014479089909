/** 
 * Informações das colunas para lista
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>} 
*/
export const columns = [
    {
        label: 'Cod.',
        name: 'cod',
        width: '10%',
        className: '',
    },
    {
        label: 'Sigla',
        name: 'sigla',
        width: '20%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Nome',
        name: 'nome',
        // width: '20%',
    }
];


const FuncaoListInfo = { columns };

export default FuncaoListInfo;
