import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import ProjetosApiService from '../../../../services/api/ProjetosApiService';
import useAuth from '../../../../context/AuthContext';
import useAlertNotification from '../../../../context/AlertNotificationContext';

const DEFAULT_VALUE = '0,00';

function CustosProjeto({ props, onCancel }) {
    const { handlerFilter } = props

    const [custosProjeto, setCustosProjeto] = useState({
        0: {
            tipoCusto: "",
            descricao: "",
            valor: DEFAULT_VALUE,
            moeda: "BRL",
            cotacao: DEFAULT_VALUE,
        }
    });
    const [isLoading, setIsLoading] = useState(false)
    const [cotacoes, setCotacoes] = useState({})
    const [errors, setErrors] = useState({})

    const { user } = useAuth()
    const { email } = user

    const alert = useAlertNotification()

    const mask = (value) => {
        value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

        const options = { minimumFractionDigits: 2 }

        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )

        return result
    }

    const getCotacoes = async () => {
        const config = {
            method: 'get',
            url: `https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL`
          };

          const result = await axios(config);
          setCotacoes({
              BRL: DEFAULT_VALUE,
              USD: mask(parseFloat(result.data['USDBRL'].bid).toFixed(2)),
              EUR: mask(parseFloat(result.data['EURBRL'].bid).toFixed(2))
          })

    }

    const onChangeCustos = (event, key) => {
        const { name, value } = event.target;

        let newValues = {}

        if (name === 'moeda') {
            newValues = { cotacao: cotacoes[value] }
        }

        newValues = name === "valor" || name === "cotacao" ?
            { [name]: mask(value) } : { ...newValues, [name]: value }

        setCustosProjeto(state => {
            return {
                ...state,
                [key]: {...state[key], ...newValues }
            }
        })

        setErrors({});
    }

    useEffect(() => {
        getCotacoes()
    }, [])

    const verificaErros = () => {
        const newErrors = {};

        Object.keys(custosProjeto).forEach(key => {
            const custo = custosProjeto[key];

            if (custo.tipoCusto === '') {
                newErrors[key] = { ...newErrors[key], tipoCusto: 'Campo obrigatório' };
            }

            if (custo.valor === DEFAULT_VALUE) {
                newErrors[key] = { ...newErrors[key], valor: 'Campo obrigatório' };
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length > 0;
    };

    const saveCustos = async () => {
        try {
            if (verificaErros()) return;

            setIsLoading(true)

            let response = ""

            for (const key of Object.keys(custosProjeto)) {
                response = await ProjetosApiService.saveCustoProjeto({
                    email,
                    projeto: props.project,
                    periodo_inicio: props.periodoInicio,
                    periodo_fim: props.periodoFim,
                    tipo_custo: custosProjeto[key].tipoCusto,
                    descricao: custosProjeto[key].descricao,
                    valor: custosProjeto[key].valor.replaceAll('.', '').replace(',', '.'),
                    moeda: custosProjeto[key].moeda,
                    cotacao: custosProjeto[key].cotacao.replaceAll('.', '').replace(',', '.'),
                })

                const { status } = response

                if (status !== 200) {
                    alert.error("Erro ao salvar o(s) custo(s) do projeto.");
                    throw new Error("Erro ao salvar o(s) custo(s) do projeto.");
                }
            }

            alert.success(`Custo(s) ${props.periodoInicio} - ${props.periodoFim} salvo(s) com sucesso!`);
            onCancel();
            handlerFilter();
        } catch (error) {
            console.log("[ERROR] [CustoProjeto.salvaCusto]:", error);
            alert.error("Erro ao salvar o(s) custo(s) do projeto.");
        } finally {
            setIsLoading(false)
        }
    }

    const tiposEspecificar = ['INFRA_CLOUD', 'FORNECEDORES_TERCEIROS', 'OUTROS']

    return (
        <div>
            <Form noValidate>
                <div className='d-flex justify-content-end'>
                    <Button
                        variant='primary'
                        size='sm'
                        onClick={() => {
                            setCustosProjeto(state => {
                                return {
                                    ...state,
                                    [Object.keys(state).length]: {
                                        tipoCusto: "",
                                        descricao: "",
                                        valor: DEFAULT_VALUE,
                                        moeda: "BRL",
                                        cotacao: DEFAULT_VALUE,
                                    }
                                }
                            })
                        }}
                    >
                        <FaPlus />
                    </Button>

                    {Object.keys(custosProjeto).length > 1 && (
                        <Button variant='danger' size='sm' className='ml-2'
                            onClick={() => {
                                setCustosProjeto(state => {
                                    const newState = { ...state }
                                    delete newState[Object.keys(state).length - 1]
                                    return newState
                                })
                            }}
                        >
                            <FaMinus />
                        </Button>
                    )}
                </div>

                {Object.keys(custosProjeto).map((key, index) => (
                    <React.Fragment key={key}>
                        <Row style={{ marginBottom: 10 }}>
                            <Col>
                                <Form.Label>Tipo de custo</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="tipoCusto"
                                    value={custosProjeto[key].tipoCusto}
                                    onChange={(e) => onChangeCustos(e, key)}
                                    isInvalid={!!errors[key]?.tipoCusto}
                                >
                                    <option value="">Selecione</option>
                                    <option value="INFRA_CLOUD">Infraestrutura em Cloud</option>
                                    <option value="OUTROS_SOFTWARES">Outros softwares</option>
                                    <option value="HORAS_EXTRAS">Horas extras</option>
                                    <option value="FORNECEDORES_TERCEIROS">Fornecedores terceiros</option>
                                    <option value="OUTROS">Outros</option>
                                </Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    {errors[key]?.tipoCusto}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                            <Col>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="descricao"
                                    autoComplete='off'
                                    placeholder={tiposEspecificar.includes(custosProjeto[key].tipoCusto) ? 'Especificar tipo de custo' : ''}
                                    value={custosProjeto[key].descricao}
                                    onChange={(e) => onChangeCustos(e, key)}
                                />
                            </Col>
                            <Col>
                                <Form.Label>Valor</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="valor"
                                    autoComplete='off'
                                    value={custosProjeto[key].valor}
                                    onChange={(e) => onChangeCustos(e, key)}
                                    isInvalid={!!errors[key]?.valor}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors[key]?.valor}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                            <Col>
                                <Form.Label>Moeda</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="moeda"
                                    value={custosProjeto[key].moeda}
                                    onChange={(e) => onChangeCustos(e, key)}
                                >
                                    <option value="BRL">Real</option>
                                    <option value="USD">Dolár</option>
                                    <option value="EUR">Euro</option>
                                </Form.Control>
                            </Col>
                            {custosProjeto[key].moeda !== 'BRL' && (
                                <Col>
                                    <Form.Label>Cotação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="cotacao"
                                        autoComplete='off'
                                        maxLength={5}
                                        value={custosProjeto[key].cotacao}
                                        onChange={(e) => onChangeCustos(e, key)}
                                    />
                                </Col>
                            )}
                        </Row>

                        {Object.keys(custosProjeto).length !== (index + 1) && (
                            <hr className='mt-4' />
                        )}
                    </React.Fragment>
                ))}

                <Button className='mt-1' onClick={saveCustos} disabled={isLoading}>
                    Salvar
                </Button>
            </Form>
        </div>
    )
}

export default CustosProjeto;
