import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FaPen, FaSave } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FormBuilder } from '../../../../components/FormBuilder';
import PageContainer from '../../../../components/PageContainer';
import Loading from '../../../../components/Loading';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../../services/errorRequest';
import BeneficioApiService from '../../../../services/api/BeneficioApiService';
import BeneficioValidation from '../../../../services/validation/BeneficioValidation';
import UtilService from '../../../../services/util';
import BeneficioViewInfo from './BeneficioViewInfo';

function BeneficioView({dados, onSave, onCancel, editable=false, onEditableChange}) {
    // CONTEXT
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const alert = useAlertNotification();

    // PROPS
    const { nome='', sigla='' } = location.state || {};
    const cod = (dados && dados.cod) || match.params.cod;

    // STATE
    const [origData, setOrginData] = useState(dados);
    const [formFields, setFormFields] = useState(BeneficioViewInfo.getFormFields());
    const [formData, setFormData] = useState(dados || null);
    const [edit, setEdit] = useState(editable);
    const [isEdited, setIsEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    const isFormEdited = () => {
        if (!editable) return false;
        const jsonOrigData = JSON.stringify(origData);
        const jsonFormData = JSON.stringify(formData);
        return (jsonOrigData !== jsonFormData);
    }
        
    const askForLeave = (cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            const msg = 'Foram feitas alterações!\nDeseja realmente sair e perdê-las?';
            const leave = window.confirm(msg);
            
            if (leave) cbPositive();
            else cbNegative();
        }   
        return false;
    }

    const resetForm = () => UtilService.genericResetFormFunction();
    
    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        history.goBack();
    }

    const hanldeOnSave = () => {
        if (onSave) {
            onSave();
            return;
        } 
        const url = `${match.url.replace(`/${cod}`, '')}`;
        history.replace(url, location.state);
    }

    const saveForm = async () => {
        try {
            // validar form
            const validation = await BeneficioValidation.validate(formData);
            if (!validation.isValid) {
                alert.error("Erros no formulário!");
                return;
            }
            
            // se ok, enviar p/ API
            const resp = await BeneficioApiService.updateOne(formData.cod, formData);
            if (resp.data.result) {
                setIsEdited(false);
                setEdit(!edit);
                alert.success("Dados do beneficio atualizados!");
                hanldeOnSave();
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    }

    const toggleEdit = () => {
        if (isEdited) {
            askForLeave(() => {
                setFormData(origData);
                resetForm();
            });
            return;
        }
        setEdit(!edit);
    }

    const handleEditable = () => {
        if (editable !== edit && onEditableChange) {
            onEditableChange(edit);
        }
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                setFormData(origData);
                setEdit(!edit);
                resetForm();
            });
            return;
        }
        if (edit) {
            setEdit(!edit);
            return;
        }
        handleOnCancel();
    }
    
    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setFormData(newform);
    }

    const onEdit = () => {
        if (isFormEdited() && !isEdited) setIsEdited(true);
    }

    const onStart = () => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const formData = await BeneficioApiService.getByCod(cod).then(resp => {
                    const { data } = resp.data;
                    return data;
                })
                setFormData( formData );
                setOrginData( formData );
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors(); 
                console.log(errorMsg);
                alert.error(errorMsg);
            }
            finally {
                setIsLoading(false);
            }
        }
        if (!formData) getData();
        else setIsLoading(false);
    }

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(onEdit, [formData]);
    useEffect(handleEditable, [editable, edit]);


    const actions = [
        ...( !editable ? [
            { 
                label: 'Editar',
                icon: <FaPen />,
                color: 'primary',
                type: 'button',
                onClick: toggleEdit
            }
        ]:[
            { 
                label: 'Salvar',
                icon: <FaSave />,
                color: 'success',
                type: 'button',
                onClick: saveForm
            }
        ]),
        { 
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            onClick: handleCancel
        }
    ]
    
    const title = `Benefício ${sigla ? `(${sigla})` : ''}`;

    return (
        <PageContainer
            title={title}
            actions={actions}
        >
            { isLoading 
                ? <Loading message="Carregando..." />
                : ( origData && 
                    <FormBuilder 
                        formFields={formFields}
                        formData={formData}
                        onChange={onChange}
                        disabledForm={!editable}
                        initialValues={origData}
                        schema={BeneficioValidation.schema}
                    />
                )
            }
            
            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        resetForm();
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </PageContainer>
    )
}

export default BeneficioView;
