import React from 'react';
import { Route, Switch } from 'react-router-dom';

// PAGES
import DepartamentoList from './DepartamentoList';
import DepartamentoNew from './DepartamentoNew';
import DepartamentoView from './DepartamentoView';


function Departamento(props) {
    const { match } = props;

    return (
        <Switch>
            <Route path={match.path} exact component={DepartamentoList} />
            <Route path={`${match.path}/new`} component={DepartamentoNew} />
            <Route path={`${match.path}/:cod`} component={DepartamentoView} />
            
        </Switch>
    )
}

export default Departamento;
