import api from '../api/api';

const tokenStorageKey = '@ConVert:token';
const refresh_tokenStorageKey = '@ConVert:refresh_token';
const userStorageKey = '@ConVert:user';
const authHeader = 'x-access-token';

/** Define o token a ser usado nos requests */
const setRequestHeaders = (token) => {
    api.defaults.headers[authHeader] = token;
}

/**  Recupera os dados do usuário do web browser */
const getUserDataLocal = () => {
    const token = localStorage.getItem(tokenStorageKey);
    const refresh_token = localStorage.getItem(refresh_tokenStorageKey);
    const user = localStorage.getItem(userStorageKey);

    // Primeiro, armazenar o token p/ axios request header
    if (token && user) {
        setRequestHeaders(token);
        return { 
            token, 
            refresh_token,
            user: JSON.parse(user)
        };
    }
    return {};
}

/** Armazena os dados do usuário no web browser */
const storeUserDataLocal = ({ token, refresh_token, user }) => {
    setRequestHeaders(token);
    localStorage.setItem(tokenStorageKey, token);
    localStorage.setItem(refresh_tokenStorageKey, refresh_token);
    localStorage.setItem(userStorageKey, JSON.stringify(user));
}

/** Remove os dados do usuário do web browser */
const removeUser = () => {
    setRequestHeaders(null);
    localStorage.removeItem(tokenStorageKey);
    localStorage.removeItem(refresh_tokenStorageKey);
    localStorage.removeItem(userStorageKey);
}

const LocalStorageService = {
    getUserDataLocal,
    storeUserDataLocal,
    removeUser,
}

export default LocalStorageService;
