import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaFileAlt } from 'react-icons/fa';

import './Uploader.css';

function Uploader({ label, name, icon, accept, onChange, ...rest }) {
    // STATE
    const [fileInfo, setFileInfo] = useState(undefined);
    const [iconToShow, setIconToShow] = useState(<FaFileAlt />);

    // FUNCTIONS
    const handleOnClick = e => {
        const file = e.target.files[0];
        setFileInfo(file);
        onChange(e);
    }

    const onStart = useCallback(() => {
        if (icon) setIconToShow(icon);
    }, [icon]);

    // EFFECTS
    useEffect(onStart, []);

    const id = `uploader-${String(name).toLowerCase().replace(/\s/,'')}`;

    return (
        <div className="uploader">
            <label htmlFor={id}>
                { iconToShow } 
                <span>{ label }</span>
            </label>
            <input
                type="file"
                id={id}
                accept={accept}
                onChange={handleOnClick}
                {...rest}
            />
            <span>{ fileInfo?.name }</span>
        </div>
    )
}

Uploader.defaultProps = {
    label: undefined,
    name: 'input-upload',
    icon: undefined
}

Uploader.propTypes = {
    accept: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    name: PropTypes.string, 
    icon: PropTypes.node,
}

export default Uploader;

