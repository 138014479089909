import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ListLayout from '../../../../components/ListLayout';
import Loading from '../../../../components/Loading';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../../services/errorRequest';
import BeneficioApiService from '../../../../services/api/BeneficioApiService';
import { columns } from './BeneficioColabListInfo';
import UtilService from '../../../../services/util';

const INITIAL_PAGEPARAMS = {
    orderBy: 'sigla',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function BeneficioColablList({ onRowClick }) {
    // CONTEXT
    const match = useRouteMatch();
    const history = useHistory();
    // const location = useLocation();
    const alert = useAlertNotification();

    const { mat } = match.params || {};

    // STATE
    const [rowsData, setRowsData] = useState(null);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    /** Define o clique sobre um registro da lista */
    const rowClick = (item) => {
        if (onRowClick) {
            onRowClick(item);
            return;
        }
        history.push(`${match.url}/${item.cod}`, item);
    }

    /** Adiciona click e atributos ao header */
    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name) 
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    /** Adequa os dados ao formato para lista */
    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = rowsData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    onClick: () => rowClick(item),
                    data: columns.map(col => {
                        let value = item[col.name];
                        if (col.name === 'valor') {
                            value = UtilService.maskCurrency(value);
                        }
                        return {
                            value,
                            className: col.className
                        }
                    })
                };
                dataList.push(row);
            });
        return dataList;
    }

    /** Traz as funcoes cadastradas no database  */
    const getDataList = useCallback(async () => {
        try {
            setIsLoading(true);
            const resp = (await BeneficioApiService.getByColabMat(mat)).data;
            setRowsData(resp.data);
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    }, []);


    // EFFECTS
    useEffect(() => {
        if (!rowsData) getDataList();
    }, [rowsData, getDataList]);

    // LOADING
    if (isLoading) {
        return <Loading message="Carregando..." />;
    }
    
    return (
        <ListLayout
            columns={getListHeader()}
            rows={defineDataList()}
        />
    )
}

export default BeneficioColablList;
