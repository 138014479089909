import React, { useEffect, useState, useCallback } from "react";
import PageContainer from "../../../components/PageContainer";
import { useLocation } from 'react-router-dom';
import { MdOutlineArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PlanejamentoApiService from "../../../services/api/PlanejamentoApiService";
import Loading from "../../../components/Loading";
import { Table, Dropdown, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import ColaboradorApiService from "../../../services/api/ColaboradorApiService";
import ConvertDataService from "../../../services/util/ConvertDatas";
import UtilService from "../../../services/util";
import FuncaoApiService from "../../../services/api/FuncaoApiService";
import { FiInfo } from "react-icons/fi";

const popupStyle = {
    position: 'absolute',
    top: '-40%',
    left: '-600%',
    transform: 'translateX(-82%)',
    backgroundColor: '#e9e9e9',
    color: '#212529',
    border: '1px solid #dee2e6',
    borderRadius: '5px',
    padding: '5px 10px',
    zIndex: 1000,
    whiteSpace: 'nowrap',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    width: 'auto',
    fontWeight: 'normal'
};

function RelatorioCapacidadeView() {
    const [loading, setLoading] = useState(false);
    const [colaboradoresList, setColaboradoresList] = useState([]);
    const [colaboradoresComHoras, setColaboradoresComHoras] = useState([]);
    const [filteredColaboradores, setFilteredColaboradores] = useState([]);
    const [funcoes, setFuncoes] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedRegime, setSelectedRegime] = useState('');
    const [fetchDadosColaboradores, setFetchDadosColaboradores] = useState([]);
    const location = useLocation();
    const { data } = location.state || {};
    const history = useHistory();
    const [visiblePopup, setVisiblePopup] = useState(null);

    const dataInicialFiltro = ConvertDataService.convertDataSplitYYYYmmDD(data.periodoInicial);
    const dataFinalFiltro = ConvertDataService.convertDataSplitYYYYmmDD(data.periodoFinal);

    const fetchColaboradores = useCallback(async () => {
        try {
            const resp = (await ColaboradorApiService.getAll()).data.data;
            const colaboradores = resp.filter(colab => 
                (colab.departamento === 5 || colab.departamento === 16 || (colab.departamento == 20 && colab.regime == 3)) && 
                (colab.regime !== 2)
            ); 
            const colaboradoresAtivos = colaboradores.filter(colaborador => 
                (!colaborador.demissao || colaborador.demissao >= dataInicialFiltro) &&
                colaborador.admissao <= dataFinalFiltro &&
                colaborador.accountId
            );
            setColaboradoresList(colaboradoresAtivos);
        } catch (error) {
            console.error("Erro ao carregar colaboradores.", error);
        }
    }, [dataInicialFiltro, dataFinalFiltro]);

    const fetchFuncoes = useCallback(async () => {
        try {
            const resp = await FuncaoApiService.getAll();
            setFuncoes(resp.data.data);
        } catch (error) {
            console.error("Erro ao buscar as funções.", error);
        }
    }, []);
    
    async function fetchWithLimit(collaborators, limit) {
        const queue = [];
        for (let i = 0; i < collaborators.length; i += limit) {
            const chunk = collaborators.slice(i, i + limit);
            const promises = chunk.map(collab => fetchDataForCollaborator(collab));
            queue.push(...(await Promise.all(promises)));
        }
        setFetchDadosColaboradores(queue);
        setLoading(false)
        return queue;
    }
      
    async function fetchDataForCollaborator(colaborador) {
        setLoading(true)
        const parametros = [
            colaborador.accountId,
            dataInicialFiltro,
            dataFinalFiltro
        ];
    
        let planejadas, obrigatorias, realizadas, cargaHoraria;
    
        try {
            planejadas = await PlanejamentoApiService.getPlansByUserAndDate(parametros)
                .then(res => res.data.results);
        } catch (error) {
            console.error(`Erro ao buscar planos para ${colaborador.nome}:`, error);
            planejadas = [];
        }
    
        try {
            obrigatorias = await PlanejamentoApiService.getUserScheduleByUserAndDate([
                colaborador.accountId,
                new Date(colaborador.admissao) > new Date(dataInicialFiltro) ? colaborador.admissao : dataInicialFiltro,
                colaborador.demissao ? colaborador.demissao : dataFinalFiltro
            ]).then(res => res.data.results);
        } catch (error) {
            console.error(`Erro ao buscar horas obrigatórias para ${colaborador.nome}:`, error);
            obrigatorias = [];
        }
    
        try {
            realizadas = await PlanejamentoApiService.getUserWorklogByUserAndDate(parametros)
                .then(res => res.data);
        } catch (error) {
            console.error(`Erro ao buscar worklogs para ${colaborador.nome}:`, error);
            realizadas = [];
        }

        try{
            cargaHoraria = await PlanejamentoApiService.getUserWorkload(colaborador.accountId)
            .then(res => res.data)
        }   catch(error){
            console.error(`Erro ao buscar carga horária para ${colaborador.nome}: `, error)
            cargaHoraria = [];
        }

        return { colaborador, planejadas, obrigatorias, realizadas, cargaHoraria };
    }

    useEffect(() => {
        fetchColaboradores();
        fetchFuncoes();
    }, [fetchColaboradores, fetchFuncoes]);

    useEffect(() => {
        if (colaboradoresList.length > 0) {
            fetchWithLimit(colaboradoresList, 5)
        }
    }, [colaboradoresList]);

    useEffect(() => {
        if (Array.isArray(fetchDadosColaboradores) && fetchDadosColaboradores.length > 0) {
    
            const resultadosFetch = fetchDadosColaboradores.map((item) => {
                const { colaborador, planejadas, obrigatorias, realizadas, cargaHoraria } = item;
                
                let horasPlanejadas = planejadas.reduce((acc, plan) => acc + (plan.totalPlannedSecondsInScope || 0), 0) / 3600;

                let cargaHorariaTotal = (cargaHoraria.days[0].requiredSeconds);

                let horasPorDia = cargaHorariaTotal == 60 ? 0 : cargaHorariaTotal / 3600; 
            
                let totalHorasObrigatorias = obrigatorias
                    .filter(result => result.type === "WORKING_DAY")
                    .reduce((acc, result) => acc + (result.requiredSeconds || 0), 0) / 3600;
            
                let duracao = realizadas.reduce((acc, worklog) => acc + (worklog.timeSpentSeconds || 0), 0) / 3600;
                let horasFaturaveis = realizadas.reduce((acc, worklog) => acc + (worklog.billableSeconds || 0), 0) / 3600;

                let ferias = planejadas.reduce((acc, plan) => acc + (plan.planItem.id == 24809 ? plan.totalPlannedSecondsInScope : 0), 0) / 3600
            
                return {
                    ...colaborador,
                    horasPorDia,
                    totalPlannedHoursInScope: horasPlanejadas,
                    totalHorasObrigatorias,
                    duracao,
                    horasFaturaveis,
                    ferias
                };
            });
            setColaboradoresComHoras(resultadosFetch);
        }
    }, [fetchDadosColaboradores]);

    useEffect(() => {
        const filtered = colaboradoresComHoras.filter((item) => {
            const matchesDepartment = selectedDepartment
                ? item.departamento === (selectedDepartment === 'Delivery' ? 5 : selectedDepartment === 'ENGESOL' ? 16 : 20)
                : true;
            const matchesRegime = selectedRegime
                ? item.regime === (selectedRegime === 'CLT' ? 1 : 3)
                : true;
            return matchesDepartment && matchesRegime;
        });
        setFilteredColaboradores(filtered);
    }, [selectedDepartment, selectedRegime, colaboradoresComHoras]);

    const exportDataInExcel = async () => {
        const header = [
            [
                'Colaborador', 'Status', 'Departamento', 'Regime', 'Perfil', 'Carga Horária Diária', 'Total Horas Obrigatórias', 'Total Horas Planejadas', 'Total horas de Férias', 'Ocupação Planejada(%)', 'Total de Horas Realizadas', 'Ocupação Realizada (%)', 'Total de Horas Faturáveis', 'Ocupação Faturável (%)', 'Variação Faturável (%)'
            ]
        ];

        const formatedData = filteredColaboradores.map(item => {
            const cargaHorariaDiaria = parseFloat(item.horasPorDia.toFixed())

            const desvio = item.totalPlannedHoursInScope === 0
                ? -100
                : (((item.totalPlannedHoursInScope - item.ferias) - item.horasFaturaveis) / (item.totalPlannedHoursInScope - item.ferias)) * 100;

            return {
                colaborador: `${item.nome} ${item.sobrenome}`,
                status: item.demissao ? "Inativo" : "Ativo",
                departamento: item.departamento === 5 ? "Delivery" : item.departamento === 16 ? "ENGESOL" : "Overhead",
                regime: item.regime === 1 ? "CLT" : item.regime === 3 && "Estagiário",
                perfil: funcoes.find(funcao => funcao.cod === item.funcao)?.nome,
                cargaHorariaDiaria,
                totalHorasObrigatorias: `${(item.totalHorasObrigatorias - item.ferias).toFixed()}h`,
                totalHorasPlanejadas: `${(item.totalPlannedHoursInScope - item.ferias).toFixed()}h`,
                totalHorasFerias: `${item.ferias}h`,
                ocupacaoPlanejada: item.demissao ? "-- " : `${((item.totalPlannedHoursInScope * 100)/item.totalHorasObrigatorias).toFixed()}%`,
                totalHorasRealizadas: `${item.duracao.toFixed()}h`,
                ocupacaoRealizada: `${((item.duracao * 100)/item.totalHorasObrigatorias).toFixed()}%`,
                totalHorasFaturaveis: `${item.horasFaturaveis.toFixed()}h`,
                ocupacaoFaturavel: `${((item.horasFaturaveis * 100)/item.totalHorasObrigatorias).toFixed()}%`,
                variacaoFaturavel: parseFloat(desvio.toFixed(2))
            };
        });

        UtilService.exportXls(formatedData, `Relatório Capacidade: ${data.periodoInicial} - ${data.periodoFinal}`, header);
    };

    const totalPlannedHoursInScope = filteredColaboradores.reduce((total, item) => {
        return total + (item.totalPlannedHoursInScope - item.ferias);
    }, 0);

    const totalHorasObrigatorias = filteredColaboradores.reduce((total, item) => {
        return total + (item.totalHorasObrigatorias - item.ferias);
    }, 0)

    const totalDuracao = filteredColaboradores.reduce((total, item) => {
        return total + item.duracao;
    }, 0)

    const totalHorasFaturaveis =  filteredColaboradores.reduce((total, item) => {
        return total + item.horasFaturaveis;
    }, 0)

    const totalHorasFerias = filteredColaboradores.reduce((total, item) => {
        return total + item.ferias;
    }, 0);

    const riscoDemissao = {
        textDecoration: "line-through"
    }

    const handleCancel = () => {
        history.push('../relatorios-delivery/');
    };

    const actions = [
        {
            label: 'Voltar',
            icon: <MdOutlineArrowBack />,
            color: 'info',
            type: 'button',
            onClick: handleCancel
        }
    ];

    const fixarTitulo = {
        "position": "sticky", 
        "top":0, 
        "zIndex": 9998, 
        "backgroundColor" : "#f5f5f5",
    }

    const fixarThead = { 
        position: "sticky", 
        top: 0, 
        zIndex: 1000, 
        backgroundColor: "#f5f5f5", 
        "boxShadow" :" 0 4px 4px -4px rgba(0, 0, 0, 0.3)" 
    }

    const handleMouseEnter = (popupId) => {
        setVisiblePopup(popupId);
    };

    const handleMouseLeave = () => {
        setVisiblePopup(null);
    };

    return (
        <>
        <div style={fixarTitulo}>
            <PageContainer title={"Relatório Capacidade da Área"} actions={actions}>
                <h5>Período Inicial: {data.periodoInicial}</h5>
                <h5 className="mb-4">Período Final: {data.periodoFinal}</h5>
                
                <div className="d-flex mb-3" style={{position: "sticky"}}>
                <Button onClick={() => exportDataInExcel()} className='mr-3'>Exportar</Button>
                <Dropdown className="mr-3">
                    <Dropdown.Toggle variant="light" id="dd-column">
                        Filtrar por Departamento: <span style={{ fontWeight: 'bold' }}>{selectedDepartment ? selectedDepartment : "Todos"}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSelectedDepartment('Delivery')}>
                            Delivery
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedDepartment('ENGESOL')}>
                            ENGESOL
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedDepartment('Overhead')}>
                            Overhead
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedDepartment('')}>
                            Todos
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dd-column">
                        Filtrar por Regime: <span style={{ fontWeight: 'bold' }}>{selectedRegime  ? selectedRegime : "Todos"}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSelectedRegime('CLT')}>
                            CLT
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedRegime('Estagiário')}>
                            Estagiário
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedRegime('')}>
                            Todos
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            </PageContainer>
            </div>
                {loading ? <Loading message={"Carregando..."} /> : 
                    <div id="tabela-personalizada-relatorio-capacidade" style={{maxHeight: "600px", overflowY: "auto"}}>
                    <Table striped bordered hover responsive="xl">
                    <thead style={fixarThead}>
                        <tr>
                        <th className="fixed-column no-wrap">Colaborador</th>
                        <th className="no-wrap">Departamento</th>
                        <th className="no-wrap">Regime do contrato</th>
                        <th className="no-wrap">Perfil</th>
                        <th className="no-wrap">Cargas horárias <br/> diárias</th>
                        <th className="no-wrap">Total horas <br/> Obrigatórias</th>
                        <th className="no-wrap verde-claro-planejado">Total horas <br/> Planejadas</th>
                        <th className="no-wrap verde-claro-planejado">Total horas de <br/> Férias</th>
                        <th className="no-wrap verde-claro-planejado">Ocupação <br/> Planejada (%)</th>
                        <th className="no-wrap azul-claro-realizado">Total horas <br/> Realizadas</th>
                        <th className="no-wrap azul-claro-realizado">Ocupação <br/> Realizada (%)</th>
                        <th className="no-wrap azul-claro-realizado">Total horas <br/> Faturáveis</th>
                        <th className="no-wrap azul-claro-realizado">Ocupação <br/> Faturável (%)</th>
                        <th className="no-wrap cinza-claro-variacao">Variação <br/> Faturável (%)
                            <span 
                            onMouseEnter={()=> handleMouseEnter('variacao_faturavel')}
                            onMouseLeave={handleMouseLeave}
                            className="ml-1 info-icon"
                            style={{ position: 'relative', cursor: 'pointer'}}
                            >
                                <FiInfo />
                                {visiblePopup === 'variacao_faturavel' && (
                                    <div className="hover-popup" style={popupStyle}> 
                                        Diferença entre total de horas planejadas e o total de horas faturáveis.
                                    </div>
                                )}
                            </span>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredColaboradores
                        .sort((a, b) => a.nome.localeCompare(b.nome))
                        .map((item, index) => {
                            return (
                            <tr key={index}>
                            <td className="fixed-column no-wrap" style={item.demissao ? riscoDemissao : {}}>{item.nome} {item.sobrenome} </td>
                            <td className="no-wrap">{item.departamento === 5 ? "Delivery" : item.departamento === 16 ? "ENGESOL" : "Overhead"}</td>
                            <td className="no-wrap">{item.regime === 1 ? "CLT" : item.regime === 3 && "Estagiário"}</td>
                            <td className="no-wrap">{funcoes.find(funcao => funcao.cod === item.funcao)?.nome}</td>
                            <td className="no-wrap">{item.horasPorDia.toFixed()}h</td>
                            <td className="no-wrap">{(item.totalHorasObrigatorias - item.ferias).toFixed()}h</td>
                            <td className="no-wrap verde-claro-planejado">{(item.totalPlannedHoursInScope - item.ferias).toFixed()}h</td>
                            <td className="no-wrap verde-claro-planejado">{item.ferias.toFixed()}h</td>
                            <td className="no-wrap verde-claro-planejado">{item.demissao ? "-- " : ((item.totalPlannedHoursInScope * 100) / item.totalHorasObrigatorias).toFixed()}%</td>
                            <td className="no-wrap azul-claro-realizado">{item.duracao.toFixed()}h</td>
                            <td className="no-wrap azul-claro-realizado">{((item.duracao * 100) / item.totalHorasObrigatorias).toFixed()}%</td>
                            <td className="no-wrap azul-claro-realizado">{item.horasFaturaveis.toFixed()}h</td>
                            <td className="no-wrap azul-claro-realizado">{((item.horasFaturaveis * 100) / item.totalHorasObrigatorias).toFixed()}%</td>
                            <td className="no-wrap cinza-claro-variacao">
                                {(() => {
                                    const desvio = item.totalPlannedHoursInScope === 0
                                    ? -100
                                    : (((item.totalPlannedHoursInScope - item.ferias) - item.horasFaturaveis) / (item.totalPlannedHoursInScope - item.ferias)) * 100;

                                    return `${desvio.toFixed()}%`;
                                })()}
                            </td>
                            </tr>
                            )
                        })}
                        <tr>
                        <td className="fixed-column no-wrap"><strong>Total de colaboradores: {filteredColaboradores.length}</strong></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="no-wrap"><strong>{totalHorasObrigatorias.toFixed()}h</strong></td>
                        <td className="no-wrap verde-claro-planejado"><strong>{totalPlannedHoursInScope.toFixed()}h</strong></td>
                        <td className="no-wrap verde-claro-planejado"><strong>{totalHorasFerias.toFixed()}h</strong></td>
                        <td className="no-wrap verde-claro-planejado"><strong>{isNaN(((totalPlannedHoursInScope * 100) / totalHorasObrigatorias).toFixed()) ? "0" : ((totalPlannedHoursInScope * 100) / totalHorasObrigatorias).toFixed()}%</strong></td>
                        <td className="no-wrap azul-claro-realizado"><strong>{totalDuracao.toFixed()}h</strong></td>
                        <td className="no-wrap azul-claro-realizado"><strong>{isNaN(((totalDuracao * 100) / totalHorasObrigatorias).toFixed()) ? "0" : ((totalDuracao * 100) / totalHorasObrigatorias).toFixed()}%</strong></td>
                        <td className="no-wrap azul-claro-realizado"><strong>{totalHorasFaturaveis.toFixed()}h</strong></td>
                        <td className="no-wrap azul-claro-realizado"><strong>{isNaN(((totalHorasFaturaveis * 100) / totalHorasObrigatorias).toFixed()) ? "0" : ((totalHorasFaturaveis * 100) / totalHorasObrigatorias).toFixed()}%</strong></td>
                        <td className="no-wrap cinza-claro-variacao">
                            <strong>
                                {(() => {
                                    const desvio = totalPlannedHoursInScope == 0
                                    ? -100
                                    : ((totalPlannedHoursInScope - totalHorasFaturaveis) / totalPlannedHoursInScope) * 100;

                                    return `${desvio.toFixed()}%`;
                                })()}
                            </strong>
                        </td>
                        </tr>
                    </tbody>
                    </Table>
                </div>
                }
        </>
    );
}

export default RelatorioCapacidadeView;
