const SelectInAbstract = [
    {
        key: 'custoTotal',
        label: 'Custo total',
    },
    {
        key: 'custoHH',
        label: 'Custo hora total',
    },
    {
        key: 'timeSpentTotal',
        label: 'Tempo total utilizado',
    }
];

const SelectDashbordType = [
    {
        key: 'bar',
        label: 'Gráfico Barra',
    },
    {
        key: 'pie',
        label: 'Gráfico Pizza',
    }
];

const SelectInfo = {
    SelectInAbstract,
    SelectDashbordType
}

export default SelectInfo;
