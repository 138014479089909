import { Table } from "react-bootstrap"

function TableCustoExistente({ dataNotThisTab, changeCustoExistente }) {

    return (
        <Table striped bordered>
            <thead>
                <tr>
                    <th>Descrição</th>
                </tr>
            </thead>

            <tbody>
                { dataNotThisTab.map(item => (
                    <tr
                        key={item.id}
                        onClick={() => changeCustoExistente(item)}
                        style={{ cursor: "pointer" }}
                    >
                        <td>{item.descricao}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default TableCustoExistente
