import React from 'react';
import PropTypes from 'prop-types';
import { FaSmile } from 'react-icons/fa';

import './NothingToShow.css';

function NothingToShow({ message }) {
    return (
        <div className="list-msg-nothing">
            { message }
        </div>
    );
}

// PROPTYPES
NothingToShow.propTypes = {
    message: PropTypes.any
}

// DEFAULT PROPS
NothingToShow.defaultProps = {
    message: (<>Nenhum item para mostrar <FaSmile /></>) 
}

export default NothingToShow;