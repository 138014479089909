/** 
 * Informações das colunas para lista
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   dynamicClass: function,
 *   style: string
 * }>} 
*/
export const columns = [
    {
        label: 'Período Bloq.',
        name: 'bloqueio',
        width: '15%',
        dynamicClass: (isBloq) => (isBloq ? 'text-danger' : 'text-success') ,
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Inicio',
        name: 'dataInicio',
        width: '15%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Fim',
        name: 'dataFim',
        width: '15%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Label',
        name: 'label',
        // width: '20%',
        // className: 'd-none d-md-block w-auto',
    },
];


const FuncaoListInfo = { columns };

export default FuncaoListInfo;
