import * as Yup from 'yup';
import ValidationService from './validation';

const schema = Yup.object().shape({
    usuario: Yup
        .string()
        .required("Campo obrigatório."),

    senha: Yup
        .string()
        .required("Campo obrigatório")
        // .min(6, "Senha deve possuir no mínimo 6 dígitos."),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const LoginValidation = {
    schema,
    validate,
    castValues
};

export default LoginValidation;