import * as Yup from 'yup';
import UtilService from '../util';
import ValidationService from './validation';

// Fields Valor_Beneficio (valores padrões beneficio)
// cod, sigla, nome, valor, periodoValor, valorDesc, aliquotaDesc, baseAliqDesc, 

// Fields Rol_Beneficio (alterações p/ colaborador)
// valorBenefAdic, aliqBenefAdic, valorBenefSubtrai, aliqBenefSubtrai, 
// valorDescAdic, aliqDescAdic, valorDescSubtrai, aliqDescSubtrai

const schema = Yup.object().shape({

    valorBenefAdic: Yup
        .number("Preencher com valor numérico")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser igual ou maior que 0,00')
        .default(0),

    aliqBenefAdic: Yup
        .number()
        .transform(UtilService.yupIntFixPercentage)
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .default(0),

    valorBenefSubtrai: Yup
        .number("Preencher com valor numérico")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser igual ou maior que 0,00')
        .default(0),

    aliqBenefSubtrai: Yup
        .number()
        .transform(UtilService.yupIntFixPercentage)
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .default(0),

    valorDescAdic: Yup
        .number("Preencher com valor numérico")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser igual ou maior que 0,00')
        .default(0),

    aliqDescAdic: Yup
        .number()
        .transform(UtilService.yupIntFixPercentage)
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .default(0),

    valorDescSubtrai: Yup
        .number("Preencher com valor numérico")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser igual ou maior que 0,00')
        .default(0),

    aliqDescSubtrai: Yup
        .number()
        .transform(UtilService.yupIntFixPercentage)
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .default(0),
    
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const BeneficioColabValidation = {
    schema,
    validate,
    castValues
};

export default BeneficioColabValidation;