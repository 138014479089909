import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';

let modalInstance = null;

function DraggableModalDialog(props) {
    return (
        <Draggable handle='.modal-header'>
            <ModalDialog {...props} />
        </Draggable>
    )
}

function CustomModal() {
    const [show, setShow] = useState(false);
    const [content, setContent] = useState({});
    const [isDraggable, setIsDraggable] = useState(false);

    const handleClose = () => {
        setShow(false);
        setContent({});
    }

    const handleShow = ({ title, footer, body, isDrag = false }) => {
        setContent({ title, footer, body });
        setIsDraggable(isDrag);
        setShow(true);
    }

    const onStart = () => {
        modalInstance = {
            handleClose,
            handleShow
        }
    }

    useEffect(onStart, [])

    const { title, footer, body } = content;
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogAs={isDraggable ? DraggableModalDialog : ModalDialog}
            enforceFocus={false}
            backdrop="static"
        >
                { title &&
                    <Modal.Header closeButton style={isDraggable ? { cursor: "move" } : {}}>
                        <Modal.Title>{ title }</Modal.Title>
                    </Modal.Header>
                }

                { body &&
                    <Modal.Body>
                        { body }
                    </Modal.Body>
                }

                { footer &&
                    <Modal.Footer>
                        { footer }
                    </Modal.Footer>
                }
        </Modal>
    )
}

CustomModal.show = ({ title, footer, body, isDrag }) => {
    modalInstance.handleShow({ title, footer, body, isDrag });
}

CustomModal.hide = () => {
    modalInstance.handleClose();
}

export default CustomModal;
