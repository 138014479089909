import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

// Pages
import FechamentoList from './FechamentoList';
import FechamentoNew from './FechamentoNew';
import FechamentoPage from './FechamentoPage';
// import Lancamentos from './Lancamentos';

function Fechamentos() {
    const match = useRouteMatch();
    
    return (
        <Switch>
            <Route path={`${match.path}`} exact component={FechamentoList} />
            <Route path={`${match.path}/new`} exact component={FechamentoNew} />
            <Route path={`${match.path}/:id`} component={FechamentoPage} />

            {/* @TODO: Desativado p/ MVP. Implementação futura */}
            {/* <Route path={`${match.path}/lançamentos`} component={Lancamentos} /> */}
            
            <Redirect path="*" to="/" />
        </Switch>
    )
}

export default Fechamentos;
