import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { RiBarChartFill, RiBarChartHorizontalFill } from "react-icons/ri";
import { Container, Spinner } from "react-bootstrap";
import "./GraficoBarras.css";

function GraficoBarras(props) {
    const {
        title,
        data,
        barKey,
        dataKey,
        layout,
        variant,
        formatterTooltip,
        colorful,
        barLabelName,
        styles,
        loading,
        multibarras
    } = props;

    const colors = {
        blue: "#00a5da",
        purple: "#6265ea",
        orange: "#e98800",
        green: "#00ac23",
        red: "#aa001b",
    };

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#9b5de5', '#FF8042', '#386641', '#ff4d6d', '#15616d', '#d08c60', '#3a6ea5'];

    const generateX = () => {
        if (layout === "vertical") {
            return (
                <XAxis
                    type="number"
                    domain={[0, "auto"]}
                    // padding={{ right: 75 }}
                />
            );
        }
        return <XAxis dataKey={barKey} />;
    };

    const generateY = () => {
        if (layout === "vertical") {
            return <YAxis dataKey={barKey} type="category" width={75} />;
        }
        return (
            <YAxis
                tickCount={6}
                // padding={{ top: 15 }}
            />
        );
    };

    const formatterLabel = (value, name, props) => {
        if (typeof formatterTooltip !== 'undefined') {
            value = parseFloat(value).toFixed(2).toLocaleString('pt-br')
            return `${value}${formatterTooltip}`;
        }
        return value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <p className="label">{`${barLabelName} : ${formatterLabel(payload[0].value)}`}</p>
            </div>
          );
        }

        return null;
      };

    if (data === -1) return <h2>Não foi possível construir o gráfico...</h2>;

    const GraphSimplesBar = () => {
        return (
            <BarChart
                width={400}
                height={450}
                data={data}
                barCategoryGap={5}
                layout={layout}
            >
                {generateX()}
                {generateY()}
                <CartesianGrid strokeDasharray="3 3" />

                {typeof barLabelName !== 'undefined' ? (
                    <Tooltip content={<CustomTooltip />} />
                ) : (
                    <Tooltip
                        formatter={(value, name, props) => formatterLabel(value, name, props)}
                    />
                )}

                {colorful ? (
                    <Bar dataKey={dataKey} barSize={35}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Bar>
                ) : (
                    <Bar
                        dataKey={dataKey}
                        fill={colors[variant]}
                        barSize={35}
                    >
                    </Bar>
                )}
            </BarChart>
        )
    }

    const GraphMultiBar = () => {
        let bars = []
        data.map((d) => bars.push(...Object.keys(d)));
        bars = bars.filter((ele, pos) => bars.indexOf(ele) === pos);

        return (
            <ResponsiveContainer
                minHeight={"45vh"}
                minWidth={300}
            >
                <BarChart
                    data={data}
                    layout={layout}
                    style={styles}
                >
                    {generateX()}
                    {generateY()}
                    <CartesianGrid strokeDasharray="3 3" />
                    {typeof barLabelName !== 'undefined' ? (
                    <Tooltip content={<CustomTooltip />} />
                    ) : (
                        <Tooltip
                            formatter={(value, name, props) => formatterLabel(value, name, props)}
                        />
                    )}
                    <Legend />
                    {bars.map((bar, index) => {
                        if (bar !== "name") {
                            return <Bar dataKey={bar} fill={COLORS[index % COLORS.length]} barSize={35} />
                        } return ""
                    })}
                </BarChart>
            </ResponsiveContainer>
        )
    }

    return (
        <Container className="graph" style={styles}>
            {loading ? (
                <>
                    {" "}
                    <Spinner
                        animation="border"
                        style={{
                            color: colors[variant],
                            margin: "0 10px",
                        }}
                    />
                    <h4>Construindo gráfico: {title}</h4>
                </>
            ) : (
                <>
                    {title && <h3 className="title">
                        {variant && (layout === "vertical" ? (
                            <RiBarChartHorizontalFill
                                style={{ backgroundColor: colors[variant] }}
                            />
                        ) : (
                            <RiBarChartFill
                                style={{ backgroundColor: colors[variant] }}
                            />
                        ))}
                        {title}
                    </h3>}
                    {props.children}
                    {!data.length ?
                    <div>
                        <h5>Não foi possível carregar os dados</h5>
                    </div> :
                    !multibarras ?
                    <GraphSimplesBar /> :
                    <GraphMultiBar />
                    }
                </>
            )}
        </Container>
    );
}

export default GraficoBarras;
