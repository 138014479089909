import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function MargemDetails({ data }) {

    const transformLocaleString = (number) => {
        return parseFloat(number).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    const transformLocaleStringPercent = (number) => {
        return (number * 100).toString().replace('.', ',') + ' %';
    }

    return (
        <>
            <div className='d-flex justify-content-center'>
                <h5>Detalhes</h5>
            </div>
            <Container className='mb-4'>
                <Card className='p-3'>
                    <Row>
                        <Col>
                            <p className='mt-0'><strong>Projeto(s): </strong>{data.projeto}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='m-0'><strong>Período início: </strong>{data.periodoInicio}</p>
                        </Col>
                        <Col>
                            <p className='m-0'><strong>Período fim: </strong>{data.periodoFim}</p>
                        </Col>
                    </Row>
                </Card>

                <Card className='p-3 mt-3'>
                    <Row>
                        <Col>
                                <p><strong>Nome do relatório: </strong>{data.nome}</p>
                                <p><strong>Impostos s/ Resultado Bruto: </strong>{transformLocaleStringPercent(data.baseImpostoSemReceitaBruto)}</p>
                                <p className='m-0'><strong>RV: </strong>{transformLocaleStringPercent(data.baseRV)}</p>
                        </Col>
                        <Col>
                                <p><strong>Custo Hora Colaborador: </strong>{transformLocaleString(data.custoHH)}</p>
                                <p><strong>Impostos antes IR/CSLL: </strong>{transformLocaleStringPercent(data.baseImpostoSemResultado)}</p>
                                <p className='m-0'><strong>Rateio Administrativo: </strong>{transformLocaleStringPercent(data.baseRateioAdministrativo)}</p>
                        </Col>
                    </Row>
                </Card>

                <Card className='p-3 mt-3'>
                    <Row>
                        <Col>
                            <p className='m-0'><strong>Valor Previsto Venda: </strong>{transformLocaleString(data.receitaBruta)}</p>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    )
}

export default MargemDetails;
