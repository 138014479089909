import React from 'react';
import { Route, Switch } from 'react-router-dom';

// PAGES
import ColaboradorList from './ColaboradorList';
import ColaboradorNew from './ColaboradorNew';
import ColaboradorPage from './ColaboradorPage';


function Colaborador(props) {
    const { match } = props;

    return (
        <Switch>
            <Route path={match.path} exact component={ColaboradorList} />
            <Route path={`${match.path}/new`} component={ColaboradorNew} />
            <Route path={`${match.path}/:mat`} component={ColaboradorPage} />
            
        </Switch>
    )
}

export default Colaborador;
