import React from 'react';
import { Image } from 'react-bootstrap';
import imgLoading from '../../assets/img/loading.gif';

import './Loading.css';

/** 
 * Renderiza uma imagem de loading para o uso 
 * nos carregamentos de dados dos componentes
 */
function Loading({ message }) {
    return (
        <div className="loading">
            <Image src={imgLoading} alt="Loading" />
            <p>{ message }</p>
        </div>
    )
}

Loading.defaultProps = {
    message: ''
}

export default Loading
