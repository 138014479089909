import { ptBR } from "date-fns/locale";
import { Col, Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import UtilService from '../../services/util';

import "react-datepicker/dist/react-datepicker.css";
import CustomCurrencyInput from './CustomCurrencyInput';

const FieldCustom = ({ name, label, type, value, size, onChange, options, error, ...rest }) => {
    const className = [
        `${error ? 'field-error-highlight' : ''}`
    ].join(' ');

    const mdSize = { '2':4, '3':4, '4':6, '6':12, '12':12 };

    const ErrorText = (
        <Form.Control.Feedback type="invalid" style={{display: `${error?'block':''}`}}>
            { error }
        </Form.Control.Feedback>
    )

    const SuccessText = (
        <Form.Control.Feedback>{''}</Form.Control.Feedback>
    )

    const checkboxOnChange = (event) => {
        if (typeof(onChange) === 'function') {
            onChange({
                target: { name, value: event.target.checked }
            });
        }
    }

    switch (type) {
        case 'text':
        case 'email':
        case 'number':
        case 'password':
            return (
                <Form.Group
                    as={Col}
                    lg={size}
                    md={mdSize[size] || size}
                    controlId={`fgci-${name}`}
                >
                    <Form.Label>{ label }</Form.Label>
                    <Form.Control
                        className={className}
                        name={name}
                        value={value}
                        type={type}
                        onChange={onChange}
                        autoComplete="off"
                        {...rest}
                    />
                    { ErrorText }
                    { SuccessText }
                </Form.Group>
            );

        case 'currency':
            return (
                <Form.Group
                    as={Col}
                    lg={size}
                    md={mdSize[size] || size}
                    controlId={`fgci-${name}`}
                >
                    <Form.Label>{ label }</Form.Label>
                    <Form.Control
                        as={CustomCurrencyInput}
                        className={className}
                        name={name}
                        value={value}
                        onChange={onChange}
                        autoComplete="off"
                        {...rest}
                    />
                    { ErrorText }
                    { SuccessText }
                </Form.Group>
            );

        case 'percentage':
            return (
                <Form.Group
                    as={Col}
                    lg={size}
                    md={mdSize[size] || size}
                    controlId={`fgci-${name}`}
                >
                    <Form.Label>{ label }</Form.Label>
                    <div className="percentage-field">
                        <Form.Control
                            className={className}
                            type="number"
                            name={name}
                            value={Number((parseFloat(value)*100).toFixed(2))}
                            onChange={(ev) => {
                                const { value } = ev.target;
                                onChange({
                                    ...ev,
                                    target: {
                                        ...ev.target,
                                        name,
                                        value: Number(parseFloat(value).toFixed(2))/100
                                    }
                                })
                            }}
                            autoComplete="off"
                            {...rest}
                        />
                        <span>%</span>
                    </div>
                    { ErrorText }
                    { SuccessText }
                </Form.Group>
            );

        case 'date':
            /**
             * Renderiza um componente react-datepicker
             * @see https://www.npmjs.com/package/react-datepicker
             * @see https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
             */
            const CustomDatePicker = (props) => {
                const onChangeThis = (dtVal) => {
                    onChange({
                        target: {
                            value: UtilService.viewDateToApi(dtVal),
                            name
                        }
                    })
                }

                return (
                    <ReactDatePicker
                        id={`fgci-${name}`}
                        wrapperClassName="datepicker-adapt"
                        popperClassName="datepicker-popper"
                        dateFormat="dd-MMM-yyyy"
                        locale={ptBR}
                        maxDate={rest.max && (new Date(rest.max))}
                        minDate={rest.min && (new Date(rest.min))}
                        selected={value ? (new Date(`${value} UTC-3`)) : null}
                        onChange={onChangeThis}
                        name={name}
                        {...props}
                    />
                )
            }

            return (
                <Form.Group
                    as={Col}
                    lg={size}
                    md={mdSize[size] || size}
                    controlId={`fgci-${name}`}
                >
                    <Form.Label>{ label }</Form.Label>
                    <Form.Control
                        as={CustomDatePicker}
                        className={className}
                        {...rest}
                    />
                    { ErrorText }
                    { SuccessText }
                </Form.Group>
            );

        case 'select':
            return (
                <Form.Group
                    as={Col}
                    lg={size}
                    md={mdSize[size] || size}
                    controlId={`fgci-${name}`}
                >
                    <Form.Label>{ label }</Form.Label>
                    <Form.Control
                        className={className}
                        as="select"
                        onChange={onChange}
                        name={name}
                        title={label}
                        value={value}
                        {...rest}
                    >
                        {options.map((item, oKey) => {
                            return (
                                <option
                                    key={`fc-${name}-option-${oKey}`}
                                    value={item.value}
                                >
                                    { item.label}
                                </option>
                            )
                        })}
                    </Form.Control>
                    { ErrorText }
                    { SuccessText }
                </Form.Group>
            );

        case 'swicth':
            return (
                <Form.Group
                    as={Col}
                    lg={size}
                    md={mdSize[size] || size}
                    className="swicth-on-off"
                >
                    <Form.Switch
                        label={label}
                        name={name}
                        checked={value && value}
                        id={`swicth-${name}`}
                        onChange={checkboxOnChange}
                        isInvalid={!!error}
                        feedback={error}
                        {...rest}
                        disabled={(rest.disabled && rest.readOnly)}
                    />
                </Form.Group>
            );

        case 'checkbox':
            return (
                <Form.Group
                    as={Col}
                    lg={size}
                    md={mdSize[size] || size}
                    className="checkbox-on-off"
                >
                    <Form.Check
                        label={label}
                        name={name}
                        checked={value && value}
                        id={`checkbox-${name}`}
                        onChange={checkboxOnChange}
                        isInvalid={!!error}
                        feedback={error}
                        {...rest}
                        disabled={(rest.disabled /* && rest.readOnly */)}
                    />
                </Form.Group>
            );

        case 'textarea':
            const counter = () => {
                const { max } = rest;
                return `${String(value).length}${(!max ? '' : '/'+max)}`
            }
            return (
                <Form.Group
                    as={Col}
                    lg={size}
                    md={mdSize[size] || size}
                    controlId={`fgci-${name}`}
                >
                    <Form.Label>{ label }</Form.Label>
                    <Form.Control
                        style={{
                            minHeight: '5rem',
                        }}
                        as="textarea"
                        rows={2}
                        className={className}
                        name={name}
                        value={value}
                        type={type}
                        onChange={onChange}
                        {...rest}
                    />
                    <Form.Text className="fc-textarea-counter">{ counter() }</Form.Text>
                    { ErrorText }
                    { SuccessText }
                </Form.Group>
            );

        default: return <></>;
    }
}

export default FieldCustom;
