import { 
    FORM_EDIT,
} from '../types';

const INITIAL = {
    edit: false,
    formData: {},
    formFields: {}
}

/**
 * Reducer com dados para formulários
 */
const formReducer = (state=INITIAL, action) => {
    switch (action.type) {
        case FORM_EDIT:
            return {
                ...state,
                edit: action.payload || false
            }

        default:
            return state;
    }

}

export default formReducer;