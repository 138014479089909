import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import PageContainer from '../../../../components/PageContainer';
import ListLayout from '../../../../components/ListLayout';
import Loading from '../../../../components/Loading';
import ErrorRequestService from '../../../../services/errorRequest';
import DepartamentoApiService from '../../../../services/api/DepartamentoApiService';
import { columns } from './DepartamentoListInfo';
import { useDispatch, useSelector } from 'react-redux';
import { storeDepartamentos } from '../../../../store/actions/dadosActions';
import useAlertNotification from '../../../../context/AlertNotificationContext';

const INITIAL_PAGEPARAMS = {
    orderBy: 'cod',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function DepartamentoList(props) {
    // CONTEXT
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();

    // REDUX
    const { departamentos } = useSelector(state => state.dados);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const storeDepartsData = useCallback(
        (departs) => dispatch(storeDepartamentos(departs)),
        [ dispatch ] 
    );

    // STATE
    const [rowsData, setRowsData] = useState(departamentos);
    const [filteredData, setFilteredData] = useState(departamentos);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);

    // FUNCTIONS
    /** Define o clique sobre um registro da lista */
    const rowClick = (item) => {
        history.push(`${match.url}/${item.cod}`, item);
    }

    /** Adiciona click e atributos ao header */
    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name) 
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    /** Adequa os dados ao formato para lista */
    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    onClick: () => rowClick(item),
                    data: columns.map(col => ({
                        value: item[col.name],
                        className: col.className
                    }))
                };
                dataList.push(row);
            });
        return dataList;
    }

    /* Filtra a lista de dados */
    const filterList = (params) => {
        const { column, filter } = params;

        if (!column || !filter) {
            setFilteredData(rowsData);
            return
        }
        
        const filtered = rowsData.filter(item => {
            const lwData = String(item[column]).toLowerCase();
            const lwFilter = String(filter).toLowerCase();

            return lwData.includes(lwFilter);
        });
        setFilteredData(filtered);
    }

    /** Traz as departamentos cadastradas no database  */
    const getDataList = useCallback(async () => {
        try {
            // @TODO: Rever situação de uso do REDUX para devida atualização das listas
            // if (!departamentos) {
                setIsLoading(true);
                const resp = await DepartamentoApiService.getAll().then(r => r.data.data);
                storeDepartsData(resp);
            // }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    }, [departamentos, storeDepartsData]);


    // EFFECTS
    useEffect(getDataList, []);

    useEffect(() => {
        setRowsData(departamentos);
        setFilteredData(departamentos);
    }, [departamentos]);


    const pageActions = [
        { 
            label: 'Novo',
            icon: <FaPlus />,
            color: 'primary',
            type: 'button',
            href: `${match.path}/new`,
        }
    ]

    const title = 'Lista de Departamentos';

    return (
        <PageContainer 
            title={title}
            actions={pageActions}
        >
            { isLoading 
                ? <Loading message="Carregando..." />
                : <ListLayout
                    columns={getListHeader()}
                    rows={defineDataList()}
                    onSubmitFilter={filterList}  
                />
            }

        </PageContainer>
    )
}

export default DepartamentoList;
