import React, { 
    createContext, 
    useCallback, 
    useContext
} from 'react';
import { useDispatch } from 'react-redux';
import AlertNotification from '../components/AlertNotification';
import { addAlertNotification, removeAlertNotification } from '../store/actions/alertNotificationActions';

const ALERT_TIMEOUT = 4000;

const AlertNotificationContext = createContext();

export const AlertNotificationProvider = ({ children }) => {    
    // STORE REDUX
    const dispatch = useDispatch();

    // EXPOSED FUCNTIONS
    /** Efetivamente remove o alerta conforme ID recebido */
    const remove = useCallback(
        (id) => dispatch( removeAlertNotification(id) ),
        [dispatch],
    )

    /** Registra um timeout para remoção do alerta de ID recebido */
    const registerRemovalTimeout = useCallback(
        ({ id }) => setTimeout(() => remove(id), ALERT_TIMEOUT),
        [dispatch]
    )
    
    /** Adiciona um alert de sucesso */
    const success = useCallback(
        (message) => {
            const actionResult = dispatch( addAlertNotification(message, 'success') );
            registerRemovalTimeout(actionResult.payload);
        },
        [dispatch]
    );

    /** Adiciona um alert de informação */
    const info = useCallback(
        (message) => {
            const actionResult = dispatch( addAlertNotification(message, 'info') );
            registerRemovalTimeout(actionResult.payload);
        },
        [dispatch]
    )

    /** Adiciona um alert de error */
    const error = useCallback(
        (message) => {
            const actionResult = dispatch( addAlertNotification(message, 'error') );
            registerRemovalTimeout(actionResult.payload);
        },
        [dispatch]
    )

    return (
        <AlertNotificationContext.Provider
            value={{
                remove,
                success,
                info,
                error,
            }}
        >
            <AlertNotification />
            {children}
        </AlertNotificationContext.Provider>
    )
}

/**
 * Context que provê acesso a funcionalidades 
 * de alertas de notificação
 */
export function useAlertNotification() {
    const context = useContext(AlertNotificationContext);

    if (!context) {
        throw new Error()
    }

    return context;
}

export default useAlertNotification;
