import React, { useEffect, useState } from 'react';
import { FormControl, InputGroup, Nav } from 'react-bootstrap';
import { FaFileAlt, FaRegFrownOpen } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import CustomModal from '../../../components/CustomModal';
import NothingToShow from '../../../components/NothingToShow';
import PageContainer from '../../../components/PageContainer';
import { menuRelatorios, applyFiltroMenu } from './RelatoriosMainPageInfo';

import './RelatoriosMainPage.css';
import FiltroEmissaoRelatorio from './FiltroEmissaoRelatorio';

function RelatoriosMainPage(props) {
    // STATE
    const [filtro, setFiltro] = useState("");
    const [filtrado, setFiltrado] = useState(menuRelatorios);

    // FUNCTIONS
    const handleFiltro = () => {
        const menuFiltrado = applyFiltroMenu(filtro);
        setFiltrado(menuFiltrado);
    }

    const clearFiltro = () => setFiltro("");

    const callFiltroEmissaoRelatorio = ({label, path}) => {
        CustomModal.show({
            body: <FiltroEmissaoRelatorio
                label={label}
                path={path}
                onCancel={() => CustomModal.hide()}
            />
        })
    }

    // EFFECTS
    useEffect(handleFiltro, [filtro]);

    return (
        <PageContainer title="Relatórios DP">

            <header className="relatorios-main">
                <InputGroup>
                    <FormControl
                        className="filter-input"
                        value={filtro}
                        onChange={e => setFiltro(e.target.value)}
                        placeholder="Buscar relatório"
                        aria-label="Buscar relatório"
                        aria-describedby="busca-relatorio"
                        type="text"
                        autoComplete="off"
                    />
                    {filtro.length > 0 && (
                        <InputGroup.Text
                            id="busca-relatorio"
                            onClick={clearFiltro}
                            title="Limpar busca"
                        >
                            <MdClose/>
                        </InputGroup.Text>
                    )}
                </InputGroup>
            </header>

            { filtrado.length == 0 && (
                <div className="nenhum-relatorio">
                    <NothingToShow message={<>
                        <FaRegFrownOpen size={30}/> {`Nenhum relatório com o termo "${filtro}"`}
                    </>}/>
                </div>
            )}

            { filtrado.map((group, i) => {
                const { groupLabel, groupKey, menus } = group;
                const gKey = `${i}-${groupKey}`;
                
                return (
                    <section key={gKey} className="relatorios-main">
                        <h4>{ groupLabel }</h4>
                        <hr/>
                        <Nav className="nav-container">
                            { menus.map((item, j) => {
                                const { label, path } = item;
                                const iKey = `${gKey}-${j}-${path.replace(" ", "")}`;
                                return (
                                    <Nav.Item 
                                        className="col-md-6 col-lg-4" 
                                        key={iKey} 
                                        onClick={() => callFiltroEmissaoRelatorio({label, path})}
                                    >
                                        <FaFileAlt size={20} />
                                        <Nav.Link>{ label }</Nav.Link>
                                    </Nav.Item>
                                )
                            })}
                        </Nav>
                    </section>
                );
            })}

            <CustomModal />

        </PageContainer>
    )
}

export default RelatoriosMainPage;

