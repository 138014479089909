import React, { useState } from 'react'
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap'
import { FaPen, FaSave, FaTrash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import useAuth from '../../../../context/AuthContext';
import ProjetosApiService from '../../../../services/api/ProjetosApiService';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import { format } from 'date-fns';
import CustomModal from '../../../../components/CustomModal';

const DEFAULT_VALUE = '0,00';

function CustoDetail({ item, cotacoes, mask, onCancel, handlerFilter }) {
    const INITIAL_DATA_ITEM = {
        ...item,
        valor: mask(item.valor),
        cotacao: mask(item.cotacao)
    }

    const [itemCusto, setItemCusto] = useState(INITIAL_DATA_ITEM)
    const [isEdit, setIsEdit] = useState(false)
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const { user } = useAuth();
    const { email } = user

    const alert = useAlertNotification();

    const onChangeCusto = (event) => {
        const { name, value } = event.target;

        let newValues = {}

        if (name === 'moeda') {
            newValues = { cotacao: cotacoes[value] }
        }

        newValues = name === "valor" || name === "cotacao" ?
            { [name]: mask(value) } : { ...newValues, [name]: value }

        setItemCusto(state => {
            return {
                ...state,
                ...newValues
            }
        })

        setErrors({});
    }

    const verificaErros = () => {
        let newErrors = {};

        if (itemCusto.tipoCusto === '') {
            newErrors = { ...newErrors, tipo_custo: 'Campo obrigatório' };
        }

        if (itemCusto.valor === DEFAULT_VALUE) {
            newErrors = { ...newErrors, valor: 'Campo obrigatório' };
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length > 0;
    };

    const handleSave = async () => {
        try {
            if (verificaErros()) return

            setIsLoading(true)

            const [date, fuso] = itemCusto.periodo_inicio.split("T") 

            const periodoInicioFormted = date+"T00:00:00"

            const response = await ProjetosApiService.updateCustoProjeto(item.id, {
                email: item.email,
                nome: item.descricao,
                projeto: item.projeto,
                periodo_inicio: format(new Date(periodoInicioFormted), 'dd-LLL-yyyy'),
                periodo_fim: format(new Date(itemCusto.periodo_fim), 'dd-LLL-yyyy'),
                tipo_custo: itemCusto.tipo_custo,
                descricao: itemCusto.descricao,
                moeda: itemCusto.moeda,
                valor: itemCusto.valor.replaceAll('.', '').replace(',', '.'),
                cotacao: itemCusto.cotacao.replaceAll('.', '').replace(',', '.')
            })

            const { status } = response

            if (status !== 200) {
                throw new Error("Erro ao salvar os dados do custo.");
            } else {
                alert.success("Custo salvo com sucesso.");
                onCancel();
                handlerFilter();
            }

        } catch (error) {
            console.log("[ERROR] [CustoProjeto.salvaCusto]:", error);
            alert.error("Erro ao salvar os dados do custo");
        } finally {
            setIsLoading(false)
        }
    }

    const handleDelete = async () => {
        try {
            setIsLoading(true)

            const response = await ProjetosApiService.deleteCustoProjeto(item.id)

            const { status } = response

            if (status !== 200) {
                throw new Error("Erro ao excluir os dados do custo.");
            } else {
                alert.success("Custo excluído com sucesso.");
                onCancel();
                handlerFilter();
            }

        } catch (error) {
            console.log("[ERROR] [CustoProjeto.deleteCusto]:", error);
            alert.error("Erro ao excluir os dados do custo");
        } finally {
            setIsLoading(false)
        }
    }

    const openModalDeleteCusto = () => {
        CustomModal.show({
            body: 'Tem certeza que deseja excluir esse custo?',
            footer: (
                <>
                    <Button variant="outline-success" onClick={handleDelete}>Sim</Button>
                    <Button variant="outline-danger" onClick={() => CustomModal.hide()}>Cancelar</Button>
                </>
            )
        })
    }

    const tiposEspecificar = ['INFRA_CLOUD', 'FORNECEDORES_TERCEIROS', 'OUTROS']

    return (
        <div>
            <Form noValidate>
                <Row style={{ marginBottom: 10 }}>
                    <Col>
                        <Form.Label>Tipo de custo</Form.Label>
                        <Form.Control
                            as="select"
                            name="tipo_custo"
                            value={itemCusto["tipo_custo"]}
                            onChange={onChangeCusto}
                            disabled={!isEdit}
                            isInvalid={!!errors?.['tipo_custo']}
                        >
                            <option value="">Selecione</option>
                            <option value="INFRA_CLOUD">Infraestrutura em Cloud</option>
                            <option value="OUTROS_SOFTWARES">Outros softwares</option>
                            <option value="HORAS_EXTRAS">Horas extras</option>
                            <option value="FORNECEDORES_TERCEIROS">Fornecedores terceiros</option>
                            <option value="OUTROS">Outros</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            {errors?.['tipo_custo']}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                    <Col>
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control
                            type="text"
                            name="descricao"
                            autoComplete='off'
                            placeholder={tiposEspecificar.includes(itemCusto["tipo_custo"]) ? 'Especificar tipo de custo' : ''}
                            value={itemCusto.descricao}
                            onChange={onChangeCusto}
                            disabled={!isEdit}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Valor</Form.Label>
                        <Form.Control
                            type="text"
                            name="valor"
                            autoComplete='off'
                            value={itemCusto.valor}
                            onChange={onChangeCusto}
                            disabled={!isEdit}
                            isInvalid={!!errors?.valor}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors?.valor}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                    <Col>
                        <Form.Label>Moeda</Form.Label>
                        <Form.Control
                            as="select"
                            name="moeda"
                            value={itemCusto.moeda}
                            onChange={onChangeCusto}
                            disabled={!isEdit}
                        >
                            <option value="BRL">Real</option>
                            <option value="USD">Dolár</option>
                            <option value="EUR">Euro</option>
                        </Form.Control>
                    </Col>
                    {itemCusto.moeda !== 'BRL' && (
                        <Col>
                            <Form.Label>Cotação</Form.Label>
                            <Form.Control
                                type="text"
                                name="cotacao"
                                autoComplete='off'
                                maxLength={5}
                                value={itemCusto.cotacao}
                                onChange={onChangeCusto}
                                disabled={!isEdit}
                            />
                        </Col>
                    )}
                </Row>

                {(email === item.email) && (isEdit ? (
                    <div className='mt-3 d-flex justify-content-end'>
                        <ButtonGroup>
                            <Button variant='outline-success' disabled={isLoading} onClick={handleSave}>
                                <FaSave /> Salvar
                            </Button>
                            <Button
                                variant='outline-danger'
                                onClick={() => {
                                    setIsEdit(false)
                                    setItemCusto(INITIAL_DATA_ITEM)
                                }}
                            >
                                <MdClose /> Cancelar
                            </Button>
                        </ButtonGroup>
                    </div>
                ) : (
                    <div className='mt-3 d-flex justify-content-end'>
                        <ButtonGroup>
                            <Button variant='outline-info' onClick={() => setIsEdit(true)}>
                                <FaPen /> Editar
                            </Button>
                            <Button variant='outline-danger' disabled={isLoading} onClick={openModalDeleteCusto}>
                                <FaTrash /> Excluir
                            </Button>
                        </ButtonGroup>
                    </div>
                ))}

            </Form>
        </div>
    )
}

export default CustoDetail;
