import api from "./api";

const ENDPOINT_BASE = '/previsto';

function insert(form) {
    return api.post(`${ENDPOINT_BASE}/funcao/create`, { ...form });
}

function insertTotal(form) {
    return api.post(`${ENDPOINT_BASE}/total/create`, { ...form });
}

function getAll(projeto) {
    return api.get(`${ENDPOINT_BASE}/funcao/${projeto}`);
}

function findByProject(key) {
    return api.get(`${ENDPOINT_BASE}/total/${key}`);
}

function deleteByProject(projeto) {
    return api.delete(`${ENDPOINT_BASE}/funcao/delete/${projeto}`);
}

function getValorVendaByKey(key_project) {
    return api.get(`/valor-venda/key/${key_project}`);
}

function getValorVendaforYear(ano, key) {
    return api.get(`/valor-venda/year/${ano}/${key}`);
}

function createValorVenda(data) {
    return api.post(`/valor-venda/create`, data);
}

function deleteValorVenda(key, mesAno) {
    return api.delete(`/valor-venda/delete/${key}/${mesAno}`)
}

function updateMargemCusto(data, key) {
    return api.patch(`/previsto/total/update/${key}/margem-custo-projeto`, data);
}

function updateValorVenda(data, key) {
    return api.patch(`/previsto/total/update/${key}/total-valor-venda`, data);
}

const CustosPrevistosService = {
    getAll,
    insert,
    insertTotal,
    deleteByProject,
    getValorVendaByKey,
    getValorVendaforYear,
    createValorVenda,
    deleteValorVenda,
    updateMargemCusto,
    updateValorVenda,
    findByProject
}

export default CustosPrevistosService;
