import UtilService from "../../../services/util";

const TODAY = UtilService.viewDateToApi( new Date().toLocaleDateString('pt-br') );

/** 
 * Informações campos do form
 * @type {{
    *   groups: Array<{
        *      header: string,
        *      fields: Array<{
        *        label: string,
        *        placeholder: string,
        *        name: string,
        *        type: string,
        *        size: number,
        *        className: string
        *      }>
        *   }
        * }>} 
       */
       export const getFormFields = async () => {
           return {
               groups: [
                {
                    header: '',
                    shouldRender: (form) => {
                        return ![null, undefined].includes(form.cod);
                    },
                    fields: [
                        {
                            label: 'Id',
                            placeholder: '',
                            name: 'id',
                            type: 'text',
                            size: 2,
                            readOnly: true
                        },
                    ]
    
                },
                   {
                       header: '',
                       fields: [
                           {
                               label: 'Identificação',
                               placeholder: 'Ex: \'Dez-2020\', ou \'Jan-2020 1ª quinz.\', etc...',
                               name: 'label',
                               type: 'text',
                               size: 3,
                               max: 30,
                           },
                       ]
                   },
                   {
                       header: 'Período de apuração do fechamento',
                       fields: [
                           {
                               label: 'Início',
                               placeholder: 'dd/mm/aaaa',
                               name: 'dataInicio',
                               type: 'date',
                               size: 2,
                               defaultValue: TODAY,
                               max: TODAY
                           },
                           {
                               label: 'Fim',
                               placeholder: 'dd/mm/aaaa',
                               name: 'dataFim',
                               type: 'date',
                               size: 2,
                               defaultValue: TODAY,
                               max: TODAY
                           },
                           {
                               label: 'Qtd. dias úteis',
                               placeholder: '',
                               name: 'diasUteis',
                               type: 'number',
                               defaultValue: 30,
                               min: 1, 
                               size: 2,
                           },
                       ]
                   },
                   {
                       header: '', 
                       fields: [
                           {
                               label: 'Descrição',
                               placeholder: 'Digite aqui descrição, observações ou detalhes...',
                               name: 'descricao',
                               type: 'textarea',
                               defaultValue: '',
                               size: 6,
                               max: 100
                           },
                       ]
                   },
                   {
                       header: 'Bloqueio',
                       fields: [
                           {
                               label: 'Bloquear lançamentos?',
                               name: 'bloqueio',
                               type: 'swicth',
                               // defaultChecked: false,
                               // defaultValue: false,
                               size: 4,
                           },
                       ]
                   }
               ]
           };
       }

const FechamentoNewInfo = { getFormFields };

export default FechamentoNewInfo;
