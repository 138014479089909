import * as Yup from 'yup';
import UtilService from '../util';
import ValidationService from './validation';

const schema = Yup.object().shape({
    salario: Yup
        .number("Preencher com valor numérico")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor do salário deve ser informado.')
        .default(0),

    beneficio: Yup
        .number("Preencher com valor numérico")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor do benefício deve ser informado.')
        .default(0),

    dependentes: Yup
        .number("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, )
        .default(0),

    regime: Yup
        .number("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform),

    funcao: Yup
        .number("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform),

    filial: Yup
        .number("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema)
}

const SimulacaoValidation = {
    schema,
    validate,
    castValues
};

export default SimulacaoValidation;
