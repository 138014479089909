export const columns = [
    {
        label: 'Nome do time',
        name: 'nome',
        width: '50%',
        className: '',
    },
    {
        label: 'Valor bruto',
        name: 'custo_total',
        width: '50%',
        className: '',
    },

];
    
const SimulacaoTeamListInfo = { columns };

export default SimulacaoTeamListInfo;
