/** 
 * Informações das colunas para listagem de valores de imposto por filial
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>} 
 */
export const columns = [
    {
        label: 'Filial',
        name: 'label',
        width: '30%',
    },
    {
        label: 'Valor Fixo',
        name: 'valor_fixo',
    },
    {
        label: 'Aliquota Desc.',
        name: 'aliquota_desc',
    },
    {
        label: 'Aliquota Filial',
        name: 'aliquota_filial',
    },
];

const ValorImpostoListInfo = { columns };

export default ValorImpostoListInfo;