
const baseAliqOptions = [
    { label: '...', value:'' },
    { label: 'Salário', value: 'salario' },
    { label: 'Imposto', value: 'imposto' }
];

const comparaOptions = [
    { label: '...', value: '' },
    { label: '>', value: '>' },
    { label: '>=', value: '>=' },
    { label: '=', value: '=' },
    { label: '<=', value: '<=' },
    { label: '<', value: '<' },
]

const fields = [
    'id', 'imposto_cod', 'aliq_faixa', 'base_aliq_faixa', 'valor_faixa', 'valor_deducao', 
    'compara_inicio', 'valor_faixa_inicio', 'compara_fim', 'valor_faixa_fim', 'observacao',
];

export const getFormFields = () => {
    return {
        groups:[
            {
                header: '',
                shouldRender: (form) => {
                    return false //!['', null, undefined].includes(form.id);
                },
                fields: [
                    {
                        label: 'Id',
                        placeholder: '',
                        name: 'id',
                        type: 'text',
                        size: 3,
                    },
                ]
            },
            {
                header: 'Faixas',
                fields: [
                    {
                        label: 'Comparador',
                        name: 'compara_inicio',
                        type: 'select',
                        options: comparaOptions,
                        size: 2
                    },
                    {
                        label: 'Início Faixa',
                        name: 'valor_faixa_inicio',
                        type: 'currency',
                        size: 4,
                        defaultValue: null,
                    },
                    {
                        label: 'Comparador',
                        name: 'compara_fim',
                        type: 'select',
                        options: comparaOptions,
                        size: 2
                    },
                    {
                        label: 'Fim Faixa',
                        name: 'valor_faixa_fim',
                        type: 'currency',
                        size: 4,
                        defaultValue: null,
                    },
                ]
            },
            {
                header: 'Valores e Aliquotas',
                fields: [
                    {
                        label: 'Aliq. Faixa',
                        name: 'aliq_faixa',
                        type: 'percentage',
                        size: 3,
                        defaultValue: 0
                    },
                    {
                        label: 'Base Aliquota',
                        name: 'base_aliq_faixa',
                        type: 'select',
                        options: baseAliqOptions,
                        size: 3,
                    },
                    {
                        label: 'Valor Fixo',
                        name: 'valor_faixa',
                        type: 'currency',
                        size: 3,
                        defaultValue: 0
                    },
                    {
                        label: 'Valor Dedução',
                        name: 'valor_deducao',
                        type: 'currency',
                        size: 3,
                        defaultValue: 0
                    },
                ]
            },
            {
                fields: [
                    {
                        label: 'Observações',
                        name: 'observacao',
                        type: 'textarea',
                        size: 12,
                    }
                ]
            }
        ]
    }
}

const ImpostoFaixaFromInfo = {
    getFormFields
}

export default ImpostoFaixaFromInfo;
