import * as Yup from 'yup';
import UtilService from '../util';
import ValidationService from './validation';

// imposto_cod, aliq_faixa, base_aliq_faixa, valor_faixa, valor_deducao, 
// compara_inicio, valor_faixa_inicio, compara_fim, valor_faixa_fim, observacao,
const schema = Yup.object().shape({

    imposto_cod: Yup  
        .string(),

    aliq_faixa: Yup  
        .number()
        .transform(UtilService.yupIntFixPercentage)
        .test({
            name:'aliq-faixa-valid',
            message: 'Valor de aliquota deve ser entre 0 e 100',
            test: (value) => {
                return !['', null, undefined, NaN].includes(value);
            }
        })
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .default(0),

    base_aliq_faixa: Yup  
        .string()
        .test({
            name:'tem-aliq-faixa',
            message: 'Base da aliquota deve ser informada',
            test: (value, context) => {
                if (context.parent.aliq_faixa > 0) {
                    return !['', null, undefined].includes(value);
                }
                return true;
            }
        }),

    valor_faixa: Yup
        .number("Preencher com um valor numérico.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser maior de 0,00')
        .default(0),

    valor_deducao: Yup 
        .number()
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser maior de 0,00')
        .default(0),

    compara_inicio: Yup
        .string()
        .required("Preencha com uma das opções"),

    valor_faixa_inicio: Yup   
        .number()
        .nullable()
        .transform(UtilService.yupTransform)
        .test({
            name:'tem-compara-inicio',
            message: 'Valor de início da faixa deve ser informado',
            test: (value, context) => {
                if (!['', null, undefined].includes(context.parent.compara_inicio)) {
                    return !['', null, undefined].includes(value);
                }
                return true;
            }
        })
        .min(0, 'Valor deve ser maior de 0,00'),
    
    compara_fim: Yup
        .string(),
    
    valor_faixa_fim: Yup   
        .number()
        .nullable()
        .transform(UtilService.yupTransform)
        .test({
            name:'tem-compara-fim',
            message: 'Valor de fim da faixa deve ser informado',
            test: (value, context) => {
                if (!['', null, undefined].includes(context.parent.compara_fim)) {
                    return !['', null, undefined].includes(value);
                }
                return true;
            }
        })
        .min(0, 'Valor deve ser maior de 0,00'),
    
    observacao: Yup 
        .string()
        .max(300, "Limite máximo de 300 caracteres."),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const FaixaImpostoValidation = {
    schema,
    validate,
    castValues
};

export default FaixaImpostoValidation;