import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Provider } from 'react-redux';
import { ThemeProvider } from './context/ThemeContext';
import { AuthProvider } from './context/AuthContext';
import { AlertNotificationProvider } from './context/AlertNotificationContext';
import UtilService from './services/util';
import Routes from './routes';
import store from './store';

import HeaderCustom from './components/HeaderCustom';
import LayoutWrapper from './components/LayoutWrapper';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// Setting up app title
UtilService.setTitle();

function App() {
    return (
        <Provider store={store}>
            
            <BrowserRouter>
                <ThemeProvider>
                    <Container fluid className="app">
                        <AuthProvider>
                            <AlertNotificationProvider>
                                <HeaderCustom />
                                <LayoutWrapper>
                                    <Routes />
                                </LayoutWrapper>
                            </AlertNotificationProvider>
                        </AuthProvider>
                    </Container>
                </ThemeProvider>
            </BrowserRouter>

        </Provider>
    );
}

export default App;
