import api from "./api";

const ENDPOINT_BASE = '/squad';

/** Traz uma lista de todos os squads cadastrados */
function getAll(email) {
    return api.get(`${ENDPOINT_BASE}/${email}`);
}

/** Envia um novo squad para ser criado no database */
function createNew(form) {
    return api.post(`${ENDPOINT_BASE}/create`, { ...form });
}
/** Adiciona novo colaborador a um squad */
function addNewColab(form) {
    return api.post(`/squad-colab/create`, {...form});
}
/** Traz um squad */
function getByCod(email, idSquad) {
    return api.get(`${ENDPOINT_BASE}/${email}/${idSquad}`);
}
/** Desabilita um squad */
function removeSquad(email, form) {
    return api.patch(`${ENDPOINT_BASE}/${email}/change-active`, {...form});
}
/** Desabilita um colaborador */
function removeColab(email, form) {
    return api.patch(`/squad-colab/${email}/change-active`, {...form});
}
/** Atualiza nome de um squad */
function updateSquadName(email, idSquad, form) {
    return api.put(`${ENDPOINT_BASE}/${email}/${idSquad}`, {...form});
}
/** Envia para a API os dados para atualizar um squad */
function updateOne(email, idSquad, form) {
    return api.put(`${ENDPOINT_BASE}/${email}/${idSquad}`, { ...form });
}
/** Remove sprints de uma squad  */
function removeSprint(form) {
    return api.post(`/squad-colab/sprint/remove`, { ...form });
}
const SquadApiService = {
    getAll,
    createNew,
    getByCod,
    updateOne,
    addNewColab,
    removeSquad,
    removeColab,
    updateSquadName,
    removeSprint
}

export default SquadApiService;