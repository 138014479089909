import RelatorioColaboradorBeneficio from './colaborador-beneficio';

/**
 * Aqui a chave (path) de relatório é associada a um objeto 
 * que retorna campos a serem utilizados no filtro e no relatório
 */
const RelatoriosInfo = {
    'colaborador-beneficio': RelatorioColaboradorBeneficio,
}

export default RelatoriosInfo;