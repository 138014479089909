/** 
 * Informações para os campos do form
 * @type {{
 *   groups: Array<{
 *      header: string,
 *      fields: Array<{
 *        label: string,
 *        placeholder: string,
 *        name: string,
 *        type: string,
 *        size: number,
 *        className: string
 *      }>
 *   }
 * }>} 
*/
export const getFormFields = () => {
    return {
        groups: [
            {
                header: '',
                shouldRender: (form) => {
                    return ![null, undefined].includes(form.cod);
                },
                fields: [
                    {
                        label: 'Código',
                        placeholder: '',
                        name: 'cod',
                        type: 'text',
                        size: 3,
                        readOnly: true
                    },
                ]

            },
            {
                header: 'Informações Cadastrais',
                fields: [
                    {
                        label: 'Sigla',
                        placeholder: 'Ex:',
                        name: 'sigla',
                        type: 'text',
                        size: 3,
                        max: 10,
                    },
                    {
                        label: 'Nome',
                        placeholder: 'Ex: Assistente de vendas',
                        name: 'nome',
                        type: 'text',
                        size: 6,
                        max: 60,
                    },
                ]
            },
            {
                header: '', 
                fields: [
                    {
                        label: 'Descrição',
                        placeholder: 'Digite aqui descrição, observações ou detalhes...',
                        name: 'descricao',
                        type: 'textarea',
                        defaultValue: '',
                        size: 6,
                        max: 300
                    },
                ]
            },
            {
                header: 'Ativação',
                fields: [
                    {
                        label: 'Cadastro Ativo',
                        name: 'ativo',
                        type: 'swicth',
                        // defaultChecked: true,
                        // defaultValue: true,
                        size: 2,
                    },
                ]
            }
        ]
    };
}

const BeneficioNewInfo = { getFormFields };

export default BeneficioNewInfo;
