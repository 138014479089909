import api from "./api";

const BASE_ENDPOINT = '/cliente';

function getClientAll() {
    return api.get(BASE_ENDPOINT);
};

function addProjetoByClient(data) {
    return api.post(`${BASE_ENDPOINT}/associar`, data)
};

function getClientByProjeto(key) {
    return api.get(`${BASE_ENDPOINT}/projeto/${key}`);
};

function getHistoricoCusto(data) {
    return api.post(`${BASE_ENDPOINT}/projetos`, data);
};

function getAllClients() {
    return api.get("/cliente/clientes")
}

function getClientProjectsById(id) {
    return api.get(`/cliente/findAllProjectsByClientId/${id}`)
}

function createClientWithProjects(dataCliente, dataProjeto){
    let payload = {
        cliente: dataCliente,
        projeto: dataProjeto
    }
    return api.post('/cliente/createClientWithProjects', payload)
}

function getClientByProjectKey(key){
    return api.get(`/cliente/findClienteByChaveProjeto/${key}`)
}

function updateCliente(keyProjeto, objNovoCliente){
    let payload = {
        chaveProjeto: keyProjeto,
        novoCliente: objNovoCliente
    }
    return api.put('/cliente/updateCliente', payload);
}

function getIdClient(key) {
    return api.get(`${BASE_ENDPOINT}/clientId/${key}`)
};
const ClienteoApiService = {
    getClientAll,
    addProjetoByClient,
    getClientByProjeto,
    getHistoricoCusto,
    getAllClients,
    getClientProjectsById,
    createClientWithProjects,
    getClientByProjectKey,
    updateCliente,
    getIdClient
}

export default ClienteoApiService;