import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import FieldCustom from './FieldCustom';
import UtilService from '../../services/util';

import './FormBuilder.css';


function FormBuilder({
    schema,
    initialValues,
    formFields,
    formData,
    onChange,
    onSubmit,
    disabledForm,
    ...rest
}) {
    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={schema}
            initialValues={initialValues}
            enableReinitialize
        >
            { ({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                resetForm
            }) => {
                UtilService.genericResetFormFunction = () => resetForm();

                return (
                    <Form
                        noValidate
                        onSubmit={handleSubmit}
                        className="form-build"
                    >
                        { formFields.groups && formFields.groups.map((group, key) => {
                            const shouldRender = (group.shouldRender ? group.shouldRender(formData) : true);
                            if (!shouldRender) return;

                            const gKey = `group-${key}`;
                            return (
                                <React.Fragment key={gKey}>
                                    { group.header && (<>
                                        <h6>{group.header}</h6>
                                        <hr />
                                    </>) }
                                    <Form.Row>
                                        {group.fields.map((item, idx) => {
                                            const key = `${gKey}-field-${idx}`;
                                            const fieldProps = {
                                                ...item,
                                                onChange: (e) =>{
                                                    if (item.onChange) item.onChange(e, formData);
                                                    onChange(e);
                                                    handleChange(e);
                                                },
                                                onBlur: handleBlur,
                                                value: formData[item.name] === values[item.name] ? values[item.name] : formData[item.name], // value: (formData[item.name]),
                                                isValid: (!item.readOnly && touched[item.name] && !errors[item.name]),
                                                isInvalid: (!item.readOnly && !!errors[item.name]),
                                                disabled: (item.disabled || item.readOnly || disabledForm),
                                                error: (errors[item.name] || ''),
                                                changeCurrency: item.changeCurrency && formData[item.changeCurrency],
                                                ...(item.attr ? (item.attr(formData)) : {}),
                                                ...rest
                                            };

                                            return (
                                                <FieldCustom
                                                    key={key}
                                                    {...fieldProps}
                                                />
                                            );
                                        })}
                                    </Form.Row>

                                </React.Fragment>
                            );
                        })}
                    </Form>
                )}
            }
        </Formik>
    )
}

/** Retorna chave e valor de cada campo do form recebido */
function FormStateBuilder(form) {
    let state = {};

    form.groups.forEach(group => {
        group.fields.forEach(field => {
            state[field.name] = field.defaultValue !== undefined ? field.defaultValue : '';
        })
    });

    return state;
}

export {
    FormBuilder,
    FormStateBuilder
};
