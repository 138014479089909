import React,  { useEffect, useState } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { FaArrowLeft, FaCheck, FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import Loading from '../../../components/Loading';
import useAuth from '../../../context/AuthContext';
import useAlertNotification from '../../../context/AlertNotificationContext';
import RecoveryValidation from '../../../services/validation/RecoveryValidation';
import PasswordApiService from '../../../services/api/PasswordApiService';
import UtilService from '../../../services/util';

import './RecoveryPage.css';

const INITIAL_PAGE_PATH = "/login";

function RecoveryPage() {
    const title = "Recuperação de senha";
    UtilService.setTitle(title);

    // STATE
    const [messageDone, setMessageDone] = useState(null);
    const [isTokenChecked, setIsTokenChecked] = useState(false);
    const [isValidToken, setIsValidToken] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // CONTEXT
    const { user } = useAuth();
    const alert = useAlertNotification();
    const history = useHistory();
    const match = useRouteMatch();

    const { token } = match.params;

    // FUNCTIONS
    const handleCancel = () => {
        history.replace(INITIAL_PAGE_PATH);
    }

    const onSubmit = async (form, /* actions */) => {
        try {
            setIsLoading(true);
            const validation = await RecoveryValidation.validate(form);
            if (!validation.isValid) {
                const { errors } = validation;
                const errorsTxt = Object.keys(errors).map(key => errors[key]).join('\n');
                alert.error(errorsTxt);
                return;
            }

            const encodedSenha = btoa(form.senha);
            const response = await PasswordApiService.resetPassword(encodedSenha, token);
            if (!response.data) {
                alert.error("Problema ao validar dados enviados.");
                return;
            }

            const { status, message, errors } = response.data;
            if (errors) {
                const msgError = []
                Object.keys(errors).forEach(key => {
                    const errStr = errors[key].map(item => item.errorMessage).join('\n');
                    msgError.push(errStr);
                })
                console.log("[ERROR] [RecoveryPage.onSubmit]:", { errors, msgError });
                alert.error(msgError.join('\n'));
                return;
            }
            
            // console.log("[ OK ] [RecoveryPage.onSubmit]:", { status, message });
            setMessageDone(message);
        }
        catch (error) {
            console.log("[ERROR] [RecoveryPage.onSubmit]:", error);
            const errMsg = "Erro na tentativa de registrar nova senha."
            alert.error(errMsg);
            setMessageDone(errMsg);
        }
        finally {
            setIsLoading(false);
        }
    };

    const onStart = () => {
        const validateToken = async () => {
            try {
                setIsLoading(true);
                const resp = await PasswordApiService.checkToken(token, true);
                if (!resp.data) {
                    const errMsg = "Problema com ao validar requisição para nova senha.";
                    setMessageDone(errMsg);
                    alert.error(errMsg);
                    return;
                }
    
                const { status, message, errors } = resp.data;
                if (errors) {
                    const msgError = []
                    Object.keys(errors).forEach(key => {
                        const errStr = errors[key].map(item => item.errorMessage).join('\n');
                        msgError.push(errStr);
                    });
                    setMessageDone(msgError.join('\n'));
                    alert.error(msgError.join('\n'));
                    return;
                }
                
                console.log("[ OK ] [RecoveryPage.onStart]:", { status, message });
                const isOk = (status === 'ok');
                setIsValidToken(isOk);
            }
            catch (err) {
                console.log("[ERROR] [RecoveryPage.onStart]:", err);
                const errMsg ="Erro ao validar usuário.";
                alert.error(errMsg);
                setMessageDone(errMsg);
                setIsValidToken(false);
            }
            finally {
                setIsTokenChecked(true);
                setIsLoading(false);
            }
        }
        if (token) validateToken();
        else setIsLoading(false);
    }

    // USEEFFECTS
    useEffect(onStart, []);
    
    // RENDER
    if (!!user || !token) return <Redirect to={INITIAL_PAGE_PATH} />

    if (!isTokenChecked) return (
        <Loading message="Carregando..." />
    );

    const successCss = (isValidToken ? 'success' : 'danger');
    const cssExplain = [
        'text-explain',
        (messageDone ? `text-${successCss}` : 'text-muted'),
    ].join(' ');
    const explainMessage = messageDone || `Cadastre uma nova senha de acesso para seu usuário.`;

    return (
        <div className="cadastrar-container">
            <h4>{ title }</h4>
            <p className={cssExplain}>
                { explainMessage }
            </p>
            <hr/>

            {(messageDone) ? (
                <div className={`success-container text-${successCss}`}>
                    <div className="thumbs-up-icon">
                        { isValidToken ? <FaThumbsUp /> : <FaThumbsDown /> }
                    </div>

                    <hr/>
                    
                    <Button 
                        className="cadastrar-button"
                        variant={successCss}
                        onClick={handleCancel}
                    >
                        <FaArrowLeft /> Ir para tela de Login
                    </Button>
                </div> 
            ):(
                <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                        senha: '',
                        confirma: ''
                    }}
                    validationSchema={RecoveryValidation.schema}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form
                            noValidate
                            onSubmit={handleSubmit}
                            className="cadastrar-form"
                        >

                            <Form.Group controlId="fg-senha">
                                <Form.Label>Nova Senha</Form.Label>
                                <Form.Control
                                    name="senha"
                                    type="password"
                                    placeholder="Digite sua senha..."
                                    onChange={handleChange}
                                    onBlur={handleBlur('senha')}
                                    value={values.senha}
                                    isValid={(touched.senha && !errors.senha)}
                                    isInvalid={(touched.senha && !!errors.senha)}
                                    autoComplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.senha }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="fg-confirma">
                                <Form.Label>Confirmar Nova Senha</Form.Label>
                                <Form.Control
                                    name="confirma"
                                    type="password"
                                    placeholder="Confirme a nova senha..."
                                    onChange={handleChange}
                                    onBlur={handleBlur('confirma')}
                                    value={values.confirma}
                                    isValid={(touched.confirma && !errors.confirma)}
                                    isInvalid={(touched.confirma && !!errors.confirma)}
                                    autoComplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.confirma }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <hr/>

                            <div className="cadastrar-btn-container">
                                <Button 
                                    className="cadastrar-button"
                                    variant="primary"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    { isLoading
                                        ? <span>Redefinindo...</span>
                                        : (<><FaCheck /> Redefinir senha</>)
                                    }
                                </Button>
                            </div>

                        </Form>
                    )}
                </Formik>
            )}

        </div>
    )
}

export default RecoveryPage;
