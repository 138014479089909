/** 
 * Informações das colunas para listagem de faixas de imposto
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>} 
 */
export const columns = [
    {
        label: 'Issue',
        name: 'key',
        width: '10%'
    },
    {
        label: 'Descrição',
        name: 'summary',
        width: '40%'
    },
    {
        label: 'Horas',
        name: 'timeLogged',
        width: '10%'
    },
    {
        label: 'Colaborador',
        name: 'user',
        width: '10%'
    }
];


const IssuesListInfo = { columns };

export default IssuesListInfo;