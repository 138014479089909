import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

const clPrfx = 'CustomCurrencyInput';

/**
 * Renderiza um input monetário.
 *
 * Documentação:
 * https://github.com/cchanxzy/react-currency-input-field
 *
 * Exemplos:
 * https://cchanxzy.github.io/react-currency-input-field/
 *
 * Base:
 * https://github.com/cchanxzy/react-currency-input-field/blob/master/src/examples/Example1.tsx
 */
const CustomCurrencyInput = ({ id, name, value, className, onChange, ...rest }) => {
    const cssClass = [
        'text-right',
        className || ''
    ].join(' ');

    const [thisValue, setThisValue] = useState(String(value));

    const onValueChange = (strVal, name) => {
        const isValidValue = (![undefined, null].includes(strVal));
        const vrfdValue = isValidValue ? strVal : null;

        if (!vrfdValue) {
            console.log(`[ERROR] ${clPrfx} [0]:`, {vrfdValue, name});
            setThisValue(0);
            onChange({
                target: {
                    value: String(0),
                    name
                }
            })
            return;
        }

        const fValue = parseFloat( String(vrfdValue).replace(',', '.') );
        const nValue = Number(fValue);
        if (String(vrfdValue).replace(',', '.').length > 16) {
            return
        }

        if (Number.isNaN(nValue)) {
            console.log(`[ERROR] ${clPrfx} [1]:`, {vrfdValue, fValue, nValue, name});
            setThisValue(0);
            onChange({
                target: {
                    value: 0,
                    name
                }
            })
            return;
        }

        setThisValue(vrfdValue);
        onChange({
            target: {
                value: fValue,
                name
            }
        })
    }

    const handleRefreshValue = () => {
        if (thisValue !== String(value)) {
            setThisValue(String(value));
        }
    }

    useEffect(handleRefreshValue, [value]);

    const currency = rest.changeCurrency || "BRL"

    return (
        <CurrencyInput
            id={id}
            className={cssClass}
            decimalsLimit={2}
            onValueChange={onValueChange}
            value={thisValue}
            name={name}
            groupSeparator={'.'}
            decimalSeparator={','}
            intlConfig={{ locale:'pt-BR', currency }}
            {...rest}
        />
    )
}

export default CustomCurrencyInput;
