import React, { useState, useEffect, useCallback } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Button, ButtonGroup } from 'react-bootstrap';

import PageContainer from '../../../components/PageContainer';
import ListLayout from '../../../components/ListLayout';
import { columns } from './MargemListInfo';
import Loading from '../../../components/Loading';
import CustomModal from '../../../components/CustomModal';
import useAlertNotification from "../../../context/AlertNotificationContext";

import MargemDetail from './MargemDetail';

import ErrorRequestService from '../../../services/errorRequest';

import useAuth from '../../../context/AuthContext';
// import { MargemListHelpModal } from './MargemListHelpModal';
import { useRouteMatch, useHistory } from 'react-router-dom';
import SimulacaoMargemApiService from '../../../services/api/SimulacaoMargemApiService';

const INITIAL_PAGEPARAMS = {
    orderBy: 'cod',
    orderDir: 'asc'
};

const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function SimulacoesResultsList() {
    const { user } = useAuth();
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();

    // STATE
    const [rowsData, setRowsData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    /** Define o clique sobre um registro da lista */
    const rowClick = (item) => {
        CustomModal.show({
            title: item.nome,
            body: (
                <MargemDetail
                    onCancel={() => CustomModal.hide()}
                    onSave={() => CustomModal.hide()}
                    props={item}
                />
            ),
            footer: (
                <ButtonGroup>
                    <Button variant="outline-info" onClick={() => history.push(`${match.url}/${item.id}`)}>
                        <FaPen /> Editar
                    </Button>
                    <Button variant="outline-danger" onClick={() => modalDeleteMargin(item)}>
                        <FaTrash /> Excluir
                    </Button>
                </ButtonGroup>
            )
        })
    }

    const modalDeleteMargin = (item) => {
        CustomModal.show({
            body: `Tem certeza que deseja excluir o relatório ${item.nome} ?`,
            footer: (
                <>
                    <Button variant="outline-success" onClick={() => deleteMargin(item.id)}>Sim</Button>
                    <Button variant="outline-danger" onClick={() => CustomModal.hide()}>Cancelar</Button>
                </>
            )
        })
    }

    const deleteMargin = async (itemId) => {
        const { email } = user;

        setIsLoading(true);

        try {
            const resp = (await SimulacaoMargemApiService.deleteSimulacaoMargem(email, {
                id: itemId,
                ativo: false
            }));
            getDataList();
            CustomModal.hide();
            alert.success(resp.data.message);
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert.error(errorMsg);

        } finally {
            setIsLoading(false);
        }
    }

    /** Adiciona click e atributos ao header */
    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    /** Adequa os dados ao formato para lista */
    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    onClick: () => rowClick(item),
                    data: columns.map(col => {
                        return {
                            value: item[col.name],
                            className: col.className
                        }
                    })
                }
                dataList.push(row);
            });
        return dataList;
    }

    /* Filtra a lista de dados */
    const filterList = (params) => {
        const { column, filter } = params;

        if (!column || !filter) {
            setFilteredData(rowsData);
            return
        }

        const filtered = rowsData.filter(item => {
            const lwData = String(item[column]).toLowerCase();
            const lwFilter = String(filter).toLowerCase();

            return lwData.includes(lwFilter);
        });
        setFilteredData(filtered);
    }

    /** Traz as funcoes cadastradas no database  */
    const getDataList = useCallback(async () => {
        const { email } = user;

        setIsLoading(true);

        try {
            // TROCAR AQUI PARA O SERVIÇO DE SIMULAÇÕES
            const resp = (await SimulacaoMargemApiService.getAll(email)).data;
            setRowsData(resp.data);
            setFilteredData(resp.data);

        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert.error(errorMsg);

        } finally {
            setIsLoading(false);
        }
    }, []);


    // EFFECTS
    useEffect(() => {
        if (!rowsData) getDataList();
    }, [rowsData, getDataList]);

    const pageActions = [];

    const title = 'Lista de simulações de resultados';

    return (
        <PageContainer
            title={title}
            actions={pageActions}
            // infoButton={<MargemListHelpModal />}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                :
                <ListLayout
                    columns={getListHeader()}
                    rows={defineDataList()}
                    onSubmitFilter={filterList}
                />
            }
            <CustomModal />
        </PageContainer>
    )
}

export default SimulacoesResultsList;
