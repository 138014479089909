import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import FuncaoApiService from "../../../services/api/FuncaoApiService";
import useAlertNotification from "../../../context/AlertNotificationContext";
import CustomModal from "../../../components/CustomModal";
import ErrorRequestService from "../../../services/errorRequest";

const ModalNewFunction = ({ setIsAdd }) => {
    const alert = useAlertNotification();

    const [cbo, setCbo] = useState("");
    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");
    const [errors, setErrors] = useState({});
    const [randomNumber, setRandomNumber] = useState(null);


    useEffect(() => {
        const generateRandomNumber = () => {
            let number = '';
            while (number.length < 7) {
              number += Math.floor(Math.random() * 10).toString();
            }
            setRandomNumber(parseInt(number, 10));
        };
        generateRandomNumber()
    }, [])

    const validateInputs = () => {
        const newErrors = {};

        if (!randomNumber) {
            newErrors.randomNumber = "CBO não pode ser vazio";
        } else if (!/^\d+$/.test(randomNumber)) {
            newErrors.randomNumber = "CBO deve conter apenas números";
        }

        if (!nome) {
            newErrors.nome = "Nome não pode ser vazio";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const createFunctionApi = async (nome, descricao) => {
        if (!validateInputs()) {
            return;
        }

        const data = {
            "cbo": randomNumber,
            "nome": nome,
            "descricao": descricao,
            "ativo": 2
        }
        try {
            const req = await FuncaoApiService.createNew(data);
            alert.success("Função cadastrada com sucesso");
            setIsAdd(true)
            CustomModal.hide();
        }catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    };

    return (
        <>
            <Form className="mt-3">
                {/* <Form.Group className='mr-3'>
                    <Form.Label>CBO</Form.Label>
                    <Form.Control
                        as="input"
                        name="cbo"
                        placeholder="Ex:"
                        required="required"
                        isInvalid={!!errors.cbo}
                        onChange={(e) => {
                            setCbo(e.target.value);
                        }}
                    >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {errors.cbo}
                    </Form.Control.Feedback>
                </Form.Group> */}

                <Form.Group className='mr-3'>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                        as="input"
                        name="nome"
                        required="required"
                        placeholder="Ex: Assistente de vendas"
                        isInvalid={!!errors.nome}
                        onChange={(e) => {
                            setNome(e.target.value);
                        }}
                    >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {errors.nome}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mr-3'>
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="descricao"
                        placeholder="Digite aqui descrições, observações e detalhes..."
                        required="required"
                        onChange={(e) => {
                            setDescricao(e.target.value);
                        }}
                    >
                    </Form.Control>
                </Form.Group>
            </Form>
            <Button onClick={() => createFunctionApi(nome, descricao)}>Adicionar Função</Button>
        </>
    )
};

export default ModalNewFunction;
