// import UtilService from "../../../../services/util";

/**
 * Informações das colunas para lista de colaboradores
 * @type {{
 *   groups: Array<{
 *      header: string,
 *      shouldRender: Function,
 *      fields: Array<{
 *        label: string,
 *        placeholder: string,
 *        name: string,
 *        type: string,
 *        size: number,
 *        className: string
 *      }>
 *   }
 * }>}
*/
export const getFormFields = () => {
    return {
        groups: [
            {
                header: '',
                fields: [
                    {
                        label: 'CNPJ',
                        placeholder: 'Ex: 12345678901',
                        name: 'cnpj',
                        type: 'text',
                        mask: '00.000.000/0000-00',
                        size: 3,
                        maxLength: 18,
                        // attr: (form) => ({
                        //     pattern: '[0-9](2).[0-9](3).[0-9](3)/[0-9](4)-[0-9](2)'
                        // })
                    },
                    {
                        label: 'Label (Identificação)',
                        placeholder: 'Ex: Filial Rio',
                        name: 'label',
                        type: 'text',
                        size: 4,
                        max: 20,
                    },
                ]
            },
            // {
            //     header: 'Informações de Localização',
            //     fields: [
            //         {
            //             label: 'Cidade',
            //             placeholder: 'Ex: Rio de Janeiro...',
            //             name: 'cidade',
            //             type: 'text',
            //             size: 4,
            //         },
            //         {
            //             label: 'Estado',
            //             placeholder: '',
            //             name: 'estado',
            //             type: 'select',
            //             options: [
            //                 { value: '', label: '...' },
            //                 ...UtilService.getUFs().map(item => ({
            //                     value: item.uf, label: `${item.uf} - ${item.name}`
            //                 }))
            //             ],
            //             size: 1,
            //         },
            //     ]
            // },
            {
                header: 'Ativação',
                fields: [
                    {
                        label: 'Cadastro Ativo',
                        name: 'ativo',
                        type: 'swicth',
                        // defaultChecked: true,
                        // defaultValue: true,
                        size: 2,
                    },
                ]
            }
        ]
    };
}

const FilialNewInfo = { getFormFields };

export default FilialNewInfo;
