import { createStore, compose } from 'redux';
import reducer from './reducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
    reducer,
    /* preloadedState, */
    composeEnhancers()
);

/* // Helper p/ visualizar modificações no store
store.subscribe(() =>
  console.log('Store modificada', store.getState())
) 
*/

export default store;

/**
 * Documentação útil:
 * 
 * Configurando Redux DevTools
 * https://codeburst.io/redux-devtools-for-dummies-74566c597d7
 * https://github.com/zalmoxisus/redux-devtools-extension
 */