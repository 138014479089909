import api from "./api";

const BASE_ENDPOINT = '/password';
/**
 * Envia o email para a API a fim de executar processo de recuperação de senha
 * @param {string} email email do usuario
 */
function forgotPassword(email) {
    return api.post(`${BASE_ENDPOINT}/forgot`, { email })
}

/**
 * Verifica a validade do token enviado a api
 * @param {*} token token a ser validade
 * @param {*} isRecovery define se chamada é para recovery
 */
function checkToken(token, isRecovery=undefined) {
    return api.post(`${BASE_ENDPOINT}/check`, { token, isRecovery })
}

/**
 * Envia para a API nova senha de usuário para cadastro
 * @param {string} senha nova senha de usuario codificada
 * @param {string} token token usado para redefinir senha
 */
function resetPassword(senha, token) {
    return api.post(`${BASE_ENDPOINT}/reset`, { senha, token })
}

const PasswordApiService = {
    forgotPassword,
    checkToken,
    resetPassword,
}

export default PasswordApiService;