import React from 'react'
import { FaPlus } from 'react-icons/fa';
import { useRouteMatch } from 'react-router-dom';
import PageContainer from '../../../../components/PageContainer';
import BeneficioList from '../BeneficioList';

function BeneficiosPage() {
    const match = useRouteMatch();

    const pageActions = [
        { 
            label: 'Novo',
            icon: <FaPlus />,
            color: 'primary',
            type: 'button',
            href: `${match.path}/new`,
        }
    ]

    const title = 'Lista de Benefícios';

    return (
        <PageContainer
            title={title}
            actions={pageActions}
        >
            <BeneficioList />   
        </PageContainer>
    )
}

export default BeneficiosPage
