import React,  { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { FaArrowLeft, FaCheck, FaThumbsUp, FaTimes } from 'react-icons/fa';
import useAuth from '../../../context/AuthContext';
import useAlertNotification from '../../../context/AlertNotificationContext';
import ForgotValidation from '../../../services/validation/ForgotValidation';
import PasswordApiService from '../../../services/api/PasswordApiService';
import UtilService from '../../../services/util';
import ErrorRequestService from '../../../services/errorRequest';

import './ForgotPage.css';
// import Loading from '../../../components/Loading';

const INITIAL_PAGE_PATH = "/login";

function ForgotPage() {
    const title = "Esqueci minha senha";
    UtilService.setTitle(title);

    // STATE
    const [messageDone, setMessageDone] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // CONTEXT
    const { user } = useAuth();
    const alert = useAlertNotification();
    const history = useHistory();

    if (!!user) return <Redirect to={INITIAL_PAGE_PATH} />

    const handleCancel = () => {
        history.goBack();
    }

    const onSubmit = async (form, actions) => {
        try {
            setIsLoading(true);
            const validation = await ForgotValidation.validate(form);
            if (!validation.isValid) {
                const { errors } = validation;
                const errorsTxt = Object.keys(errors).map(key => errors[key]).join('\n');
                alert.error(errorsTxt);
                return;
            }

            const { email } = form;
            const response = await PasswordApiService.forgotPassword(email);

            if (!response.data) {
                alert.error("Problema com ao validar o e-mail inserido.");
                return;
            }

            const { status, message, errors } = response.data;
            
            if (errors) {
                const msgError = []
                Object.keys(errors).forEach(key => {
                    const errStr = errors[key].map(item => item.errorMessage).join('\n');
                    msgError.push(errStr);
                })
                console.log("[ERROR] [ForgotPage.onSubmit]:", { errors, msgError });
                alert.error(msgError.join('\n'));
                return;
            }
            
            console.log("[ OK ] [ForgotPage.onSubmit]:", { status, message });
            setMessageDone(message);
        }
        catch (error) {
            const errorService = new ErrorRequestService(error);
            const errMsg = errorService.getErrors();
            console.log("[ERROR] [ForgotPage.onSubmit]:", errMsg);
            alert.error(errMsg);  /* "Erro na tentativa de recuperar senha." */
        }
        finally {
            setIsLoading(false);
        }
    };

    // RENDER
    const cssExplain = `text-explain ${(!messageDone) ? 'text-muted' : 'text-success'}`;
    const explainMessage = messageDone || `Você receberá uma mensagem por e-mail com um 
    link para redefinir sua senha.`;

    return (
        <div className="forgot-container">
            <h4>{ title }</h4>
            <p className={cssExplain}>
                { explainMessage }
            </p>
            <hr/>

            {(messageDone) ? (
                <div className="success-container">
                    <div className="thumbs-up-icon text-success">
                        <FaThumbsUp />
                    </div>

                    <hr/>
                    
                    <Button 
                        className="forgot-button"
                        variant="success"
                        onClick={handleCancel}
                    >
                        <FaArrowLeft /> Voltar à tela de Login
                    </Button>
                </div> 
            ):(<>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={{ email:'' }}
                    validationSchema={ForgotValidation.schema}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form
                            noValidate
                            onSubmit={handleSubmit}
                            className="forgot-form"
                        >
                            <Form.Group controlId="fg-email">
                                <Form.Label>E-mail de usuário</Form.Label>
                                <Form.Control 
                                    name="email"
                                    type="email"
                                    placeholder="Digite seu e-mail de usuário..."
                                    onChange={handleChange}
                                    onBlur={handleBlur('email')}
                                    value={values.email}
                                    isValid={(touched.email && !errors.email)}
                                    isInvalid={(touched.email && !!errors.email)}
                                    autoComplete="off"
                                    disabled={isLoading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.email }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <hr/>

                            <div className="forgot-btn-container">
                                <Button 
                                    className="forgot-button button-recover"
                                    variant="primary"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    { isLoading
                                        ? <span>Em processamento...</span>
                                        : (<><FaCheck /> Recuperar senha</>)
                                    }
                                </Button>

                                <Button 
                                    className="forgot-button button-cancel"
                                    variant="secondary"
                                    onClick={handleCancel}
                                    disabled={isLoading}
                                >
                                    <FaTimes /> Cancelar
                                </Button>
                            </div>

                        </Form>
                    )}
                </Formik>

                {/* { isLoading && <Loading message="Aguarde, processando..." />} */}
            </>)}

        </div>
    )
}

export default ForgotPage;
