import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListLayout from '../../../../components/ListLayout';
import Loading from '../../../../components/Loading';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../../services/errorRequest';
import BeneficioApiService from '../../../../services/api/BeneficioApiService';
import { storeBeneficios } from '../../../../store/actions/dadosActions';
import { columns } from './BeneficioListInfo';

const INITIAL_PAGEPARAMS = {
    orderBy: 'cod',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function BeneficiolList({ onRowClick }) {
    // CONTEXT
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();

    // REDUX
    const { beneficios } = useSelector(state => state.dados);

    const dispatch = useDispatch();
    const storeBenefsData = useCallback(
        (benefs) => dispatch(storeBeneficios(benefs)),
        [ dispatch ]
    );

    // STATE
    const [rowsData, setRowsData] = useState(beneficios);
    const [filteredData, setFilteredData] = useState(beneficios);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    /** Define o clique sobre um registro da lista */
    const rowClick = (item) => {
        if (onRowClick) {
            onRowClick(item);
            return;
        }
        history.push(`${match.url}/${item.cod}`, item);
    }

    /** Adiciona click e atributos ao header */
    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name) 
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    /** Adequa os dados ao formato para lista */
    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    onClick: () => rowClick(item),
                    data: columns.map(col => ({
                        value: item[col.name],
                        className: col.className
                    }))
                };
                dataList.push(row);
            });
        return dataList;
    }

    /* Filtra a lista de dados */
    const filterList = (params) => {
        const { column, filter } = params;

        if (!column || !filter) {
            setFilteredData(rowsData);
            return
        }
        
        const filtered = rowsData.filter(item => {
            const lwData = String(item[column]).toLowerCase();
            const lwFilter = String(filter).toLowerCase();

            return lwData.includes(lwFilter);
        });
        setFilteredData(filtered);
    }

    /** Traz as funcoes cadastradas no database  */
    const getDataList = useCallback(async () => {
        try {
            // @TODO: Rever situação de uso do REDUX para devida atualização das listas
            // if (!beneficios) {
                setIsLoading(true);

                const resp = await BeneficioApiService.getAll().then(r => r.data.data);
                resp.forEach(item => item.ativo === 1 ? item.ativo = 'Sim' : item.ativo = 'Não')
                
                storeBenefsData(resp);
            // }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    }, []);


    // EFFECTS
    useEffect(getDataList, []);

    useEffect(() => {
        setRowsData(beneficios);
        setFilteredData(beneficios);
    }, [beneficios]);

    // LOADING
    if (isLoading) {
        return <Loading message="Carregando..." />;
    }

    return (
        <ListLayout
            columns={getListHeader()}
            rows={defineDataList()}
            onSubmitFilter={filterList}  
        />
    )
}

export default BeneficiolList;
