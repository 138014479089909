import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import useAuth from './context/AuthContext';
import LocalStorageService from './services/localStorage';

import Projetos from './pages/Projetos';
import DashboardPage from './pages/DashboardPage';
import Cadastros from './pages/Cadastros';
import Fechamentos from './pages/Fechamentos';
import Importacoes from './pages/Importacoes';
import Login from './pages/Login';
import RecoveryPage from './pages/Login/RecoveryPage';
import ConfirmRegisterPage from './pages/Login/ConfirmRegisterPage';
import Relatorios from './pages/Relatorios';
import Simulacoes from './pages/Simulacoes';
import RelatoriosDelivery from './pages/RelatoriosDelivery';


/** Sistema de rotas validando usuário */
function PrivateRoute({ component: Component, ...rest }) {
    const { token } = LocalStorageService.getUserDataLocal();
    const { user } = useAuth();

    if (!token) return <Redirect path='*' to='/login' />;

    const resource_access = user?.roles['convert-api'] ? user?.roles['convert-api']['roles'] : [];

    const hasRole = resource_access.some(item => item === rest.path.split('/')[1]);
    
    if (!hasRole) {
        return (
            <Route path='/' component={DashboardPage}/>
        )
    }

    return (
        <Route
            {...rest}
            render={props => (!!user)
                ? <Component {...props} user={user} />
                : <Redirect to="/login" {...props} />
            }
        />
    )
}

/** Roteador padrão chamando também rotas privadas */
function Routes() {
    // const { user } = LocalStorageService.getUserDataLocal();
    // const { user } = useAuth();



    return (
        <Switch>
            <Route path='/login' component={Login}/>
            <Route path='/recover/:token' exact component={RecoveryPage} />
            <Route path='/register/:token' exact component={ConfirmRegisterPage} />

            <PrivateRoute path='/projetos' component={Projetos} />
            <PrivateRoute path='/dashboard' component={DashboardPage} />
            <PrivateRoute path='/cadastros' component={Cadastros} />
            <PrivateRoute path='/simulacoes' component={Simulacoes} />
            <PrivateRoute path='/fechamentos' component={Fechamentos} />
            <PrivateRoute path='/importacoes' component={Importacoes} />
            <PrivateRoute path='/relatorios' component={Relatorios} />
            <PrivateRoute path='/relatorios-delivery' component={RelatoriosDelivery} />

            <Redirect path='*' to='/login' />
        </Switch>
    )
}

export default Routes;
