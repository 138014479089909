export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const FORM_EDIT = 'FORM_EDIT';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const DADOS_FILIAIS = 'DADOS_FILIAIS';
export const DADOS_DEPARTAMENTOS = 'DADOS_DEPARTAMENTOS';
export const DADOS_FUNCOES = 'DADOS_FUNCOES';
export const DADOS_REGIMES = 'DADOS_REGIMES';
export const DADOS_BENEFICIOS = 'DADOS_BENEFICIOS';
export const DADOS_BENEFICIO_VALOR = 'DADOS_BENEFICIO_VALOR';

export const FECHAMENTO_FETCH = 'FECHAMENTO_FETCH';
export const FECHAMENTO_FETCH_SUCCEEDED = 'FECHAMENTO_FETCH_SUCCEEDED';
export const FECHAMENTO_FETCH_CUSTO_HORA_BY_FECHAMENTO = 'FECHAMENTO_FETCH_CUSTO_HORA_BY_FECHAMENTO';
export const FECHAMENTO_POST_CALCULO_CUSTO_HORA_BY_FECHAMENTO = 'FECHAMENTO_POST_CALCULO_CUSTO_HORA_BY_FECHAMENTO';
export const FECHAMENTO_FETCH_CUSTO_HORA_BY_SUCCEEDED = 'FECHAMENTO_FETCH_CUSTO_HORA_BY_SUCCEEDED';

export const ALERT_NOTIFICATION_ADD = 'ALERT_NOTIFICATION_ADD';
export const ALERT_NOTIFICATION_REMOVE = 'ALERT_NOTIFICATION_REMOVE';