import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiArrowUpLeft, FiHelpCircle } from 'react-icons/fi';
import '../../../components/HelpModal/HelpModal.css';

function ProjetosListHelpeModal() {
    const [show, setShow] = useState(false);
    const [isSteps, setIsSteps] = useState(1);

    const handleClose = () => {
        setIsSteps(1);
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const getStepsView = (step) => {
        switch (step) {
            case 1: return (
                <Modal.Body>
                    <p className="description-text-info">
                        A Lista de projeto é todos os projetos que temos na Vertigo e estão cadastrados no Jira. Para saber mais detalhes como descrição de cada projeto, acesse o Jira!
                    </p>
                </Modal.Body>
            );
            case 2: return (
                <Modal.Body>
                    <p className="description-text-info">
                        Para calcular a margem e/ou para acessar as horas utilizadas em cada projeto, com os calaboradores que estão no projeto, selecione um projeto e clique em Calculo Margem que está no canto superior esquerdo.
                    </p>
                    <div className="icon-arrow-left">
                        <FiArrowUpLeft size={25} />
                    </div>
                </Modal.Body>
            );
            case 3: return (
                <Modal.Body>
                    <p className="description-text-info">
                        Para visualizar e comparar as horas trabalhadas nos projetos e os custo billable e não billable em gráficos, selecione os projetos que deseja comparar e clique em Comparar Projetos que está no canto superior esquerdo.
                    </p>
                    <div className="icon-arrow-left">
                        <FiArrowUpLeft size={25} />
                    </div>
                </Modal.Body>
            );
            default: return ""
        }
    }

    const handleNext = () => {
        if (isSteps !== 3)
            setIsSteps(isSteps + 1)
    }

    const handlePrevious = () => {
        if (isSteps !== 1)
            setIsSteps(isSteps - 1)
    }

    return (
        <>
            <button onClick={() => handleShow(true)} className="button-info">
                <FiHelpCircle size={17} />
            </button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Lista de projetos</Modal.Title>
                </Modal.Header>

                {getStepsView(isSteps)}

                <Modal.Footer>
                    {isSteps !== 1 && (
                        <Button variant="outline-primary" onClick={handlePrevious}>
                            Voltar
                        </Button>
                    )}

                    {isSteps !== 3 && (
                        <Button variant="outline-primary" onClick={handleNext}>
                            Próximo
                        </Button>
                    )}

                    <Button variant="outline-danger" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { ProjetosListHelpeModal }
