import * as Yup from 'yup';
import UtilService from '../util';

/**
 * Realiza as validações necessárias do `form` conforme o `schema` recebido.
 * @param {*} form contem os dados a serem validados
 * @param {*} schema contem o esquema para validar
 * 
 * @see https://github.com/jquense/yup
 */
async function validate(form, schema) {
    const validation = { isValid: false, errors: {} };

    try {
        await schema.validate(form, { abortEarly: false });
    }
    catch (error) {
        console.log("validation error", error);
        if (error instanceof Yup.ValidationError) {
            validation.errors = UtilService.getValidationErrors(error);
        }
    }
    
    validation.isValid = (Object.keys(validation.errors).length === 0);
    return validation;
}

async function castValues(form, schema) {
    const castForm = schema.cast(form);
    return castForm;
}

const ValidationService = {
    validate,
    castValues
};

export default ValidationService;