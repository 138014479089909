import api from "./api";

const BASE_ENDPOINT = '/login';

/** 
 * Efetua o login de usuário na API
 * @param authorization string em base64 contendo objeto `{usuário, senha}`
 */
 function logout(token, refresh_token) {
    return api.post(`${BASE_ENDPOINT}/logout`, {token, refresh_token})
}

/** 
 * Efetua o login de usuário na API
 * @param authorization string em base64 contendo objeto `{usuário, senha}`
 */
function sendLogin(authorization) {
    return api.post(BASE_ENDPOINT, { authorization })
}

/**
 * Envia para a API os dados de um novo usuário para cadastro
 * @param {*} form dados para cadastro do novo do usuario
 */
function requestRegister(form) {
    return api.post(`${BASE_ENDPOINT}/request-access`, { form })
}

/**
 * Envia para a API autenticação especial para aceitação de cadastro de usuário
 * @param {string} b64Form string do form em base64 contendo objeto `{usuário, senha}`
 */
function authenticateRegister(b64Form) {
    return api.post(`${BASE_ENDPOINT}/authenticate-register`, { authorization: b64Form })
}

/**
 * Envia token da solicitação de cadastro de usuário para a API, 
 * a fim de verificar validade, retornando parte dos dados da solicitação.
 * @param {string} solicToken token recebido no email de solicitação
 */
 function verifySolicitation(solicToken) {
    return api.post(`${BASE_ENDPOINT}/verify-solicitation`, { token: solicToken })
}

/**
 * Envia para a API a confirmação (positiva ou negativa) do cadastro do usuário
 * @param {boolean} confirmation confirmação positiva ou negativa p/ cadastro
 * @param {string} authToken token de autenticação
 * @param {string} token token recebido no email de solicitação
 */
function confirmRegister(confirmation, authToken, token) {
    const headers = { 'x-access-token': authToken };
    return api.post(
        `${BASE_ENDPOINT}/confirm-register`,
        { confirmation, token },
        { headers }
    )
}

const AuthApiService = {
    sendLogin,
    requestRegister,
    authenticateRegister,
    verifySolicitation,
    confirmRegister,
    logout
}

export default AuthApiService;