//Converter a data recebendo uma string e passando para ano mês (YYYY-MM)
const convertDataStrToYYYYmm = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
};

//Converter a data recebendo uma string e passando para ano mês (MM-YYYY)
const convertDataStrToMMyyyy = (dateStr) => {
    const date = new Date(dateStr);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month < 10 ? "0" : ""}${month}/${year}`;
};

//Converter a data por split '/' retornando YYYY-MM-DD
const convertDataSplitYYYYmmDD = (data) => {
    const [day, month, year] = data.split('/');
    return `${year}-${month}-${day}`;
};

//Retorna anos de 1999 até o ano atual
const getAnos = () => {
    const anoAtual = new Date().getFullYear();
    const anos = [];

    for (let a = (anoAtual); a >= 1999; a--) {
        anos.push(a.toString());
    }
    return anos;
};

const ConvertDataService = {
    convertDataStrToYYYYmm,
    convertDataStrToMMyyyy,
    convertDataSplitYYYYmmDD,
    getAnos
}

export default ConvertDataService;