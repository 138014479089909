import React, { useState } from 'react'
import { Button, Nav, Navbar, Accordion, Image } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link, withRouter } from 'react-router-dom';
import useAuth from '../../context/AuthContext';
import useTheme from '../../context/ThemeContext';
import NavigationService from '../../services/navigation'
import Avatar from '../Avatar';
import './LayoutWrapper.css';

const sortArr = (a, b, orderBy) => {
    if (a[orderBy] < b[orderBy]) return -1;
    if (a[orderBy] > b[orderBy]) return 1;
}

function LayoutWrapper({ menu, children }) {
    const [sideHide, setSideHide] = useState(true);

    const themeContext = useTheme();
    const { user } = useAuth();
    
    const toggleSide = () => setSideHide(!sideHide);

    const asideClass = `side-wrapper shadow ${sideHide ? 'hide' : 'show'} flex-column col-md-2`;
    const sideOverlay = `side-overlay d-md-none ${sideHide ? 'hide' : 'show'}`;
    const mainwrapperClass = [
        user ? 'main-wrapper' : '', 
        sideHide ? 'hide' : 'show',
        'col-xs-12 col-md'
    ].join(' ');

    // acesso aos recursos associados ao user(tokenParsed) via keycloak
    const resource_access = user?.roles['convert-api'] ? user?.roles['convert-api']['roles'] : [];

    return (
        <div className="layout-wrapper">

            { user && 
                <aside className={asideClass}>

                    <Button 
                        className="side-toggle d-md-none"
                        variant="primary"
                        onClick={toggleSide}
                    >
                        { sideHide ? <FaChevronRight/> : <FaChevronLeft/> }
                    </Button>

                    {/* side header visível a partir de 768px (md) */}
                    <div className="side-header px-2 pb-2">
                        <Navbar.Brand 
                            as={Link} 
                            to="/" 
                            className="navbar-logo text-center"
                        >
                            <Image 
                                src={themeContext.logo} 
                                style={{width: '95%', margin: 'auto'}}
                            />
                        </Navbar.Brand>
                        <Avatar dropSide="right" />
                    </div>                        

                    <div className="side-nav">
                        <Nav className="flex-column">
                            { NavigationService.getNavigationData(resource_access).map((item, k)=> (
                                <Nav.Item 
                                    key={`navitem-${k}`}
                                    className="side-item" 
                                >
                                    {
                                        item &&
                                        <CollapsibleMenuItem 
                                            menu={item}
                                            onItemClick={toggleSide}
                                            items={NavigationService.getSubNavData(item.key, resource_access)}
                                        />
                                    }
                                </Nav.Item>
                            )) }
                        </Nav>
                    </div>
                </aside>
            }

            <main className={mainwrapperClass}>
                { children }
                <div // Overlay
                    className={sideOverlay}
                    onClick={toggleSide}
                />
            </main>

        </div>
    )
}


const CollapsibleMenuItem = withRouter((props) => {
    // PROPS
    const { menu, items, onItemClick, location } = props;

    // STATE
    const [isOpen, setIsOpen] = useState(true);
    const [iconUpDown, setIconUpDown] = useState(<FaChevronUp />);

    const toggleStates = () => {
        setIsOpen(!isOpen);
        setIconUpDown(isOpen ? <FaChevronDown /> : <FaChevronUp />)
    };

    const checkIsActive = (strPath) => {
        const { pathname } = location;
        const subLoc = String(pathname).split('/');

        if (subLoc.length <= 2) return false;

        const mainPath = `/${subLoc[1]}/${subLoc[2]}`;
        return (mainPath === strPath);
    }

    const eventKey = `collap-menu-${menu.key}`;

    return (
        <Accordion className="collapsible-menu" defaultActiveKey={eventKey} > 

            { (items.length === 0) 
                ? (
                    <Link to={menu.href} onClick={onItemClick}>
                        <h5 className="collapsible-menu-title">
                            <span style={{ color: menu.color }}>
                                { menu.icon }{' '}{ menu.label }
                            </span>
                        </h5>
                    </Link>
                )
                : (<>
                        <Accordion.Toggle 
                            eventKey={eventKey}
                            onClick={toggleStates}
                            as="div" 
                        >
                            <h5 className={`collapsible-menu-title ${isOpen ? 'shadow' : ''}`}>
                                <span style={{ color: menu.color }}>
                                    { menu.icon }{' '}{ menu.label }
                                </span>
                                { iconUpDown }
                            </h5>
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey={eventKey}>
                            <div className="collapsible-menu-items">
                                { items
                                    .sort((a,b) => sortArr(a,b, 'label'))
                                    .map((sub, k) => {
                                        const to = menu.href + sub.href;
                                        const active = checkIsActive(to);
                                        
                                        return (
                                            <Nav.Link 
                                                as={Link}
                                                key={`sub-navitem-${menu.key}-${k}`}
                                                to={to}
                                                active={active}
                                                onClick={onItemClick}
                                            >
                                                {sub.label}
                                            </Nav.Link>
                                        )
                                    })
                                }
                            </div>
                        </Accordion.Collapse>
                </>) 
            }

        </Accordion>
    )
});


export default LayoutWrapper;
