import React from 'react';
import { Card } from 'react-bootstrap';
import * as R from "ramda";

const cardStyle = {
    margin: 2,
}

const backgroundGreen= {
    marginTop: 10,
}

const cardContentStyle = {
    display: 'grid',
    padding: 10
}

function MargemDetail({ props }) {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h4>{ `${props.projeto} - Período ${props.periodo_inicial.replace('-', '/')} a ${props.periodo_fim.replace('-', '/')}` }</h4>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr", justifyContent: 'center', marginTop: 5 }}>
                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span><strong>Receita Bruta:</strong> {parseFloat(props.receita_bruta).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span>Impostos: {parseFloat(props.impostos).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span><strong>Receita Líquida:</strong> {parseFloat(props.receita_liquida).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                    </div>
                </Card>

                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span><strong>Custos de vendas:</strong> {parseFloat(props.custo_venda).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span>Custos Indiretos: R$ {parseFloat(props.total_custo_indireto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span>Custo HH: {parseFloat(props.custo_hora_colaborador).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                    </div>
                </Card>

                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span><strong>Resultado Bruto:</strong> {parseFloat(props.resultado_bruto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span><strong>Margem Bruta:</strong> {parseFloat(props.margem_bruta).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</span>
                        <span>&nbsp;</span>
                    </div>
                </Card>

                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span>
                            <strong>Desp. Operacionais:</strong>
                            {parseFloat(props.despesas_operacionais).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                        <span>RV: {parseFloat(props.rv).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span>Rateio Administrativo: {parseFloat(props.rateio_administrativo).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                    </div>
                </Card>

                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span>
                            <strong>Resultado IR/CSLL:</strong> {parseFloat(props.resultado_ir_csll).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                        <span>
                            Imposto s/ Resultado: {parseFloat(props.imposto_sem_resultado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                        <span>&nbsp;</span>
                    </div>
                </Card>
                <Card style={{ ...cardStyle, background: '#dedede' }}>
                    <div style={{ ...cardContentStyle }}>
                        <span>
                            <strong>Resultado Líquido:</strong> {parseFloat(props.resultado_liquido).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                        <span>
                            <strong>Margem Líquida:</strong> {parseFloat(props.margem_liquida).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}
                        </span>
{/*                         <span>
                            <strong>Margem de Contribuição:</strong> {parseFloat(props.margem_contribuicao).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}
                        </span> */}
                    </div>
                </Card>
                
            </div>
            <Card style={{ ...cardStyle, ...backgroundGreen}}>
                    <div style={{ ...cardContentStyle }}>
                        <span>
                            <strong>Margem de Contribuição:</strong> {parseFloat(props.margem_contribuicao).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}
                        </span>
                    </div>
            </Card>
        </>
    )
}

export default MargemDetail;
