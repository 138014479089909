import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import LoginPage from './LoginPage';
import ForgotPage from './ForgotPage';
import CadastrarPage from './CadastrarPage';

function Login(props) {
    // PROPS
    const { match } = props;

    return (
        <Switch>
            <Route path={`${match.path}`} exact component={LoginPage} />
            <Route path={`${match.path}/esqueci`} exact component={ForgotPage} />
            <Route path={`${match.path}/cadastrar`} exact component={CadastrarPage} />
            
            <Redirect path='*' to='/' />
        </Switch>
    )
}

export default Login;
