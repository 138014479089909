import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiArrowDown, FiHelpCircle } from 'react-icons/fi';
import '../../../components/HelpModal/HelpModal.css';

function MargemListHelpModal() {
    const [show, setShow] = useState(false);
    const [isSteps, setIsSteps] = useState(1);

    const handleClose = () => {
        setIsSteps(1);
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const getStepsView = (step) => {
        switch (step) {
            case 1: return (
                <Modal.Body >
                    <p className="description-text-info">
                        Aqui mostra os Relatórios que foram criados a partir dos projetos onde pode visualizar os detalhes do relatório selecionando nele ou se necessário, podendo excluir.
                    </p>
                    <div className="icon-arrow-left">
                        <FiArrowDown size={25} />
                    </div>
                </Modal.Body>
            );
            default: return ""
        }
    }

    return (
        <>
            <button onClick={() => handleShow(true)} className="button-info">
                <FiHelpCircle size={17} />
            </button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>O que é os Relatórios</Modal.Title>
                </Modal.Header>

                {getStepsView(isSteps)}

                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { MargemListHelpModal }
