import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";
import CustosPrevistosService from "../../../services/api/CustosPrevistosService";
import ProjetosApiService from "../../../services/api/ProjetosApiService";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAuth from "../../../context/AuthContext";
import { Button, Table } from "react-bootstrap";
import * as R from "ramda";
import { MdOutlineArrowBack } from "react-icons/md";
import UtilService from "../../../services/util";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import Loading from "../../../components/Loading";
import { FiInfo } from "react-icons/fi";

const popupStyle = {
    position: 'absolute',
    top: '-250%',
    left: '-70%',
    transform: 'translateX(-70%)',
    backgroundColor: '#e9e9e9',
    color: '#212529',
    border: '1px solid #dee2e6',
    borderRadius: '5px',
    padding: '5px 10px',
    zIndex: 1000,
    whiteSpace: 'nowrap',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    width: 'auto',
    fontWeight: 'normal'
};

function ComparativoFaturamentoView() {
    const [valoresVenda, setValoresVenda] = useState([]);
    const [margensCusto, setMargensCusto] = useState([]);
    const [visiblePopup, setVisiblePopup] = useState(null);

    const { ano, key } = useParams();
    const { user } = useAuth();
    const { email } = user;


    const handleValorVendaPrevisto = async () => {
        try {
            const resp = await CustosPrevistosService.getValorVendaforYear(ano, key);
            setValoresVenda(resp.data.data);
        } catch (err) {
            console.error("Erro ao buscar valor venda", err)
        }
    };

    const handleValorTotalNF = async () => {
        try {
            const resp = await ProjetosApiService.getMargemFaturamento(ano, key, email);
            setMargensCusto(resp.data);
        } catch (err) {
            console.error("Erro ao buscar margem", err)
        }
    };

    const generateMonthsForYear = () => {
        let months = [];
        for (let month = 1; month <= 12; month++) {
            let formattedMonth = month.toString().padStart(2, '0');
            months.push(`${formattedMonth}-${ano}`);
        }
        return months;
    };

    const allMonths = generateMonthsForYear();

    const margensCustoMap = margensCusto.reduce((acc, report) => {
        const monthYear = new Date(report.periodo_inicial).toLocaleString('default', { month: '2-digit', year: 'numeric' }).replace(',', '');
        const monthYearFormated = monthYear.replace("/", "-")
        acc[monthYearFormated] = report.receita_bruta;

        return acc;
    }, {});

    const valoresVendaMap = valoresVenda.reduce((acc, item) => {
        acc[item.mesAno] = item.valor;

        return acc;
    }, {});

    const calculetedVariation = (margem, venda) => {
        const margemFormated = Number(margem);
        const vendaFormated = Number(venda);

        if (isNaN(margemFormated)) {
            return <td style={{ color: "red", fontWeight: "bold" }}>-100%</td>
        }

        if (isNaN(vendaFormated)) {
            return <td style={{ color: "green", fontWeight: "bold" }}>100%</td>
        }

        let dividendo = R.subtract(margemFormated, vendaFormated);
        let result = R.divide(dividendo, margemFormated);

        if (result > 0) {
            return <td style={{ color: "green", fontWeight: "bold" }}>{result.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</td>
        }

        if(isNaN(result)) {
            return <td>-</td>
        }

        return <td style={{ color: "red", fontWeight: "bold" }}>{result.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</td>
    };

    const getMonthName = (dateString) => {
        const monthNames = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ];

        const [month] = dateString.split('-');
        const monthIndex = parseInt(month, 10) - 1;

        return monthNames[monthIndex];
    };

    const combinedData = allMonths.map((monthYear) => {
        return {
            mesAno: getMonthName(monthYear),
            receita_bruta: margensCustoMap[monthYear] ? margensCustoMap[monthYear] : '-',
            valor: valoresVendaMap[monthYear] ? valoresVendaMap[monthYear] : '-',
            variation: (margensCustoMap[monthYear] || valoresVendaMap[monthYear]) ? calculetedVariation(valoresVendaMap[monthYear], margensCustoMap[monthYear]) : <td>-</td>,
            variationLine: (margensCustoMap[monthYear] || valoresVendaMap[monthYear]) && R.subtract(valoresVendaMap[monthYear], margensCustoMap[monthYear])
        };
    });

    const calculateTotals = (data) => {
        const totals = data.reduce(
            (acc, item) => {
                const receitaBruta = parseFloat(item.receita_bruta.replace('R$', '').replace(',', '').replace(' ', '')) || 0;
                const valor = item.valor === '-' ? 0 : parseFloat(item.valor.replace('R$', '').replace(',', '').replace(' ', ''));

                acc.total_receita_bruta += receitaBruta;
                acc.total_valor += valor;

                return acc;
            },
            { total_receita_bruta: 0, total_valor: 0 }
        );

        return {
            total_receita_bruta: totals.total_receita_bruta.toFixed(2),
            total_valor: totals.total_valor.toFixed(2)
        };
    };

    const processCosts = (data) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        const daysInCurrentMonth = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate();
        
        let sumPredictedCost = 0;

        data.forEach((item, index) => {
            const predictedCost = parseFloat(item.valor.replace('R$', '').replace(',', '')) || 0;

            if (currentDay === daysInCurrentMonth && index <= currentMonth) {
                sumPredictedCost += predictedCost;
            }
            
            if (currentDay !== daysInCurrentMonth && index < currentMonth) {
                sumPredictedCost += predictedCost;
            }
        });

        return {
            sumPredictedCost: sumPredictedCost.toFixed(2)
        };
    };

    const processCostsReal = (data) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        const daysInCurrentMonth = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate();
        
        let sumRealCost = 0;

        data.forEach((item, index) => {
            const realCost = parseFloat(item.receita_bruta.replace('R$', '').replace(',', '')) || 0;

            if (currentDay === daysInCurrentMonth && index <= currentMonth) {
                sumRealCost += realCost;
            }
            
            if (currentDay !== daysInCurrentMonth && index < currentMonth) {
                sumRealCost += realCost;
            }
        });

        return {
            sumRealCost: sumRealCost.toFixed(2)
        };
    };

    const exportDataInExcel = async () => {
        const header = [
            [
                'Mês', 'Faturamento Previsto', 'Faturamento Real', 'Variação',
            ]
        ];

        const formatedData = await combinedData.map(item => {
            
            const formatValue = (value) => {
                if (value === "-") return ""; // Verifica se o valor é "-" e retorna vazio
                return parseFloat(String(value).replace(',', '.')) || ""; // Faz a conversão para número ou deixa em branco se não for possível
            };
    
            return {
                mes: item.mesAno,
                valor_venda: formatValue(item.valor),
                receita_bruta: formatValue(item.receita_bruta),
                variation: item.variation?.props.children,
            };
        });
    
        UtilService.exportXls(formatedData, `Relatório Faturamento: ${key} - ${ano}`, header);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const variationLegend  = ((!isNaN(payload[0].value) && !isNaN(payload[1].value)) ? R.subtract(parseFloat(payload[0].value),parseFloat(payload[1].value)).toFixed(2)  : "-");
            return (        
                <div
                    tabIndex="-1"
                    className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom"
                    >
                    <div
                        className="recharts-default-tooltip"
                        style={{
                        margin: '0px',
                        padding: '10px',
                        backgroundColor: 'rgb(255, 255, 255)',
                        border: '1px solid rgb(204, 204, 204)',
                        whiteSpace: 'nowrap'
                        }}
                    >
                        <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
                        {label}
                        </p>
                        <ul className="recharts-tooltip-item-list" style={{ padding: '0px', margin: '0px' }}>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: '#00c49f' }}>
                            <span className="recharts-tooltip-item-name">Faturamento Previsto</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">R$ {!isNaN(payload[0].value) ? payload[0].value.toLocaleString('pt-br') : "-" }</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: '#0088fe' }}>
                            <span className="recharts-tooltip-item-name">Faturamento Real</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">R$ {!isNaN(payload[1].value) ? payload[1].value.toLocaleString('pt-br') : "-" }</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: 'rgb(255, 115, 0)' }}>
                            <span className="recharts-tooltip-item-name">Variação</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">R$ {(variationLegend).toLocaleString("pt-br")}</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        </ul>
                    </div>
                </div>
            );
        }
    
        return null;
    };

    const actions = [
        {
            label: 'Voltar',
            icon: <MdOutlineArrowBack />,
            color: 'info',
            type: 'button',
            onClick: useHistory().goBack
        }
    ]

    useEffect(() => {
        handleValorVendaPrevisto();
        handleValorTotalNF();
        generateMonthsForYear();
    }, [])

    const handleMouseEnter = (popupId) => {
        setVisiblePopup(popupId);
    };

    const handleMouseLeave = () => {
        setVisiblePopup(null);
    };

    const totais = calculateTotals(combinedData);
    const total_predict_acumulated = processCosts(combinedData);
    const total_real_acumulated = processCostsReal(combinedData);
    const diferenca = R.subtract(Number(total_predict_acumulated.sumPredictedCost), Number(total_real_acumulated.sumRealCost));
    const total_variaiton_acumulated = diferenca/Number(total_predict_acumulated.sumPredictedCost);

    return (
        <PageContainer title={`Comparativo de faturamento ${key} - ${ano}`} actions={actions} >
            {valoresVenda.length == 0 ? <h5>Não existe dados para o período selecionado.</h5> :  
            <>
                <Button onClick={exportDataInExcel} className='mb-3'>Exportar</Button>
                <div className='w-25'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Total Variação Acumulada
                                <span
                                    onMouseEnter={() => handleMouseEnter('variation_total')}
                                    onMouseLeave={handleMouseLeave}
                                    className="ml-1 info-icon"
                                    style={{ position: 'relative', cursor: 'pointer' }}
                                >
                                    <FiInfo />
                                    {visiblePopup === 'variation_total' && (
                                        <div className="hover-popup" style={popupStyle}>
                                            Diferença entre o Faturamento Previsto e o Faturamento Real acumulado até o último mês fechado.
                                        </div>
                                    )}
                                </span>
                            </th>
                                {total_variaiton_acumulated < 0 ? 
                                    <th style={{color: "red"}}>{total_variaiton_acumulated.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</th>
                                : 
                                    <th style={{color: "green"}}>{total_variaiton_acumulated.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</th>
                                }
                            </tr>
                        </thead>
                    </Table>
                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Mês</th>
                            <th>Faturamento Previsto</th>
                            <th>Faturamento Real</th>
                            <th>
                                Variação
                                <span
                                    onMouseEnter={() => handleMouseEnter('variation')}
                                    onMouseLeave={handleMouseLeave}
                                    className="ml-1 info-icon"
                                    style={{ position: 'relative', cursor: 'pointer' }}
                                >
                                    <FiInfo />
                                    {visiblePopup === 'variation' && (
                                        <div className="hover-popup" style={popupStyle}>
                                            Diferença entre o Faturamento Previsto e o Faturamento Real: (FP - FR) / FP
                                        </div>
                                    )}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {combinedData.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.mesAno}</td>
                                <td>{item.valor != "-" ? `R$ ${parseFloat(item.valor).toLocaleString("pt-br")}` : item.valor}</td>
                                <td>{item.receita_bruta != "-" ? `R$ ${parseFloat(item.receita_bruta).toLocaleString('pt-br')}` : item.receita_bruta}</td>
                                {item.variation}
                            </tr>
                        ))}
                        <tr>
                            <td><strong>Total</strong></td>
                            <td></td>
                            <td>R$ {parseFloat(totais.total_valor).toLocaleString("pt-br")}</td>
                            <td>R$ {parseFloat(totais.total_receita_bruta).toLocaleString("pt-br")}</td>
                            {calculetedVariation(totais.total_valor, totais.total_receita_bruta)}
                        </tr>
                    </tbody>
                </Table>
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                        data={combinedData}
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="mesAno" />
                        <YAxis tickFormatter={(value) => `R$ ${value.toLocaleString('pt-br')}`}  />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar dataKey="valor" fill="#00c49f" name="Faturamento Previsto" />
                        <Bar dataKey="receita_bruta" fill="#0088fe" cursor="pointer" name="Faturamento Real" />
                        <Line strokeWidth={2} type="monotone" dataKey="variationLine" name="Variação" stroke="#ff7300" />
                    </ComposedChart>
                </ResponsiveContainer>
            </>}
        </PageContainer>
    )
}

export default ComparativoFaturamentoView;
