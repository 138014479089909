import React from 'react'
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import UtilService from '../../services/util';
import TopActions from '../TopActions';

import './ContentContainer.css';

function ContentContainer({ title, actions, children }) {
    return (
        <Container fluid className="content-container">
            <div className="content-head"> 
                <h3>{ title }</h3>
                { (actions.length > 0) && 
                    <TopActions actions={actions} />
                }
            </div>
            <hr/>
            <div className="content-body">
                { children }
            </div>
        </Container>
    )
}

// DEFAULT PROPS
ContentContainer.defaultProps = {
    title: UtilService.getTitle(),
    actions: [],
    children: []
}

// PROPTYPES
ContentContainer.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.array,
    children: PropTypes.any
}

export default ContentContainer;

