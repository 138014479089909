import React, { useEffect, useState } from "react";
import ConvertDataService from '../../../../services/util/ConvertDatas';
import ClienteoApiService from "../../../../services/api/CadastroClientesService";
import { Button, Form } from "react-bootstrap";

const FiltroRelatorioComparativo = ({clientes, data, pathUrl, handleDadosProjetos}) =>{

    //States
    const [projetoSelecionado, setProjetoSelecionado] = useState("");
    const [anoSelecionado, setAnoSelecionado] = useState("");
    const [clienteSelecionado, setClienteSelecionado] = useState("");
    const [projetosFiltrados, setProjetosFiltrados] = useState(data);
    const [selectedOptionRadio, setSelectedOptionRadio] = useState('default');
    const [selectedOptionRadioPeriod, setSelectedOptionRadioPeriod] = useState('default');

    const handleRadioChange = (event) => {
        setSelectedOptionRadio(event.target.value);
        if (event.target.value === "projeto") {
            setProjetosFiltrados(data)
        }
    };

    const handleRadioChangePeriodo = (event) => {
        setSelectedOptionRadioPeriod(event.target.value);
        if(event.target.value ===  'total') {
            setAnoSelecionado('total')
        }
    };

    const handleClienteChange = (e) => {
        setProjetoSelecionado("")
        const selectedValue = e.target.value;
        if (selectedValue === '--Selecione o cliente--') {
            setClienteSelecionado("");
            setProjetosFiltrados(data);
        } else {
            setClienteSelecionado(selectedValue);
        }
    };

    useEffect( async () => {
        if(clienteSelecionado) {
            try {
                const response = await ClienteoApiService.getClientProjectsById(clienteSelecionado);

                filteredData(response.data.projetos);
            } catch (error) {
                console.error("Erro ao buscar projetos do cliente.", error);
            }
        }
    }, [clienteSelecionado]);

    const filteredData = (projetos) => {
        
        const filteredProjects = data.filter(item => 
            projetos.some(projeto => projeto.chave === item.key)
        );

        setProjetosFiltrados(filteredProjects)
    };

    return(
        <>
            <Form className="d-flex flex-column mt-3">
                <Form.Group>
                    <Form.Label>Filtrar por:</Form.Label>
                    <Form.Check
                        type="radio"
                        label="Cliente"
                        name="radioGroup"
                        id="por-cliente"
                        value="cliente"
                        checked={selectedOptionRadio === 'cliente'}
                        onChange={handleRadioChange}
                    />
                    <Form.Check
                        type="radio"
                        label="Projeto"
                        name="radioGroup"
                        id="por-projeto"
                        value="projeto"
                        checked={selectedOptionRadio === 'projeto'}
                        onChange={handleRadioChange}
                    />
                </Form.Group>
                {selectedOptionRadio == "cliente" ? 
                <Form.Group>
                    <Form.Label>Selecione o cliente:</Form.Label>
                    <Form.Control
                        as="select"
                        name="clientes"
                        onChange={handleClienteChange}
                    >
                    <option>--Selecione o cliente--</option>
                    {clientes
                        .slice()
                        .sort((a, b) => a.nome.localeCompare(b.nome))
                        .map((item, index) => (
                            <option value={item.id} key={index}>{item.nome}</option>
                        ))
                    }
                    </Form.Control>
                </Form.Group>
                : <></>}
                <Form.Group className='mr-3'>
                    <Form.Label>Projetos</Form.Label>
                    <Form.Control
                        as="select"
                        name="projetos"
                        required="required"
                        onChange={(e) => {
                            setProjetoSelecionado(e.target.value);
                        }}
                    >
                    <option value="" >--Projetos--</option>
                    {projetosFiltrados
                        .slice()
                        .sort((a, b) => a.key.localeCompare(b.key))
                        .map((item, index) => (
                            <option value={item.key} key={index}>{item.key} - {item.name}</option>
                            ))
                    }
                    </Form.Control>
                </Form.Group>
                {pathUrl == 'resultado-anual' &&
                    <Form>
                        <Form.Group>
                            <Form.Label>Filtrar por Período:</Form.Label>
                            <Form.Check
                                type="radio"
                                label="Ano"
                                name="radioGroup"
                                id="por-ano"
                                value="ano"
                                checked={selectedOptionRadioPeriod === 'ano'}
                                onChange={handleRadioChangePeriodo}
                            />
                            <Form.Check
                                type="radio"
                                label="Total"
                                name="radioGroup"
                                id="por-total"
                                value="total"
                                checked={selectedOptionRadioPeriod === 'total'}
                                onChange={handleRadioChangePeriodo}
                            />
                        </Form.Group>

                        {selectedOptionRadioPeriod === 'ano' &&
                            <Form.Group className='mr-3'>
                                <Form.Label>Ano</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="ano"
                                    onChange={(e) => setAnoSelecionado(e.target.value)}
                                >
                                <option value="">Selecione</option>

                                {ConvertDataService.getAnos().map(ano => (
                                    <option key={ano} value={ano}>{ano}</option>
                                ))}
                                </Form.Control>
                            </Form.Group>
                        }
                    </Form>
                }
                {pathUrl != 'resultado-anual' &&
                    <Form.Group className='mr-3'>
                        <Form.Label>Ano</Form.Label>
                        <Form.Control
                            as="select"
                            name="ano"
                            onChange={(e) => setAnoSelecionado(e.target.value)}
                        >
                        <option value="">Selecione</option>

                        {ConvertDataService.getAnos().map(ano => (
                            <option key={ano} value={ano}>{ano}</option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                }
            </Form>
            <Button onClick={() => handleDadosProjetos(projetoSelecionado, anoSelecionado, pathUrl)}>Gerar</Button>
        </>
    )
}
export default FiltroRelatorioComparativo;