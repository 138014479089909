import { useState } from "react";
import { FaSpinner, FaUpload } from "react-icons/fa";
import * as XLSX from 'xlsx';
import LoadingProgress from "../../../../components/LoadingProgress";
import PageContainer from "../../../../components/PageContainer";
import Uploader from "../../../../components/Uploader";
import useAlertNotification from "../../../../context/AlertNotificationContext";
import ImportacaoApiService from "../../../../services/api/ImportacaoApiService";
import ErrorRequestService from "../../../../services/errorRequest";

// progress values
let progressInterval = null;
let progVal = 0;

function AtualizarColaboradoresImport(props) {
    // Hooks
    const alert = useAlertNotification();

    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [imported, setImported] = useState([]);
    const [failed, setFailed] = useState(null);
    const [progress, setProgress] = useState(0);

    const convertToString = (value) => {
        if (value === null || value === undefined) return '';
        return String(value);
    };

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            if (file.name.endsWith('.csv')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const content = e.target.result;
                    const separator = content.includes(';') ? ';' : ',';
                    
                    const lines = content.split(/\r\n|\n|\r/);
                    const [headerLine, ...rows] = lines;
                    const headers = headerLine.split(separator).map(header => header.trim());
                
                    let result = [];
                    rows.forEach(line => {
                        if (line.trim() !== "") {
                            const values = line.split(separator).map(value => convertToString(value.trim()));
                            let obj = {};
                
                            headers.forEach((header, index) => {
                                obj[header] = values[index];
                            });
                
                            result.push(obj);
                        }
                    });
                
                    resolve(result);
                };
                reader.onerror = reject;
                reader.readAsText(file);
            } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, {type: 'array'});
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const result = XLSX.utils.sheet_to_json(worksheet, {header: 1, raw: false, defval: ''});
                    
                    const [headers, ...rows] = result;
                    const jsonResult = rows.map(row => {
                        let obj = {};
                        headers.forEach((header, index) => {
                            obj[header] = convertToString(row[index]);
                        });
                        return obj;
                    });
                    
                    resolve(jsonResult);
                };
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            } else {
                reject(new Error('Formato de arquivo não suportado'));
            }
        });
    };

    /** Function que atua na exibição de valores do progress */
    const controlProgressInterval = () => {
        if (progVal == 80) {
            progVal = 0;
            clearInterval(progressInterval);
            return;
        }
        progVal += 2;
        setProgress(progVal);
    }

    const changeHandler = async (event) => {
        const fileEv = event.target.files[0];

        if (!fileEv) {
            alert.error("Nenhum arquivo selecionado!");
            setFile(null);
            return;
        }

        setFile(fileEv);
        setProgress(0);
        try {
            const result = await readFile(fileEv);
            setFileData(result);
        } catch (error) {
            alert.error("Erro ao ler o arquivo: " + error.message);
            setFile(null);
        }
    };

    const sendData = async () => {
        try {
            setIsSending(true);
            setProgress(0);
            progressInterval = setInterval(controlProgressInterval, 500);

            const result = await ImportacaoApiService.updateColaborador(fileData);

            if (!result.data.result) {
                throw new ErrorRequestService();
            }

            const { success, fail } = result.data.result;

            setProgress(100);
            setImported(success);

            const arrErr = [];
            for (const [key, value] of Object.entries(fail)) {
                arrErr.push({
                    'Colaborador': key,
                    'Erros': value
                })
            }
            setFailed(arrErr)

            const successCount = Object.entries(success).length;
            if (!!successCount) {
                alert.success(`Atualizados ${successCount} com sucesso`);
            }
            
            const failCount = Object.entries(fail).length;
            if (!!failCount) {
                alert.error(`Ocorreu ${failCount} falhas`);
            }

        } catch (err) {
            setProgress(0);
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            clearInterval(progressInterval);
            setIsSending(false);
        }
    };

    const actions = [
        {
            label: isSending ? "Importando..." : "Importar",
            icon: isSending ? <FaSpinner /> : <FaUpload />,
            color: "success",
            type: "button",
            disabled: !file || isSending || (progress == 100),
            onClick: sendData,
        },
    ];

    const title = `Atualizar colaboradores`;

    return (
        <PageContainer title={title} actions={actions}>
            
            <Uploader
                accept=".csv,.xlsx,.xls"
                onChange={changeHandler}
                label="Selecione um arquivo XLS, XLSX, ou CSV"
            />

            {(isSending || (progress > 0)) &&
                <LoadingProgress
                    value={progress}
                    altProgressText={`${progress}%`}
                    finishText="Upload Concluído!"
                />
            }

            <br />
            <br />

            {failed && (
                <p>
                    <p>
                        <h3>{ failed.length } registros com falhas na importação</h3>
                    </p>
                    
                    {failed.map(item => (
                        <>
                            <strong>CPF</strong>: { item.Colaborador }
                            <p>
                                { item.Erros.map(item => <>{ item }<br /></>) }
                            </p>
                            <hr />
                        </>
                    )) }
                </p>
            )}
            
        </PageContainer>
    );
}

export default AtualizarColaboradoresImport;
