export const getFormFields = async () => {
    return {
        groups: [
            {
                header: 'Informações Cadastrais',
                fields: [
                    {
                        label: 'Nome do Squad',
                        placeholder: '',
                        name: 'nome',
                        type: 'text',
                        size: 6,
                        max: 10,
                    },
                    {
                        label: 'Custo total do squad',
                        placeholder: '',
                        name: 'custo_total',
                        type: 'currency',
                        size: 6,
                        max: 10,
                        readOnly: true,
                    },
                ]

            },
        ]
    };
}

const SimulacaoEditTeamInfo = { getFormFields };

export default SimulacaoEditTeamInfo;
