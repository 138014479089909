/**
 * Informações das colunas para lista
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   dynamicClass: function,
 *   style: string
 * }>}
*/
export const columns = [
    {
        label: '',
        name: 'checkbox',
        width: '1%',
        labelAlternative: true
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Key',
        name: 'key',
        width: '10%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Nome',
        name: 'name',
        width: '30%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Categoria',
        name: 'category',
        width: '10%'
    },
    {
        label: 'Líder',
        name: 'lead',
        width: '15%'
    },
    {
        label: 'Última atualização',
        name: 'lastIssueUpdateTime',
        width: '15%'
    },
    
];


const FuncaoListInfo = { columns };

export default FuncaoListInfo;
