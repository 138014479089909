import React, { useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FaPen, FaSave } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FormBuilder } from '../../../components/FormBuilder';
import PageContainer from '../../../components/PageContainer';
import Loading from '../../../components/Loading';
import useAlertNotification from '../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../services/errorRequest';
import FechamentoApiService from '../../../services/api/FechamentoApiService';
import FechamentoValidation from '../../../services/validation/FechamentoValidation';
import UtilService from '../../../services/util';
import FechamentoViewInfo from './FechamentoViewInfo';
import { FechamentoViewHelpModal } from './FechamentoViewHelpModal';

function FechamentoView({ extraActions=[] }) {
    // CONTEXT
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const alert = useAlertNotification();

    // PROPS
    const { label, dateInicio, dateFim } = location.state || {};
    const { id } = match.params;

    // STATE
    const [origData, setOrginData] = useState(null);
    const [formFields, setFormFields] = useState({});
    const [formData, setFormData] = useState(null);
    const [edit, setEdit] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    const isFormEdited = () => {
        const jsonOrigData = JSON.stringify(origData);
        const jsonFormData = JSON.stringify(formData);
        return (edit) && (jsonOrigData !== jsonFormData);
    }

    const askForLeave = (cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            const msg = 'Foram feitas alterações!\nDeseja realmente sair e perdê-las?';
            const leave = window.confirm(msg);

            if (leave) cbPositive();
            else cbNegative();
        }
        return false;
    }

    const saveForm = async () => {
        try {
            // validar form
            const validation = await FechamentoValidation.validate(formData);
            if (!validation.isValid) {
                alert.error("Erros no formulário!");
                return;
            }

            // se ok, enviar p/ API
            const resp = await FechamentoApiService.updateOne(formData.id, formData);
            if (resp.data.result) {
                setIsEdited(false);
                setEdit(false);
                alert.success("Dados do fechamento atualizados!");
                const url = `${match.url.replace(`/${id}`, '')}`;
                history.replace(url, location.state);
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    }

    const toggleEdit = () => {
        if (isEdited) {
            askForLeave(() => {
                setFormData(origData);
                setEdit(!edit);
            });
            return;
        }
        setEdit(!edit);
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                setFormData(origData);
                setEdit(!edit);
            });
            return;
        }

        if (edit) {
            setEdit(!edit);
            return;
        }

        history.goBack();
    }

    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setFormData(newform);
    }

    const onEdit = () => {
        if (isFormEdited() && !isEdited) setIsEdited(true);
    }

    const onStart = useCallback(async () => {
        try {
            setIsLoading(true);
            const [ formFields, formData ] = await Promise.all([
                FechamentoViewInfo.getFormFields(),
                FechamentoApiService.getByCod(id).then(resp => {
                    const { data } = resp.data;
                    return data;
                })
            ]);
            setFormFields( formFields );
            setFormData( formData );
            setOrginData( formData );
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    }, [id])

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(onEdit, [formData]);

    const actions = [
        ...( !edit ? [
            {
                label: 'Editar',
                icon: <FaPen />,
                color: 'primary',
                type: 'button',
                onClick: toggleEdit
            }
        ]:[
            {
                label: 'Salvar',
                icon: <FaSave />,
                color: 'success',
                type: 'button',
                onClick: saveForm
            }
        ]),
        {
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            onClick: handleCancel
        },
    ];

    // Adicionando botões extras, e desabilitando quando editar form
    extraActions.forEach(item => actions.push({...item, disabled: edit}))

    const dtIni = dateInicio && UtilService.apiDateToView(dateInicio);
    const dtFim = dateFim && UtilService.apiDateToView(dateFim);
    const title = `Fechamento ${label} ${(dtIni && dtFim) ? `(${dtIni} a ${dtFim})`: '' }`;

    return (
        <PageContainer
            title={title}
            actions={actions}
            infoButton={<FechamentoViewHelpModal />}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                : ( origData &&
                    <FormBuilder
                        formFields={formFields}
                        formData={formData}
                        onChange={onChange}
                        disabledForm={!edit}
                        initialValues={origData}
                        schema={FechamentoValidation.schema}
                    />
                )
            }

            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </PageContainer>
    )
}

export default FechamentoView;
