import * as Yup from 'yup';
import ValidationService from './validation';

// cod, cnpj, label, estado, cidade, ativo
const schema = Yup.object().shape({
    label: Yup
        .string()
        .required("Campo obrigatório.")
        .max(20, 'Label deve possuir no máximo 20 dígitos.'),

    cnpj: Yup
        .string()
        .required("Campo obrigatório")
        .test({
            name: 'cnpj-numbers',
            message: 'Deve conter apenas números',
            exclusive: false,
            test: (value) => {
                const isNull = [null, undefined].includes(value);
                if (isNull) return false;
                const regExp = /^[0-9]*$/g;
                const match = regExp.test(value);
                return match;
            }   
        })
        .min(11, "CNPJ deve possuir pelo menos 11 dígitos."),
                
    // estado: Yup
    //     .string("Preencher com uma das opções.")
    //     .required("Campo obrigatório.")
    //     .nullable()
    //     .transform(UtilService.yupTransform),
    
    // cidade: Yup
    //     .string()
    //     .required("Campo obrigatório"),

    ativo: Yup
        .bool()
        .required("Confirme se ativo ou inativo."),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const FilialValidation = {
    schema,
    validate,
    castValues
};

export default FilialValidation;