import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function SimulacaoResult({ formResult }) {

    const {
        funcao,
        beneficioTotal,
        custoHH,
        custoHHMeioPeriodo,
        custoTotal,
        impostoTotal,
        provisaoDecimoTerceiro,
        provisaoFerias,
        provisaoRescisao,
        salarioBruto,
        salarioFilial,
        salarioLiquido
    } = formResult;

    const transformLocaleString = (number) => {
        return parseFloat(number).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    return (
        <>
            <div className='d-flex justify-content-center'>
                <h4>Resultado da simulação</h4>
            </div>
            <Container>
                <Row>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Função: </strong>{funcao}</p>
                            <p><strong>Benefício total: </strong>{transformLocaleString(beneficioTotal)}</p>
                            <p className='m-0'><strong>Imposto total: </strong>{transformLocaleString(impostoTotal)}</p>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Salário bruto: </strong>{transformLocaleString(salarioBruto)}</p>
                            <p><strong>Salário filial: </strong>{transformLocaleString(salarioFilial)}</p>
                            <p className='m-0'><strong>Salário líquido: </strong>{transformLocaleString(salarioLiquido)}</p>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Custo total: </strong>{transformLocaleString(custoTotal)}</p>
                            <p><strong>Custo HH: </strong>{transformLocaleString(custoHH)}</p>
                            <p className='m-0'><strong>Custo HH meio período: </strong>{transformLocaleString(custoHHMeioPeriodo)}</p>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Provisão do décimo terceiro: </strong>{transformLocaleString(provisaoDecimoTerceiro)}</p>
                            <p><strong>Provisão das férias: </strong>{transformLocaleString(provisaoFerias)}</p>
                            <p className='m-0'><strong>Provisão da rescisão: </strong>{transformLocaleString(provisaoRescisao)}</p>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SimulacaoResult;
