import { combineReducers } from 'redux';

import alertNotificationReducer from './reducers/alertNotificationReducer';
import formReducer from './reducers/formReducer';
import userReducer from './reducers/userReducer';
import dadosReducer from './reducers/dadosReducer';
import fechamentoReducer from './reducers/fechamentoReducer';
import loadingReducer from './reducers/loadingReducer';

const reducer = combineReducers({
    notification: alertNotificationReducer,
    form: formReducer,
    user: userReducer,
    dados: dadosReducer,
    fechamento: fechamentoReducer,
    loading: loadingReducer,
});

export default reducer;