import {
    DADOS_BENEFICIOS,
    DADOS_BENEFICIO_VALOR,
    DADOS_DEPARTAMENTOS,
    DADOS_FILIAIS,
    DADOS_FUNCOES,
    DADOS_REGIMES
} from '../types';

const INITIAL = {};

/**
 * Reducer para guardar dados de beneficio, colaboradores, etc. 
 */
const dadosReducer = (state = INITIAL, actions) => {
    switch (actions.type) {
        case DADOS_FILIAIS:
            return {
                ...state, 
                filiais: actions.payload
            }
        
        case DADOS_DEPARTAMENTOS:
            return {
                ...state, 
                departamentos: actions.payload
            }
        
        case DADOS_FUNCOES:
            return {
                ...state, 
                funcoes: actions.payload
            }
        
        case DADOS_REGIMES:
            return {
                ...state, 
                regimes: actions.payload
            }

        case DADOS_BENEFICIOS:
            return {
                ...state, 
                beneficios: actions.payload
            }

        case DADOS_BENEFICIO_VALOR:
            return {
                ...state, 
                beneficioValor: actions.payload
            }

        default:
            return state
    }
}

export default dadosReducer;
