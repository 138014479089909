import { AxiosError } from 'axios';
import LocalStorageService from '../localStorage';
import store from '../../store';
import { USER_LOGOUT } from '../../store/types';

/**
 * Service simples para manipulação de erros de resposta da API
 */
class ErrorRequestService {
    messages = [];
    count = 0;
    
    /**
     * @param {AxiosError} errorResponse 
     */
    constructor (errorResponse) {
        const { response } = errorResponse || {};
        if (!response) {
            this.messages.push("Problema ao requsitar informações do servidor!");
            this.count++;
            return;
        }

        // UNAUTHORIZED USER
        const { status } = response;
        if ([401].includes(status)) {
            LocalStorageService.removeUser();
            store.dispatch({ type: USER_LOGOUT });
            alert("Sessão expirada. Efetue novamente o login");
            window.history.go('/');
            return;
        }

        const { errors } = response.data;
        if (!errors) {
            this.messages.push("Erro de resposta indefinido do servidor!");
            this.count++;
            return;
        }

        Object.keys(errors).forEach(key => {
            errors[key].forEach(item => {
                if (item.errorMessage) {
                    this.count++;
                    this.messages.push(item.errorMessage);
                }
            })
        })
    }
    
    getErrors = () => {
        if (this.count === 0) return '';
        return this.messages.join('\n');
    }
}

export default ErrorRequestService;