import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageContainer from "../../components/PageContainer";
import GraficoBarras from "../../components/GraficoBarras";
import useTheme from "../../context/ThemeContext";
import ErrorRequestService from "../../services/errorRequest";
import FechamentoApiService from "../../services/api/FechamentoApiService";
import useAlertNotification from "../../context/AlertNotificationContext";
import CardDashboard from "../../components/CardDashboard";
import useAuth from '../../context/AuthContext';
import "./DashboardPage.css";
import { id } from 'date-fns/locale';
import DepartamentoApiService from "../../services/api/DepartamentoApiService";
import GraficosCustoTotalBruto from "./GraficosCustoTotalBruto";

/** Pagina contendo informações iniciais */
function DashboardPage() {
    const themeCtxt = useTheme();
    const [fechamentos, setFechamentos] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [meanData, setMeanData] = useState([]);
    const [departamentoData, setDepartamentoData] = useState([]);
    const [custosDepartamentos, setCustosDepartamentos] = useState([]);
    const [custosDepartamentosFormated, setCustosDepartamentosFormated] = useState([]);

    const [selectedMonth, setSelectedMonth] = useState(undefined);
    const [selectedDep, setSelectedDep] = useState(undefined);
    const [selectedPeriodo, setSelectedPeriodo] = useState(6);
    const [selectedPeriodo2, setSelectedPeriodo2] = useState(6);
    const [selectedPeriodo3, setSelectedPeriodo3] = useState(6);

    const [isLoadingValorMedioHH, setIsLoadingValorMedioHH] = useState(true);
    const [isLoadindCustoTotalDep, setIsLoadingCustoTotalDep] = useState(true);
    const [isLoadingCustoTotalBrutoArea, setIsLoadingCustoTotalBrutoArea] = useState(true);


    const { user } = useAuth();

    // acesso aos recursos associados ao user(tokenParsed) via keycloak
    const resource_access = user?.roles['convert-api'] ? user?.roles['convert-api']['roles'] : [];

    const alert = useAlertNotification();

    const onStart = async () => {
        setIsLoadingCustoTotalDep(true);
        try {
            const respFechamento = await FechamentoApiService.getAll().then(r => r.data);
            const respDepartamento = await DepartamentoApiService.getAll().then(r => r.data);
            const respCustosDepartamentos = await FechamentoApiService
                .getTodosCustosDepartamentos().then((r) => r.data);

            setFechamentos(respFechamento.data);
            setDepartamentos(respDepartamento.data);
            setCustosDepartamentos(respCustosDepartamentos.result);

            setSelectedMonth(respFechamento.data[0].id);
            setSelectedDep(respDepartamento.data[0].nome);

            setDepartamentoData(respCustosDepartamentos.result
                .filter(d => d.nome === respDepartamento.data[0].nome));

            getTodosCustosDepartamentos(respCustosDepartamentos.result);
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            setIsLoadingCustoTotalDep(false);
        }
    };

    const getMediaHH = async () => {
        let dados = [
            // { Mês: "AGO-2020", "Valor médio HH": 19.03 },
        ];
        let erros = [];
        setIsLoadingValorMedioHH(true);
        for (const mes of fechamentos.map(fechamentos.pop, [...fechamentos])) {
            if (dados.length === 12) break;
            try {
                const resp = await FechamentoApiService.getMediaHH(mes.id).then(
                    (r) => r.data
                );

                dados.push({
                    Mês: mes.label,
                    "Valor médio HH": resp.result,
                });
            } catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                erros.push(mes.label);
            }
        }

        setIsLoadingValorMedioHH(false);
        alert.info(`Existem fechamentos sem cálculo:${erros.map(err => (`\n${err}`))}`);
        setMeanData(dados.reverse());
    };

    const getTotalDepartamentosArea = async () => {
        // { departamento: "Financeiro", custo: 80000 },
        const data = [];
        if (selectedMonth) {
            setIsLoadingCustoTotalBrutoArea(true);
            try {
                if (Array.isArray(selectedMonth) && !selectedMonth.length)
                    setSelectedMonth(0);

                const resp = await FechamentoApiService
                                    .getTotalDepartamentosArea(selectedMonth)
                                    .then((r) => r.data);

                resp.result.forEach((item) => {
                    data.push({
                        ...item,
                        custo: Number(item.custo),
                    });
                });

                setTotalData(data);
            } catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                alert.error(errorMsg);
            } finally {
                setIsLoadingCustoTotalBrutoArea(false)
            }
        }
    };

    const getTodosCustosDepartamentos = (arrCustos) => {
        const labels = []

        const result = arrCustos.reduce((newArr, data) => {
        if(labels.includes(data.label)) {
            newArr[labels.indexOf(data.label)] = {
            ...newArr[labels.indexOf(data.label)],
            [data.nome]: data.custo,
            };
        } else {
            labels.push(data.label);
            newArr.push({
            name: data.label,
            [data.nome]: data.custo
            });
        }
        return newArr;
        }, [])

        setCustosDepartamentosFormated(result);
    }

    const getCustoTotalDepartamento = (departamentoNome) => {
        setSelectedDep(departamentoNome);
        setDepartamentoData(custosDepartamentos.filter(d => d.nome === departamentoNome));
    };

    const topMeanValue = useMemo(() => {
        if (meanData.length) {
            const topValue = meanData.reduce((result, item) => {
                if (item["Valor médio HH"] >= result["Valor médio HH"]) {
                    return item;
                }
                return result;
            });

            return {
                label: topValue["Mês"],
                value: topValue["Valor médio HH"],
            };
        }
        return undefined;
    }, [meanData]);

    const minorMeanValue = useMemo(() => {
        if (meanData.length) {
            const topValue = meanData.reduce((result, item) => {
                if (item["Valor médio HH"] <= result["Valor médio HH"]) {
                    return item;
                }
                return result;
            });

            return {
                label: topValue["Mês"],
                value: topValue["Valor médio HH"],
            };
        }
        return undefined;
    }, [meanData]);

    useEffect(() => {
        if (resource_access.some(item => item === 'dashboard'))
            onStart();
    }, []);

    useEffect(() => {
        if (fechamentos.length && resource_access.some(item => item === 'dashboard')) {
            getMediaHH();
            setSelectedMonth(fechamentos[0].id);
        }
    }, [fechamentos]);

    useEffect(() => {
        if (resource_access.some(item => item === 'dashboard'))
            getTotalDepartamentosArea();
    }, [selectedMonth]);
    

    return (
        !resource_access.some(item => item === 'dashboard') ? <></>:
        <PageContainer title="Dashboard">
            <Container className="dashboard">
                <Row className="m-auto">
                    <Col>
                        <CardDashboard
                            title="Valor médio HH"
                            maior={topMeanValue}
                            menor={minorMeanValue}
                        />
                    </Col>
                </Row>
                <Row className="m-auto">
                    <div className="graficosBarras">
                        <GraficoBarras
                            data={meanData.length <= selectedPeriodo ? meanData.slice(0, selectedPeriodo) : meanData.slice(meanData.length - selectedPeriodo, meanData.length)}
                            title={`Valor médio HH - último${selectedPeriodo > 1 ? 's' : ''} ${selectedPeriodo > 1 ? selectedPeriodo + ' meses' : 'mês'}`}
                            barKey="Mês"
                            dataKey="Valor médio HH"
                            layout="horizontal"
                            variant="blue"
                            loading={isLoadingValorMedioHH}
                        >
                            <div className="grafico2 selectbox">
                                <h5>Período de referência: </h5>
                                <select
                                    className="select grafico2"
                                    value={selectedPeriodo}
                                    onChange={(e) =>{
                                        setSelectedPeriodo(Number(e.target.value));
                                    }}
                                >
                                    <option value={3}>
                                    3 meses
                                    </option>
                                    <option value={6}>
                                    6 meses
                                    </option>
                                    <option value={9}>
                                    9 meses
                                    </option>
                                    <option value={12}>
                                    12 meses
                                    </option>
                                </select>
                            </div>
                        </GraficoBarras>

                        <GraficoBarras
                            data={departamentoData.length <= selectedPeriodo2
                                ? departamentoData.slice(0, selectedPeriodo2)
                                : departamentoData.slice(departamentoData.length - selectedPeriodo2, departamentoData.length)}
                            title={`Custo Total por Departamento - últimos ${selectedPeriodo2} meses`}
                            barKey="label"
                            dataKey="custo"
                            layout="horizontal"
                            variant="green"
                            loading={isLoadindCustoTotalDep}
                        >
                            <div className="grafico2 selectbox">
                                <h5>Período de referência: </h5>
                                <select
                                    className="select grafico2"
                                    value={selectedPeriodo2}
                                    onChange={(e) =>{
                                        setSelectedPeriodo2(Number(e.target.value));
                                    }}
                                >
                                    <option value={3}>
                                    3 meses
                                    </option>
                                    <option value={6}>
                                    6 meses
                                    </option>
                                    <option value={9}>
                                    9 meses
                                    </option>
                                    <option value={12}>
                                    12 meses
                                    </option>
                                </select>
                            </div>
                            <div className="grafico2 selectbox">
                                <h5>Departamento: </h5>
                                <select
                                    className="select grafico2"
                                    value={selectedDep}
                                    onChange={(e) =>
                                        getCustoTotalDepartamento(e.target.value)
                                    }
                                >
                                    {departamentos.map((item) => (
                                        <option value={item.nome} key={item.cod}>
                                            {item.nome}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </GraficoBarras>
                    </div>
                </Row>
                <Row className="m-auto w-100">
                    <Col className="mb-4">
                        <GraficoBarras
                            data={custosDepartamentosFormated.length <= selectedPeriodo3
                                ? custosDepartamentosFormated.slice(0, selectedPeriodo3)
                                : custosDepartamentosFormated.slice(custosDepartamentosFormated.length - selectedPeriodo3, custosDepartamentosFormated.length)}
                            title={`Custo Total dos departamentos - últimos ${selectedPeriodo3} meses`}
                            barKey="name"
                            dataKey="custo"
                            layout="horizontal"
                            loading={isLoadindCustoTotalDep}
                            multibarras
                        >
                            <div className="grafico2 selectbox">
                                <h5>Período de referência: </h5>
                                <select
                                    className="select grafico2"
                                    value={selectedPeriodo3}
                                    onChange={(e) =>{
                                        setSelectedPeriodo3(Number(e.target.value));
                                    }}
                                >
                                    <option value={3}>
                                    3 meses
                                    </option>
                                    <option value={6}>
                                    6 meses
                                    </option>
                                    <option value={9}>
                                    9 meses
                                    </option>
                                    <option value={12}>
                                    12 meses
                                    </option>
                                </select>
                            </div>
                        </GraficoBarras>
                    </Col>
                </Row>
                <GraficosCustoTotalBruto
                    fechamentos={fechamentos}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    isLoading={isLoadingCustoTotalBrutoArea}
                    totalData={totalData}
                />
            </Container>
        </PageContainer>
    );
}

export default DashboardPage;
