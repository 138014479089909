import React from 'react';
import { Route, Switch } from 'react-router-dom';

// PAGES
import FiliaisPage from './FiliaisPage';
import FilialNew from './FilialNew';
import FilialView from './FilialView';

function Filial(props) {
    const { match } = props;

    return (
        <Switch>
            <Route path={match.path} exact component={FiliaisPage} />
            <Route path={`${match.path}/new`} component={FilialNew} />
            <Route path={`${match.path}/:cod`} component={FilialView} />
            
        </Switch>
    )
}

export default Filial;
