import { USER_LOGIN, USER_LOGOUT } from '../types';

const INITIAL = {
    user: null,
    token: null
}

/**
 * Reducer com dados para usuário
 */
const userReducer = (state=INITIAL, action) => {
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token
            }

        case USER_LOGOUT:
            return INITIAL;

        default:
            return state;
    }

}

export default userReducer;