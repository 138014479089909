import { Container } from 'react-bootstrap';
import { RiPieChartFill } from 'react-icons/ri';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import "./GraficoPie.css";

function GraficoPie(props) {
    const { title, data, dataKey, variant, formatterTooltip, styles } = props;
    const colors = {
        blue: "#00a5da",
        purple: "#6265ea",
        orange: "#e98800",
        green: "#00ac23",
        red: "#aa001b",
    };
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#9b5de5', '#FF8042', '#386641', '#ff4d6d', '#15616d', '#d08c60', '#3a6ea5'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const formatterLabel = (value, name, props) => {
        if (typeof formatterTooltip !== 'undefined') {
            value = parseFloat(value).toFixed(2).toLocaleString('pt-br')
            return `${value}${formatterTooltip}`;
        }
        return value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    return (
        <Container className="graph-container" style={styles}>
            <div className="graph-title">
                {title && <h4><RiPieChartFill style={{ color: "#555" }} /> {title}</h4>}
                {data.map((item, index) => (
                    <div key={`name-${index}`} className="d-flex align-items-center">
                        <div style={{ backgroundColor: `${variant ? colors[variant] : COLORS[index % COLORS.length]}`, width: "10px", height: "10px"}}></div>
                        <span className="ml-2 graph-projets">{item.name}: {formatterLabel(item[dataKey])}</span>
                    </div>
                ))}
            </div>
            <div className="graph">
                <PieChart width={400} height={400}>
                    <Pie
                        data={data}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={130}
                        fill="#8884d8"
                        dataKey={dataKey}
                        isAnimationActive={false}
                        cx={200}
                        cy={200}
                    >
                        {data.map((entry, index) => {
                            return <Cell key={`cell-${index}`} fill={variant ? colors[variant] : COLORS[index % COLORS.length]} />
                        })}
                    </Pie>

                    <Tooltip
                        formatter={(value, name, props) => formatterLabel(value, name, props)}
                    />
                </PieChart>
            </div>
        </Container>
    );
}

export default GraficoPie;
