import * as Yup from 'yup';
import UtilService from '../util';
import ValidationService from './validation';

// cod, sigla, nome, descricao, ativo
const schema = Yup.object().shape({
    nome: Yup
        .string()
        .required("Campo obrigatório.")
        .max(60, "Nome deve possuir no máximo 60 dígitos."),

    cbo: Yup
        .string()
        .required("Campo obrigatório")
        .test({
            name: 'cbo-numbers',
            message: 'Deve conter apenas números',
            exclusive: false,
            test: UtilService.yupTestIsNumber  
        })
        .max(10, "CBO deve possuir no máximo 10 dígitos."),
    
    descricao: Yup
        .string()
        .max(300, "Limite máximo de 300 caracteres."),

    ativo: Yup
        .bool()
        .required("Confirme se ativo ou inativo."),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const FuncaoValidation = {
    schema,
    validate,
    castValues
};

export default FuncaoValidation;