import React, { useState, useEffect, useCallback } from 'react';
import { FaPen, FaRegCalendar, FaRegCalendarCheck, FaSearch, FaTrash, FaSave } from 'react-icons/fa';
import { Button, ButtonGroup, Dropdown, Form, InputGroup } from 'react-bootstrap';

import PageContainer from '../../../components/PageContainer';
import ListLayout from '../../../components/ListLayout';
import { columns } from './MargemListInfo';
import Loading from '../../../components/Loading';
import CustomModal from '../../../components/CustomModal';
import useAlertNotification from "../../../context/AlertNotificationContext";

import MargemDetail from './MargemDetail';

import ErrorRequestService from '../../../services/errorRequest';
import ProjetosApiService from '../../../services/api/ProjetosApiService';
import UtilService from '../../../services/util';

import useAuth from '../../../context/AuthContext';
import { MargemListHelpModal } from './MargemListHelpModal';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { BsThreeDotsVertical, BsFillPencilFill, BsFillTrash3Fill, BsEye } from "react-icons/bs";
import { MdClose } from 'react-icons/md';

const INITIAL_PAGEPARAMS = {
    orderBy: 'cod',
    orderDir: 'asc'
};

const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function MargemList() {
    const { user } = useAuth();
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();

    // STATE
    const [rowsData, setRowsData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);
    // FUNCTIONS
    /** Define o clique sobre um registro da lista */
    const rowClick = (e, item) => {
        const auxDadosLinha = e.target.parentElement.className
        const auxDadosLinhaParent = e.target.parentElement.parentElement.className;

        const excludeClasses = [
            "d-flex container-botoes-opcoes",
            "icone-menu"
          ];
        
        if(!excludeClasses.includes(auxDadosLinhaParent) && !excludeClasses.includes(auxDadosLinha)) {
            
            CustomModal.show({
                title: item.nome,
                body: (
                    <MargemDetail
                        onCancel={() => CustomModal.hide()}
                        onSave={() => CustomModal.hide()}
                        props={item}
                    />
                )
            })
        }
    }

    

    const modalDeleteMargin = (e, item) => {
            CustomModal.show({
                body: `Tem certeza que deseja excluir o relatório ${item.nome} ?`,
                footer: (
                    <>
                        <Button variant="outline-success" onClick={() => deleteMargin(item.id)}>Sim</Button>
                        <Button variant="outline-danger" onClick={() => CustomModal.hide()}>Cancelar</Button>
                    </>
                )
            })
    }

    const deleteMargin = async (itemId) => {
        const { email } = user;

        setIsLoading(true);

        try {
            const resp = (await ProjetosApiService.deleteMargem(email, {
                id: itemId,
                ativo: false
            }));
            getDataList();
            CustomModal.hide();
            alert.success(resp.data.message);
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert.error(errorMsg);

        } finally {
            setIsLoading(false);
        }
    }

    /** Adiciona click e atributos ao header */
    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? { ordered: `${waySign}` }
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    const BodyAlterName = ({item}) => {
        const [alterName, setAlterName] = useState(item.nome);
        const [errors, setErrors] = useState({});

        const postAlterName = async (obj) => {
            const relatorioExistente = await ProjetosApiService.getNameMargem(alterName.trim());
    
            if (relatorioExistente.data.found) {
                setErrors({ alterName: "Já existe um relatório com este nome." });
                document.getElementById("alterName").focus();
                return;
            }
            try {
                const resp = await ProjetosApiService.updateNameMargem(obj.id, {"nome": alterName});
                CustomModal.hide();
                getDataList();
            } catch (error) {
                console.error("Não foi possível atualizar o nome do relatório", error);
                alert.error("Não foi possível alterar o nome do relatório.")
            }
        }

        return (
            <>
                <Form.Group>
                    <Form.Control
                        placeholder="Digite o novo nome do relatório..."
                        aria-label="Nome"
                        id="alterName"
                        name='alterName'
                        type='text'
                        value={alterName}
                        onChange={(e) => {
                            const { value } = e.target;
                            setAlterName(value);
                            setErrors({});
                        }}
                        isInvalid= {!!errors.alterName}
                    />
                    <Form.Control.Feedback type="invalid">
                        { errors.alterName }
                    </Form.Control.Feedback>
                </Form.Group>

                <div className='mt-3 d-flex justify-content-end'>
                    <Button variant="outline-info" onClick={() => postAlterName(item)}>
                        <FaSave /> Salvar
                    </Button>
                    <Button variant="outline-danger" onClick={() => CustomModal.hide()}>
                        <MdClose /> Cancelar
                    </Button>
                </div>

            </>
        )
    }

    const FuncionalidadesButtons = ({ item }) => {

        const redirectToCompleteView = (item) => {
            history.push(`${match.url}/${item.id}`);
        }

        const alterTitle = (item) => {

            CustomModal.show({
                title: "Deseja alterar o nome do relatório ?",
                body: < BodyAlterName item={item}/>,
            })
        }

        return (
            <div className="d-flex container-botoes-opcoes">
                <BsEye className="icone-menu" style={{ fill: "#17a2b8", marginRight:"16px" }} onClick={() => redirectToCompleteView(item)}/>
                <BsFillPencilFill className="icone-menu" style={{ fill: "#28a745", marginRight:"16px" }} onClick={() => alterTitle(item)} />
                <BsFillTrash3Fill className="icone-menu" style={{ fill: "#dc3545" }} onClick={(e) => modalDeleteMargin(e, item)}/>
            </div>
        )
    }

    /** Adequa os dados ao formato para lista */
    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    onClick: (e) => rowClick(e, item),
                    data: columns.map(col => {
                        // ---------------- cor/icone bloqueio ----------------
                        if (col.name === 'bloqueio') {
                            return {
                                value: (item[col.name] ? <FaRegCalendarCheck /> : <FaRegCalendar />),
                                className: col.dynamicClass && col.dynamicClass(item[col.name]),
                                attr: {
                                    title: `${item[col.name] ? '' : 'Não '}Bloqueado`,
                                    'data-toggle': 'tooltip',
                                }
                            }
                        }
                        // ---------------- formato date dd/mm/yyyy ----------------
                        if (['dataInicio', 'dataFim'].includes(col.name)) {
                            return {
                                value: UtilService.apiDateToView(item[col.name]),
                                className: col.className
                            }
                        }
                        item["menu_opcoes"] = <FuncionalidadesButtons item={item}/>;
                        return {
                            value: item[col.name],
                            className: col.className
                        }
                    })
                }
                dataList.push(row);
            });
        return dataList;
    }

    /* Filtra a lista de dados */
    const filterList = (params) => {
        const { column, filter } = params;

        if (!column || !filter) {
            setFilteredData(rowsData);
            return
        }

        const filtered = rowsData.filter(item => {
            const lwData = String(item[column]).toLowerCase();
            const lwFilter = String(filter).toLowerCase();

            return lwData.includes(lwFilter);
        });
        setFilteredData(filtered);
    }

    /** Traz as funcoes cadastradas no database  */
    const getDataList = useCallback(async () => {
        const { email } = user;

        setIsLoading(true);

        try {
            const resp = (await ProjetosApiService.getAll(email)).data;
            setRowsData(resp.data);
            setFilteredData(resp.data);

        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert.error(errorMsg);

        } finally {
            setIsLoading(false);
        }
    }, []);


    // EFFECTS
    useEffect(() => {
        if (!rowsData) getDataList();
    }, [rowsData, getDataList]);

    const pageActions = [];

    const title = 'Relatórios';

    return (
        <PageContainer
            title={title}
            actions={pageActions}
            infoButton={<MargemListHelpModal />}
        >
            {isLoading
                ? <Loading message="Carregando..." />
                :
                <div id="tabela-meus-relatorios-de-margem">
                    <ListLayout
                        columns={getListHeader()}
                        rows={defineDataList()}
                        onSubmitFilter={filterList}
                    />
                </div>
            }
            <CustomModal />
        </PageContainer>
    )
}

export default MargemList;
