 import React, { useEffect, useState } from "react"
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import TableFuncaoExistente from "./TableFuncaoExistente";
import UtilService from "../../../../services/util";
import { FaPen, FaSave, FaTrash } from "react-icons/fa";
import { MdClose } from "react-icons/md";

function FuncaoDetail({
    item,
    handleFuncao,
    dataFuncoes,
    onCancel,
    currentTab,
    rowsDataFuncoes,
    deleteHoraFuncao,
    edit
}) {
    const [formData, setFormData] = useState({ tipoFuncao: "", horas: {}, taxaCustoVenda: '0,00' })
    const [editForm, setEditForm] = useState(false)
    const [errors, setErrors] = useState({})
    const [showExistentes, setShowExistentes] = useState(false)

    const dataNotThisTab = rowsDataFuncoes.filter(item => !item.horas.hasOwnProperty(currentTab))

    const mask = (value) => {
        value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

        const options = { minimumFractionDigits: 2 }

        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )

        return result
    }

    const handleSave = () => {
        const newErrors = {}

        if (!formData.tipoFuncao) newErrors.tipoFuncao = "Campo obrigatório"

        if (!formData.horas[currentTab] || formData.horas[currentTab] === "0,00") {
            newErrors.horas = "Campo obrigatório"
        }

        if (formData.taxaCustoVenda === "0,00") newErrors.taxaCustoVenda = "Campo obrigatório"

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            return;
        }

        handleFuncao(formData);
    }

    const onChangeData = (event) => {
        if (event.target.name === "horas") {
            const inputValue = event.target.value

            setFormData({ ...formData, horas: { ...formData.horas, [currentTab]: mask(inputValue) } })
        }

        if (event.target.name === "tipoFuncao") {
            const nomeFuncao = dataFuncoes.find(item => Number(item.cod) === Number(event.target.value))?.nome

            setFormData({ ...formData, [event.target.name]: event.target.value, nomeFuncao })
        }

        if (event.target.name === "taxaCustoVenda") {
            const value = mask(event.target.value)

            setFormData({ ...formData, [event.target.name]: value })
        }

        setErrors({})
    }

    const changeFuncaoExistente = (item) => {
        setFormData(item)
        setShowExistentes(false)
    }

    const onStart = () => {
        const formDataItem = item ||
            {
                tipoFuncao: "",
                horas: { [currentTab]: "0,00" },
                taxaCustoVenda: '0,00'
            }

        setFormData(formDataItem);
        setEditForm(!!item)
    }

    useEffect(onStart, [item])

    return (
        <Form>
            { !showExistentes ? (
                <>
                    <Row>
                        <Col>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control
                                as="select"
                                name="tipoFuncao"
                                value={formData.tipoFuncao}
                                onChange={onChangeData}
                                disabled={editForm}
                                isInvalid={!!errors?.tipoFuncao}
                            >
                                <option value="">...</option>
                                {dataFuncoes
                                    .sort((a, b) => UtilService.sortArrCallback(a, b, 'nome'))
                                    .map(item => (
                                        <option key={item.cod} value={item.cod}>
                                            {`${item.cbo} - ${item.nome}`}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors?.tipoFuncao}
                            </Form.Control.Feedback>
                        </Col>

                        <Col>
                            <Form.Label>Horas</Form.Label>
                            <Form.Control
                                type="text"
                                name="horas"
                                value={formData.horas[currentTab]}
                                onChange={onChangeData}
                                disabled={editForm}
                                isInvalid={!!errors?.horas}
                                maxLength={6}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.horas}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            <Form.Label>Taxa de custo da venda</Form.Label>
                            <Form.Control
                                type="text"
                                name="taxaCustoVenda"
                                value={formData.taxaCustoVenda}
                                onChange={onChangeData}
                                disabled={editForm}
                                isInvalid={!!errors?.taxaCustoVenda}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.taxaCustoVenda}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                </>
            ) : (
                <TableFuncaoExistente
                    dataNotThisTab={dataNotThisTab}
                    changeFuncaoExistente={changeFuncaoExistente}

                />
            )}
            { edit ?
                item ? (
                    <div className="mt-3 d-flex justify-content-end">
                        { editForm ? (
                            <ButtonGroup>
                                <Button
                                    variant="outline-info"
                                    onClick={() => setEditForm(false)}
                                    disabled={!edit}
                                >
                                    <FaPen /> Editar
                                </Button>
                                <Button
                                    variant="outline-danger"
                                    onClick={() => deleteHoraFuncao(item?.id)}
                                    disabled={!edit}
                                >
                                    <FaTrash /> Excluir
                                </Button>
                            </ButtonGroup>
                        ) : (
                            <ButtonGroup>
                                <Button
                                    variant="outline-success"
                                    onClick={handleSave}
                                >
                                    <FaSave /> Salvar
                                </Button>
                                <Button
                                    variant="outline-danger"
                                    onClick={() => setEditForm(true)}
                                >
                                    <MdClose /> Cancelar
                                </Button>
                            </ButtonGroup>
                        )}
                    </div>
                ) : (
                    <div className={`mt-3 d-flex justify-content-${dataNotThisTab.length <= 0 ? 'end' : 'between'}`}>
                        { dataNotThisTab.length > 0 && (
                            <Button variant="outline-info" onClick={() => setShowExistentes(!showExistentes)}>
                                { showExistentes ? "Voltar" : "Adicionar um de outro mês" }
                            </Button>
                        )}

                        { !showExistentes && (
                            <ButtonGroup>
                                <Button variant="outline-success" onClick={handleSave}>Salvar</Button>
                                <Button variant="outline-danger" onClick={onCancel}>Cancelar</Button>
                            </ButtonGroup>
                        )}
                    </div>
                )
                : null
            }
        </Form>
    )
}

export default FuncaoDetail;
