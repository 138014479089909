import FilialApiService from "../../api/FilialApiService";

const tipoApresentacaoOptions = [
    { label: '...', value: '' },
    { label: 'Sintético', value: 'sintetico' },
    { label: 'Analítico', value: 'analitico' }
];

export const formFieldInfo = async () => {
    const filiaisData = await (FilialApiService.getAll()).then(resp => resp.data);

    return {
        groups:[
            {
                header: '',
                fields: [
                    {
                        label: 'Apresentação',
                        name: 'apresentacao',
                        type: 'select',
                        options: tipoApresentacaoOptions,
                        size: 4,
                    },
                    {
                        label: 'Filial',
                        placeholder: 'Ex: Vertigo Rio',
                        name: 'filialCod',
                        type: 'select',
                        size: 3,
                        options: [
                            { value: '0', label: '--- Todas ---' },
                            ...(filiaisData.data.map(item => ({
                                    value: item.cod,
                                    label: item.label
                                })
                            )),
                        ],
                        defaultValue: 0,
                    },
                ]
            },
        ]
    }
}

export default formFieldInfo;
