import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiArrowUpLeft, FiArrowUpRight, FiHelpCircle } from 'react-icons/fi';
import '../../../components/HelpModal/HelpModal.css';

function ProjetoViewHelpModal() {
    const [show, setShow] = useState(false);
    const [isSteps, setIsSteps] = useState(1);

    const handleClose = () => {
        setIsSteps(1);
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const getStepsView = (step) => {
        switch (step) {
            case 1: return (
                <Modal.Body >
                    <p className="description-text-info">
                        No Projeto mostra os colaboradores que estão no projeto, as tarefas realizadas, as horas trabalhadas no projeto.
                    </p>
                    <div className="icon-arrow-left">
                        <FiArrowUpLeft size={25} />
                    </div>
                </Modal.Body>
            );
            case 2: return (
                <Modal.Body>
                    <p className="description-text-info">
                        Se quiser calcular a margem do projeto, clique do botão que está no canto superior direito.
                        <br></br><br></br>
                        Para calcular a margem, deve inserir os valores dos impostos e os possíveis valores da venda e os custos indiretos e clicar no botão Calcular.
                        <br></br><br></br>
                        Após calcular será mostrado o Sumário Executivo onde mostra o resumo do calculo. Para salvar esse calculo, insira um nome para o relatório e clique no botão para salvar.
                        <br></br><br></br>
                        O Relatório salvo estará disponível no <b>Relatório</b> que fica dentro do <b>Projetos</b>.
                    </p>
                    <div className="icon-arrow-right">
                        <FiArrowUpRight size={25} />
                    </div>
                </Modal.Body>
            );
            default: return ""
        }
    }

    const handleNext = () => {
        if (isSteps !== 2)
            setIsSteps(isSteps + 1)
    }

    const handlePrevious = () => {
        if (isSteps !== 1)
            setIsSteps(isSteps - 1)
    }

    return (
        <>
            <button onClick={() => handleShow(true)} className="button-info">
                <FiHelpCircle size={17} />
            </button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>O que é os Projetos</Modal.Title>
                </Modal.Header>

                {getStepsView(isSteps)}

                <Modal.Footer>
                    {isSteps !== 1 && (
                        <Button variant="outline-primary" onClick={handlePrevious}>
                            Voltar
                        </Button>
                    )}

                    {isSteps !== 2 && (
                        <Button variant="outline-primary" onClick={handleNext}>
                            Próximo
                        </Button>
                    )}

                    <Button variant="outline-danger" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { ProjetoViewHelpModal }
