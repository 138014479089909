import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import RelatorioComparativoView from './RelatorioComparativoView';
import RelatoriosMainPage from './RelatoriosMainPage';
import HistoricoCustosProjetosClientesView from './HistoricoCustosProjetosClientesView'
import ComparativoFaturamentoView from './ComparativoFaturamentoView';
import ResultadoAnualProjeto from './ResultadoAnualProjetoView';
import RelatorioCapacidadeView from './RelatorioCapacidadeView';
import RelatorioCapacidadeProjetoView from './RelatorioCapacidadeProjetoView';

function RelatoriosDelivery() {
    const match = useRouteMatch();
    
    return (
        <Switch>
        <Route path={`${match.path}`} exact component={RelatoriosMainPage} />
        <Route path={`${match.path}/comparativo/:key-:ano`} exact component={RelatorioComparativoView} />
        <Route path={`${match.path}/historico-custos`} exact component={HistoricoCustosProjetosClientesView} />
        <Route path={`${match.path}/comparativo-faturamento/:key-:ano`} exact component={ComparativoFaturamentoView} />
        <Route path={`${match.path}/resultado-anual/:key-:ano`} exact component={ResultadoAnualProjeto} />
        <Route path={`${match.path}/relatorio-capacidade`} exact component={RelatorioCapacidadeView} />
        <Route path={`${match.path}/relatorio-capacidade-projeto`} exact component={RelatorioCapacidadeProjetoView} />
        <Redirect path="*" to="/" />
    </Switch>
    )
}

export default RelatoriosDelivery;
