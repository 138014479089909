import api from "./api";

const BASE_ENDPOINT = "/fechamento";

/** Traz uma lista dos fechamentos cadastradss */
function getAll() {
    return api.get(`${BASE_ENDPOINT}/list`);
}

/**
 * Traz dados de um fechamento pelo seu id
 * @param {number | string} id do fechamento
 */
function getByCod(id) {
    return api.get(`${BASE_ENDPOINT}/${id}`);
}

/** Cria um novo fechamento no database */
function createNew(form) {
    return api.post(`${BASE_ENDPOINT}/create`, { ...form });
}

/** Atualiza os dados de um fechamento */
function updateOne(cod, form) {
    return api.put(`${BASE_ENDPOINT}/update/${cod}`, { ...form });
}

/**
 * Calcula o custo hora dos colaboradores, dentro de um fechamento
 * @param {number | string} id do fechamento
 */
function calculoCustoHora(id, partTimeColab) {
    return api.post(`${BASE_ENDPOINT}/custo-hh/${id}`, { partTimeColab });
}

/**
 * Lista o calculo do custo hora dos colaboradores, dentro de um fechamento
 * @param {number | string} id do fechamento
 */
function getCustoHH(id) {
    return api.get(`${BASE_ENDPOINT}/custo-hh/${id}`);
}

/**
 * Lista o a média do custo hora dos colaboradores por mês, dentro de um fechamento
 * @param {number | string} id do fechamento
 */
function getMediaHH(id) {
    return api.get(`${BASE_ENDPOINT}/custo-hh/media/${id}`);
}

/**
 * Lista o a média do custo hora dos colaboradores por mês, separados por departamento, dentro de um fechamento
 * @param {number | string} id do fechamento
 */
function getTotalDepartamentosArea(id) {
    return api.get(`${BASE_ENDPOINT}/custo/todosDepartamentoArea/${id ? id : 0}`);
}

/** Lista todos custos de todos departamentos dos últimos 12 fechamentos */
function getTodosCustosDepartamentos() {
    return api.get(`${BASE_ENDPOINT}/custo/departamentos`);
}

const FechamentoApiService = {
    getAll,
    getByCod,
    createNew,
    updateOne,
    getCustoHH,
    getMediaHH,
    getTotalDepartamentosArea,
    calculoCustoHora,
    getTodosCustosDepartamentos
};

export default FechamentoApiService;
