import ErrorRequestService from "../errorRequest";
import RelatoriosInfo from "./RelatoriosInfo";
import {AxiosResponse} from "axios";

/**
 * Classe que modela informações de relatórios
 */
class RelatorioService {
    /** @type {string} */
    templateName;
    /** @type {string} */
    description;
    /** @type {Object} */
    filterFields;
    /** @type {Object} */
    filterValidation;
    /** @type {Object} */
    reportData;
    /** @type {"portrait" | "landscape"} */
    orientation;
    /** @type {"sintetico" | "analitico"} */
    apresentacao;
    /** @type {Promise<AxiosResponse>} */
    apiRequestService;
    
    async init(templateName) {
        try {
            const { formFieldInfo, filterValidation, description, orientation, apiRequest } = RelatoriosInfo[templateName];
            
            this.templateName = String(templateName);
            this.filterFields = await formFieldInfo();
            this.filterValidation = filterValidation;
            this.description = description;
            this.orientation = orientation || 'portrait';
            this.apiRequestService = apiRequest;
            this.apresentacao = 'sintetico';
        }
        catch (err) {
            const error = new ErrorRequestService(err);
            const errMsg = error.getErrors();
            console.log(errMsg);
        }
    }

    /**
     * Retorna os campos para filtro de dados do relatório
     * @returns 
     */
    getFilterFields = () => {
        if (this.filterFields) return this.filterFields;
        return {};
    }

    /**
     * Define a forma de apresentação dos dados
     * @param {"sintetico" | "analitico"} apresentacao 
     */
    setApresentacao(apresentacao = 'sintetico') {
        this.apresentacao = apresentacao;
    }

    /**
     * Retorna o relatório pronto para ser plotado
     * @param {{}} filterData objeto chave e valor dos filtros aplicados
     * @returns 
     */
    getReportData = async (filterData) => {
        try{
            const resp = await this.apiRequestService(filterData).then(r => r.data);
            this.reportData = resp.data;
        }
        catch (err) {
            const error = new ErrorRequestService(err);
            const errMsg = error.getErrors();
            console.log(errMsg);
        }
    }

}

export default RelatorioService;