import { 
    ALERT_NOTIFICATION_ADD, 
    ALERT_NOTIFICATION_REMOVE 
} from "../types";

const INITIAL = {
    alerts: []
}

const alertNotificationReducer = (state = INITIAL, { type, payload }) => {
    
    const appendAlert = () => {
        const alerts = [...state.alerts];
        alerts.push(payload);
        return alerts;
    }

    switch (type) {
        case ALERT_NOTIFICATION_ADD:
            return {
                ...state,
                alerts: appendAlert()
            }
        
        case ALERT_NOTIFICATION_REMOVE:
            return {
                ...state,
                alerts: state.alerts.filter(item => item.id !== payload)
            }
        
        default:
           return state;
    }
}

export default alertNotificationReducer;