import * as Yup from 'yup';
import UtilService from '../util';
import ValidationService from './validation';

// id, dataInicio, dataFim, diasUteis, label, descricao, bloqueio
const schema = Yup.object().shape({
    label: Yup
        .string()
        .required("Campo obrigatório.")
        .max(60, "Nome deve possuir no máximo 60 dígitos."),

    dataInicio: Yup
        .date()
        .nullable()
        .transform(UtilService.yupTransform)
        .required("Campo obrigatório.")
        .max((new Date()), 'Não pode ser maior que hoje.'),

    dataFim: Yup
        .date()
        .nullable()
        .transform(UtilService.yupTransform)
        .required("Campo obrigatório.")
        .test({
            name: 'test-dateFim-greater-dateInicio',
            message: 'Data fim deve ser maior que data início',
            test: function(value) {
                const { dataInicio } = this.parent;
                return value.valueOf() > dataInicio.valueOf();
            }
        })
        .max((new Date()), 'Não pode ser maior que hoje.'),
                
    diasUteis: Yup
        .number()
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, )
        .default(0),

    descricao: Yup
        .string()
        .max(300, "Limite máximo de 300 caracteres."),

    bloqueio: Yup
        .bool()
        .required("Confirme se deve BLOQUEAR ou NÃO."),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const FechamentoValidation = {
    schema,
    validate,
    castValues
};

export default FechamentoValidation;