import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import ProjetosList from './ProjetosList';
import ProjetoView from './ProjetoView';
import MargemList from './MargemList';
import MargemView from './MargemView';
import ProjetoDashboard from './ProjetoDashboard';
import ProjetoPrevistoView from './ProjetoPrevistoView';

function Projetos() {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}`} exact component={ProjetosList} />
            <Route path={`${match.path}/:key`} exact component={ProjetoView} />
            <Route path={`${match.path}/compare/:key`} exact component={ProjetoDashboard} />
            <Route path={`${match.path}/margem/relatorios`} exact component={MargemList} />
            <Route path={`${match.path}/margem/relatorios/:id`} exact component={MargemView} />
            <Route path={`${match.path}/previsao/:key`} exact component={ProjetoPrevistoView} />

            <Redirect path="*" to="/" />
        </Switch>
    )
}

export default Projetos;
