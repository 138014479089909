import React from 'react';
import { Route, Switch } from 'react-router-dom';

// PAGES
import FuncaoList from './FuncaoList';
import FuncaoNew from './FuncaoNew';
import FuncaoView from './FuncaoView';

function Funcao(props) {
    const { match } = props;

    return (
        <Switch>
            <Route path={match.path} exact component={FuncaoList} />
            <Route path={`${match.path}/new`} exact component={FuncaoNew} />
            <Route path={`${match.path}/:cod`} component={FuncaoView} />
            
        </Switch>
    )
}

export default Funcao;
