import api from "./api";

const BASE_ENDPOINT = '/filial';

/** Traz uma lista das filiais cadastradss */
function getAll() {
    return api.get(`${BASE_ENDPOINT}/list`);
}

/**
 * Traz dados de uma filial pelo seu codigo, e se ativo/inativo
 * @param {number | string} cod codigo da filial
 * @param {number | string} ativo 1=ativo, 0=inativo
 */
function getByCod(cod, ativo) {
    return api.get(
        `${BASE_ENDPOINT}/${cod}`,{
        params: {
            ativo
        }
    });
}

/** Cria uma nova filial no database */
function createNew(form) {
    return api.post(
        `${BASE_ENDPOINT}/create`,
        {...form}
    );
}

/** Atualiza os dados de uma filial */
function updateOne(cod, form) {
    return api.put(`${BASE_ENDPOINT}/update/${cod}`, { ...form })
}

const FilialApiService = {
    getAll,
    getByCod,
    createNew,
    updateOne
}

export default FilialApiService;