import React, { useState } from 'react'
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Button, Image } from 'react-bootstrap';
import { FaSignInAlt } from 'react-icons/fa';
import useAuth from '../../../context/AuthContext';
import useAlertNotification from '../../../context/AlertNotificationContext';
import useTheme from '../../../context/ThemeContext';
import LoginValidation from '../../../services/validation/LoginValidation';
import UtilService from '../../../services/util';
import AuthApiService from '../../../services/api/AuthApiService';

import './LoginPage.css';

const INITIAL_PAGE_PATH = "/dashboard";

function LoginPage() {
    const title = "Login";
    UtilService.setTitle(title);

    // STATE
    const [isLoading, setIsLoading] = useState(false);

    // CONTEXT
    const { user, signIn } = useAuth();
    const alert = useAlertNotification();
    const themeContext = useTheme();
    const history = useHistory();
    const match = useRouteMatch();

    if (!!user) return <Redirect to={INITIAL_PAGE_PATH} />

    const onSubmit = async (form, actions) => {
        try {
            setIsLoading(true);
            const validation = await LoginValidation.validate(form);
            if (!validation.isValid) {
                const { errors } = validation;
                const errorsTxt = Object.keys(errors).map(key => errors[key]).join('\n');
                alert.error(errorsTxt);
                return;
            }

            const authorization = UtilService.encodeUtf8AsBase64(JSON.stringify(form));
            const response = await AuthApiService.sendLogin(authorization);
            if (!response.data) {
                alert.error("Problema ao efetuar login.");
                return;
            }

            const { errors, token, refresh_token, user } = response.data;

            if (errors) {
                const msgError = []
                Object.keys(errors).forEach(key => {
                    const errStr = errors[key].map(item => item.errorMessage).join('\n');
                    msgError.push(errStr);
                })
                console.log("[ERROR] [LoginPage.onSubmit]:", { errors, msgError });
                alert.error(msgError.join('\n'));
                return;
            }

            signIn({token, refresh_token, user});
            alert.success(`Bem-vindo ${user.name}!`);
            history.push(INITIAL_PAGE_PATH);
        }
        catch (error) {
            console.log("[ERROR] [LoginPage.onSubmit]:", error);
            alert.error("Erro ao efetuar o login.");
        }
        finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="login-form-container">
            <Image 
                className="d-none d-md-block"
                src={themeContext.logo} 
                style={{margin: "auto"}}
                width={260}
            />
            <p className="text-explain text-muted">
                Complete o formulário abaixo com seu nome de usuário e sua senha para entrar no ConVert.
            </p>
            <hr/>

            <Formik
                onSubmit={onSubmit}
                initialValues={{ usuario:'', senha:'' }}
                validationSchema={LoginValidation.schema}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                }) => (
                    <Form
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Form.Group controlId="fg-usuario">
                            <Form.Label>Usuário</Form.Label>
                            <Form.Control 
                                name="usuario"
                                type="text"
                                placeholder="Digite seu nome de usuário..."
                                onChange={handleChange}
                                onBlur={handleBlur('usuario')}
                                value={values.usuario}
                                isValid={(touched.usuario && !errors.usuario)}
                                isInvalid={(touched.usuario && !!errors.usuario)}
                                autoComplete="off"
                            />
                            <Form.Control.Feedback type="invalid">
                                { errors.usuario }
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="fg-senha">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                name="senha"
                                type="password"
                                placeholder="Digite sua senha..."
                                onChange={handleChange}
                                onBlur={handleBlur('senha')}
                                value={values.senha}
                                isValid={(touched.senha && !errors.senha)}
                                isInvalid={(touched.senha && !!errors.senha)}
                                autoComplete="off"
                            />
                            <Form.Control.Feedback type="invalid">
                                { errors.senha }
                            </Form.Control.Feedback>
                        </Form.Group>

                        <hr/>
                        
                        <Button 
                            className="form-button button-submit"
                            type="submit"
                            disabled={isLoading}
                        >
                            { isLoading
                                ? <span>Entrando...</span>
                                : (<><FaSignInAlt /> Entrar</>)
                            }
                        </Button>
                    </Form>
                )}
            </Formik>

            <div className="links-container">
                <Link to={`${match.url}/esqueci`}>
                    Esqueci minha senha
                </Link>
                <Link to={`${match.url}/cadastrar`}>
                    Solicitar cadastro
                </Link>
            </div>
        </div>
    )
}

export default LoginPage;
