import React, { useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import { FaPen, FaPlus, FaRedo, FaSave, FaTrash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FormBuilder } from '../../../components/FormBuilder';
import PageContainer from '../../../components/PageContainer';
import Loading from '../../../components/Loading';
import ErrorRequestService from '../../../services/errorRequest';
import useAlertNotification from '../../../context/AlertNotificationContext';
import MargemViewInfo, { columnsCustosIndiretos } from './MargemViewInfo';
import useAuth from '../../../context/AuthContext';
import * as R from 'ramda'
import MargemResult from './MargemResult';
import ProjetoRelatorioValidation from '../../../services/validation/ProjetoRelatorioValidation';
import ListLayout from '../../../components/ListLayout';
import { Button } from 'react-bootstrap';
import CustomModal from '../../../components/CustomModal';
import CustoIndiretoModal from './CustoIndiretoModal';
import SimulacaoMargemApiService from '../../../services/api/SimulacaoMargemApiService';

const MOEDA = {
    "BRL": "Real",
    "USD": "Dólar",
    "EUR": "Euro"
}

const TIPO_CUSTO = {
    "INFRA_CLOUD": "Infraestrutura em Cloud",
    "OUTROS_SOFTWARES": "Outros softwares",
    "HORAS_EXTRAS": "Horas extras",
    "FORNECEDORES_TERCEIROS": "Fornecedores terceiros",
    "OUTROS": "Outros",
}

function SimulacoesResultView(props) {
    // CONTEXT
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();

    const { user } = useAuth();
    const { email } = user;

    // // PROPS
    // const { sigla, nome } = location.state || {};
    const { id } = match.params;

    // // STATE
    const [origData, setOrginData] = useState(null);
    const [formFields, setFormFields] = useState({});
    const [formData, setFormData] = useState(null);
    const [formResult, setFormResult] = useState(null);
    const [edit, setEdit] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCalculate, setIsCalculate] = useState(false);
    const [custosIndiretos, setCustosIndiretos] = useState([]);

    // FUNCTIONS
    const isFormEdited = () => {
        const jsonOrigData = JSON.stringify(origData);
        const jsonFormData = JSON.stringify(formData);
        return (edit) && (jsonOrigData !== jsonFormData);
    }

    const askForLeave = (cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            const msg = 'Foram feitas alterações!\nDeseja realmente sair e perdê-las?';
            const leave = window.confirm(msg);

            if (leave) cbPositive();
            else cbNegative();
        }
        return false;
    }

    const saveForm = async () => {
        try {

            if (isEdited && !isCalculate) {
                alert.error("É necessário recalcular antes de salvar");
                return;
            }

            const formCustosIndiretos = custosIndiretos.map(custo => {
                return {
                    descricao: custo.descricao,
                    valor: custo.valor,
                    cotacao: custo.cotacao,
                    moeda: custo.moeda,
                    tipo_custo: custo.tipo_custo
                }
            })

            // se ok, enviar p/ API
            const resp = await SimulacaoMargemApiService.updateOneSimulacaoMargem(email, {
                receitaBruta: formResult["receita_bruta"],
                impostos: formResult["impostos"],
                receitaLiquida: formResult["receita_liquida"],
                custoTotalVenda: formResult["custo_venda"],
                custoHH: formResult["custo_hora_colaborador"],
                resultadoBruto: formResult["resultado_bruto"],
                margemBruta: formResult["margem_bruta"],
                despesasOperacionais: formResult["despesas_operacionais"],
                rv: formResult["rv"],
                rateioAdministrativo: formResult["rateio_administrativo"],
                resultadoAntesIRCSLL: formResult["resultado_ir_csll"],
                impostoSemResultado: formResult["imposto_sem_resultado"],
                resultadoLiquido: formResult["resultado_liquido"],
                margemLiquida: formResult["margem_liquida"],
                nomeRelatorio: formData["nome"],
                projeto: formResult["projeto"],
                periodoInicio: formResult["periodo_inicial"],
                periodoFim: formResult["periodo_fim"],
                totalCustoIndireto: formResult["total_custo_indireto"],
                custosIndiretos: formCustosIndiretos,
                id
            });

            if (resp.data) {
                setIsEdited(false);
                setEdit(false);
                alert.success("Dados do relatório atualizados com sucesso!");
                history.push( match.url.replace(`/${id}`,''));
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    }

    const toggleEdit = () => {
        if (isEdited) {
            askForLeave(() => {
                setFormData(origData);
                setEdit(!edit);
            });
            return;
        }
        setEdit(!edit);
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                setFormData(origData);
                setEdit(!edit);
            });
            return;
        }
        if (edit) {
            setEdit(!edit);
            return;
        }
        history.goBack();
    }

    const onChange = (ev) => {
        const { value, name } = ev.target;

        const newform = {...formData, [name]: value};
        setIsCalculate(false);
        setFormData(newform);
    }

    const onEdit = () => {
        if (isFormEdited() && !isEdited) setIsEdited(true);
    }

    const onStart = () => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const [ formFields, formData ] = await Promise.all([
                    MargemViewInfo.getFormFields(),
                    SimulacaoMargemApiService.getMargemById(email, id).then(resp => {
                        const { data } = resp.data;
                        return data;
                    })
                ]);

                setFormFields( formFields );
                setFormResult(formData);
                setCustosIndiretos(formData.custosIndiretos.map((custo, index) => { return {...custo, id: index } }));

                const form = {
                    nome: formData['nome'],
                    custoHH: formData['custo_hora_colaborador'],
                    receitaBruta: formData["receita_bruta"],
                    baseImpostoSemReceitaBruto: R.divide(formData["impostos"], formData["receita_bruta"]),
                    baseImpostoSemResultado: R.divide(formData["imposto_sem_resultado"], formData["receita_bruta"]),
                    baseRateioAdministrativo: R.divide(formData["rateio_administrativo"], formData["receita_bruta"]),
                    baseRV: R.divide(formData["rv"], formData["receita_bruta"]),
                    periodoInicio: formData["periodo_inicial"],
                    periodoFim: formData["periodo_fim"],
                    projeto: formData.projeto
                }
                setOrginData(form);
                setFormData(form);
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert.error(errorMsg);
            }
            finally {
                setIsLoading(false);
            }
        }
        getData();
    }

    const changeCustosIndiretos = (custo) => {
        let newCustos = []
        if (custosIndiretos.findIndex(item => item.id === custo.id) === -1) {
            newCustos = [...custosIndiretos, custo];
        } else {
            newCustos = custosIndiretos.map(item => item.id === custo.id ? custo : item)
        }

        setCustosIndiretos(newCustos);
        CustomModal.hide();
        setIsCalculate(false);
        setIsEdited(true);
    }

    const removeCustoIndireto = id => {
        const newCustosIndiretos = custosIndiretos.filter(custo => custo.id !== id);
        setCustosIndiretos(newCustosIndiretos);
        setIsCalculate(false);
        setIsEdited(true);
    }

    const mask = (value) => {
        const cleanedValue = value.toString().replace(/[^\d.,]/g, '');

        const formattedValue = parseFloat(cleanedValue).toFixed(2);

        const result = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 })
            .format(formattedValue);

        return result;
    }

    const getCustosIndiretosRows = useCallback(() => {
        const rows = custosIndiretos || [];
        const dataList = [];
        rows.forEach(item => {
            const row = {
                data: columnsCustosIndiretos.map(col => {
                    if (col.name === "cotacao" || col.name === "valor") {
                        return {
                            value: mask(item[col.name]),
                            className: col.className
                        }
                    }

                    if (col.name === "moeda") {
                        return {
                            value: MOEDA[item[col.name]],
                            className: col.className
                        }
                    }

                    if (col.name === "tipo_custo") {
                        return {
                            value: TIPO_CUSTO[item[col.name]],
                            className: col.className
                        }
                    }

                    if (col.name === "acoes") {
                        return {
                            value: (
                                <>
                                    <Button
                                        variant="outline-primary"
                                        className="mr-2"
                                        onClick={() => modalCustoIndireto(item)}
                                        disabled={!edit}
                                    >
                                        <FaPen />
                                    </Button>
                                    {custosIndiretos.length > 1 &&
                                        <Button
                                            variant="outline-danger"
                                            onClick={() => removeCustoIndireto(item.id)}
                                            disabled={!edit}
                                        >
                                            <FaTrash />
                                        </Button>
                                    }
                                </>
                            ),
                            className: col.className
                        }
                    }

                    return {
                        value: item[col.name],
                        className: col.className
                    }
                })
            };
            dataList.push(row);
        })
        return dataList;
    }, [custosIndiretos, edit])

    const modalCustoIndireto = (item) => {
        CustomModal.show({
            title: 'Adicionar novo custo indireto',
            body:
                <CustoIndiretoModal
                    salvarCusto={changeCustosIndiretos}
                    id={custosIndiretos.length}
                    item={item && { ...item, cotacao: parseFloat(item?.cotacao) }}
                />
        })
    }

    const calculate = async () => {
        try {

            const validation = await ProjetoRelatorioValidation.validate(formData, ProjetoRelatorioValidation.schema)

            if (!validation.isValid) {
                alert.error("Formulário possui erros");
                return;
            }

            if (formData.receitaBruta <= 0) {
                alert.error("Informe uma valor de venda maior que zero");
                return;
            }

            const formCustosIndiretos = custosIndiretos.map(custo => {
                return {
                    descricao: custo.descricao,
                    valor: custo.valor,
                    cotacao: custo.cotacao,
                    moeda: custo.moeda,
                    tipo_custo: custo.tipo_custo
                }
            })

            const resp = await SimulacaoMargemApiService.calculateMargem({ ...formData, custosIndiretos: formCustosIndiretos})
            const result = resp.data.data[0]

            setFormResult({
                ...formResult,
                "receita_bruta": formData.receitaBruta,
                impostos: result.totalImpostos,
                "receita_liquida": result.totalReceitaLiquida,
                "custo_venda": result.custoTotalVenda,
                "total_custo_indireto": result.totalCustoIndireto,
                "custo_hora_colaborador": formData.custoHH,
                "resultado_bruto": result.totalResultadoBruto,
                "margem_bruta": result.totalMargemBruta.toFixed(4),
                "despesas_operacionais": result.totalDespesasOperacionais,
                rv: result.totalRv,
                "rateio_administrativo": result.totalRateioAdministrativo.toFixed(2),
                "resultado_ir_csll": result.totalAntesIRCSLL,
                "imposto_sem_resultado": result.totalImpostoSemResultado.toFixed(2),
                "resultado_liquido": result.totalResultadoLiquido,
                "margem_liquida": result.totalMargemLiquida.toFixed(4),
            })

            setIsCalculate(true)
        } catch (err) {
            console.log("[ERROR] [MargemForm.calculaMargem]:", err);
            alert.error("Erro ao calcular a margem do projeto.");

        }
    }

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(onEdit, [formData]);

    const actions = [
        ...( !edit ? [
            {
                label: 'Editar',
                icon: <FaPen />,
                color: 'primary',
                type: 'button',
                onClick: toggleEdit
            }
        ]:[
            {
                label: 'Salvar',
                icon: <FaSave />,
                color: 'success',
                type: 'button',
                onClick: saveForm
            }
        ]),
        {
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            onClick: handleCancel
        },
        {
            label: 'Recalcular',
            icon: <FaRedo /> ,
            color: 'info',
            type: 'button',
            onClick: calculate
        }
    ]

    const title = `Resultado ${id}`;

    return (
        <PageContainer
            title={title}
            actions={actions}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                : ( origData &&
                    (
                        <>
                            <div>
                                <p><strong>Projeto(s):</strong> {formData.projeto}</p>
                                <p><strong>Período:</strong> {formData.periodoInicio} a {formData.periodoFim}</p>
                                <hr />
                            </div>

                            <FormBuilder
                                formFields={formFields}
                                formData={formData}
                                onChange={onChange}
                                disabledForm={!edit}
                                initialValues={origData}
                                schema={ProjetoRelatorioValidation.schema}
                            />
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <h5 className="m-0">Custos Indiretos</h5>
                                <Button
                                    variant="outline-primary"
                                    onClick={() => modalCustoIndireto()}
                                    disabled={!edit}
                                >
                                    <FaPlus/> Adicionar custo
                                </Button>
                            </div>
                            <ListLayout
                                columns={MargemViewInfo.columnsCustosIndiretos}
                                rows={getCustosIndiretosRows()}
                            />
                            <MargemResult formResult={formResult} />
                            <CustomModal />
                        </>
                    )
                )
            }
            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </PageContainer>
    )
}

export default SimulacoesResultView;
