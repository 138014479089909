import * as Yup from 'yup';
import UtilService from '../../util';
import ValidationService from '../../validation/validation';

// cod, sigla, nome, descricao, ativo
const schema = Yup.object().shape({
    apresentacao: Yup
        .string("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform),

    filialCod: Yup
        .string("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform),

});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const filterValidation = {
    schema,
    validate,
    castValues
};

export default filterValidation;