import React from 'react';
import {
    FaTable,
    FaChartPie,
    FaCalculator,
    FaCode,
    FaFileContract,
    FaFileImport,
    FaFlask,
    FaClipboardList
} from 'react-icons/fa'

const navigation = [
    {
        label: 'Dashboard',
        href:  '/dashboard',
        icon: <FaChartPie />,
        key : 'dashboard',
        color: '#6265EA',
        order: 1
    },
    {
        label: 'Projetos',
        href:  '/projetos',
        icon: <FaCode />,
        key : 'projetos',
        color: '#8B22E8',
        order: 2
    },
    {
        label: 'Simulações',
        href: '/simulacoes',
        icon: <FaFlask />,
        key: 'simulacoes',
        color: '#e76f51',
        order: 3
    },
    {
        label: 'Fechamentos',
        href: '/fechamentos',
        icon: <FaCalculator />,
        key : 'fechamentos',
        color: 'red',
        order: 4
    },
    {
        label: 'Relatórios Delivery',
        href:  '/relatorios-delivery/',
        icon: <FaClipboardList />,
        key : 'relatorios-delivery',
        color: '#c7b108',
        order: 5
    },
    {
        label: 'Relatórios DP',
        href:  '/relatorios',
        icon: <FaFileContract />,
        key : 'relatorios',
        color: 'green',
        order: 6
    },
    {
        label: 'Cadastros',
        href:  '/cadastros',
        icon: <FaTable />,
        key : 'cadastros',
        color: '#77C3D0',
        order: 7
    },
    {
        label: 'Importações',
        href:  '/importacoes',
        icon: <FaFileImport />,
        key : 'importacoes',
        color: '#77C3D0',
        order: 8
    },
]

const subgroups = {
    'cadastros': [
        {
            label: 'Filial',
            href: '/filial',
            key : 'cadastros-filial',
            order: 1
        },
        {
            label: 'Colaboradores',
            href: '/colaboradores',
            key : 'cadastros-colaborador',
            order: 2
        },
        {
            label: 'Benefícios',
            href: '/beneficios',
            key : 'cadastros-beneficio',
            order: 3
        },
        {
            label: 'Funções',
            href: '/funcoes',
            key : 'cadastros-funcao',
            order: 4
        },
        {
            label: 'Departamentos',
            href: '/departamentos',
            key : 'cadastros-departamento',
            order: 5
        },
        {
            label: 'Regimes de contrato',
            href: '/regime-contrato',
            key : 'cadastros-regime-contrato',
            order: 6
        },
        {
            label: 'Impostos',
            href: '/impostos',
            key : 'cadastros-imposto',
            order: 7
        },
    ],
    'importacoes': [
        {
            label: 'Atualizar colaboradores',
            href: '/Atualizar-colaboradores',
            key: 'importacoes-atualizar-colaboradores',
            order: 3
        },
        {
            label: 'Novos colaboradores',
            href: '/colaboradores',
            key : 'importacoes-colaborador',
            order: 1
        },
        {
            label: 'Benefícios de colaboradores',
            href: '/beneficios-colaborador',
            key : 'importacoes-beneficio-colaborador',
            order: 2
        },
    ],
    'projetos': [
        {
            label: 'Detalhes',
            href: '/',
            key : 'projetos',
            order: 1
        },
        {
            label: 'Meus Relatórios de Margem',
            href: '/margem/relatorios',
            key : 'importacoes-beneficio-colaborador',
            order: 2
        },
    ],
    'simulacoes': [
        {
            label: 'Colaboradores',
            href: '/',
            key : 'simulacoes',
            order: 1
        },
        {
            label: 'Squads',
            href: '/my-teams',
            key : 'simulacao-squad',
            order: 2
        },
        {
            label: 'Resultados',
            href: '/results',
            key : 'simulacao-resultados',
            order: 3
        }
    ],

    // { // @TODO: Desativado p/ MVP. Implementação futura
    //     label: 'Lançamentos',
    //     href: '/lançamentos'
    // },

}

const NavigationData = {
    navigation,
    subgroups
};

export default NavigationData;
