import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiArrowDownLeft, FiArrowDownRight, FiArrowUpLeft, FiArrowUpRight, FiHelpCircle } from 'react-icons/fi';
import '../../../components/HelpModal/HelpModal.css';

function ProjetoDashboardHelpModal() {
    const [show, setShow] = useState(false);
    const [isSteps, setIsSteps] = useState(1);

    const handleClose = () => {
        setIsSteps(1);
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const getStepsView = (step) => {
        switch (step) {
            case 1: return (
                <Modal.Body >
                    <p className="description-text-info">
                        Neste Dashboard, você pode comparar entre os projetos que foram selecionados o total de horas que foram utilizados para desenvolver o projeto e o seu custo tanto billable como não billable filtrando por datas específicas.
                    </p>
                </Modal.Body>
            );
            case 2: return (
                <Modal.Body>
                    <p className="description-text-info">
                        Para alterar as datas, selecione as datas e clique no botão filtrar. O resultado da busca será mostrado logo a baixo nos gráficos.
                    </p>
                    <div className="icon-arrow-left">
                        <FiArrowDownLeft size={25} />
                    </div>
                </Modal.Body>
            );
            case 3: return (
                <Modal.Body>
                    <p className="description-text-info">
                        Para visualizar o total das horas e os custos, entre os gráficos, selecione na opção 'Gráfico Pizza' para alterar entre os gráficos.
                        <br></br><br></br>
                        Para visualizar entre as horas e os custos, selecione na opção 'Valor faturado'.
                    </p>
                    <div className="icon-arrow-right">
                        <FiArrowDownRight size={25} />
                    </div>
                </Modal.Body>
            );
            default: return ""
        }
    }

    const handleNext = () => {
        if (isSteps !== 3)
            setIsSteps(isSteps + 1)
    }

    const handlePrevious = () => {
        if (isSteps !== 1)
            setIsSteps(isSteps - 1)
    }

    return (
        <>
            <button onClick={() => handleShow(true)} className="button-info">
                <FiHelpCircle size={17} />
            </button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Projetos Dashboard</Modal.Title>
                </Modal.Header>

                {getStepsView(isSteps)}

                <Modal.Footer>
                    {isSteps !== 1 && (
                        <Button variant="outline-primary" onClick={handlePrevious}>
                            Voltar
                        </Button>
                    )}

                    {isSteps !== 3 && (
                        <Button variant="outline-primary" onClick={handleNext}>
                            Próximo
                        </Button>
                    )}

                    <Button variant="outline-danger" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProjetoDashboardHelpModal;
