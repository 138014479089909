/** 
 * Informações para os campos do form
 * @type {{
 *   groups: Array<{
 *      header: string,
 *      fields: Array<{
 *        label: string,
 *        placeholder: string,
 *        name: string,
 *        type: string,
 *        size: number,
 *        className: string
 *      }>
 *   }
 * }>} 
*/
export const getFormFields = (regimes) => {
    
    // Montagem das opções de regimes para escolha
    const regimeOptions = [...regimes.map(item => {
        return {
            value: item.cod,
            label: item.sigla
        }
    })]
    regimeOptions.unshift({value: '', label: '...'});

    return {
        groups: [
            {
                header: '',
                fields: [
                    {
                        label: 'Regime',
                        placeholder: '',
                        name: 'regime_cod',
                        type: 'select',
                        options: regimeOptions,
                        size: 4,
                    },
                ]
            },
            {
                header: 'Informações Cadastrais',
                shouldRender: (form) => {
                    return !['', null, undefined].includes(form.regime_cod);
                },
                fields: [
                    {
                        label: 'Código',
                        placeholder: '',
                        name: 'cod',
                        type: 'text',
                        size: 3,
                        // readOnly: true,
                        disabled: true,
                    },
                    {
                        label: 'Nome',
                        placeholder: '',
                        name: 'nome',
                        type: 'text',
                        size: 9,
                        max: 60,
                        // readOnly: true,
                        disabled: true,
                    },
                    {
                        label: 'Descrição',
                        placeholder: 'Digite aqui descrição, observações ou detalhes...',
                        name: 'descricao',
                        type: 'textarea',
                        defaultValue: '',
                        size: 12,
                        max: 300,
                        // readOnly: true,
                        disabled: true,
                    },
                ]
            },
        ]
    };
}

const RegimeImpostoFormNewInfo = { getFormFields };

export default RegimeImpostoFormNewInfo;
