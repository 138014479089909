import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Loading from "../../../../components/Loading";
import "./SeparateProject.css";

function SeparateProjectTest({ projects, onSepareProject }) {
    const [isLoading, setIsLoading] = useState(true);
    const [selectProjects, setSelectProjects] = useState(null);

    const handleAddMoreColumn = () => {
        const existProject = selectProjects !== null ? selectProjects : [projects];
        existProject.push(projects);

        changeProjectColumn(existProject);
    }

    const changeProjectColumn = (projectData) => {
        const dataWithCheck = projectData.map((projectItem, index) => {
            return projectItem.map((item) => {
                if (!item.hasOwnProperty('id')) {
                    return {
                        ...item,
                        id: `${item.name}-${index+1}`,
                        checked: false
                    }
                }

                return item
            })
        });

        setSelectProjects(dataWithCheck);
        setIsLoading(false);
    }

    const handleRemoveLastColumn = () => {
        const removed = selectProjects.filter((item, index) => index !== selectProjects.length - 1);

        setSelectProjects(removed);
    }

    const onChangeSelect = (projeto) => {
        const changed = selectProjects.map((project, index) => {
            const selected = projeto.id.substr(projeto.id.length - 1);

            if (Number(selected) === Number(index + 1)) {
                const changedProjets = project.map((item) => {
                    if (item.id === projeto.id) {
                        return {
                            ...item,
                            checked: !item.checked
                        }
                    }

                    return item;
                })

                return changedProjets;
            }

            return project;
        })

        setSelectProjects(changed);
    }

    const handleSepareProject = () => {
        onSepareProject(selectProjects);
    }

    useEffect(() => {
        setIsLoading(true);
        handleAddMoreColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects])

    return isLoading ? (<Loading />) : (
        <div className="separate-container">
            <div className="separate-radio">
                {selectProjects.map((projeto, index) => {
                    return (
                        <Form key={`form-${index}`} className="border-right border-left pl-3">
                            <span className="projectNumber">Grupo {index + 1}</span>
                            {projeto.map(item => {
                                return (
                                    <div key={item.id} className="mb-3">
                                        <Form.Check
                                            inline
                                            label={item.name}
                                            name={item.name}
                                            type="checkbox"
                                            checked={item.checked}
                                            onChange={() => onChangeSelect(item)}
                                        />
                                    </div>
                                )
                            })}
                        </Form>
                    )
                })}
            </div>
            <div className="buttons">
                {selectProjects.length > 2 && (
                    <Button
                        variant="outline-danger"
                        style={{ marginRight: 10 }}
                        onClick={handleRemoveLastColumn}
                    >
                        Remover última Separação
                    </Button>
                )}
                {selectProjects.length < 12 && (
                    <Button
                        variant="outline-info"
                        style={{ marginRight: 10 }}
                        onClick={handleAddMoreColumn}
                    >
                        Adicionar Separação
                    </Button>
                )}
                <Button variant="outline-success" onClick={handleSepareProject}>
                    Visualizar
                </Button>
            </div>
        </div>
    )
}

export default SeparateProjectTest;
