

/** 
 * Informações para os campos do form
 * @type {{
 *   groups: Array<{
 *      header: string,
 *      fields: Array<{
 *        label: string,
 *        placeholder: string,
 *        name: string,
 *        type: string,
 *        size: number,
 *        className: string
 *      }>
 *   }
 * }>} 
*/
export const getFormFields = () => {
    return {
        groups: [
            /* {
                header: '',
                shouldRender: (form) => {
                    return !['', null, undefined].includes(form.cod);
                },
                fields: [
                    {
                        label: 'Código',
                        placeholder: '',
                        name: 'cod',
                        type: 'text',
                        size: 3,
                        readOnly: true,
                        // hidden: true,
                    },
                ]
            }, */
            {
                fields: [
                    {
                        label: 'Sigla',
                        placeholder: 'Ex:',
                        name: 'sigla',
                        type: 'text',
                        size: 4,
                        max: 10,
                        readOnly: true
                    },
                    {
                        label: 'Nome',
                        placeholder: '',
                        name: 'nome',
                        type: 'text',
                        size: 8,
                        max: 60,
                        readOnly: true
                    }
                ]
            },
            {
                header: 'Valores Padrões', 
                fields: [
                    {
                        label: 'Valor Benefício Padrão',
                        placeholder: '',
                        name: 'valor',
                        type: 'currency',
                        size: 4,
                        readOnly: true,
                        defaultValue: 0
                    },
                    {
                        label: 'Período do Valor',
                        placeholder: '',
                        name: 'periodoValor',
                        type: 'select',
                        options: [
                            { label: '...' },
                            { label: 'Diário', value: 'dia' },
                            { label: 'Mensal', value: 'mes' },
                        ],
                        size: 3,
                        readOnly: true,
                    },
                ]
            },
            {
                fields: [
                    {
                        label: 'Valor Desconto Padrão',
                        placeholder: '',
                        name: 'valorDesc',
                        type: 'currency',
                        size: 4,
                        readOnly: true,
                        defaultValue: 0
                    },
                    {
                        label: 'Alíq.Desconto Padrão',
                        placeholder: '',
                        name: 'aliquotaDesc',
                        type: 'percentage',
                        size: 3,
                        readOnly: true,
                        defaultValue: 0
                    },
                    {
                        label: 'Base Aliq.Desconto',
                        placeholder: '',
                        name: 'baseAliqDesc',
                        type: 'select',
                        options: [
                            { label: '...' },
                            { label: 'Salário', value: 'salario' },
                            { label: 'Benefício', value: 'beneficio' },
                        ],
                        size: 3,
                        readOnly: true,
                    },
                ]
            },
            {
                header: 'Benefício Adicional e Subtração',
                fields: [
                    {
                        label: 'Valor Adicional Benefício',
                        placeholder: '',
                        name: 'valorBenefAdic',
                        type: 'currency',
                        size: 3,
                        defaultValue: 0
                    },
                    {
                        label: 'Alíq. Adicional Benefício',
                        placeholder: '',
                        name: 'aliqBenefAdic',
                        type: 'percentage',
                        size: 3,
                        defaultValue: 0
                    },
                    {
                        label: 'Valor Subtrai Benefício',
                        placeholder: '',
                        name: 'valorBenefSubtrai',
                        type: 'currency',
                        size: 3,
                        defaultValue: 0
                    },
                    {
                        label: 'Alíq. Subtrai Benefício',
                        placeholder: '',
                        name: 'aliqBenefSubtrai',
                        type: 'percentage',
                        size: 3,
                        defaultValue: 0
                    },
                ]
            },
            {
                header: 'Desconto Adicional e Subtração',
                fields: [
                    {
                        label: 'Valor Adicional Desconto',
                        placeholder: '',
                        name: 'valorDescAdic',
                        type: 'currency',
                        size: 3,
                        defaultValue: 0
                    },
                    {
                        label: 'Alíq. Adicional Desconto',
                        placeholder: '',
                        name: 'aliqDescAdic',
                        type: 'percentage',
                        size: 3,
                        defaultValue: 0
                    },
                    {
                        label: 'Valor Subtrai Desconto',
                        placeholder: '',
                        name: 'valorDescSubtrai',
                        type: 'currency',
                        size: 3,
                        defaultValue: 0
                    },
                    {
                        label: 'Alíq. Subtrai Desconto',
                        placeholder: '',
                        name: 'aliqDescSubtrai',
                        type: 'percentage',
                        size: 3,
                        defaultValue: 0
                    },
                ]
            }
        ]
    };
}

const BeneficioColabFormInfo = { getFormFields };

export default BeneficioColabFormInfo;
