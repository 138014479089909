
import React from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';

function TopActionButton({ label, icon, onClick, href, color, styleCustom, ...rest }) {
    return (
        <Button 
            onClick={onClick}
            variant={`outline-${color}`}
            style={styleCustom}
            {...href && {as: Link, to: href}}
            {...rest}
        >
            { icon } { label }
        </Button>
    );
}

function TopActions({ actions }) {
    return (
        <ButtonGroup className="topactions-btn-grp">
            { actions.map((action, k) => {
                return (
                    <TopActionButton 
                        key={`top-action-btn-${k}`}
                        {...action}
                    />
                );
            }) }
        </ButtonGroup>
    )
}

// DEFAULT PROPS
TopActions.defaultProps = {
    actions: []
}

TopActionButton.defaultProps = {
    label: '',
    icon: null,
    onClick: () => {},
    color: 'outline-primary',
    styleCustom: {}
}

// PROPTYPES
TopActions.propTypes = {
    actions: PropTypes.array
}

TopActionButton.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.instanceOf(Object),
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    styleCustom: PropTypes.object
}

export default TopActions;
