import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function SimulacaoResult({ formResult }) {
    const {
        funcao,
        beneficioTotal,
        custoHH,
        custoHHMeioPeriodo,
        custoTotal,
        impostoTotal,
        provisaoDecimoTerceiro,
        provisaoFerias,
        provisaoRescisao,
        salarioBruto,
        salarioFilial,
        salarioLiquido
    } = formResult;


    return (
        <>
            <div className='d-flex justify-content-center'>
                <h4>Resultado da simulação</h4>
            </div>
            <Container>
                <Row>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Função: </strong>{funcao}</p>
                            <p><strong>Benefício total: </strong>{beneficioTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            <p className='m-0'><strong>Imposto total: </strong>{impostoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Salário bruto: </strong>{salarioBruto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            <p><strong>Salário filial: </strong>{salarioFilial.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            <p className='m-0'><strong>Salário líquido: </strong>{salarioLiquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Custo total: </strong>{custoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            <p><strong>Custo HH: </strong>{custoHH.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            <p className='m-0'><strong>Custo HH meio período: </strong>{custoHHMeioPeriodo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-3 mt-3'>
                            <p><strong>Provisão do décimo terceiro: </strong>{provisaoDecimoTerceiro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            <p><strong>Provisão das férias: </strong>{provisaoFerias.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                            <p className='m-0'><strong>Provisão da rescisão: </strong>{provisaoRescisao.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SimulacaoResult;
