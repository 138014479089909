import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';

import './LoadingProgress.css';

const MIN = 0;
const MAX = 100;

function LoadingProgress({ 
    value, 
    altProgressText, 
    finishText,
    color
}) {
    // STATE
    const [text, setText] = useState(altProgressText || value);
    const [isAnimated, setIsAnimated] = useState(true);

    // FUNCTIONS
    const checkFinish = useCallback(() => {
        const finished = value >= MAX;
        setText(altProgressText || value);
        if (finished) {
            setText(finishText);
            setIsAnimated(!finished);
        }
    }, [value, finishText])

    // EFFECTS
    useEffect(checkFinish, [value]);

    return (
        <div className="loading-progress">
            <ProgressBar
                now={value}
                label={text}
                min={MIN}
                max={MAX}
                variant={color}
                animated={isAnimated}
                striped
            />
        </div>
    )
}

LoadingProgress.defaultProps = {
    altProgressText: undefined, 
    finishText: "Concluído",
    color: "danger",
}

LoadingProgress.propTypes = {
    value: PropTypes.number.isRequired, 
    altProgressText: PropTypes.string, 
    finishText: PropTypes.string,
    color: PropTypes.string,
}

export default LoadingProgress;

