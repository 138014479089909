import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowLeft, FaPrint } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading';
import NothingToShow from '../../../components/NothingToShow';
import PageContainer from '../../../components/PageContainer';
import useAlertNotification from '../../../context/AlertNotificationContext';
import RelatorioService from '../../../services/relatorio';

import './RelatorioViewPage.css';

/**
 * Renderiza a página de visualização do relatório
 */
function RelatorioViewPage() {
    // HOOKS
    const history = useHistory();
    const location = useLocation();
    const alert = useAlertNotification();
    
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));

    // STATE
    const [relatorio] = useState(new RelatorioService());
    const [reportData, setReportData] = useState(null);
    const [isReady, setIsReady] = useState(false);

    // FUNCTIONS
    const onStart = useCallback(async () => {
        try {
            const { path, title, ...formData } = searchParams;
            if (!path) throw new Error("Parâmetro 'path' de relatório não recebido");
            
            await relatorio.init(path);

            // enviando dados do filtro p/ API
            await relatorio.getReportData(formData);
            setReportData(relatorio.reportData);
        }
        catch (err) {
            const errMsg = String(err);
            console.log(errMsg);
            alert.error(errMsg);
            history.push('/');
        }
        finally {
            setIsReady(true);
        }
    }, [relatorio, alert]);

    // USEEFFECTS
    useEffect(onStart, []);

    return (
        <PageContainer title={searchParams.title}>
            
            {(!isReady) && <Loading />}

            {(isReady) && (<>
                <div className="print-bar">
                    <Button
                        variant="light"
                        onClick={() => history.goBack()}
                    >
                        <FaArrowLeft size={20} /> Voltar
                    </Button>
                    <Button
                        variant="light"
                        onClick={() => window.print()}
                    >
                        <FaPrint size={20} /> Imprimir
                    </Button>
                </div>

                {(!reportData)
                    ? <NothingToShow message="Não há dados a serem exibidos" /> 
                    : <div className="report-viewer">
                        <div class="report-container">
                            <div dangerouslySetInnerHTML={{ __html: reportData }} />
                        </div>
                    </div>
                }
            </>)}
        </PageContainer>
    );
}

export default RelatorioViewPage;

