import * as Yup from 'yup';
import UtilService from '../util';
import ValidationService from './validation';

// id, filialCod, benefCod, valor, valorDesc, aliquotaBenef, baseAliqBenef, aliquotaDesc,
// baseAliqDesc, valorFilial, aliquotaFilial, baseAliqFilial, updated
const schema = Yup.object().shape({

    filialCod: Yup 
        .number(),

    benefCod: Yup  
        .string(),

    valor: Yup
        .number("Preencher com um valor numérico.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser maior de 0,00')
        .default(0),

    periodoValor: Yup 
        .string()
        .required('Umas das opções deve ser informada.'),

    valorDesc: Yup 
        .number()
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser maior de 0,00')
        .default(0),

    aliquotaBenef: Yup 
        .number()
        .transform(UtilService.yupIntFixPercentage)
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .default(0),

    baseAliqBenef: Yup 
        .string()
        .test({
            name:'tem-aliq-benf',
            message: 'Base da aliquota deve ser informada',
            test: (value, context) => {
                if (context.parent.aliquotaBenef > 0) {
                    return !['', null, undefined].includes(value);
                }
                return true;
            }
        }),

    aliquotaDesc: Yup  
        .number()
        .transform(UtilService.yupIntFixPercentage)
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .default(0),

    // baseAliqDesc: Yup  
    //     .string()
    //     .required('Informe a base de desconto do colaborador')
    //     .test({
    //         name:'tem-aliq-benf',
    //         message: 'Base da aliquota deve ser informada',
    //         test: (value, context) => {
    //             if (context.parent.aliquotaDesc > 0) {
    //                 return !['', null, undefined].includes(value);
    //             }
    //             return true;
    //         }
    //     }),

    valorFilial: Yup   
        .number()
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor deve ser maior de 0,00')
        .default(0),

    aliquotaFilial: Yup
        .number()
        .min(0, 'Valor mínimo de 0%')
        .max(1, 'Valor máximo de 100%')
        .transform(UtilService.yupIntFixPercentage)
        .default(0),

    baseAliqFilial: Yup
        .string()
        .required('Informe a base de desconto da empresa')
        .test({
            name:'tem-aliq-benf',
            message: 'Base da aliquota deve ser informada',
            test: (value, context) => {
                if (context.parent.aliquotaFilial > 0) {
                    return !['', null, undefined].includes(value);
                }
                return true;
            }
        }),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const BeneficioValidation = {
    schema,
    validate,
    castValues
};

export default BeneficioValidation;