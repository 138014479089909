import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';

import CustomModal from '../../../../components/CustomModal';
import MargemForm from './MargemForm';
import CustosProjeto from "./CustosProjeto";
import * as R from "ramda";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { MdOutlineArrowBack } from "react-icons/md";

function HorasDetail(props) {
    const {
        isLoading,
        isLoadingRecalculoCustoTotal,
        dataProjectDetail,
        dataProjectCosts,
        changeCompareCustos,
        showTables,
        formData
    } = props;

    const match = useRouteMatch();

    const { key } = match.params;

    const numberKeys = key.split(",")

    const openModalMargemProjeto = () => {
        CustomModal.show({
            title: 'Cálculo de Margem do Projeto',
            body: (
                <MargemForm
                    onCancel={() => CustomModal.hide()}
                    onSave={() => CustomModal.hide()}
                    props={props}
                />
            ),
            isDrag: true
        })
    }

    const openModalCustosProjeto = () => {
        CustomModal.show({
            title: 'Custos do Projeto',
            body: (
                <CustosProjeto
                    props={props}
                    onCancel={() => CustomModal.hide()}
                />
            ),
            isDrag: true
        })
    }

    const getTotal = () => {
        let totalCosts = 0

        if (dataProjectCosts) {
            totalCosts = R.pipe(
                R.map(cost => parseFloat(cost.cotacao) !== 0 ?
                    R.multiply(parseFloat(cost.valor), parseFloat(cost.cotacao)) : parseFloat(cost.valor)),
                R.sum
            )(dataProjectCosts)

            totalCosts = R.sum([totalCosts, dataProjectDetail?.total])
        }

        return totalCosts.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }

    const [isInitialDateFirstDay, setIsInitialDateFirstDay] = useState(false);
    const [isFinalDateLastDay, setIsFinalDateLastDay] = useState(false);
    const [isMonthEquals, setIsMonthEquals] = useState(false);


    const verifyDateforMonth = (initialDate, finalDate) => {
        if(!isLoadingRecalculoCustoTotal) {
    
            const checkIfFirstDayOfMonth = (dateStr) => {
                if(typeof dateStr === "string") {
                    let [year, month, day] = dateStr.split('-')
                    return day == "01"
                }
            };

            const checkIfLastDayOfMonth = (dateStr) => {
                if(typeof dateStr == "string") {
                    const date = new Date(dateStr);
                    
                    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
                    return date.getDate()+1 === lastDay;
                }
            };

            const checkIfMonthInitialAndMonthFinal = (dateStrInitial, dateStrFinal) => {
                if(typeof dateStrInitial == "string" && typeof dateStrFinal == "string") {
                    let [yearIn, monthIn, dayIn] = dateStrInitial.split('-')
                    let [yearFn, monthFn, dayFn] = dateStrFinal.split('-')

                    return monthIn == monthFn;
                }
            };
        
            setIsInitialDateFirstDay(checkIfFirstDayOfMonth(initialDate));
            setIsFinalDateLastDay(checkIfLastDayOfMonth(finalDate));
            setIsMonthEquals(checkIfMonthInitialAndMonthFinal(initialDate, finalDate))
        }

    }

    useEffect(() => {
        verifyDateforMonth(formData.initialDate, formData.finalDate)
    }, [isLoadingRecalculoCustoTotal])

    return (
        <>
            <div style={{ display: "grid" }}>
                <strong>
                    Horas trabalhadas: {
                        !isLoading
                        ? dataProjectDetail?.timeSpentTotal.toLocaleString('pt-br')
                        : '...'
                    }
                </strong>

                <strong>
                    Horas faturáveis: {
                        !isLoading
                        ? dataProjectDetail?.timeSpentBillable.toLocaleString('pt-br')
                        : '...'
                    }
                </strong>

                <strong>
                    Custo Total HH: {
                        !isLoading || !isLoadingRecalculoCustoTotal
                        ? dataProjectDetail?.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                        : '...'
                    }
                </strong>

                <strong>
                    Custo Total: {
                        !isLoading || !isLoadingRecalculoCustoTotal
                        ? getTotal()
                        : '...'
                    }
                </strong>

                {
                    !isLoading &&
                        <div className="mt-2 d-flex flex-wrap" style={{ gap: "10px" }}>
                            <Button onClick={openModalMargemProjeto} disabled={dataProjectDetail.timeSpentUser.length <= 0}>
                                Margem do Projeto
                            </Button>

                            <Button onClick={openModalCustosProjeto} disabled={isInitialDateFirstDay && isFinalDateLastDay && isMonthEquals ? false : true}>
                                Adicionar Custos
                            </Button>
                            <Button onClick={changeCompareCustos} disabled={numberKeys.length == 1 && isInitialDateFirstDay && isFinalDateLastDay && isMonthEquals && showTables ? false : true}>
                                Comparar Custos
                            </Button>
                        </div>
                }
            </div>

        </>
    );
}

export default HorasDetail;
