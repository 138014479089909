import React, { useState, useEffect } from 'react'
import SimulacaoEditTeamInfo from './SimulacaoEditTeamInfo';
import { useRouteMatch, useHistory } from 'react-router-dom';
import ErrorRequestService from '../../../services/errorRequest';
import PageContainer from '../../../components/PageContainer';
import { FormBuilder } from '../../../components/FormBuilder';
import { FaMinus, FaPen, FaSave, FaTrash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import Loading from '../../../components/Loading';
import SquadApiService from '../../../services/api/SquadApiService';
import useAuth from '../../../context/AuthContext';
import { Button, ButtonGroup, Col, Row, Tab, Tabs } from 'react-bootstrap';
import ListLayout from '../../../components/ListLayout';
import { FaPlus } from 'react-icons/fa';
import { columns, columnsSprints } from './ListSquadViewInfo';
import SimulacaoApiService from '../../../services/api/SimulacaoApiService';
import useAlertNotification from '../../../context/AlertNotificationContext';
import * as R from 'ramda';

const INITIAL_PAGEPARAMS = {
    orderBy: 'id',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function SimulacaoTeamDetalhes(){
    // CONTEXT
    const { user } = useAuth();
    const { email } = user;
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();
    const { id } = match.params;


    const [isLoading, setIsLoading] = useState(true);
    const [originData, setOrginData] = useState(null);
    const [formFields, setFormFields] = useState({});
    const [formData, setFormData] = useState(null);
    const [edit, setEdit] = useState(false);
    const [squadName, setSquadName] = useState('');

    const [rowsData, setRowsData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [filteredDataMySquad, setFilteredDataMySquad] = useState([]);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isEdited, setIsEdited] = useState(false);
    const [tabs, setTabs] = useState([]);
    const [currentTab, setCurrentTab] = useState(0)

    const addNewColab = (item) => {
        const itemFound = filteredDataMySquad[currentTab].find(el => el.id === item.id)
        if (!itemFound) {
            item.quantidade = 1;
            setFilteredDataMySquad({
                ...filteredDataMySquad,
                [currentTab]: [ ...filteredDataMySquad[currentTab], item]
            });
        } else {
            item = itemFound;
            item.quantidade++;
        }
        setFormData({
            ...formData,
            "custo_total": (R.sum([parseFloat(formData["custo_total"]), parseFloat(item["custo_total"])])).toFixed(2)
        });
    }
    const removeSprint = () =>{
        let custoSprint = 0.0;

        setFilteredDataMySquad({ ...filteredDataMySquad, [tabs.length - 1]: [] });
        setTabs([...tabs].slice(0, -1));
        setCurrentTab(currentTab - 1);

        filteredDataMySquad[currentTab].map((item) =>{
            custoSprint += R.multiply(parseFloat(item['custo_total']), parseInt(item['quantidade']));
        })
        setFormData({
            ...formData,
            "custo_total": R.subtract(parseFloat(formData["custo_total"]), custoSprint).toFixed(2)
        });
        return;
    }
    const removeColab = (item) => {
        if (item.quantidade === 1) {
            setFilteredDataMySquad({
                ...filteredDataMySquad,
                [currentTab]: filteredDataMySquad[currentTab].filter(element => element !== item)
            });
        } else {
            item.quantidade--;
        }
        const custoTotal = R.subtract(parseFloat(formData["custo_total"]), parseFloat(item["custo_total"])).toFixed(2);
        setFormData({
            ...formData,
            "custo_total": custoTotal < 0.2 ? '0.00' : custoTotal 
        });
    }

    const getListHeader = (isSprint = false) => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        const columnsHeader = isSprint ? columnsSprints : columns;
        columnsHeader.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    data: columns.map(col => {
                        if (col.name !== "funcao_nome" && col.name !== "button" ) {
                            return {
                                value: parseFloat(item[col.name])
                                    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                className: col.className
                            }
                        }
                        if (col.name === 'button'  && edit) {
                            return {
                                value: <ButtonGroup size="sm">
                                        <Button onClick={() => addNewColab(item)} variant='primary ml-3 my-2' size="sm">
                                            <FaPlus/>
                                        </Button>
                                    </ButtonGroup>
                            }
                        }
                        return {
                            value: item[col.name],
                            className: col.className
                        }
                    })
                };
                dataList.push(row);
            });
        return dataList;
    }
    const defineDataListMySquad = (sprintNumber) => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const row = filteredDataMySquad[sprintNumber] || [];
        row.sort(sortRows)
            .forEach(item => {
                const row = {
                    data: columnsSprints.map(col => {
                        if (col.name !== "funcao_nome" && col.name !== "button" && col.name !== 'quantidade') {
                            return {
                                value: parseFloat(item[col.name])
                                    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                className: col.className
                            }
                        }
                        if (col.name === 'button' && edit) {
                            return {
                                value: <ButtonGroup size="sm">
                                        <Button onClick={() => removeColab(item)} variant='danger ml-3 my-2' size="sm">
                                            <FaMinus/>
                                        </Button>
                                    </ButtonGroup>
                            }
                        }
                        return {
                            value: item[col.name],
                            className: col.className
                        }
                    })
                };
                dataList.push(row);
            });
        return dataList;
    }
    const filterList = (params) => {
        const { column, filter } = params;

        if (!column || !filter) {
            setFilteredData(rowsData);
            return
        }

        const filtered = rowsData.filter(item => {
            const lwData = String(item[column]).toLowerCase();
            const lwFilter = String(filter).toLowerCase();

            return lwData.includes(lwFilter);
        });
        setFilteredData(filtered);
    }
    const saveForm = async () => {
        if (squadName === "") {
            alert.error("Nome do time não pode estar vazio");
            return;
        }

        const arrRemoveSprints = [];
        let sprint = - 1;
        let sprintRemove = Object.keys(filteredDataMySquad).length - 1;
        const arrSprints = Object.keys(filteredDataMySquad).reduce((arr, itemId) => {
            if (filteredDataMySquad[itemId].length === 0) {
                arrRemoveSprints.push(sprintRemove);
                sprintRemove--;

                return [...arr]
            }

            sprint++;
            return [...arr, {
                sprint,
                colabs: filteredDataMySquad[itemId].map(elemento => {
                    return { id: elemento.id, quantidade: elemento.quantidade }
                })
            }]
        }, [])

        try {
            await SquadApiService.updateSquadName(email, id, {"nome":  squadName});
            await SquadApiService.addNewColab({
                email,
                squadId: id,
                sprints: arrSprints
            })
            await SquadApiService.removeSprint({
                email,
                squadId: id,
                sprints: arrRemoveSprints
            })
            setIsEdited(false);
            setEdit(false);

            alert.success("Dados da simulação do time atualizados com sucesso!");
            history.push(match.url.replace(`/${id}`, ""));
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    }

    const askForLeave = (cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            const msg = 'Foram feitas alterações!\nDeseja realmente sair e perdê-las?';
            const leave = window.confirm(msg);

            if (leave) cbPositive();
            else cbNegative();
        }
        return false;
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                setFormData(originData);
                setEdit(!edit);
            });
            return;
        }
        if (edit) {
            setEdit(!edit);
            return;
        }
        history.goBack();
    }
    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setIsEdited(true);
        const newName = value;
        setSquadName(newName);
        setFormData(newform);
    }

    const toggleEdit = () => {
        if (isEdited) {
            askForLeave(() => {
                setFormData(formData);
                setEdit(!edit);
            });
            return;
        }
        setEdit(!edit);
    }
    const setSprintArray = (objColabs) =>{
        let newSprint = [];
        Object.keys(objColabs).map((sprint) =>{
            newSprint.push(sprint);
        })
        setTabs(newSprint);
       if(newSprint.length == 0){
           setFilteredDataMySquad({ ...filteredDataMySquad, [tabs.length]: [] });
           setTabs([ ...tabs, tabs.length ]);
           setCurrentTab(tabs.length);
       }

    }

    const onStart = () => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const formdata = await SquadApiService.getByCod(email, id).then(r => r.data.data[0].squad);
                const formFields = await SimulacaoEditTeamInfo.getFormFields();
                setFormFields( formFields );
                setFormData( formdata );
                setOrginData( formdata );
                setSquadName(formdata.nome);
                //Pegar os colaboradores
                const resp = await SimulacaoApiService.getAll(email).then(r => r.data);
                setRowsData(resp.data);
                setFilteredData(resp.data);

                setFilteredDataMySquad(formdata.squadColab);
                setSprintArray(formdata.squadColab);
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert.error(errorMsg);
            }
            finally {
                setIsLoading(false);

            }
        }
        getData();
    }
    // USEEFFECTS
    useEffect(onStart, []);

    const actions = [
        ...( !edit ? [
            {
                label: 'Editar',
                icon: <FaPen />,
                color: 'primary',
                type: 'button',
                onClick: toggleEdit
            }
        ]:[
            {
                label: 'Salvar',
                icon: <FaSave />,
                color: 'success',
                type: 'button',
                onClick: saveForm
            }
        ]),
        {
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            onClick: handleCancel
        }
    ]
    const title = `Montagem de Squad`;


    return (
        <PageContainer
        title={title}
        actions={actions}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                : ( originData &&
                    <>
                        <FormBuilder
                            formFields={formFields}
                            formData={formData}
                            onChange={onChange}
                            disabledForm={!edit}
                            initialValues={originData}
                        />
                        <Row className="appended-list">
                            <Col lg={6} className="valor-list-col">
                                <div>
                                    <h4>Adicionar colaborador</h4>
                                </div>
                                <ListLayout
                                    columns={getListHeader()}
                                    rows={defineDataList()}
                                    onSubmitFilter={filterList}
                                    optionInitialFilter="funcao_nome"
                                />
                            </Col>
                            <Col lg={6} className="valor-list-col">
                                <div className='mb-2 mt-2 text-right'>
                                    {tabs.length !== 1 && (
                                        <Button
                                            variant="outline-danger"
                                            className='d-inline-flex align-items-center'
                                            onClick={() => removeSprint()}
                                            disabled={!edit}
                                        >
                                            <FaTrash className='mr-1'/>
                                            Remover Sprint
                                        </Button>
                                    )}
                                    {tabs.length < 5 && (
                                        <Button
                                        variant="outline-primary"
                                        className='ml-2 d-inline-flex align-items-center'
                                        onClick={() => {
                                            setFilteredDataMySquad({ ...filteredDataMySquad, [tabs.length]: [] });
                                            setTabs([ ...tabs, tabs.length ]);
                                            setCurrentTab(tabs.length);
                                        }}
                                        disabled={!edit}
                                    >
                                        <FaPlus className='mr-1'/>
                                        Adicionar Sprint
                                    </Button>
                                    )}
                                </div>
                                <Tabs
                                    activeKey={currentTab}
                                    onSelect={(key) => setCurrentTab(key)}>
                                    {tabs.map((tab, idx) =>
                                        (
                                            <Tab key={idx} eventKey={tab} title={`Sprint ${Number(tab) + 1}`}>
                                                <ListLayout
                                                    columns={getListHeader(true)}
                                                    rows={defineDataListMySquad(currentTab)}
                                                />
                                            </Tab>
                                        ))}
                                </Tabs>
                            </Col>
                        </Row>
                    </>
                )
            }
        </PageContainer>
    )
}

export default SimulacaoTeamDetalhes;
