import * as Yup from 'yup';
import ValidationService from './validation';

// id, usuario, *nome, *email, *observacao
// novaSenha, confirma -> se preenchido novaSenha, ambos obrigatório
const schema = Yup.object().shape({
    nome: Yup
        .string()
        // .required("Campo obrigatório.")
        .max(60, "Nome deve possuir no máximo 60 dígitos."),

    email: Yup
        .string()
        .required("Campo obrigatório.")
        .email("E-mail preenchido é inválido."),
    
    observacao: Yup
        .string()
        .max(300, "Limite máximo de 300 caracteres."),


    altera: Yup.bool(),
    
    novaSenha: Yup
        .string()
        .test({
            name: 'min-6-digitos',
            message: 'Senha deve possuir no mínimo 6 dígitos.',
            test: (value, context) => {
                if (!context.parent.altera) return true;
                // if (!String(value).length) return true;
                return (![undefined].includes(value) && String(value).length >= 6);
            }
        }),

    confirma: Yup
        .string()
        .test({
            name: 'confirma-novaSenha-igual',
            message: 'Senhas não conferem.',
            test: (value, context) => {
                if (!context.parent.altera) return true;
                // if (!String(context.parent.novaSenha).length) return true;
                return (![undefined].includes(value) && value === context.parent.novaSenha);
            }
        }),

});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const UsuarioValidation = {
    schema,
    validate,
    castValues
};

export default UsuarioValidation;