import api from "./api";

const BASE_ENDPOINT = '/regime';

/** Traz uma lista das funcões cadastradss */
function getAll() {
    return api.get(`${BASE_ENDPOINT}/list`);
}

/**
 * Traz dados de um regime pelo seu codigo, e se ativo/inativo
 * @param {number | string} cod codigo da regime
 * @param {number | string} ativo 1=ativo, 0=inativo
 */
function getByCod(cod, ativo) {
    return api.get(
        `${BASE_ENDPOINT}/${cod}`,{
        params: { ativo }
    });
}

/** Cria um novo regime no database */
function createNew(form) {
    return api.post(
        `${BASE_ENDPOINT}/create`,
        {...form}
    );
}

/** Atualiza os dados de um regime */
function updateOne(cod, form) {
    return api.put(`${BASE_ENDPOINT}/update/${cod}`, { ...form })
}

// REGIME-IMPOSTO
/**
 * Traz dados dos regimes associados ao código do imposto
 * @param {number | string} impostoCod codigo do imposto
 */
function getByImpostoCod(impostoCod) {
    return api.get(`${BASE_ENDPOINT}/imposto/${impostoCod}`);
}

/** Adiciona uma nova associação de regime a imposto no database */
function addRegimeImposto(imposto_cod, regime_cod) {
    return api.post(
        `${BASE_ENDPOINT}/imposto/${imposto_cod}`,
        { regime_cod }
    );
}

/** Atualiza os dados de uma associação de regime a imposto no database */
function updateRegimeImposto(imposto_cod, form) {
    return api.put(`${BASE_ENDPOINT}/imposto/${imposto_cod}`, { ...form })
}

const RegimeApiService = {
    getAll,
    getByCod,
    createNew,
    updateOne,
    getByImpostoCod,
    addRegimeImposto,
    updateRegimeImposto,
}

export default RegimeApiService;