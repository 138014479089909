import React, { useCallback, useEffect, useState } from 'react';
import ListLayout from '../../../components/ListLayout';
import Loading from '../../../components/Loading';
import { columns } from './SimulacaoTeamColabInfo';
import ErrorRequestService from '../../../services/errorRequest';
import useAlertNotification from '../../../context/AlertNotificationContext';
import { Accordion, Button, Card } from 'react-bootstrap';
import UtilService from '../../../services/util';

const INITIAL_PAGEPARAMS = {
    orderBy: 'id',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function ColabListView({item}) {
    // CONTEXT
    const alert = useAlertNotification();

    const [rowsData, setRowsData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);

    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    const defineDataList = (sprintNumber) => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData[sprintNumber] || [];
        rows.sort(sortRows).forEach(item => {
            const row = {
                data: columns.map(col => {
                    if (col.name !== "id" && col.name !== "funcao_nome") {
                        return {
                            value: parseFloat(item[col.name])
                                .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                            className: col.className
                        };
                    }
                    return {
                        value: item[col.name],
                        className: col.className
                    };
                })
            };
            dataList.push(row);
        });
        return dataList;
    };

    const getDataList = useCallback(async () => {
        setIsLoading(true);
        try {
            const { squadColab } = item.squad || {};
            if (squadColab) {
                setRowsData(squadColab);
                setFilteredData(squadColab);
            } else {
                setRowsData([]);
                setFilteredData([]);
            }
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    }, [item]);

    const exportDataInExcel = async () => {
        const transformFormatNumber = (number) => {
            return parseFloat(parseFloat(number).toFixed(2));
        };

        const header = [
            [
                'Nº sprint', 'Quantidade', 'Função', 'Regime', 'Filial', 'Benefícios', 'Impostos',
                'Dependentes', 'Salário', 'Custo Folha', 'Salário Líquido',
                'Custo Total', 'Custo Hora', 'Custo Hora Meio Período',
                'Provisão 13º Salário', 'Provisão Férias', 'Provisão Rescisão'
            ]
        ];

        let formattedData = [];
        Object.keys(rowsData || {}).forEach(sprint => {
            rowsData[sprint].forEach(item => {
                formattedData.push({
                    sprint: item['sprint'] + 1,
                    quantidade: item['quantidade'],
                    funcaoNome: item['funcao_nome'],
                    regimeSigla: item['regime_sigla'],
                    filialLabel: item['filial_label'],
                    beneficioTotal: transformFormatNumber(item["beneficio_total"]),
                    impostoTotal: transformFormatNumber(item["imposto_total"]),
                    dependentes: item['dependentes'],
                    salarioBruto: transformFormatNumber(item["salario_bruto"]),
                    slarioFilial: transformFormatNumber(item["salario_filial"]),
                    salarioLiquido: transformFormatNumber(item["salario_liquido"]),
                    custoTotal: transformFormatNumber(item["custo_total"]),
                    custoHH: transformFormatNumber(item["custo_hora_colaborador"]),
                    custoHHMeioPeriodo: transformFormatNumber(item["custo_hora_colaborador_meio_periodo"]),
                    provisaoDecimoTerceiro: transformFormatNumber(item["provisao_decimo_terceiro"]),
                    provisaoFerias: transformFormatNumber(item["provisao_ferias"]),
                    provisaoRescisao: transformFormatNumber(item["provisao_rescisao"])
                });
            });
        });

        UtilService.exportXls(formattedData, item.squad.nome, header);
    };

    useEffect(() => {
        if (!rowsData) {
            getDataList();
        }
    }, [rowsData, getDataList]);

    return (
        <>
            {isLoading
                ? <Loading message="Carregando..." />
                : (
                    <>
                        <Button onClick={exportDataInExcel} className="mb-2">Exportar</Button>
                        <Accordion>
                            {Object.keys(filteredData || {}).map(item => (
                                <Card key={`sprint-${item}`}>
                                    <Accordion.Toggle as={Card.Header} eventKey={item}>
                                        {`Sprint ${Number(item) + 1}`}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={item}>
                                        <ListLayout
                                            columns={getListHeader()}
                                            rows={defineDataList(item)}
                                        />
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    </>
                )}
        </>
    );
}

export default ColabListView;
