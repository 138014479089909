import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import AuthApiService from '../services/api/AuthApiService';
import PasswordApiService from '../services/api/PasswordApiService';
import LocalStorageService from '../services/localStorage';
import { USER_LOGIN, USER_LOGOUT } from '../store/types';


/** Context para autenticação de usuário */
const AuthContext = createContext();

/** "Provider" para autenticação de usuário */
export const AuthProvider = ({ children }) => {
    // STATE
    const [userData, setUserData] = useState({});

    // STORE REDUX
    const dispatch = useDispatch();
    const storeUserRedux = useCallback(
        ({token, user}) => dispatch({
            type: USER_LOGIN, 
            payload: {token, user}
        }),
        [dispatch]
    );

    const removeUserRedux = useCallback(
        () => dispatch({ type: USER_LOGOUT }),
        [dispatch]
    );

    /** Faz processo de login de usuário no AuthContext */
    const signIn = useCallback(({ token, refresh_token, user }) => {
        LocalStorageService.storeUserDataLocal({ token, refresh_token, user });
        setUserData({ token, refresh_token, user });
        storeUserRedux({ token, refresh_token, user });
    }, [setUserData]);

    /** Faz processo de logout de usuário no AuthContext */
    const signOut = useCallback(async () => {
        const { token, refresh_token } = LocalStorageService.getUserDataLocal();
        AuthApiService.logout(token, refresh_token);
        removeUserRedux();
        setUserData({});
        LocalStorageService.removeUser();

    }, [setUserData]);

    /** Atualiza dados do usuário onde for preciso */
    const updateUser = useCallback((user) => {
        const { token } = userData;
        setUserData({ token, user });
        storeUserRedux({ token, user });
        LocalStorageService.storeUserDataLocal({ token, user});
    }, [setUserData, userData, storeUserRedux]);

    /** Ao iniciar aplicação */
    const onStart = useCallback(async () => {
        const { token, user } = LocalStorageService.getUserDataLocal();
        // console.log("[ AuthProvider ] onStart:", {user, token});
        if (!!token && !!user) {
            const resp = await PasswordApiService.checkToken(token);
            const { status, errors } = resp.data;
            const isOk = (status === 'ok');
            if (!!errors || !isOk) {
                signOut();
                return;
            }
            signIn({ token, user});
        }
    }, []);

    // EFFECTS
    useEffect(onStart, []);

    // RENDER
    const { user } = userData;
    // console.log("[ AuthProvider ] render:", user);
    return (
        <AuthContext.Provider
            value={{ 
                user, 
                signIn, 
                signOut, 
                updateUser
            }}
        >
            { children }
        </AuthContext.Provider>
    );
};

/** Provê accesso o contexto de usuário, com suas propriedades e functions */
export function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}

export default useAuth;