/** 
 * Informações das colunas para listagem de regimes associados ao imposto
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>} 
 */
export const columns = [
    {
        label: 'Sigla',
        name: 'sigla',
        width: '25%',
    },
    {
        label: 'Regime',
        name: 'nome',
    },
];

const RegimeImpostoListInfo = { columns };

export default RegimeImpostoListInfo;