/**
 * Informações das colunas para lista
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>}
*/
export const columns = [
    {
        label: 'Id',
        name: 'id',
        width: '5%',
    },
    {
        label: 'Função',
        name: 'funcao_nome',
        width: '25%'
    },
    {
        label: 'Custo HH',
        name: 'custo_hora_colaborador',
    },
    {
        label: 'Benefício total',
        name: 'beneficio_total',
    },
    {
        label: 'Salário bruto',
        name: 'salario_bruto',
    },
    {
        label: 'Custo total',
        name: 'custo_total',
    },

];


const SimulacaoListInfo = { columns };

export default SimulacaoListInfo;
