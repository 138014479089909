import * as Yup from 'yup';
import ValidationService from './validation';

// nome, email, senha, confirma, observacao
const schema = Yup.object().shape({
    nome: Yup
        .string()
        .required("Campo obrigatório."),
    
    email: Yup
        .string()
        .required("Campo obrigatório.")
        .email("Digite um e-mail válido"),

    senha: Yup
        .string()
        .required("Campo obrigatório")
        .min(6, "Senha deve possuir no mínimo 6 dígitos."),
    
    confirma: Yup
        .string()
        .required("Campo obrigatório")
        .test({
            name: 'senha-igual',
            message: 'Senha não confere.',
            test: (value, context) => {
                return (value === context.parent.senha);
            }
        }),
    
    // mensagem: Yup
    //     .string()
    //     .required("Campo obrigatório")
    //     .max(300, "Limite máximo de 300 caracteres."),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema);
}

const CadastrarLoginValidation = {
    schema,
    validate,
    castValues
};

export default CadastrarLoginValidation;