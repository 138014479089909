/** 
 * Informações das colunas para listagem de faixas de imposto
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>} 
 */
export const columns = [
    {
        label: 'Base Aliquota',
        name: 'base_aliq_faixa',
        width: '20%',
        className: 'd-none d-md-table-cell',
    },
    {
        label: 'Aliquota Faixa',
        name: 'aliq_faixa',
        width: '20%',
        className: '',
    },
    {
        label: '',
        name: 'compara_inicio',
        width: '5%',
        className: 'd-none d-md-table-cell',
    },
    {
        label: 'Início',
        name: 'valor_faixa_inicio',
        className: '',
    },
    {
        label: '',
        name: 'compara_fim',
        width: '5%',
        className: 'd-none d-md-table-cell',
    },
    {
        label: 'Fim',
        name: 'valor_faixa_fim',
        className: '',
    },
];


const FaixaImpostoListInfo = { columns };

export default FaixaImpostoListInfo;