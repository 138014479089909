import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'
import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { FormBuilder, FormStateBuilder } from '../../../components/FormBuilder';
import PageContainer from '../../../components/PageContainer';
import Loading from '../../../components/Loading';
import GraficoBarras from '../../../components/GraficoBarras';
import GraficoPie from '../../../components/GraficoPie';
import CustomModal from '../../../components/CustomModal';
import FiltroInfo from '../ProjetoView/FiltroInfo';
import SelectInfo from './SelectInfo';
// import SeparateProject from './SeparateProject';
import SeparateProjectTest from './SeparateProjectTest';
import ProjetoDashboardHelpModal from './ProjetoDashboardHelpModal';

import ErrorRequestService from '../../../services/errorRequest';
import ProjetosApiService from '../../../services/api/ProjetosApiService';
import UtilService from '../../../services/util';

import './ProjetoDashboard.css';
import '../../DashboardPage/DashboardPage.css';
import FormatDataService from '../../../services/util/FormatData';

function ProjetoDashboard() {
    const match = useRouteMatch();

    const { key } = match.params;

    const [dataProjectInitial, setDataProjectInitial] = useState([]);
    const [dataProjectAbstract, setDataProjectAbstract] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isGettingExportData, setIsGettingExportData] = useState(false);
    const [formFields, setFormFields] = useState(null);
    const [formData, setFormData] = useState(null);
    const [origData, setOrginData] = useState(null);

    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());

    const [selectedDashboardType, setSelectedDashboardType] = useState('pie');
    const [selectedComparative, setSelectedComparative] = useState('custoTotal');
    const [barLabelName, setBarLabelName] = useState('Valor faturado');

    const handlerSepareProjects = () => {
        CustomModal.show({
            title: 'Selecione os projetos para comparar',
            body: (
                // <SeparateProject
                //     projects={dataProjectInitial}
                //     onSepareProject={(value) => handleChangeGrafics(value)}
                // />
                <SeparateProjectTest
                    projects={dataProjectInitial}
                    onSepareProject={(value) => handleChangeGrafics(value)}
                />
            )
        })
    }

    const handleChangeGrafics = (values) => {
        CustomModal.hide();
        setIsLoading(true);

        const initial = {
            name: '',
            timeSpentTotal: 0,
            timeSpentBillable: 0,
            custoHH: 0,
            custoTotal: 0
        }

        const reducedData = [];
        for (const project in values) {
            const reduced = values[project].reduce((accum, curr) => {
                const accumName = accum.name.length <= 0 ? curr.name : `${accum.name},${curr.name}`;
                const name = curr.checked === true ? accumName : accum.name;
                const timeSpentTotal = curr.checked === true ? accum.timeSpentTotal + curr.timeSpentTotal : accum.timeSpentTotal;
                const timeSpentBillable = curr.checked === true ? accum.timeSpentBillable + curr.timeSpentBillable : accum.timeSpentBillable;
                const custoHH = curr.checked === true ? accum.custoHH + curr.custoHH : accum.custoHH;
                const custoTotal = curr.checked === true ? accum.custoTotal + curr.custoTotal : accum.custoTotal;

                return {
                    name,
                    timeSpentTotal,
                    timeSpentBillable,
                    custoHH,
                    custoTotal
                }
            }, initial);

            reducedData.push(reduced);
        }

        const removedNoneData = reducedData.filter(item => item.name.length > 0);

        setDataProjectAbstract(removedNoneData);
        setIsLoading(false);
    }

    const handlerFilter = async () => {
        const initialDateAdjusted = format(utcToZonedTime(initialDate, 'America/Sao_Paulo'), 'dd/MM/yyyy');
        const finalDateAdjusted = format(utcToZonedTime(finalDate, 'America/Sao_Paulo'), 'dd/MM/yyyy');

        try {
            setIsLoading(true);

            const resp = await ProjetosApiService.getTimesProject(key, initialDateAdjusted, finalDateAdjusted);
            const { data } = resp.data;

            setDataProjectAbstract(data);
            setDataProjectInitial(data);
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert(errorMsg);
        } finally {
            setIsLoading(false);
        }
    }

    const getDataList = useCallback(async () => {
        try {
            setIsLoading(true);

            const formFields = FiltroInfo.getFormFields();
            const formData = FormStateBuilder(formFields);

            setOrginData(formData);
            setFormFields(formFields);
            setFormData(formData);

            await handlerFilter();
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert(errorMsg);

        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = async (ev) => {
        const { value, name } = ev.target;
        const newform = await { ...formData, [name]: value };

        setInitialDate(typeof newform.initialDate === 'string' ? `${newform.initialDate}T00:00` : newform.initialDate);

        const getDataFormat = new Date(newform.initialDate+"T12:00:00")
        if(getDataFormat.getDate() === 1 && typeof newform.finalDate != 'string') {
            const month = getDataFormat.getMonth()+1;
            const year = getDataFormat.getFullYear();
            const day = new Date(year, month, 0).getDate();

            if(month < 10) {
                setFinalDate(newform.finalDate = `${year}-0${month}-${day}`);
            } else {
                setFinalDate(newform.finalDate = `${year}-${month}-${day}`);
            }
        }else {
            setFinalDate(typeof newform.finalDate === 'string' ? `${newform.finalDate}T00:00` : newform.finalDate);
        }

        setFormData(newform);
    }

    const changeDashboardType = (value) => {
        setIsLoading(true);

        const selected = SelectInfo.SelectInAbstract.filter(item => item.key === 'custoTotal');
        setBarLabelName(selected[0].label);
        setSelectedComparative(selected[0].key);
        setSelectedDashboardType(value);

        setIsLoading(false);
    }

    const changeSelectedComparativeType = (value) => {
        setIsLoading(true);

        const selected = SelectInfo.SelectInAbstract.filter(item => item.key === value);
        setBarLabelName(selected[0].label);
        setSelectedComparative(value);

        setIsLoading(false);
    }

    const exportAllListInExcel = async () => {
        setIsGettingExportData(true);
        const header = [['Nome', 'Tempo Total', 'Tempo Billable', 'Custo HH Total', 'Custo Total']];

        const exportData = dataProjectAbstract.map(item => {
            return {
                name: item.name,
                timeSpentTotal: `${FormatDataService.hoursToMinutesInBR(item.timeSpentTotal)}`,
                timeSpentBillable: `${FormatDataService.hoursToMinutesInBR(item.timeSpentBillable)}`,
                custoHH: parseFloat(item.custoHH).toFixed(2).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                }),
                custoTotal: parseFloat(item.custoTotal).toFixed(2).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })
            }
        })

        UtilService.exportXls(exportData, 'Comparação de Projetos', header);
        setIsGettingExportData(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getDataList, []);

    return (
        <PageContainer
            title="Projeto Dashboard"
            infoButton={<ProjetoDashboardHelpModal />}
        >
            {formFields &&
                <>
                    <FormBuilder
                        formFields={formFields}
                        formData={formData}
                        onChange={onChange}
                        initialValues={origData}
                        disabledForm={isLoading}
                    />
                    <Button
                        variant="outline-primary"
                        style={{ marginRight: 10 }}
                        onClick={handlerFilter}
                        disabled={isLoading}
                    >Filtrar</Button>
                    <Button
                        variant="outline-primary"
                        style={{ marginRight: 10 }}
                        onClick={exportAllListInExcel}
                        disabled={isLoading || isGettingExportData}
                    >Exportar</Button>
                    <Button
                        variant="outline-primary"
                        onClick={handlerSepareProjects}
                        disabled={isLoading}
                    >Separar Projetos</Button>
                </>
            }

            {isLoading
                ? <Loading message="Carregando..." />
                : <>
                    <Row className="d-flex my-4">
                        <Col xs={6} md={3}>
                            <Form.Control
                                as="select"
                                value={selectedDashboardType}
                                onChange={(e) => changeDashboardType(e.target.value)}
                            >
                                {SelectInfo.SelectDashbordType.map((item) => (
                                    <option value={item.key} key={item.key}>
                                        {item.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col xs={6} md={3}>
                            <Form.Control
                                as="select"
                                value={selectedComparative}
                                onChange={(e) => changeSelectedComparativeType(e.target.value)}
                            >
                                {SelectInfo.SelectInAbstract.map((item) => (
                                    <option value={item.key} key={item.key}>
                                        {item.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>
                    {selectedDashboardType === 'pie' && (
                        <div className="project-grafico">
                            <GraficoPie
                                data={dataProjectAbstract}
                                title="Projetos"
                                dataKey={selectedComparative}
                                formatterTooltip={
                                    selectedComparative === 'timeSpentTotal' ? 'h' : undefined}
                            />
                        </div>
                    )}
                    {selectedDashboardType === 'bar' && (
                        <div className="project-grafico">
                            <GraficoBarras
                                data={dataProjectAbstract}
                                title={`Projeto: ${key}`}
                                barKey='name'
                                dataKey={selectedComparative}
                                barLabelName={barLabelName}
                                layout="horizontal"
                                variant="blue"
                                formatterTooltip={selectedComparative === 'timeSpentTotal' ? 'h' : undefined}
                                colorful
                            />
                        </div>
                    )}
                </>
            }
            <CustomModal />
        </PageContainer>
    )
}

export default ProjetoDashboard;
