import React, { useContext, useState } from 'react'
import { createContext } from "react";

// import logoDark from '..assets/img/vertigo-logo-dark.png';
// import logoLight from '..assets/img/vertigo-logo-light.png';
import logoConvert from '../assets/img/convert-logo.png';

const INITIAL_THEME = {
    dark: { logo: logoConvert, bg: 'dark' },
    light: { logo: logoConvert, bg: 'light' },
};

/** Context para theme */
const ThemeContext = createContext();

/** "Provider" para tema */
export function ThemeProvider({ children }) {
    // STATE
    const [theme, setTheme] = useState(INITIAL_THEME.light);

    /** Altera o tema corrente */
    const changeTheme = () => {
        const chosen = (INITIAL_THEME[theme.bg].bg === 'dark') ? 'light' : 'dark';
        setTheme(INITIAL_THEME[chosen]);
    };

    return (
        <ThemeContext.Provider
            value={{ 
                bg: theme.bg,
                logo: theme.logo,
                changeTheme
            }}
        >
            { children }
        </ThemeContext.Provider>
    )
}

/** Provê acesso ao context de tema */
export function useTheme(){
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error('useTheme deve estar dentro de ThemeProvider');
    }

    return context;
}

export default useTheme;
