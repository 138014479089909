import api from "./api";

const BASE_ENDPOINT = '/simulacao/margem';

function salvarSimulacaoMargem(payload) {
    return api.post(
        `${BASE_ENDPOINT}`,
        {...payload}
    );
}

function getAll(email) {
    return api.get(`${BASE_ENDPOINT}/${email}`);
}

function getMargemById(email, id) {
    return api.get(`${BASE_ENDPOINT}/${email}/${id}`);
}

function updateOneSimulacaoMargem(email, form) {
    return api.put(`${BASE_ENDPOINT}/${email}`, { ...form });
}

function deleteSimulacaoMargem(email, payload) {
    return api.patch(
        `${BASE_ENDPOINT}/${email}/change-active`,
        {...payload}
    );
}

function calculateMargem(form) {
    return api.post(`${BASE_ENDPOINT}/simulator`, { ...form });
}

const SimulacaoMargemApiService = {
    salvarSimulacaoMargem,
    getAll,
    getMargemById,
    updateOneSimulacaoMargem,
    deleteSimulacaoMargem,
    calculateMargem,
}

export default SimulacaoMargemApiService;
