import React from 'react';
import { Navbar, Image, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaAdjust, FaBars, FaRegSun } from 'react-icons/fa';
import useAuth from '../../context/AuthContext';
import useTheme from '../../context/ThemeContext';
import './HeaderCustom.css';
import Avatar from '../Avatar';

const COLLAPSE_ID = 'header-navbar'

const NavToggler = ({ cssCustom }) => (
    <Navbar.Toggle 
        className={`btn btn-outline-light ${cssCustom ? cssCustom : ''}`}
        aria-controls={COLLAPSE_ID}
    >
        <FaBars />
    </Navbar.Toggle>
)

const NavButtonTheme = ({ changeTheme, cssCustom }) => (
    <Nav.Item className={cssCustom ? cssCustom : ''}>
        <Button 
            variant="outline-primary" 
            onClick={changeTheme}
        > 
            <FaAdjust />
        </Button>
    </Nav.Item>
)

const NavAvatar = ({ cssCustom, onClick }) => (
    <Nav.Item className={`nav-avatar ${cssCustom ? cssCustom : ''}`}>
        <Avatar onClick={onClick} />
    </Nav.Item>
)

function HeaderCustom({ navRight, navLeft }) {
    // CONTEXT
    const themeContext = useTheme();
    const { user, signOut } = useAuth();

    return (
        <Navbar 
            className="header-custom shadow" 
            collapseOnSelect 
            bg={themeContext.bg} 
            variant={themeContext.bg} 
            expand="md"
            sticky="top"
        >
            <Navbar.Brand 
                as={Link} 
                to="/" 
                className="navbar-logo order-first"
            >
                <Image 
                    src={themeContext.logo} 
                    height={56} 
                />
            </Navbar.Brand>

            {user && (
                <React.Fragment>
                    <Nav className="flex-row">
                        { navLeft && navLeft.map((item, k) => (
                            <Nav.Item key={`header-navitem-left-${k}`}>
                                { item }
                            </Nav.Item>
                        )) }
                    </Nav>

                    <Nav className="flex-row order-12 order-md-last">
                        {(navRight && navRight.length > 0) && 
                            <NavToggler />
                        }
                        <NavAvatar />
                    </Nav>

                    <Navbar.Collapse 
                        id={COLLAPSE_ID} 
                        className="header-collapse order-last order-md-12"
                    >
                        <Nav>
                            { navRight && navRight.map((item, k) => (
                                <Nav.Item key={`header-navitem-center-${k}`}>
                                    { item }
                                </Nav.Item>
                            )) }
                            {/* <NavButtonTheme changeTheme={themeContext.changeTheme} /> */}
                        </Nav>
                    </Navbar.Collapse>
                </React.Fragment>
            )}
            
        </Navbar>
    );
}

HeaderCustom.defaultProps = {
    navRight: [],
    navLeft: [],
}

export default HeaderCustom;
