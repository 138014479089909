/** 
 * Informações das colunas para lista de filiais
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>} 
*/
export const columns = [
    {
        label: 'Cod.',
        name: 'cod',
        width: '10%',
        className: '',
    },
    {
        label: 'CNPJ',
        name: 'cnpj',
        width: '20%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Identificação',
        name: 'label',
        width: '20%',
    },
    // {
    //     label: 'Estado',
    //     name: 'estado',
    //     // width: '10%',
    // },
];


const FilialListInfo = { columns };

export default FilialListInfo;
