import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import { FaSave } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FormBuilder, FormStateBuilder } from '../../../../components/FormBuilder';
import PageContainer from '../../../../components/PageContainer';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../../services/errorRequest';
import BeneficioValidation from '../../../../services/validation/BeneficioValidation';
import BeneficioApiService from '../../../../services/api/BeneficioApiService';
import BeneficioNewInfo from './BeneficioNewInfo';

function BeneficioNew(props) {
    // CONTEXT
    const history = useHistory();
    const match = useRouteMatch();
    const alert = useAlertNotification();

    // STATE
    const [origData, setOrginData] = useState(null);
    const [formFields, setFormFields] = useState(null);
    const [formData, setFormData] = useState(null);
    const [isEdited, setIsEdited] = useState(false);

    // FUNCTIONS
    const isFormEdited = () => {
        const jsonOrigData = JSON.stringify(origData);
        const jsonFormData = JSON.stringify(formData);
        return (jsonOrigData !== jsonFormData);
    }

    const askForLeave = (cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            const msg = 'Foram adicionados dados!\nDeseja realmente sair e perdê-los?';
            const leave = window.confirm(msg);
            
            if (leave) cbPositive();
            else cbNegative();
        }   
        return false;
    }
    
    const saveForm = async() => {
        try {
            // valida os dados preenchidos
            const validation = await BeneficioValidation.validate(formData);
            if (!validation.isValid) {
                alert.error("Erros no formulário!");
                return;
            }
            
            // se ok, enviar dados p/ API
            const result = await BeneficioApiService.createNew(formData);

            if (result.data.result) {
                const { insertId } = result.data.result;
                setIsEdited(false);
                alert.success("Criado novo benefício codigo: "+ insertId);
                // history.push(`${match.path.replace('/new','')}/${insertId}`);
                history.push( match.path.replace('/new','') );
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                history.goBack()
            });
            return;
        }
        history.goBack();
    }

    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setFormData(newform);
    }

    const onEdit = () => {
        if (isFormEdited() && !isEdited) {
            setIsEdited(true);
        }
    }

    const onStart = () => {
        try {
            const formFields = BeneficioNewInfo.getFormFields();
            const formData = FormStateBuilder(formFields );
            setOrginData( formData );
            setFormFields( formFields );
            setFormData( formData );
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    }

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(onEdit, [formData])

    const actions = [
        { 
            label: 'Salvar',
            icon: <FaSave />,
            color: 'success',
            type: 'button',
            onClick: saveForm
        },
        { 
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            onClick: handleCancel
        },
    ]
    
    const title = `Novo Benefício`;

    return (
        <PageContainer
            title={title}
            actions={actions}
        >
            {formData && 
                <FormBuilder 
                    formFields={formFields}
                    formData={formData}
                    onChange={onChange}
                    initialValues={origData}
                    schema={BeneficioValidation.schema}
                />
            }
            
            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </PageContainer>
    )
}

export default BeneficioNew;
