import React, { useEffect, useState } from "react";
import { Prompt, useHistory, useRouteMatch } from "react-router-dom";
import { FaPen, FaRedo, FaSave } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { FormBuilder } from "../../../components/FormBuilder";
import PageContainer from "../../../components/PageContainer";
import Loading from "../../../components/Loading";
import SimulacaoApiService from "../../../services/api/SimulacaoApiService";
import ErrorRequestService from "../../../services/errorRequest";
import useAlertNotification from "../../../context/AlertNotificationContext";
import SimulacaoViewInfo from "./SimulacaoViewInfo";
import useAuth from "../../../context/AuthContext";
import SimulacaoResult from "./SimulacaoResult";
import SimulacaoValidation from "../../../services/validation/SimulacaoValidation";

function SimulacaoView(props) {
    // CONTEXT
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();

    const { user } = useAuth();
    const { email } = user;

    // // PROPS
    const { id } = match.params;

    // // STATE
    const [origData, setOrignData] = useState(null);
    const [formFields, setFormFields] = useState({});
    const [formData, setFormData] = useState(null);
    const [formResult, setFormResult] = useState(null);
    const [edit, setEdit] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCalculate, setIsCalculate] = useState(false);

    // FUNCTIONS
    const isFormEdited = () => {
        const jsonOrigData = JSON.stringify(origData);
        const jsonFormData = JSON.stringify(formData);
        return edit && jsonOrigData !== jsonFormData;
    };

    const askForLeave = (cbPositive, cbNegative = () => {}) => {
        if (cbPositive instanceof Function) {
            const msg =
                "Foram feitas alterações!\nDeseja realmente sair e perdê-las?";
            const leave = window.confirm(msg);

            if (leave) cbPositive();
            else cbNegative();
        }
        return false;
    };

    const saveForm = async () => {
        try {
            const validation = await SimulacaoValidation.validate(formData)

            //mostra possiveis erros
            if (!validation.isValid) {
                alert.error("Formulário possui erros");
                return;
            }

            if (isEdited && !isCalculate) {
                alert.error("É necessário recalcular antes de salvar");
                return;
            }

            const data = { ...formResult };
            data.funcao = formData.funcao;

            // se ok, enviar p/ API
            const resp = await SimulacaoApiService.updateOne(email, id, data);
            if (resp.data) {
                setIsEdited(false);
                setEdit(false);
                alert.success("Dados da simulação atualizados com sucesso!");
                history.push(match.url.replace(`/${id}`, ""));
            }
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    };

    const toggleEdit = () => {
        if (isEdited) {
            askForLeave(() => {
                setFormData(origData);
                setEdit(!edit);
            });
            return;
        }
        setEdit(!edit);
    };

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                setFormData(origData);
                setEdit(!edit);
            });
            return;
        }
        if (edit) {
            setEdit(!edit);
            return;
        }
        history.goBack();
    };

    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = { ...formData, [name]: value };
        setIsCalculate(false);
        setFormData(newform);
    };

    const onEdit = () => {
        if (isFormEdited() && !isEdited) setIsEdited(true);
    };

    const onStart = () => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const [formFields, formData] = await Promise.all([
                    SimulacaoViewInfo.getFormFields(),
                    SimulacaoApiService.getById(email, id).then((resp) => {
                        const { data } = resp.data;
                        return data[0];
                    }),
                ]);
                setFormFields(formFields);

                const form = {
                    salario: formData["salario_bruto"],
                    beneficio: formData["beneficio_total"],
                    dependentes: formData["dependentes"],
                    regime: formData["regime_cod"],
                    funcao: formData["funcao_cod"],
                    filial: formData["filial_cod"]
                };
                setOrignData(form);
                setFormData(form);

                setFormResult({
                    funcao: formData["funcao_nome"],
                    beneficioTotal: formData["beneficio_total"],
                    custoHH: formData["custo_hora_colaborador"],
                    custoHHMeioPeriodo: formData["custo_hora_colaborador_meio_periodo"],
                    custoTotal: formData["custo_total"],
                    impostoTotal: formData["imposto_total"],
                    provisaoDecimoTerceiro: formData["provisao_decimo_terceiro"],
                    provisaoFerias: formData["provisao_ferias"],
                    provisaoRescisao: formData["provisao_rescisao"],
                    salarioBruto: formData["salario_bruto"],
                    salarioFilial: formData["salario_filial"],
                    salarioLiquido: formData["salario_liquido"]
                });

            } catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert.error(errorMsg);
            } finally {
                setIsLoading(false);
            }
        }
        getData();
    };

    const calculate = async () => {
        try {
            // validar form
            const validation = await SimulacaoValidation.validate(formData)

            //mostra possiveis erros
            if (!validation.isValid) {
                alert.error("Formulário possui erros");
                return;
            }

            const result = await SimulacaoApiService.calculateSimulation(formData);
            setFormResult({
                ...result.data,
                regime: formData.regime,
                filial: formData.filial,
                dependentes: formData.dependentes
            });
            setIsCalculate(true);
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    };

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(onEdit, [formData]);

    const actions = [
        ...(!edit
            ? [
                  {
                      label: "Editar",
                      icon: <FaPen />,
                      color: "primary",
                      type: "button",
                      onClick: toggleEdit,
                  },
              ]
            : [
                  {
                      label: "Salvar",
                      icon: <FaSave />,
                      color: "success",
                      type: "button",
                      onClick: saveForm,
                  },
              ]),
        {
            label: "Cancelar",
            icon: <MdClose />,
            color: "danger",
            type: "button",
            onClick: handleCancel,
        },
        {
            label: "Recalcular",
            icon: <FaRedo />,
            color: "info",
            type: "button",
            onClick: calculate,
        },
    ];

    const title = `Simulação - ${id}`;

    return (
        <PageContainer title={title} actions={actions}>
            {isLoading ? (
                <Loading message="Carregando..." />
            ) : (
                origData && (
                    <>
                        <FormBuilder
                            formFields={formFields}
                            formData={formData}
                            onChange={onChange}
                            disabledForm={!edit}
                            initialValues={origData}
                            // schema={DepartamentoValidation.schema}
                        />
                        <SimulacaoResult formResult={formResult} />
                    </>
                )
            )}
            <Prompt
                when={isEdited}
                message={(location) =>
                    askForLeave(() => {
                        setIsEdited(false);
                        setTimeout(() => {
                            history.push(location.pathname);
                        }, 100);
                    })
                }
            />
        </PageContainer>
    );
}

export default SimulacaoView;
