import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

import CustomModal from '../../../../components/CustomModal';
import ListLayout from '../../../../components/ListLayout';
import Loading from '../../../../components/Loading';
import PageContainer from '../../../../components/PageContainer';

import useAlertNotification from '../../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../../services/errorRequest';
import ImpostoApiService from '../../../../services/api/ImpostoApiService';
import RegimeApiService from '../../../../services/api/RegimeApiService';
import UtilService from '../../../../services/util';

import ImpostoView from '../ImpostoView';
import ImpostoValorForm from '../ImpostoValorForm';
import RegimeImpostoForm from '../../Regime/RegimeImpostoForm';
import ImpostoFaixaForm from '../ImpostoFaixaForm';
import FaixaImpostoListInfo from './FaixaImpostoListInfo';
import RegimeImpostoListInfo from './RegimeImpostoListInfo';
import ValorImpostoListInfo from './ValorImpostoListInfo';

import './ImpostoPage.css';

let editable = false;

function ImpostoPage() {
    // CONTEXT
    const match = useRouteMatch();
    const location = useLocation();
    const alert = useAlertNotification();

    const { cod } = match.params;
    const { sigla } = location.state;

    // STATE
    const [edit, setEdit] = useState(false);
    const [dados, setDados] = useState(null);
    const [dadosRegimes, setDadosRegimes] = useState(null);
    const [dadosFaixas, setDadosFiliais] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    const toggleEdit = (value) => {
        if (edit !== value) setEdit(value);
    }

    const onSaveForms = useCallback(async () => {
        CustomModal.hide();
        setEdit(false);
        await onStart();
    }, []);

    const onCancelForms = useCallback(() => CustomModal.hide(), []);

    const openValorFormModal = useCallback((row) => {
        CustomModal.show({
            body: (
                <ImpostoValorForm
                    dados={row}
                    onSave={() => onSaveForms()}
                    onCancel={() => onCancelForms()}
                    editable={editable}
                />
            )
        });
    }, [onSaveForms, onCancelForms]);

    const openRegimeFormModal = useCallback((row) => {
        CustomModal.show({
            body: (
                <RegimeImpostoForm
                    dados={row}
                    onSave={() => onSaveForms()}
                    onCancel={() => onCancelForms()}
                    editable={editable}
                />
            )
        });
    }, [onSaveForms, onCancelForms]);

    const openFaixaFormModal = useCallback((row) => {
        CustomModal.show({
            body: (
                <ImpostoFaixaForm
                    dados={row}
                    onSave={() => onSaveForms()}
                    onCancel={() => onCancelForms()}
                    editable={editable}
                />
            )
        });
    }, [onSaveForms, onCancelForms]);

    const getFilialRows = useCallback(() => {
        if (!dados) return [];
        const { valores=[], filiais=[] } = dados;

        const rowsValorFilial = [...filiais].map(filial => {
            const valObj = valores.find(v => (v.filial_cod === filial.cod));
            return { ...filial, ...valObj };
        }).filter(r => r !== null);
        
        const rows = rowsValorFilial.map(item => {
            const row = {
                onClick: () => openValorFormModal(item),
                data: ValorImpostoListInfo.columns.map(col => {
                    if (['aliquota_desc','aliquota_filial'].includes(col.name)) {
                        return {
                            value: UtilService.maskPercentage(item[col.name]),
                            className: col.className || ''
                        }
                    }
                    if (['valor_fixo'].includes(col.name)) {
                        return {
                            value: UtilService.maskCurrency(item[col.name]),
                            className: col.className || ''
                        }
                    }
                    return {
                        value: item[col.name],
                        className: col.className || ''
                    }
                })
            }
            return row;
        });

        return rows;
    }, [dados, openValorFormModal]);

    const getRegimeRows = useCallback(() => {
        if (!dadosRegimes) return [];
        const rows = dadosRegimes.map(item => {
            const row = {
                onClick: () => openRegimeFormModal(item),
                data: RegimeImpostoListInfo.columns.map(col => ({
                    value: item[col.name],
                    className: col.className || ''
                }))
            }
            return row;
        });
        return rows;
    }, [dadosRegimes, openRegimeFormModal]);

    const getFaixaRows = useCallback(() => {
        if (!dadosFaixas) return [];
        const rows = dadosFaixas
            .sort((a,b) => a.valor_faixa_inicio - b.valor_faixa_inicio)
            .map(item => {
                const row = {
                    onClick: () => openFaixaFormModal(item),
                    data: FaixaImpostoListInfo.columns.map(col => {
                        if (['aliq_faixa'].includes(col.name)) {
                            return {
                                value: UtilService.maskPercentage(item[col.name]),
                                className: col.className || ''
                            }
                        }
                        if (['valor_faixa_inicio', 'valor_faixa_fim'].includes(col.name)) {
                            return {
                                value: UtilService.maskCurrency(item[col.name]),
                                className: col.className || ''
                            }
                        }
                        return {
                            value: item[col.name],
                            className: col.className || ''
                        }
                    })
                }
                return row;
            });
        return rows;
    }, [dadosFaixas]);

    const onStart = useCallback(async () => {
        try {
            setIsLoading(true);
            const [dadosImp, dadosRegimes, dadosFaixas] = await Promise.all([
                ImpostoApiService.getByCod(cod).then(r => r.data.data),
                RegimeApiService.getByImpostoCod(cod).then(r => r.data.data),
                ImpostoApiService.getFaixas(cod).then(r => r.data.data)
            ])
            setDadosFiliais(dadosFaixas)    // dados das faixas
            setDadosRegimes(dadosRegimes);  // dados dos regimes
            setDados(dadosImp);             // result contendo: imposto, valores, filiais
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    }, [cod]);

    const handleEdit = () => {
        editable = edit;
    };

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(handleEdit, [edit]);

    // RENDER
    const sectionHeadCss = "d-flex justify-content-between mb-3";
    const splitLine = {borderLeft:'1px solid lightgrey'};

    return (
        <div className="imposto-page">

            { isLoading && (
                <PageContainer title={`Imposto ${sigla ? `(${sigla})` : ''}`}>
                    <Loading message="Carregando..." />
                </PageContainer>
            )}

            { (!isLoading && dados !== null) && (
                <>
                    <ImpostoView
                        editable={edit}
                        onEditableChange={toggleEdit}
                        dados={dados}
                    />

                    <Row className="appended-list">
                        {/* Valores Filiais */}
                        <Col lg={6} className="valor-list-col">
                            <div className={sectionHeadCss}>
                                <h4>Valor por Filial</h4>
                                <Button 
                                    variant="outline-primary" 
                                    onClick={() => openValorFormModal()}
                                    disabled={!edit}
                                >
                                    <FaPlus/> Adic.Filial
                                </Button>
                            </div>
                            <ListLayout
                                key={`filiais-${location.key}`}
                                columns={ValorImpostoListInfo.columns}
                                rows={getFilialRows()}
                            />
                        </Col>

                        {/* Regimes */}
                        <Col lg={6} className="valor-list-col" style={splitLine}>
                            <div className={sectionHeadCss}>
                                <h4>Regimes Contr.</h4>
                                <Button 
                                    variant="outline-primary" 
                                    onClick={() => openRegimeFormModal()}
                                    disabled={!edit}
                                >
                                    <FaPlus/> Adic.Regime
                                </Button>
                            </div>
                            <ListLayout
                                key={`regimes-${location.key}`}
                                columns={RegimeImpostoListInfo.columns}
                                rows={getRegimeRows()}
                            />
                        </Col>
                    </Row>

                    <Row className="appended-list">
                        {/* Faixas de Imposto */}
                        <Col className="valor-list-col" >
                            <div className={sectionHeadCss}>
                                <h4>Faixas de Imposto</h4>
                                <Button 
                                    variant="outline-primary" 
                                    onClick={() => openFaixaFormModal()}
                                    disabled={!edit}
                                >
                                    <FaPlus/> Adic.Faixa
                                </Button>
                            </div>
                            <ListLayout
                                key={`faixas-${location.key}`}
                                columns={FaixaImpostoListInfo.columns}
                                rows={getFaixaRows()}
                            />
                        </Col>
                    </Row>
                </>
            )}

            <CustomModal />
        </div>
    )
}


export default ImpostoPage;
