import React from "react";
import { Button } from 'react-bootstrap';

// import UtilService from '../../../../services/util';

function HeaderDetailCustos(props) {
    const data = []

    return (
        <div style={{ marginTop: 30 }}>
            <h4>
                Custos Indiretos &nbsp;

                {
                    data.length > 0 &&
                    <Button onClick={() => console.log("Exportar")}
                    >
                        Exportar
                    </Button>
                }
            </h4>
        </div>
    );
}

export default HeaderDetailCustos;
