

import React, { useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FaPen, FaSave } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import ContentContainer from '../../../../components/ContentContainer';
import { FormBuilder, FormStateBuilder } from '../../../../components/FormBuilder';
import Loading from '../../../../components/Loading';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../../services/errorRequest';
import ValorImpostoApiService from '../../../../services/api/ValorImpostoApiService';
import ValorImpostoValidation from '../../../../services/validation/ValorImpostoValidation';
import UtilService from '../../../../services/util';
import ImpostoValorFormInfo from './ImpostoValorFormInfo';

/**
 * Formulário que adiciona os valores de um imposto para uma filial,
 * criando o relacionamento entre esse imposto e a filial
 */
function ImpostoValorForm({ dados, onSave, onCancel, editable=false }) {
    // CONTEXT
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const alert = useAlertNotification();
    
    // PROPS
    const { cod } = match.params;
    const { sigla=null } = location.state;

    // STATE
    const [origData, setOrginData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [formFields, setFormFields] = useState(null);
    const [edit, setEdit] = useState([]);
    const [isEdited, setIsEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    const isFormEdited = () => {
        if (!edit.length) return false;
        const jsonOrigData = JSON.stringify(origData);
        const jsonFormData = JSON.stringify(formData);
        return (jsonOrigData !== jsonFormData);
    }
        
    const askForLeave = (cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            const msg = 'Foram feitas alterações!\nDeseja realmente sair e perdê-las?';
            const leave = window.confirm(msg);
            
            if (leave) cbPositive();
            else cbNegative();
        }   
        return false;
    }

    const resetForm = () => UtilService.genericResetFormFunction();
    
    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        history.goBack();
    }

    const hanldeOnSave = () => {
        if (onSave) {
            onSave();
            return;
        } 
        const url = `${match.url.replace(`/${cod}`, '')}`;
        history.replace(url, location.state);
    }

    const saveForm = async () => {
        try {
            // validar form
            const validation = await ValorImpostoValidation.validate(formData);
            if (!validation.isValid) {
                alert.error('Erros no formulário!');
                return;
            }
            // se ok, enviar p/ API
            const form = {...formData, imposto_cod: cod};
            const resp = !dados // se novo, ou existente
                ? await ValorImpostoApiService.createNew({imposto_cod: cod, ...form})
                : await ValorImpostoApiService.updateOne(form.id, form);

            if (resp.data) {
                setIsEdited(false);
                // setEdit(false);
                alert.success(`Valores do imposto ${!dados ? 'criados' : 'atualizados'}!`);
                hanldeOnSave();
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                setFormData(origData);
                resetForm();
                handleOnCancel();
            });
            return;
        }
        handleOnCancel();
    }
    
    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setFormData(newform);
        setEdit([...edit, name]);
    }

    const onEdit = () => {
        if (isFormEdited() && !isEdited) setIsEdited(true);
    }

    const onStart = useCallback(async () => {
        try {
            let [ formCampos, formDados ] = await Promise.all([
                ImpostoValorFormInfo.getFormFields(),
                dados ? {...dados} : null
            ]);
            if (!formDados) {
                formDados = FormStateBuilder(formCampos);
            }
            setFormFields( formCampos );
            setFormData( formDados );
            setOrginData( formDados );
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    }, []);

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(onEdit, [formData]);

    const actions = [
        { 
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            onClick: handleCancel
        }
    ]

    if (editable) {
        actions.unshift(
            { 
                label: 'Salvar',
                icon: <FaSave />,
                color: 'success',
                type: 'button',
                onClick: saveForm
            }
        );
    }

    const title = `Valor de Imposto ${sigla ? `(${sigla})` : ''}`;

    return (
        <ContentContainer
            title={title}
            actions={actions}
        >
            { isLoading 
                ? <Loading message="Carregando..." />
                : ( origData && 
                    <FormBuilder 
                        formFields={formFields}
                        formData={formData}
                        onChange={onChange}
                        disabledForm={!editable}
                        initialValues={origData}
                        schema={ValorImpostoValidation.schema}
                    />
                )
            }
            
            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </ContentContainer>
    )
}

export default ImpostoValorForm;
