import React from "react";
import { Spinner } from "react-bootstrap";
import { FiArrowUpCircle, FiArrowDownCircle } from "react-icons/fi";
import "./CardDashboard.css";

function CardDashboard({ maior, menor, title }) {
    return (
        <section className="section subsection">
            {!maior || !menor ? (
                <>
                    <Spinner animation="border" style={{ margin: "0 10px" }} />
                    <h4>Calculando...</h4>
                </>
            ) : (
                <>
                    {" "}
                    <h3 className="title">
                        {title}
                        <hr />
                    </h3>
                    <span className="info up">
                        <h4>
                            <FiArrowUpCircle />
                            MAIOR
                        </h4>
                        <p>
                            {maior.label} |{" "}
                            <strong>
                                {maior.value.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </strong>
                        </p>
                    </span>
                    <span className="info down">
                        <h4>
                            <FiArrowDownCircle />
                            MENOR
                        </h4>
                        <p>
                            {menor.label} |{" "}
                            <strong>
                                {menor.value.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </strong>
                        </p>
                    </span>
                </>
            )}
        </section>
    );
}

export default CardDashboard;
