import React from 'react'
import { FaPlus } from 'react-icons/fa';
import { useRouteMatch } from 'react-router-dom';
import PageContainer from '../../../../components/PageContainer';
import ImpostoList from '../ImpostoList';

function ImpostosPage() {
    const match = useRouteMatch();

    const pageActions = [
        { 
            label: 'Novo',
            icon: <FaPlus />,
            color: 'primary',
            type: 'button',
            href: `${match.path}/new`,
        }
    ]

    const title = 'Lista de Impostos';

    return (
        <PageContainer
            title={title}
            actions={pageActions}
        >
            <ImpostoList />   
        </PageContainer>
    )
}

export default ImpostosPage
