import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

/**
 * Exporta list data para o formato .xls
 * @param csvData 
 * @param fileName 
 */
const exportXls = (csvData, fileName, header) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);

    XLSX.utils.sheet_add_aoa(ws, header);

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    
    FileSaver.saveAs(data, fileName + fileExtension);
}

/**
 * WebVitals from React default create-react-app
 * @param {Function} onPerfEntry 
 */
const reportWebVitals = onPerfEntry => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
            getCLS(onPerfEntry);
            getFID(onPerfEntry);
            getFCP(onPerfEntry);
            getLCP(onPerfEntry);
            getTTFB(onPerfEntry);
        });
    }
};

/**
 * Retorna o título geral exibido na aba ou barra de título
 */
function getTitle() {
    const appTitle = document.getElementById('app-title');
    const title = String(appTitle.innerText).split(' | ');

    return title[0];
}

/**
 * Define o título geral exibido na aba ou barra de título
 * @param {string} text Texto para o título
 */
function setTitle(text) {
    const DEFAULT_SUFIX = 'Convert';
    const title = [];
    
    if (text) title.push(String(text));
    title.push(DEFAULT_SUFIX);

    const appTitle = document.getElementById('app-title');
    appTitle.innerText = title.join(' | ');
}

/**
 * Retorna date num formato para a API
 * @param {string} dt date no formato dd-mm-yyyy
 */
function viewDateToApi(dt) {
    if (!dt) return dt;
    
    if (typeof(dt) === 'string') {
        return dt.split('/').reverse().join('-');
    }

    if (dt instanceof Date) {
        const locDt = dt.toLocaleDateString('pt-br');
        return locDt.substr(0, 10).split('/').reverse().join('-');
    }

    return dt;
}

/**
 * Retorna date num formato para a view
 * @param {string} dt date no formato Date
 */
function apiDateToView(dt) {
    if (!dt) return dt;

    if (typeof(dt) === 'string') {
        return dt.substr(0, 10).split('-').reverse().join('/');
    }
    
    if (dt instanceof Date) {
        const locDt = dt.toLocaleDateString('pt-br');
        return locDt.substr(0, 10).split('-').reverse().join('/');
    }

    return dt
}

const maskCurrency = (value) => {
    if(!value) return '0.00';

    const base =  parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1.');
    
    const inteiro = base.slice(0,base.length-3).replace(',','.');
    const decimais = base.slice(base.length-2);
    return  `${inteiro},${decimais}`;
}

/**
 * Mascara um valor com simbolo de percentual
 * @param {number | string} value valor a usar máscara
 */
const maskPercentage = (value) => {
    const floatVal = parseFloat(value);
    const decimalVal = floatVal * 100;
    const masked = `${parseFloat(decimalVal).toFixed(2)}%`;
    return masked;
} 

/**
 * Formata a data para os inputs
 * @param {Date} value 
 */
function maskDate(value) {
    if(!value) return '';
    if (!(value instanceof Date)) return '';
    
    value = value.toLocaleDateString('pt-BR');
    let mask = '__/__/____';
    const dt = value.split('-').reverse().join('').replace(/\D+/g, '').toString();
    for(let c of dt) {
        mask = mask.replace('_', c);
    }
    
    return mask;
}

/** Function for validation errors of forms */
function getValidationErrors(err) {
    const validationErrors = {};

    err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
    });

    return validationErrors;
}

/** Retorna Lista de UFs (estados) brasileiros */
function getUFs() {
    return [
        { uf: "AC", name: "Acre"},
        { uf: "AL", name: "Alagoas"},
        { uf: "AM", name: "Amazonas"},
        { uf: "AP", name: "Amapá"},
        { uf: "BA", name: "Bahia"},
        { uf: "CE", name: "Ceará"},
        { uf: "DF", name: "Distrito Federal"},
        { uf: "ES", name: "Espírito Santo"},
        { uf: "GO", name: "Goiás"},
        { uf: "MA", name: "Maranhão"},
        { uf: "MG", name: "Minas Gerais"},
        { uf: "MS", name: "Mato Grosso do Sul"},
        { uf: "MT", name: "Mato Grosso"},
        { uf: "PA", name: "Pará"},
        { uf: "PB", name: "Paraíba"},
        { uf: "PE", name: "Pernambuco"},
        { uf: "PI", name: "Piauí"},
        { uf: "PR", name: "Paraná"},
        { uf: "RJ", name: "Rio de Janeiro"},
        { uf: "RN", name: "Rio Grande do Norte"},
        { uf: "RO", name: "Rondônia"},
        { uf: "RR", name: "Roraima"},
        { uf: "RS", name: "Rio Grande do Sul"},
        { uf: "SC", name: "Santa Catarina"},
        { uf: "SE", name: "Sergipe"},
        { uf: "SP", name: "São Paulo"},
        { uf: "TO", name: "Tocantins"},
    ];
}

/**
 * Function que pode ser anexada para callback de `array.sort()`
 * @param {*} a 1ª param de sort
 * @param {*} b 2º param de sort
 * @param {string} orderBy atritbuto do objeto para ordernar 
 * @param {'asc' | 'desc'} orderDir direção para ordernar (asc, desc)
 */
function sortArrCallback(a, b, orderBy=null, orderDir="asc") {
    const ORDER_DIR_NUM = { asc: 1, desc: -1 };
    if (orderBy) {
        if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
        if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
    }
    else {
        if (a < b) return (-1 * ORDER_DIR_NUM[orderDir]);
        if (a > b) return (1 * ORDER_DIR_NUM[orderDir]);
    }
}

/** Para uso com Yup. Transforma valor "" do campo (orig) para o formato correto (curr) */
function yupTransform(curr, orig) {
    return (orig === '' ? null : curr)
};

/** Teste usado no YUP para situações de verificação se é number */
function yupTestIsNumber(value) {
    const isNull = [null, undefined].includes(value);
    if (isNull) return false;
    const regExp = /^[0-9]*$/g;
    const match = regExp.test(value);
    return match;
}

/** Function usado no YUP para situaçãoes em que campo é de porcentagem */
function yupIntFixPercentage(value) {
    return Number(value)/100;
}

/** Function auxiliar para Formik atribuir ação de reset do form em uso */
const genericResetFormFunction = () => {};

/**
 * Faz encode do valor recebido strign utf-8 em string base64
 * @param {string} value 
 */
const encodeUtf8AsBase64 = (value) => Buffer.from(value, 'utf-8').toString('base64');

/**
 * Faz decode do valor recebido string base64 em string utf-8 
 * @param {string} value 
 */
const decodeBase64AsUtf8 = (value) => Buffer.from(value, 'base64').toString('utf-8');

const UtilService = {
    reportWebVitals,
    getTitle,
    setTitle,
    viewDateToApi,
    apiDateToView,
    getValidationErrors,
    getUFs,
    sortArrCallback,
    yupTransform,
    yupTestIsNumber,
    yupIntFixPercentage,
    maskDate,
    maskCurrency,
    maskPercentage,
    genericResetFormFunction,
    encodeUtf8AsBase64,
    decodeBase64AsUtf8,
    exportXls
}

export default UtilService;
