import { 
    ALERT_NOTIFICATION_ADD, 
    ALERT_NOTIFICATION_REMOVE 
} from "../types"

export const addAlertNotification = (message, typeAlert) => {
    return {
        type: ALERT_NOTIFICATION_ADD,
        payload: {
            id: (new Date).getTime(), // workaroud inicial p/ id
            message, 
            typeAlert
        }
    }
}

export const removeAlertNotification = (id) => {
    return {
        type: ALERT_NOTIFICATION_REMOVE,
        payload: id
    }
}